 :root{--blue: #007bff;--indigo: #6610f2;--purple: #6f42c1;--pink: #e83e8c;--red: #dc3545;--orange: #fd7e14;--yellow: #ffc107;--green: #28a745;--teal: #20c997;--cyan: #17a2b8;--white: #fff;--gray: #6c757d;--gray-dark: #343a40;--primary: #5867dd;--secondary: #e1e1ef;--success: #0abb87;--info: #5578eb;--warning: #ffb822;--danger: #fd397a;--light: #f8f9fa;--dark: #343a40;--breakpoint-xs: 0;--breakpoint-sm: 576px;--breakpoint-md: 768px;--breakpoint-lg: 1024px;--breakpoint-xl: 1399px;--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;}
*, *::before, *::after{-webkit-box-sizing: border-box;box-sizing: border-box;}
html{font-family: sans-serif;line-height: 1.15;-webkit-text-size-adjust: 100%;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section{display: block;}
body{margin: 0;font-family: "Poppins";font-size: 1rem;font-weight: 400;line-height: 1.5;color: #212529;text-align: left;background-color: #fff;}
[tabindex="-1"]:focus{outline: 0 !important;}
hr{-webkit-box-sizing: content-box;box-sizing: content-box;height: 0;overflow: visible;}
h1, h2, h3, h4, h5, h6{margin-top: 0;margin-bottom: 0.5rem;}
p{margin-top: 0;margin-bottom: 1rem;}
abbr[title], abbr[data-original-title]{text-decoration: underline;-webkit-text-decoration: underline dotted;text-decoration: underline dotted;cursor: help;border-bottom: 0;-webkit-text-decoration-skip-ink: none;text-decoration-skip-ink: none;}
address{margin-bottom: 1rem;font-style: normal;line-height: inherit;}
ol, ul, dl{margin-top: 0;margin-bottom: 1rem;}
ol ol, ul ul, ol ul, ul ol{margin-bottom: 0;}
dt{font-weight: 700;}
dd{margin-bottom: .5rem;margin-left: 0;}
blockquote{margin: 0 0 1rem;}
b, strong{font-weight: bolder;}
small{font-size: 80%;}
sub, sup{position: relative;font-size: 75%;line-height: 0;vertical-align: baseline;}
sub{bottom: -.25em;}
sup{top: -.5em;}
a{color: #5867dd;text-decoration: none;background-color: transparent;}
a:hover{color: #2739c1;text-decoration: underline;}
a:not([href]):not([tabindex]){
color: inherit;text-decoration: none;}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus{color: inherit;text-decoration: none;}
a:not([href]):not([tabindex]):focus{outline: 0;}
pre, code, kbd, samp{font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;font-size: 1em;}
pre{margin-top: 0;margin-bottom: 1rem;overflow: auto;}
figure{margin: 0 0 1rem;}
img{vertical-align: middle;border-style: none;}
svg{overflow: hidden;vertical-align: middle;}
table{border-collapse: collapse;}
caption{padding-top: 0.75rem;padding-bottom: 0.75rem;color: #a7abc3;text-align: left;caption-side: bottom;}
th{text-align: inherit;}
label{display: inline-block;margin-bottom: 0.5rem;}
button{border-radius: 0;}
button:focus{outline: 1px dotted;outline: 5px auto -webkit-focus-ring-color;}
input, button, select, optgroup, textarea{margin: 0;font-family: inherit;font-size: inherit;line-height: inherit;}
button, input{overflow: visible;}
button, select{text-transform: none;}
select{word-wrap: normal;}
button, [type="button"], [type="reset"], [type="submit"]{-webkit-appearance: button;}
button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled){
cursor: pointer;}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner{padding: 0;border-style: none;}
input[type="radio"], input[type="checkbox"]{-webkit-box-sizing: border-box;box-sizing: border-box;padding: 0;}
input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"]{-webkit-appearance: listbox;}
textarea{overflow: auto;resize: vertical;}
fieldset{min-width: 0;padding: 0;margin: 0;border: 0;}
legend{display: block;width: 100%;max-width: 100%;padding: 0;margin-bottom: .5rem;font-size: 1.5rem;line-height: inherit;color: inherit;white-space: normal;}
progress{vertical-align: baseline;}
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button{height: auto;}
[type="search"]{outline-offset: -2px;-webkit-appearance: none;}
[type="search"]::-webkit-search-decoration{-webkit-appearance: none;}
::-webkit-file-upload-button{font: inherit;-webkit-appearance: button;}
output{display: inline-block;}
summary{display: list-item;cursor: pointer;}
template{display: none;}
[hidden]{display: none !important;}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{margin-bottom: 0.5rem;font-weight: 500;line-height: 1.2;}
h1, .h1{font-size: 2.5rem;}
h2, .h2{font-size: 2rem;}
h3, .h3{font-size: 1.75rem;}
h4, .h4{font-size: 1.5rem;}
h5, .h5{font-size: 1.25rem;}
h6, .h6{font-size: 1rem;}
.lead{font-size: 1.25rem;font-weight: 300;}
.display-1{font-size: 6rem;font-weight: 300;line-height: 1.2;}
.display-2{font-size: 5.5rem;font-weight: 300;line-height: 1.2;}
.display-3{font-size: 4.5rem;font-weight: 300;line-height: 1.2;}
.display-4{font-size: 3.5rem;font-weight: 300;line-height: 1.2;}
hr{margin-top: 1rem;margin-bottom: 1rem;border: 0;border-top: 1px solid rgba(0, 0, 0, 0.1);}
small, .small{font-size: 80%;font-weight: 400;}
mark, .mark{padding: 0.2em;background-color: #fcf8e3;}
.list-unstyled{padding-left: 0;list-style: none;}
.list-inline{padding-left: 0;list-style: none;}
.list-inline-item{display: inline-block;}
.list-inline-item:not(:last-child){
margin-right: 0.5rem;}
.initialism{font-size: 90%;text-transform: uppercase;}
.blockquote{margin-bottom: 1rem;font-size: 1.25rem;}
.blockquote-footer{display: block;font-size: 80%;color: #6c757d;}
.blockquote-footer::before{content: "\2014\00A0";}
.img-fluid{max-width: 100%;height: auto;}
.img-thumbnail{padding: 0.25rem;background-color: #fff;border: 1px solid #dee2e6;border-radius: 0.25rem;max-width: 100%;height: auto;}
.figure{display: inline-block;}
.figure-img{margin-bottom: 0.5rem;line-height: 1;}
.figure-caption{font-size: 90%;color: #6c757d;}
code{font-size: 87.5%;color: #e83e8c;word-break: break-word;}
a > code{color: inherit;}
kbd{padding: 0.2rem 0.4rem;font-size: 87.5%;color: #fff;background-color: #212529;border-radius: 0.2rem;}
kbd kbd{padding: 0;font-size: 100%;font-weight: 700;}
pre{display: block;font-size: 87.5%;color: #212529;}
pre code{font-size: inherit;color: inherit;word-break: normal;}
.pre-scrollable{max-height: 340px;overflow-y: scroll;}
.container{width: 100%;padding-right: 10px;padding-left: 10px;margin-right: auto;margin-left: auto;}
@media (min-width: 576px){
.container{max-width: 540px;}
}
@media (min-width: 768px){
.container{max-width: 720px;}
}
@media (min-width: 1024px){
.container{max-width: 960px;}
}
@media (min-width: 1399px){
.container{max-width: 1140px;}
}
.container-fluid{width: 100%;padding-right: 10px;padding-left: 10px;margin-right: auto;margin-left: auto;}
.row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin-right: -10px;margin-left: -10px;}
.no-gutters{margin-right: 0;margin-left: 0;}
.no-gutters > .col, .no-gutters > [class*="col-"]{padding-right: 0;padding-left: 0;}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{position: relative;width: 100%;padding-right: 10px;padding-left: 10px;}
.col{-ms-flex-preferred-size: 0;flex-basis: 0;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;max-width: 100%;}
.col-auto{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;width: auto;max-width: 100%;}
.col-1{-webkit-box-flex: 0;-ms-flex: 0 0 8.33333%;flex: 0 0 8.33333%;max-width: 8.33333%;}
.col-2{-webkit-box-flex: 0;-ms-flex: 0 0 16.66667%;flex: 0 0 16.66667%;max-width: 16.66667%;}
.col-3{-webkit-box-flex: 0;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%;}
.col-4{-webkit-box-flex: 0;-ms-flex: 0 0 33.33333%;flex: 0 0 33.33333%;max-width: 33.33333%;}
.col-5{-webkit-box-flex: 0;-ms-flex: 0 0 41.66667%;flex: 0 0 41.66667%;max-width: 41.66667%;}
.col-6{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%;}
.col-7{-webkit-box-flex: 0;-ms-flex: 0 0 58.33333%;flex: 0 0 58.33333%;max-width: 58.33333%;}
.col-8{-webkit-box-flex: 0;-ms-flex: 0 0 66.66667%;flex: 0 0 66.66667%;max-width: 66.66667%;}
.col-9{-webkit-box-flex: 0;-ms-flex: 0 0 75%;flex: 0 0 75%;max-width: 75%;}
.col-10{-webkit-box-flex: 0;-ms-flex: 0 0 83.33333%;flex: 0 0 83.33333%;max-width: 83.33333%;}
.col-11{-webkit-box-flex: 0;-ms-flex: 0 0 91.66667%;flex: 0 0 91.66667%;max-width: 91.66667%;}
.col-12{-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
.order-first{-webkit-box-ordinal-group: 0;-ms-flex-order: -1;order: -1;}
.order-last{-webkit-box-ordinal-group: 14;-ms-flex-order: 13;order: 13;}
.order-0{-webkit-box-ordinal-group: 1;-ms-flex-order: 0;order: 0;}
.order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.offset-1{margin-left: 8.33333%;}
.offset-2{margin-left: 16.66667%;}
.offset-3{margin-left: 25%;}
.offset-4{margin-left: 33.33333%;}
.offset-5{margin-left: 41.66667%;}
.offset-6{margin-left: 50%;}
.offset-7{margin-left: 58.33333%;}
.offset-8{margin-left: 66.66667%;}
.offset-9{margin-left: 75%;}
.offset-10{margin-left: 83.33333%;}
.offset-11{margin-left: 91.66667%;}
@media (min-width: 576px){
.col-sm{-ms-flex-preferred-size: 0;flex-basis: 0;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;max-width: 100%;}
.col-sm-auto{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;width: auto;max-width: 100%;}
.col-sm-1{-webkit-box-flex: 0;-ms-flex: 0 0 8.33333%;flex: 0 0 8.33333%;max-width: 8.33333%;}
.col-sm-2{-webkit-box-flex: 0;-ms-flex: 0 0 16.66667%;flex: 0 0 16.66667%;max-width: 16.66667%;}
.col-sm-3{-webkit-box-flex: 0;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%;}
.col-sm-4{-webkit-box-flex: 0;-ms-flex: 0 0 33.33333%;flex: 0 0 33.33333%;max-width: 33.33333%;}
.col-sm-5{-webkit-box-flex: 0;-ms-flex: 0 0 41.66667%;flex: 0 0 41.66667%;max-width: 41.66667%;}
.col-sm-6{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%;}
.col-sm-7{-webkit-box-flex: 0;-ms-flex: 0 0 58.33333%;flex: 0 0 58.33333%;max-width: 58.33333%;}
.col-sm-8{-webkit-box-flex: 0;-ms-flex: 0 0 66.66667%;flex: 0 0 66.66667%;max-width: 66.66667%;}
.col-sm-9{-webkit-box-flex: 0;-ms-flex: 0 0 75%;flex: 0 0 75%;max-width: 75%;}
.col-sm-10{-webkit-box-flex: 0;-ms-flex: 0 0 83.33333%;flex: 0 0 83.33333%;max-width: 83.33333%;}
.col-sm-11{-webkit-box-flex: 0;-ms-flex: 0 0 91.66667%;flex: 0 0 91.66667%;max-width: 91.66667%;}
.col-sm-12{-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
.order-sm-first{-webkit-box-ordinal-group: 0;-ms-flex-order: -1;order: -1;}
.order-sm-last{-webkit-box-ordinal-group: 14;-ms-flex-order: 13;order: 13;}
.order-sm-0{-webkit-box-ordinal-group: 1;-ms-flex-order: 0;order: 0;}
.order-sm-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.order-sm-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.order-sm-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.order-sm-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.order-sm-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.order-sm-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.order-sm-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.order-sm-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.order-sm-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.order-sm-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.order-sm-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.order-sm-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.offset-sm-0{margin-left: 0;}
.offset-sm-1{margin-left: 8.33333%;}
.offset-sm-2{margin-left: 16.66667%;}
.offset-sm-3{margin-left: 25%;}
.offset-sm-4{margin-left: 33.33333%;}
.offset-sm-5{margin-left: 41.66667%;}
.offset-sm-6{margin-left: 50%;}
.offset-sm-7{margin-left: 58.33333%;}
.offset-sm-8{margin-left: 66.66667%;}
.offset-sm-9{margin-left: 75%;}
.offset-sm-10{margin-left: 83.33333%;}
.offset-sm-11{margin-left: 91.66667%;}
}
@media (min-width: 768px){
.col-md{-ms-flex-preferred-size: 0;flex-basis: 0;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;max-width: 100%;}
.col-md-auto{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;width: auto;max-width: 100%;}
.col-md-1{-webkit-box-flex: 0;-ms-flex: 0 0 8.33333%;flex: 0 0 8.33333%;max-width: 8.33333%;}
.col-md-2{-webkit-box-flex: 0;-ms-flex: 0 0 16.66667%;flex: 0 0 16.66667%;max-width: 16.66667%;}
.col-md-3{-webkit-box-flex: 0;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%;}
.col-md-4{-webkit-box-flex: 0;-ms-flex: 0 0 33.33333%;flex: 0 0 33.33333%;max-width: 33.33333%;}
.col-md-5{-webkit-box-flex: 0;-ms-flex: 0 0 41.66667%;flex: 0 0 41.66667%;max-width: 41.66667%;}
.col-md-6{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%;}
.col-md-7{-webkit-box-flex: 0;-ms-flex: 0 0 58.33333%;flex: 0 0 58.33333%;max-width: 58.33333%;}
.col-md-8{-webkit-box-flex: 0;-ms-flex: 0 0 66.66667%;flex: 0 0 66.66667%;max-width: 66.66667%;}
.col-md-9{-webkit-box-flex: 0;-ms-flex: 0 0 75%;flex: 0 0 75%;max-width: 75%;}
.col-md-10{-webkit-box-flex: 0;-ms-flex: 0 0 83.33333%;flex: 0 0 83.33333%;max-width: 83.33333%;}
.col-md-11{-webkit-box-flex: 0;-ms-flex: 0 0 91.66667%;flex: 0 0 91.66667%;max-width: 91.66667%;}
.col-md-12{-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
.order-md-first{-webkit-box-ordinal-group: 0;-ms-flex-order: -1;order: -1;}
.order-md-last{-webkit-box-ordinal-group: 14;-ms-flex-order: 13;order: 13;}
.order-md-0{-webkit-box-ordinal-group: 1;-ms-flex-order: 0;order: 0;}
.order-md-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.order-md-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.order-md-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.order-md-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.order-md-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.order-md-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.order-md-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.order-md-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.order-md-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.order-md-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.order-md-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.order-md-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.offset-md-0{margin-left: 0;}
.offset-md-1{margin-left: 8.33333%;}
.offset-md-2{margin-left: 16.66667%;}
.offset-md-3{margin-left: 25%;}
.offset-md-4{margin-left: 33.33333%;}
.offset-md-5{margin-left: 41.66667%;}
.offset-md-6{margin-left: 50%;}
.offset-md-7{margin-left: 58.33333%;}
.offset-md-8{margin-left: 66.66667%;}
.offset-md-9{margin-left: 75%;}
.offset-md-10{margin-left: 83.33333%;}
.offset-md-11{margin-left: 91.66667%;}
}
@media (min-width: 1024px){
.col-lg{-ms-flex-preferred-size: 0;flex-basis: 0;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;max-width: 100%;}
.col-lg-auto{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;width: auto;max-width: 100%;}
.col-lg-1{-webkit-box-flex: 0;-ms-flex: 0 0 8.33333%;flex: 0 0 8.33333%;max-width: 8.33333%;}
.col-lg-2{-webkit-box-flex: 0;-ms-flex: 0 0 16.66667%;flex: 0 0 16.66667%;max-width: 16.66667%;}
.col-lg-3{-webkit-box-flex: 0;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%;}
.col-lg-4{-webkit-box-flex: 0;-ms-flex: 0 0 33.33333%;flex: 0 0 33.33333%;max-width: 33.33333%;}
.col-lg-5{-webkit-box-flex: 0;-ms-flex: 0 0 41.66667%;flex: 0 0 41.66667%;max-width: 41.66667%;}
.col-lg-6{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%;}
.col-lg-7{-webkit-box-flex: 0;-ms-flex: 0 0 58.33333%;flex: 0 0 58.33333%;max-width: 58.33333%;}
.col-lg-8{-webkit-box-flex: 0;-ms-flex: 0 0 66.66667%;flex: 0 0 66.66667%;max-width: 66.66667%;}
.col-lg-9{-webkit-box-flex: 0;-ms-flex: 0 0 75%;flex: 0 0 75%;max-width: 75%;}
.col-lg-10{-webkit-box-flex: 0;-ms-flex: 0 0 83.33333%;flex: 0 0 83.33333%;max-width: 83.33333%;}
.col-lg-11{-webkit-box-flex: 0;-ms-flex: 0 0 91.66667%;flex: 0 0 91.66667%;max-width: 91.66667%;}
.col-lg-12{-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
.order-lg-first{-webkit-box-ordinal-group: 0;-ms-flex-order: -1;order: -1;}
.order-lg-last{-webkit-box-ordinal-group: 14;-ms-flex-order: 13;order: 13;}
.order-lg-0{-webkit-box-ordinal-group: 1;-ms-flex-order: 0;order: 0;}
.order-lg-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.order-lg-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.order-lg-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.order-lg-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.order-lg-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.order-lg-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.order-lg-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.order-lg-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.order-lg-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.order-lg-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.order-lg-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.order-lg-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.offset-lg-0{margin-left: 0;}
.offset-lg-1{margin-left: 8.33333%;}
.offset-lg-2{margin-left: 16.66667%;}
.offset-lg-3{margin-left: 25%;}
.offset-lg-4{margin-left: 33.33333%;}
.offset-lg-5{margin-left: 41.66667%;}
.offset-lg-6{margin-left: 50%;}
.offset-lg-7{margin-left: 58.33333%;}
.offset-lg-8{margin-left: 66.66667%;}
.offset-lg-9{margin-left: 75%;}
.offset-lg-10{margin-left: 83.33333%;}
.offset-lg-11{margin-left: 91.66667%;}
}
@media (min-width: 1399px){
.col-xl{-ms-flex-preferred-size: 0;flex-basis: 0;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;max-width: 100%;}
.col-xl-auto{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;width: auto;max-width: 100%;}
.col-xl-1{-webkit-box-flex: 0;-ms-flex: 0 0 8.33333%;flex: 0 0 8.33333%;max-width: 8.33333%;}
.col-xl-2{-webkit-box-flex: 0;-ms-flex: 0 0 16.66667%;flex: 0 0 16.66667%;max-width: 16.66667%;}
.col-xl-3{-webkit-box-flex: 0;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%;}
.col-xl-4{-webkit-box-flex: 0;-ms-flex: 0 0 33.33333%;flex: 0 0 33.33333%;max-width: 33.33333%;}
.col-xl-5{-webkit-box-flex: 0;-ms-flex: 0 0 41.66667%;flex: 0 0 41.66667%;max-width: 41.66667%;}
.col-xl-6{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%;}
.col-xl-7{-webkit-box-flex: 0;-ms-flex: 0 0 58.33333%;flex: 0 0 58.33333%;max-width: 58.33333%;}
.col-xl-8{-webkit-box-flex: 0;-ms-flex: 0 0 66.66667%;flex: 0 0 66.66667%;max-width: 66.66667%;}
.col-xl-9{-webkit-box-flex: 0;-ms-flex: 0 0 75%;flex: 0 0 75%;max-width: 75%;}
.col-xl-10{-webkit-box-flex: 0;-ms-flex: 0 0 83.33333%;flex: 0 0 83.33333%;max-width: 83.33333%;}
.col-xl-11{-webkit-box-flex: 0;-ms-flex: 0 0 91.66667%;flex: 0 0 91.66667%;max-width: 91.66667%;}
.col-xl-12{-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
.order-xl-first{-webkit-box-ordinal-group: 0;-ms-flex-order: -1;order: -1;}
.order-xl-last{-webkit-box-ordinal-group: 14;-ms-flex-order: 13;order: 13;}
.order-xl-0{-webkit-box-ordinal-group: 1;-ms-flex-order: 0;order: 0;}
.order-xl-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.order-xl-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.order-xl-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.order-xl-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.order-xl-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.order-xl-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.order-xl-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.order-xl-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.order-xl-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.order-xl-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.order-xl-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.order-xl-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.offset-xl-0{margin-left: 0;}
.offset-xl-1{margin-left: 8.33333%;}
.offset-xl-2{margin-left: 16.66667%;}
.offset-xl-3{margin-left: 25%;}
.offset-xl-4{margin-left: 33.33333%;}
.offset-xl-5{margin-left: 41.66667%;}
.offset-xl-6{margin-left: 50%;}
.offset-xl-7{margin-left: 58.33333%;}
.offset-xl-8{margin-left: 66.66667%;}
.offset-xl-9{margin-left: 75%;}
.offset-xl-10{margin-left: 83.33333%;}
.offset-xl-11{margin-left: 91.66667%;}
}
.table{width: 100%;margin-bottom: 1rem;color: #212529;background-color: transparent;}
.table th, .table td{padding: 0.75rem;vertical-align: top;border-top: 1px solid #ebedf2;}
.table thead th{vertical-align: bottom;border-bottom: 2px solid #ebedf2;}
.table tbody + tbody{border-top: 2px solid #ebedf2;}
.table-sm th, .table-sm td{padding: 0.3rem;}
.table-bordered{border: 1px solid #ebedf2;}
.table-bordered th, .table-bordered td{border: 1px solid #ebedf2;}
.table-bordered thead th, .table-bordered thead td{border-bottom-width: 2px;}
.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody{border: 0;}
.table-striped tbody tr:nth-of-type(odd){
background-color: #f7f8fa;}
.table-hover tbody tr:hover{color: #212529;background-color: #fafbfc;}
.table-primary, .table-primary > th, .table-primary > td{background-color: #d0d4f5;}
.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody + tbody{border-color: #a8b0ed;}
.table-hover .table-primary:hover{background-color: #bbc1f1;}
.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th{background-color: #bbc1f1;}
.table-secondary, .table-secondary > th, .table-secondary > td{background-color: #f7f7fb;}
.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody{border-color: #efeff7;}
.table-hover .table-secondary:hover{background-color: #e6e6f3;}
.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th{background-color: #e6e6f3;}
.table-success, .table-success > th, .table-success > td{background-color: #baecdd;}
.table-success th, .table-success td, .table-success thead th, .table-success tbody + tbody{border-color: #80dcc1;}
.table-hover .table-success:hover{background-color: #a6e6d3;}
.table-hover .table-success:hover > td, .table-hover .table-success:hover > th{background-color: #a6e6d3;}
.table-info, .table-info > th, .table-info > td{background-color: #cfd9f9;}
.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody{border-color: #a7b9f5;}
.table-hover .table-info:hover{background-color: #b8c7f6;}
.table-hover .table-info:hover > td, .table-hover .table-info:hover > th{background-color: #b8c7f6;}
.table-warning, .table-warning > th, .table-warning > td{background-color: #ffebc1;}
.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody + tbody{border-color: #ffda8c;}
.table-hover .table-warning:hover{background-color: #ffe3a8;}
.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th{background-color: #ffe3a8;}
.table-danger, .table-danger > th, .table-danger > td{background-color: #fec8da;}
.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody + tbody{border-color: #fe98ba;}
.table-hover .table-danger:hover{background-color: #feafc9;}
.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th{background-color: #feafc9;}
.table-light, .table-light > th, .table-light > td{background-color: #fdfdfe;}
.table-light th, .table-light td, .table-light thead th, .table-light tbody + tbody{border-color: #fbfcfc;}
.table-hover .table-light:hover{background-color: #ececf6;}
.table-hover .table-light:hover > td, .table-hover .table-light:hover > th{background-color: #ececf6;}
.table-dark, .table-dark > th, .table-dark > td{background-color: #c6c8ca;}
.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody{border-color: #95999c;}
.table-hover .table-dark:hover{background-color: #b9bbbe;}
.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th{background-color: #b9bbbe;}
.table-active, .table-active > th, .table-active > td{background-color: #5867dd;}
.table-hover .table-active:hover{background-color: #4354d9;}
.table-hover .table-active:hover > td, .table-hover .table-active:hover > th{background-color: #4354d9;}
.table .thead-dark th{color: #fff;background-color: #343a40;border-color: #454d55;}
.table .thead-light th{color: #6c7293;background-color: #ebedf2;border-color: #ebedf2;}
.table-dark{color: #fff;background-color: #343a40;}
.table-dark th, .table-dark td, .table-dark thead th{border-color: #454d55;}
.table-dark.table-bordered{border: 0;}
.table-dark.table-striped tbody tr:nth-of-type(odd){
background-color: rgba(255, 255, 255, 0.05);}
.table-dark.table-hover tbody tr:hover{color: #fff;background-color: rgba(255, 255, 255, 0.075);}
@media (max-width: 575.98px){
.table-responsive-sm{display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
.table-responsive-sm > .table-bordered{border: 0;}
}
@media (max-width: 767.98px){
.table-responsive-md{display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
.table-responsive-md > .table-bordered{border: 0;}
}
@media (max-width: 1023.98px){
.table-responsive-lg{display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
.table-responsive-lg > .table-bordered{border: 0;}
}
@media (max-width: 1398.98px){
.table-responsive-xl{display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
.table-responsive-xl > .table-bordered{border: 0;}
}
.table-responsive{display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
.table-responsive > .table-bordered{border: 0;}
.form-control{display: block;width: 100%;height: calc(1.5em + 1.3rem + 2px);padding: 0.65rem 1rem;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #e2e5ec;border-radius: 4px;-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;}
@media (prefers-reduced-motion: reduce){
.form-control{-webkit-transition: none;transition: none;}
}
.form-control::-ms-expand{background-color: transparent;border: 0;}
.form-control:focus{color: #495057;background-color: #fff;border-color: #9aabff;outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.form-control::-webkit-input-placeholder{color: #a7abc3;opacity: 1;}
.form-control:-ms-input-placeholder{color: #a7abc3;opacity: 1;}
.form-control::-ms-input-placeholder{color: #a7abc3;opacity: 1;}
.form-control::placeholder{color: #a7abc3;opacity: 1;}
.form-control:disabled, .form-control[readonly]{background-color: #f7f8fa;opacity: 1;}
select.form-control:focus::-ms-value{color: #495057;background-color: #fff;}
.form-control-file, .form-control-range{display: block;width: 100%;}
.col-form-label{padding-top: calc(0.65rem + 1px);padding-bottom: calc(0.65rem + 1px);margin-bottom: 0;font-size: inherit;line-height: 1.5;}
.col-form-label-lg{padding-top: calc(1.15rem + 1px);padding-bottom: calc(1.15rem + 1px);font-size: 1.25rem;line-height: 1.5;}
.col-form-label-sm{padding-top: calc(0.5rem + 1px);padding-bottom: calc(0.5rem + 1px);font-size: 0.875rem;line-height: 1.5;}
.form-control-plaintext{display: block;width: 100%;padding-top: 0.65rem;padding-bottom: 0.65rem;margin-bottom: 0;line-height: 1.5;color: #6c7293;background-color: transparent;border: solid transparent;border-width: 1px 0;}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg{padding-right: 0;padding-left: 0;}
.form-control-sm{height: calc(1.5em + 1rem + 2px);padding: 0.5rem 1rem;font-size: 0.875rem;line-height: 1.5;border-radius: 0.2rem;}
.form-control-lg{height: calc(1.5em + 2.3rem + 2px);padding: 1.15rem 1.65rem;font-size: 1.25rem;line-height: 1.5;border-radius: 0.3rem;}
select.form-control[size], select.form-control[multiple]{height: auto;}
textarea.form-control{height: auto;}
.form-group{margin-bottom: 1rem;}
.form-text{display: block;margin-top: 0.25rem;}
.form-row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin-right: -5px;margin-left: -5px;}
.form-row > .col, .form-row > [class*="col-"]{padding-right: 5px;padding-left: 5px;}
.form-check{position: relative;display: block;padding-left: 1.25rem;}
.form-check-input{position: absolute;margin-top: 0.3rem;margin-left: -1.25rem;}
.form-check-input:disabled ~ .form-check-label{color: #a7abc3;}
.form-check-label{margin-bottom: 0;}
.form-check-inline{display: -webkit-inline-box;display: -ms-inline-flexbox;display: inline-flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding-left: 0;margin-right: 0.75rem;}
.form-check-inline .form-check-input{position: static;margin-top: 0;margin-right: 0.3125rem;margin-left: 0;}
.valid-feedback{display: none;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #0abb87;}
.valid-tooltip{position: absolute;top: 100%;z-index: 5;display: none;max-width: 100%;padding: 0.75rem 1rem;margin-top: .1rem;font-size: 0.9rem;line-height: 1.5;color: #fff;background-color: #0abb87;border-radius: 3px;}
.was-validated .form-control:valid, .form-control.is-valid{border-color: #0abb87;padding-right: calc(1.5em + 1.3rem);background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230abb87' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");background-repeat: no-repeat;background-position: center right calc(0.375em + 0.325rem);background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus{border-color: #0abb87;-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);}
.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip{display: block;}
.was-validated textarea.form-control:valid, textarea.form-control.is-valid{padding-right: calc(1.5em + 1.3rem);background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);}
.was-validated .custom-select:valid, .custom-select.is-valid{border-color: #0abb87;padding-right: calc((1em + 1.3rem) * 3 / 4 + 2rem);background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230abb87' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus{border-color: #0abb87;-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);}
.was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip{display: block;}
.was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip{display: block;}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label{color: #0abb87;}
.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip{display: block;}
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label{color: #0abb87;}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before{border-color: #0abb87;}
.was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip{display: block;}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before{border-color: #0debaa;background-color: #0debaa;}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before{-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before{border-color: #0abb87;}
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label{border-color: #0abb87;}
.was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip{display: block;}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label{border-color: #0abb87;-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.25);}
.invalid-feedback{display: none;width: 100%;margin-top: 0.25rem;font-size: 80%;color: #fd397a;}
.invalid-tooltip{position: absolute;top: 100%;z-index: 5;display: none;max-width: 100%;padding: 0.75rem 1rem;margin-top: .1rem;font-size: 0.9rem;line-height: 1.5;color: #fff;background-color: #fd397a;border-radius: 3px;}
.was-validated .form-control:invalid, .form-control.is-invalid{border-color: #fd397a;padding-right: calc(1.5em + 1.3rem);background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fd397a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fd397a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");background-repeat: no-repeat;background-position: center right calc(0.375em + 0.325rem);background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus{border-color: #fd397a;-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);}
.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip{display: block;}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid{padding-right: calc(1.5em + 1.3rem);background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);}
.was-validated .custom-select:invalid, .custom-select.is-invalid{border-color: #fd397a;padding-right: calc((1em + 1.3rem) * 3 / 4 + 2rem);background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fd397a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fd397a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2rem/calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus{border-color: #fd397a;-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);}
.was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip{display: block;}
.was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip{display: block;}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label{color: #fd397a;}
.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip{display: block;}
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label{color: #fd397a;}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before{border-color: #fd397a;}
.was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip{display: block;}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before{border-color: #fe6b9c;background-color: #fe6b9c;}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before{border-color: #fd397a;}
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label{border-color: #fd397a;}
.was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip{display: block;}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label{border-color: #fd397a;-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);}
.form-inline{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row wrap;flex-flow: row wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.form-inline .form-check{width: 100%;}
@media (min-width: 576px){
.form-inline label{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;margin-bottom: 0;}
.form-inline .form-group{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row wrap;flex-flow: row wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 0;}
.form-inline .form-control{display: inline-block;width: auto;vertical-align: middle;}
.form-inline .form-control-plaintext{display: inline-block;}
.form-inline .input-group, .form-inline .custom-select{width: auto;}
.form-inline .form-check{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;width: auto;padding-left: 0;}
.form-inline .form-check-input{position: relative;-ms-flex-negative: 0;flex-shrink: 0;margin-top: 0;margin-right: 0.25rem;margin-left: 0;}
.form-inline .custom-control{-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.form-inline .custom-control-label{margin-bottom: 0;}
}
.btn{display: inline-block;font-weight: normal;color: #212529;text-align: center;vertical-align: middle;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;background-color: transparent;border: 1px solid transparent;padding: 0.65rem 1rem;font-size: 1rem;line-height: 1.5;border-radius: 0.25rem;-webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;}
@media (prefers-reduced-motion: reduce){
.btn{-webkit-transition: none;transition: none;}
}
.btn:hover{color: #212529;text-decoration: none;}
.btn:focus, .btn.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.btn.disabled, .btn:disabled{opacity: 0.65;}
a.btn.disabled, fieldset:disabled a.btn{pointer-events: none;}
.btn-primary{color: #fff;background-color: #5867dd;border-color: #5867dd;}
.btn-primary:hover{color: #fff;background-color: #384ad7;border-color: #2e40d4;}
.btn-primary:focus, .btn-primary.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);}
.btn-primary.disabled, .btn-primary:disabled{color: #fff;background-color: #5867dd;border-color: #5867dd;}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{color: #fff;background-color: #2e40d4;border-color: #293ccc;}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);}
.btn-secondary{color: #212529;background-color: #e1e1ef;border-color: #e1e1ef;}
.btn-secondary:hover{color: #212529;background-color: #c8c8e2;border-color: #c0c0dd;}
.btn-secondary:focus, .btn-secondary.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(196, 197, 209, 0.5);box-shadow: 0 0 0 0.2rem rgba(196, 197, 209, 0.5);}
.btn-secondary.disabled, .btn-secondary:disabled{color: #212529;background-color: #e1e1ef;border-color: #e1e1ef;}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{color: #212529;background-color: #c0c0dd;border-color: #b7b7d9;}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(196, 197, 209, 0.5);box-shadow: 0 0 0 0.2rem rgba(196, 197, 209, 0.5);}
.btn-success{color: #fff;background-color: #0abb87;border-color: #0abb87;}
.btn-success:hover{color: #fff;background-color: #08976d;border-color: #078b64;}
.btn-success:focus, .btn-success.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);}
.btn-success.disabled, .btn-success:disabled{color: #fff;background-color: #0abb87;border-color: #0abb87;}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle{color: #fff;background-color: #078b64;border-color: #077e5b;}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);}
.btn-info{color: #fff;background-color: #5578eb;border-color: #5578eb;}
.btn-info:hover{color: #fff;background-color: #335de7;border-color: #2754e6;}
.btn-info:focus, .btn-info.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);}
.btn-info.disabled, .btn-info:disabled{color: #fff;background-color: #5578eb;border-color: #5578eb;}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle{color: #fff;background-color: #2754e6;border-color: #1c4be4;}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);}
.btn-warning{color: #212529;background-color: #ffb822;border-color: #ffb822;}
.btn-warning:hover{color: #212529;background-color: #fbaa00;border-color: #eea200;}
.btn-warning:focus, .btn-warning.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);}
.btn-warning.disabled, .btn-warning:disabled{color: #212529;background-color: #ffb822;border-color: #ffb822;}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle{color: #212529;background-color: #eea200;border-color: #e19900;}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);}
.btn-danger{color: #fff;background-color: #fd397a;border-color: #fd397a;}
.btn-danger:hover{color: #fff;background-color: #fd1361;border-color: #fc0758;}
.btn-danger:focus, .btn-danger.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);}
.btn-danger.disabled, .btn-danger:disabled{color: #fff;background-color: #fd397a;border-color: #fd397a;}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle{color: #fff;background-color: #fc0758;border-color: #f40252;}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);}
.btn-light{color: #212529;background-color: #f8f9fa;border-color: #f8f9fa;}
.btn-light:hover{color: #212529;background-color: #e2e6ea;border-color: #dae0e5;}
.btn-light:focus, .btn-light.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);}
.btn-light.disabled, .btn-light:disabled{color: #212529;background-color: #f8f9fa;border-color: #f8f9fa;}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle{color: #212529;background-color: #dae0e5;border-color: #d3d9df;}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);}
.btn-dark{color: #fff;background-color: #343a40;border-color: #343a40;}
.btn-dark:hover{color: #fff;background-color: #23272b;border-color: #1d2124;}
.btn-dark:focus, .btn-dark.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);}
.btn-dark.disabled, .btn-dark:disabled{color: #fff;background-color: #343a40;border-color: #343a40;}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle{color: #fff;background-color: #1d2124;border-color: #171a1d;}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);}
.btn-outline-primary{color: #5867dd;border-color: #5867dd;}
.btn-outline-primary:hover{color: #fff;background-color: #5867dd;border-color: #5867dd;}
.btn-outline-primary:focus, .btn-outline-primary.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);}
.btn-outline-primary.disabled, .btn-outline-primary:disabled{color: #5867dd;background-color: transparent;}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle{color: #fff;background-color: #5867dd;border-color: #5867dd;}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);}
.btn-outline-secondary{color: #e1e1ef;border-color: #e1e1ef;}
.btn-outline-secondary:hover{color: #212529;background-color: #e1e1ef;border-color: #e1e1ef;}
.btn-outline-secondary:focus, .btn-outline-secondary.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(225, 225, 239, 0.5);box-shadow: 0 0 0 0.2rem rgba(225, 225, 239, 0.5);}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled{color: #e1e1ef;background-color: transparent;}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle{color: #212529;background-color: #e1e1ef;border-color: #e1e1ef;}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(225, 225, 239, 0.5);box-shadow: 0 0 0 0.2rem rgba(225, 225, 239, 0.5);}
.btn-outline-success{color: #0abb87;border-color: #0abb87;}
.btn-outline-success:hover{color: #fff;background-color: #0abb87;border-color: #0abb87;}
.btn-outline-success:focus, .btn-outline-success.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);}
.btn-outline-success.disabled, .btn-outline-success:disabled{color: #0abb87;background-color: transparent;}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle{color: #fff;background-color: #0abb87;border-color: #0abb87;}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);}
.btn-outline-info{color: #5578eb;border-color: #5578eb;}
.btn-outline-info:hover{color: #fff;background-color: #5578eb;border-color: #5578eb;}
.btn-outline-info:focus, .btn-outline-info.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);}
.btn-outline-info.disabled, .btn-outline-info:disabled{color: #5578eb;background-color: transparent;}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle{color: #fff;background-color: #5578eb;border-color: #5578eb;}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);}
.btn-outline-warning{color: #ffb822;border-color: #ffb822;}
.btn-outline-warning:hover{color: #212529;background-color: #ffb822;border-color: #ffb822;}
.btn-outline-warning:focus, .btn-outline-warning.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);}
.btn-outline-warning.disabled, .btn-outline-warning:disabled{color: #ffb822;background-color: transparent;}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle{color: #212529;background-color: #ffb822;border-color: #ffb822;}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);}
.btn-outline-danger{color: #fd397a;border-color: #fd397a;}
.btn-outline-danger:hover{color: #fff;background-color: #fd397a;border-color: #fd397a;}
.btn-outline-danger:focus, .btn-outline-danger.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);}
.btn-outline-danger.disabled, .btn-outline-danger:disabled{color: #fd397a;background-color: transparent;}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle{color: #fff;background-color: #fd397a;border-color: #fd397a;}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);}
.btn-outline-light{color: #f8f9fa;border-color: #f8f9fa;}
.btn-outline-light:hover{color: #212529;background-color: #f8f9fa;border-color: #f8f9fa;}
.btn-outline-light:focus, .btn-outline-light.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);}
.btn-outline-light.disabled, .btn-outline-light:disabled{color: #f8f9fa;background-color: transparent;}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle{color: #212529;background-color: #f8f9fa;border-color: #f8f9fa;}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);}
.btn-outline-dark{color: #343a40;border-color: #343a40;}
.btn-outline-dark:hover{color: #fff;background-color: #343a40;border-color: #343a40;}
.btn-outline-dark:focus, .btn-outline-dark.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);}
.btn-outline-dark.disabled, .btn-outline-dark:disabled{color: #343a40;background-color: transparent;}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle{color: #fff;background-color: #343a40;border-color: #343a40;}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);}
.btn-link{font-weight: 400;color: #5867dd;text-decoration: none;}
.btn-link:hover{color: #2739c1;text-decoration: underline;}
.btn-link:focus, .btn-link.focus{text-decoration: underline;-webkit-box-shadow: none;box-shadow: none;}
.btn-link:disabled, .btn-link.disabled{color: #6c757d;pointer-events: none;}
.btn-lg, .btn-group-lg > .btn{padding: 1.15rem 1.65rem;font-size: 1.25rem;line-height: 1.5;border-radius: 0.3rem;}
.btn-sm, .btn-group-sm > .btn{padding: 0.5rem 1rem;font-size: 0.875rem;line-height: 1.5;border-radius: 0.2rem;}
.btn-block{display: block;width: 100%;}
.btn-block + .btn-block{margin-top: 0.5rem;}
input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block{width: 100%;}
.fade{-webkit-transition: opacity 0.15s linear;transition: opacity 0.15s linear;}
@media (prefers-reduced-motion: reduce){
.fade{-webkit-transition: none;transition: none;}
}
.fade:not(.show){
opacity: 0;}
.collapse:not(.show){
display: none;}
.collapsing{position: relative;height: 0;overflow: hidden;-webkit-transition: height 0.35s ease;transition: height 0.35s ease;}
@media (prefers-reduced-motion: reduce){
.collapsing{-webkit-transition: none;transition: none;}
}
.dropup, .dropright, .dropdown, .dropleft{position: relative;}
.dropdown-toggle{white-space: nowrap;}
.dropdown-toggle::after{display: inline-block;margin-left: 0.255em;vertical-align: 0.255em;content: "";border-top: 0.3em solid;border-right: 0.3em solid transparent;border-bottom: 0;border-left: 0.3em solid transparent;}
.dropdown-toggle:empty::after{margin-left: 0;}
.dropdown-menu{position: absolute;top: 100%;left: 0;z-index: 95;display: none;float: left;min-width: 10rem;padding: 0.5rem 0;margin: 0.125rem 0 0;font-size: 1rem;color: #212529;text-align: left;list-style: none;background-color: #fff;background-clip: padding-box;border: 1px solid rgba(0, 0, 0, 0.15);border-radius: 0.25rem;}
.dropdown-menu-left{right: auto;left: 0;}
.dropdown-menu-right{right: 0;left: auto;}
@media (min-width: 576px){
.dropdown-menu-sm-left{right: auto;left: 0;}
.dropdown-menu-sm-right{right: 0;left: auto;}
}
@media (min-width: 768px){
.dropdown-menu-md-left{right: auto;left: 0;}
.dropdown-menu-md-right{right: 0;left: auto;}
}
@media (min-width: 1024px){
.dropdown-menu-lg-left{right: auto;left: 0;}
.dropdown-menu-lg-right{right: 0;left: auto;}
}
@media (min-width: 1399px){
.dropdown-menu-xl-left{right: auto;left: 0;}
.dropdown-menu-xl-right{right: 0;left: auto;}
}
.dropup .dropdown-menu{top: auto;bottom: 100%;margin-top: 0;margin-bottom: 0.125rem;}
.dropup .dropdown-toggle::after{display: inline-block;margin-left: 0.255em;vertical-align: 0.255em;content: "";border-top: 0;border-right: 0.3em solid transparent;border-bottom: 0.3em solid;border-left: 0.3em solid transparent;}
.dropup .dropdown-toggle:empty::after{margin-left: 0;}
.dropright .dropdown-menu{top: 0;right: auto;left: 100%;margin-top: 0;margin-left: 0.125rem;}
.dropright .dropdown-toggle::after{display: inline-block;margin-left: 0.255em;vertical-align: 0.255em;content: "";border-top: 0.3em solid transparent;border-right: 0;border-bottom: 0.3em solid transparent;border-left: 0.3em solid;}
.dropright .dropdown-toggle:empty::after{margin-left: 0;}
.dropright .dropdown-toggle::after{vertical-align: 0;}
.dropleft .dropdown-menu{top: 0;right: 100%;left: auto;margin-top: 0;margin-right: 0.125rem;}
.dropleft .dropdown-toggle::after{display: inline-block;margin-left: 0.255em;vertical-align: 0.255em;content: "";}
.dropleft .dropdown-toggle::after{display: none;}
.dropleft .dropdown-toggle::before{display: inline-block;margin-right: 0.255em;vertical-align: 0.255em;content: "";border-top: 0.3em solid transparent;border-right: 0.3em solid;border-bottom: 0.3em solid transparent;}
.dropleft .dropdown-toggle:empty::after{margin-left: 0;}
.dropleft .dropdown-toggle::before{vertical-align: 0;}
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"]{right: auto;bottom: auto;}
.dropdown-divider{height: 0;margin: 0.5rem 0;overflow: hidden;border-top: 1px solid #e9ecef;}
.dropdown-item{display: block;width: 100%;padding: 0.25rem 1.5rem;clear: both;font-weight: 400;color: #a7abc3;text-align: inherit;white-space: nowrap;background-color: transparent;border: 0;}
.dropdown-item:hover, .dropdown-item:focus{color: #6c7293;text-decoration: none;background-color: #f7f8fa;}
.dropdown-item.active, .dropdown-item:active{color: #ffffff;text-decoration: none;background-color: #5d78ff;}
.dropdown-item.disabled, .dropdown-item:disabled{color: #adb1c7;pointer-events: none;background-color: transparent;}
.dropdown-menu.show{display: block;}
.dropdown-header{display: block;padding: 0.5rem 1.5rem;margin-bottom: 0;font-size: 0.875rem;color: #a7abc3;white-space: nowrap;}
.dropdown-item-text{display: block;padding: 0.25rem 1.5rem;color: #a7abc3;}
.btn-group, .btn-group-vertical{position: relative;display: -webkit-inline-box;display: -ms-inline-flexbox;display: inline-flex;vertical-align: middle;}
.btn-group > .btn, .btn-group-vertical > .btn{position: relative;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;}
.btn-group > .btn:hover, .btn-group-vertical > .btn:hover{z-index: 1;}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active{z-index: 1;}
.btn-toolbar{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.btn-toolbar .input-group{width: auto;}
.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child){
margin-left: -1px;}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn{border-top-right-radius: 0;border-bottom-right-radius: 0;}
.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn{border-top-left-radius: 0;border-bottom-left-radius: 0;}
.dropdown-toggle-split{padding-right: 0.75rem;padding-left: 0.75rem;}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after{margin-left: 0;}
.dropleft .dropdown-toggle-split::before{margin-right: 0;}
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split{padding-right: 0.75rem;padding-left: 0.75rem;}
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split{padding-right: 1.2375rem;padding-left: 1.2375rem;}
.btn-group-vertical{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.btn-group-vertical > .btn, .btn-group-vertical > .btn-group{width: 100%;}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child){
margin-top: -1px;}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn{border-bottom-right-radius: 0;border-bottom-left-radius: 0;}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn{border-top-left-radius: 0;border-top-right-radius: 0;}
.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn{margin-bottom: 0;}
.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"]{position: absolute;clip: rect(0, 0, 0, 0);pointer-events: none;}
.input-group{position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;width: 100%;}
.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file{position: relative;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;width: 1%;margin-bottom: 0;}
.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file{margin-left: -1px;}
.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label{z-index: 3;}
.input-group > .custom-file .custom-file-input:focus{z-index: 4;}
.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child){
border-top-right-radius: 0;border-bottom-right-radius: 0;}
.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child){
border-top-left-radius: 0;border-bottom-left-radius: 0;}
.input-group > .custom-file{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after{border-top-right-radius: 0;border-bottom-right-radius: 0;}
.input-group > .custom-file:not(:first-child) .custom-file-label{border-top-left-radius: 0;border-bottom-left-radius: 0;}
.input-group-prepend, .input-group-append{display: -webkit-box;display: -ms-flexbox;display: flex;}
.input-group-prepend .btn, .input-group-append .btn{position: relative;z-index: 2;}
.input-group-prepend .btn:focus, .input-group-append .btn:focus{z-index: 3;}
.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn{margin-left: -1px;}
.input-group-prepend{margin-right: -1px;}
.input-group-append{margin-left: -1px;}
.input-group-text{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0.65rem 1rem;margin-bottom: 0;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #a7abc3;text-align: center;white-space: nowrap;background-color: #f7f8fa;border: 1px solid #e2e5ec;border-radius: 4px;}
.input-group-text input[type="radio"], .input-group-text input[type="checkbox"]{margin-top: 0;}
.input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select{height: calc(1.5em + 2.3rem + 2px);}
.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn{padding: 1.15rem 1.65rem;font-size: 1.25rem;line-height: 1.5;border-radius: 0.3rem;}
.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select{height: calc(1.5em + 1rem + 2px);}
.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn{padding: 0.5rem 1rem;font-size: 0.875rem;line-height: 1.5;border-radius: 0.2rem;}
.input-group-lg > .custom-select, .input-group-sm > .custom-select{padding-right: 2rem;}
.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child){
border-top-right-radius: 0;border-bottom-right-radius: 0;}
.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child){
border-top-left-radius: 0;border-bottom-left-radius: 0;}
.custom-control{position: relative;display: block;min-height: 1.5rem;padding-left: 1.5rem;}
.custom-control-inline{display: -webkit-inline-box;display: -ms-inline-flexbox;display: inline-flex;margin-right: 1rem;}
.custom-control-input{position: absolute;z-index: -1;opacity: 0;}
.custom-control-input:checked ~ .custom-control-label::before{color: #fff;border-color: #5867dd;background-color: #5867dd;}
.custom-control-input:focus ~ .custom-control-label::before{-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before{border-color: #9aabff;}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before{color: #fff;background-color: #eceefb;border-color: #eceefb;}
.custom-control-input:disabled ~ .custom-control-label{color: #6c757d;}
.custom-control-input:disabled ~ .custom-control-label::before{background-color: #f7f8fa;}
.custom-control-label{position: relative;margin-bottom: 0;vertical-align: top;}
.custom-control-label::before{position: absolute;top: 0.25rem;left: -1.5rem;display: block;width: 1rem;height: 1rem;pointer-events: none;content: "";background-color: #fff;border: #adb5bd solid 1px;}
.custom-control-label::after{position: absolute;top: 0.25rem;left: -1.5rem;display: block;width: 1rem;height: 1rem;content: "";background: no-repeat 50% / 50% 50%;}
.custom-checkbox .custom-control-label::before{border-radius: 0.25rem;}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before{border-color: #5867dd;background-color: #5867dd;}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before{background-color: rgba(88, 103, 221, 0.5);}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before{background-color: rgba(88, 103, 221, 0.5);}
.custom-radio .custom-control-label::before{border-radius: 50%;}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before{background-color: rgba(88, 103, 221, 0.5);}
.custom-switch{padding-left: 2.25rem;}
.custom-switch .custom-control-label::before{left: -2.25rem;width: 1.75rem;pointer-events: all;border-radius: 0.5rem;}
.custom-switch .custom-control-label::after{top: calc(0.25rem + 2px);left: calc(-2.25rem + 2px);width: calc(1rem - 4px);height: calc(1rem - 4px);background-color: #adb5bd;border-radius: 0.5rem;-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;}
@media (prefers-reduced-motion: reduce){
.custom-switch .custom-control-label::after{-webkit-transition: none;transition: none;}
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after{background-color: #fff;-webkit-transform: translateX(0.75rem);transform: translateX(0.75rem);}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before{background-color: rgba(88, 103, 221, 0.5);}
.custom-select{display: inline-block;width: 100%;height: calc(1.5em + 1.3rem + 2px);padding: 0.65rem 2rem 0.65rem 1rem;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #495057;vertical-align: middle;background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;background-color: #fff;border: 1px solid #e2e5ec;border-radius: 0.25rem;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
.custom-select:focus{border-color: #9aabff;outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.custom-select:focus::-ms-value{color: #495057;background-color: #fff;}
.custom-select[multiple], .custom-select[size]:not([size="1"]){
height: auto;padding-right: 1rem;background-image: none;}
.custom-select:disabled{color: #6c757d;background-color: #e9ecef;}
.custom-select::-ms-expand{display: none;}
.custom-select-sm{height: calc(1.5em + 1rem + 2px);padding-top: 0.5rem;padding-bottom: 0.5rem;padding-left: 1rem;font-size: 0.875rem;}
.custom-select-lg{height: calc(1.5em + 2.3rem + 2px);padding-top: 1.15rem;padding-bottom: 1.15rem;padding-left: 1.65rem;font-size: 1.25rem;}
.custom-file{position: relative;display: inline-block;width: 100%;height: calc(1.5em + 1.3rem + 2px);margin-bottom: 0;}
.custom-file-input{position: relative;z-index: 2;width: 100%;height: calc(1.5em + 1.3rem + 2px);margin: 0;opacity: 0;}
.custom-file-input:focus ~ .custom-file-label{border-color: #9aabff;-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.custom-file-input:disabled ~ .custom-file-label{background-color: #f7f8fa;}
.custom-file-input:lang(en) ~ .custom-file-label::after{content: "Browse";}
.custom-file-input ~ .custom-file-label[data-browse]::after{content: attr(data-browse);}
.custom-file-label{position: absolute;top: 0;right: 0;left: 0;z-index: 1;height: calc(1.5em + 1.3rem + 2px);padding: 0.65rem 1rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;border: 1px solid #e2e5ec;border-radius: 4px;}
.custom-file-label::after{position: absolute;top: 0;right: 0;bottom: 0;z-index: 3;display: block;height: calc(1.5em + 1.3rem);padding: 0.65rem 1rem;line-height: 1.5;color: #495057;content: "Browse";background-color: #f7f8fa;border-left: inherit;border-radius: 0 4px 4px 0;}
.custom-range{width: 100%;height: calc(1rem + 0.4rem);padding: 0;background-color: transparent;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
.custom-range:focus{outline: none;}
.custom-range:focus::-webkit-slider-thumb{-webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(88, 103, 221, 0.25);box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.custom-range:focus::-moz-range-thumb{box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.custom-range:focus::-ms-thumb{box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.custom-range::-moz-focus-outer{border: 0;}
.custom-range::-webkit-slider-thumb{width: 1rem;height: 1rem;margin-top: -0.25rem;background-color: #5867dd;border: 0;border-radius: 1rem;-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;-webkit-appearance: none;appearance: none;}
@media (prefers-reduced-motion: reduce){
.custom-range::-webkit-slider-thumb{-webkit-transition: none;transition: none;}
}
.custom-range::-webkit-slider-thumb:active{background-color: #eceefb;}
.custom-range::-webkit-slider-runnable-track{width: 100%;height: 0.5rem;color: transparent;cursor: pointer;background-color: #dee2e6;border-color: transparent;border-radius: 1rem;}
.custom-range::-moz-range-thumb{width: 1rem;height: 1rem;background-color: #5867dd;border: 0;border-radius: 1rem;-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;-moz-appearance: none;appearance: none;}
@media (prefers-reduced-motion: reduce){
.custom-range::-moz-range-thumb{-webkit-transition: none;transition: none;}
}
.custom-range::-moz-range-thumb:active{background-color: #eceefb;}
.custom-range::-moz-range-track{width: 100%;height: 0.5rem;color: transparent;cursor: pointer;background-color: #dee2e6;border-color: transparent;border-radius: 1rem;}
.custom-range::-ms-thumb{width: 1rem;height: 1rem;margin-top: 0;margin-right: 0.2rem;margin-left: 0.2rem;background-color: #5867dd;border: 0;border-radius: 1rem;-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;appearance: none;}
@media (prefers-reduced-motion: reduce){
.custom-range::-ms-thumb{-webkit-transition: none;transition: none;}
}
.custom-range::-ms-thumb:active{background-color: #eceefb;}
.custom-range::-ms-track{width: 100%;height: 0.5rem;color: transparent;cursor: pointer;background-color: transparent;border-color: transparent;border-width: 0.5rem;}
.custom-range::-ms-fill-lower{background-color: #dee2e6;border-radius: 1rem;}
.custom-range::-ms-fill-upper{margin-right: 15px;background-color: #dee2e6;border-radius: 1rem;}
.custom-range:disabled::-webkit-slider-thumb{background-color: #adb5bd;}
.custom-range:disabled::-webkit-slider-runnable-track{cursor: default;}
.custom-range:disabled::-moz-range-thumb{background-color: #adb5bd;}
.custom-range:disabled::-moz-range-track{cursor: default;}
.custom-range:disabled::-ms-thumb{background-color: #adb5bd;}
.custom-control-label::before, .custom-file-label, .custom-select{-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;}
@media (prefers-reduced-motion: reduce){
.custom-control-label::before, .custom-file-label, .custom-select{-webkit-transition: none;transition: none;}
}
.nav{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;padding-left: 0;margin-bottom: 0;list-style: none;}
.nav-link{display: block;padding: 0.5rem 1rem;}
.nav-link:hover, .nav-link:focus{text-decoration: none;}
.nav-link.disabled{color: #6c757d;pointer-events: none;cursor: default;}
.nav-tabs{border-bottom: 1px solid #dee2e6;}
.nav-tabs .nav-item{margin-bottom: -1px;}
.nav-tabs .nav-link{border: 1px solid transparent;border-top-left-radius: 0.25rem;border-top-right-radius: 0.25rem;}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{border-color: #e9ecef #e9ecef #dee2e6;}
.nav-tabs .nav-link.disabled{color: #6c757d;background-color: transparent;border-color: transparent;}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{color: #495057;background-color: #fff;border-color: #dee2e6 #dee2e6 #fff;}
.nav-tabs .dropdown-menu{margin-top: -1px;border-top-left-radius: 0;border-top-right-radius: 0;}
.nav-pills .nav-link{border-radius: 0.25rem;}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{color: #fff;background-color: #5867dd;}
.nav-fill .nav-item{-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;text-align: center;}
.nav-justified .nav-item{-ms-flex-preferred-size: 0;flex-basis: 0;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-align: center;}
.tab-content > .tab-pane{display: none;}
.tab-content > .active{display: block;}
.navbar{position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 0.5rem 1rem;}
.navbar > .container, .navbar > .container-fluid{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.navbar-brand{display: inline-block;padding-top: 0.3125rem;padding-bottom: 0.3125rem;margin-right: 1rem;font-size: 1.25rem;line-height: inherit;white-space: nowrap;}
.navbar-brand:hover, .navbar-brand:focus{text-decoration: none;}
.navbar-nav{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;padding-left: 0;margin-bottom: 0;list-style: none;}
.navbar-nav .nav-link{padding-right: 0;padding-left: 0;}
.navbar-nav .dropdown-menu{position: static;float: none;}
.navbar-text{display: inline-block;padding-top: 0.5rem;padding-bottom: 0.5rem;}
.navbar-collapse{-ms-flex-preferred-size: 100%;flex-basis: 100%;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.navbar-toggler{padding: 0.25rem 0.75rem;font-size: 1.25rem;line-height: 1;background-color: transparent;border: 1px solid transparent;border-radius: 0.25rem;}
.navbar-toggler:hover, .navbar-toggler:focus{text-decoration: none;}
.navbar-toggler-icon{display: inline-block;width: 1.5em;height: 1.5em;vertical-align: middle;content: "";background: no-repeat center center;background-size: 100% 100%;}
@media (max-width: 575.98px){
.navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid{padding-right: 0;padding-left: 0;}
}
@media (min-width: 576px){
.navbar-expand-sm{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row nowrap;flex-flow: row nowrap;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.navbar-expand-sm .navbar-nav{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.navbar-expand-sm .navbar-nav .dropdown-menu{position: absolute;}
.navbar-expand-sm .navbar-nav .nav-link{padding-right: 0.5rem;padding-left: 0.5rem;}
.navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid{-ms-flex-wrap: nowrap;flex-wrap: nowrap;}
.navbar-expand-sm .navbar-collapse{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;-ms-flex-preferred-size: auto;flex-basis: auto;}
.navbar-expand-sm .navbar-toggler{display: none;}
}
@media (max-width: 767.98px){
.navbar-expand-md > .container, .navbar-expand-md > .container-fluid{padding-right: 0;padding-left: 0;}
}
@media (min-width: 768px){
.navbar-expand-md{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row nowrap;flex-flow: row nowrap;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.navbar-expand-md .navbar-nav{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.navbar-expand-md .navbar-nav .dropdown-menu{position: absolute;}
.navbar-expand-md .navbar-nav .nav-link{padding-right: 0.5rem;padding-left: 0.5rem;}
.navbar-expand-md > .container, .navbar-expand-md > .container-fluid{-ms-flex-wrap: nowrap;flex-wrap: nowrap;}
.navbar-expand-md .navbar-collapse{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;-ms-flex-preferred-size: auto;flex-basis: auto;}
.navbar-expand-md .navbar-toggler{display: none;}
}
@media (max-width: 1023.98px){
.navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid{padding-right: 0;padding-left: 0;}
}
@media (min-width: 1024px){
.navbar-expand-lg{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row nowrap;flex-flow: row nowrap;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.navbar-expand-lg .navbar-nav{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.navbar-expand-lg .navbar-nav .dropdown-menu{position: absolute;}
.navbar-expand-lg .navbar-nav .nav-link{padding-right: 0.5rem;padding-left: 0.5rem;}
.navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid{-ms-flex-wrap: nowrap;flex-wrap: nowrap;}
.navbar-expand-lg .navbar-collapse{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;-ms-flex-preferred-size: auto;flex-basis: auto;}
.navbar-expand-lg .navbar-toggler{display: none;}
}
@media (max-width: 1398.98px){
.navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid{padding-right: 0;padding-left: 0;}
}
@media (min-width: 1399px){
.navbar-expand-xl{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row nowrap;flex-flow: row nowrap;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.navbar-expand-xl .navbar-nav{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.navbar-expand-xl .navbar-nav .dropdown-menu{position: absolute;}
.navbar-expand-xl .navbar-nav .nav-link{padding-right: 0.5rem;padding-left: 0.5rem;}
.navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid{-ms-flex-wrap: nowrap;flex-wrap: nowrap;}
.navbar-expand-xl .navbar-collapse{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;-ms-flex-preferred-size: auto;flex-basis: auto;}
.navbar-expand-xl .navbar-toggler{display: none;}
}
.navbar-expand{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row nowrap;flex-flow: row nowrap;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.navbar-expand > .container, .navbar-expand > .container-fluid{padding-right: 0;padding-left: 0;}
.navbar-expand .navbar-nav{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.navbar-expand .navbar-nav .dropdown-menu{position: absolute;}
.navbar-expand .navbar-nav .nav-link{padding-right: 0.5rem;padding-left: 0.5rem;}
.navbar-expand > .container, .navbar-expand > .container-fluid{-ms-flex-wrap: nowrap;flex-wrap: nowrap;}
.navbar-expand .navbar-collapse{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;-ms-flex-preferred-size: auto;flex-basis: auto;}
.navbar-expand .navbar-toggler{display: none;}
.navbar-light .navbar-brand{color: rgba(0, 0, 0, 0.9);}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus{color: rgba(0, 0, 0, 0.9);}
.navbar-light .navbar-nav .nav-link{color: rgba(0, 0, 0, 0.5);}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus{color: rgba(0, 0, 0, 0.7);}
.navbar-light .navbar-nav .nav-link.disabled{color: rgba(0, 0, 0, 0.3);}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active{color: rgba(0, 0, 0, 0.9);}
.navbar-light .navbar-toggler{color: rgba(0, 0, 0, 0.5);border-color: rgba(0, 0, 0, 0.1);}
.navbar-light .navbar-toggler-icon{background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
.navbar-light .navbar-text{color: rgba(0, 0, 0, 0.5);}
.navbar-light .navbar-text a{color: rgba(0, 0, 0, 0.9);}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus{color: rgba(0, 0, 0, 0.9);}
.navbar-dark .navbar-brand{color: #fff;}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus{color: #fff;}
.navbar-dark .navbar-nav .nav-link{color: rgba(255, 255, 255, 0.5);}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{color: rgba(255, 255, 255, 0.75);}
.navbar-dark .navbar-nav .nav-link.disabled{color: rgba(255, 255, 255, 0.25);}
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active{color: #fff;}
.navbar-dark .navbar-toggler{color: rgba(255, 255, 255, 0.5);border-color: rgba(255, 255, 255, 0.1);}
.navbar-dark .navbar-toggler-icon{background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");}
.navbar-dark .navbar-text{color: rgba(255, 255, 255, 0.5);}
.navbar-dark .navbar-text a{color: #fff;}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus{color: #fff;}
.card{position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;min-width: 0;word-wrap: break-word;background-color: #fff;background-clip: border-box;border: 1px solid #ebedf2;border-radius: 0.25rem;}
.card > hr{margin-right: 0;margin-left: 0;}
.card > .list-group:first-child .list-group-item:first-child{border-top-left-radius: 0.25rem;border-top-right-radius: 0.25rem;}
.card > .list-group:last-child .list-group-item:last-child{border-bottom-right-radius: 0.25rem;border-bottom-left-radius: 0.25rem;}
.card-body{-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;padding: 1.25rem;}
.card-title{margin-bottom: 0.75rem;}
.card-subtitle{margin-top: -0.375rem;margin-bottom: 0;}
.card-text:last-child{margin-bottom: 0;}
.card-link:hover{text-decoration: none;}
.card-link + .card-link{margin-left: 1.25rem;}
.card-header{padding: 0.75rem 1.25rem;margin-bottom: 0;background-color: #f7f8fa;border-bottom: 1px solid #ebedf2;}
.card-header:first-child{border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;}
.card-header + .list-group .list-group-item:first-child{border-top: 0;}
.card-footer{padding: 0.75rem 1.25rem;background-color: #f7f8fa;border-top: 1px solid #ebedf2;}
.card-footer:last-child{border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);}
.card-header-tabs{margin-right: -0.625rem;margin-bottom: -0.75rem;margin-left: -0.625rem;border-bottom: 0;}
.card-header-pills{margin-right: -0.625rem;margin-left: -0.625rem;}
.card-img-overlay{position: absolute;top: 0;right: 0;bottom: 0;left: 0;padding: 1.25rem;}
.card-img{width: 100%;border-radius: calc(0.25rem - 1px);}
.card-img-top{width: 100%;border-top-left-radius: calc(0.25rem - 1px);border-top-right-radius: calc(0.25rem - 1px);}
.card-img-bottom{width: 100%;border-bottom-right-radius: calc(0.25rem - 1px);border-bottom-left-radius: calc(0.25rem - 1px);}
.card-deck{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.card-deck .card{margin-bottom: 10px;}
@media (min-width: 576px){
.card-deck{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row wrap;flex-flow: row wrap;margin-right: -10px;margin-left: -10px;}
.card-deck .card{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex: 1 0 0%;flex: 1 0 0%;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;margin-right: 10px;margin-bottom: 0;margin-left: 10px;}
}
.card-group{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.card-group > .card{margin-bottom: 10px;}
@media (min-width: 576px){
.card-group{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row wrap;flex-flow: row wrap;}
.card-group > .card{-webkit-box-flex: 1;-ms-flex: 1 0 0%;flex: 1 0 0%;margin-bottom: 0;}
.card-group > .card + .card{margin-left: 0;border-left: 0;}
.card-group > .card:not(:last-child){
border-top-right-radius: 0;border-bottom-right-radius: 0;}
.card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header{border-top-right-radius: 0;}
.card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer{border-bottom-right-radius: 0;}
.card-group > .card:not(:first-child){
border-top-left-radius: 0;border-bottom-left-radius: 0;}
.card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header{border-top-left-radius: 0;}
.card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer{border-bottom-left-radius: 0;}
}
.card-columns .card{margin-bottom: 0.75rem;}
@media (min-width: 576px){
.card-columns{-webkit-column-count: 3;column-count: 3;-webkit-column-gap: 1.25rem;column-gap: 1.25rem;orphans: 1;widows: 1;}
.card-columns .card{display: inline-block;width: 100%;}
}
.accordion > .card{overflow: hidden;}
.accordion > .card:not(:first-of-type) .card-header:first-child{border-radius: 0;}
.accordion > .card:not(:first-of-type):not(:last-of-type){
border-bottom: 0;border-radius: 0;}
.accordion > .card:first-of-type{border-bottom: 0;border-bottom-right-radius: 0;border-bottom-left-radius: 0;}
.accordion > .card:last-of-type{border-top-left-radius: 0;border-top-right-radius: 0;}
.accordion > .card .card-header{margin-bottom: -1px;}
.breadcrumb{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;padding: 0.75rem 1rem;margin-bottom: 1rem;list-style: none;background-color: #e9ecef;border-radius: 0.25rem;}
.breadcrumb-item + .breadcrumb-item{padding-left: 0.5rem;}
.breadcrumb-item + .breadcrumb-item::before{display: inline-block;padding-right: 0.5rem;color: #6c757d;content: "/";}
.breadcrumb-item + .breadcrumb-item:hover::before{text-decoration: underline;}
.breadcrumb-item + .breadcrumb-item:hover::before{text-decoration: none;}
.breadcrumb-item.active{color: #6c757d;}
.pagination{display: -webkit-box;display: -ms-flexbox;display: flex;padding-left: 0;list-style: none;border-radius: 0.25rem;}
.page-link{position: relative;display: block;padding: 0.5rem 0.75rem;margin-left: -1px;line-height: 1.25;color: #5d78ff;background-color: #fff;border: 1px solid #ebedf2;}
.page-link:hover{z-index: 2;color: #2739c1;text-decoration: none;background-color: #ebedf2;border-color: #ebedf2;}
.page-link:focus{z-index: 2;outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.25);}
.page-item:first-child .page-link{margin-left: 0;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;}
.page-item:last-child .page-link{border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;}
.page-item.active .page-link{z-index: 1;color: #fff;background-color: #5867dd;border-color: #5867dd;}
.page-item.disabled .page-link{color: #6c757d;pointer-events: none;cursor: auto;background-color: #fff;border-color: #ebedf2;}
.pagination-lg .page-link{padding: 0.75rem 1.5rem;font-size: 1.25rem;line-height: 1.5;}
.pagination-lg .page-item:first-child .page-link{border-top-left-radius: 0.3rem;border-bottom-left-radius: 0.3rem;}
.pagination-lg .page-item:last-child .page-link{border-top-right-radius: 0.3rem;border-bottom-right-radius: 0.3rem;}
.pagination-sm .page-link{padding: 0.25rem 0.5rem;font-size: 0.875rem;line-height: 1.5;}
.pagination-sm .page-item:first-child .page-link{border-top-left-radius: 0.2rem;border-bottom-left-radius: 0.2rem;}
.pagination-sm .page-item:last-child .page-link{border-top-right-radius: 0.2rem;border-bottom-right-radius: 0.2rem;}
.badge{display: inline-block;padding: 0.5em 0.75em;font-size: 85%;font-weight: 700;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 0.25rem;-webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;}
@media (prefers-reduced-motion: reduce){
.badge{-webkit-transition: none;transition: none;}
}
a.badge:hover, a.badge:focus{text-decoration: none;}
.badge:empty{display: none;}
.btn .badge{position: relative;top: -1px;}
.badge-pill{padding-right: 0.6em;padding-left: 0.6em;border-radius: 10rem;}
.badge-primary{color: #fff;background-color: #5867dd;}
a.badge-primary:hover, a.badge-primary:focus{color: #fff;background-color: #2e40d4;}
a.badge-primary:focus, a.badge-primary.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);}
.badge-secondary{color: #212529;background-color: #e1e1ef;}
a.badge-secondary:hover, a.badge-secondary:focus{color: #212529;background-color: #c0c0dd;}
a.badge-secondary:focus, a.badge-secondary.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(225, 225, 239, 0.5);box-shadow: 0 0 0 0.2rem rgba(225, 225, 239, 0.5);}
.badge-success{color: #fff;background-color: #0abb87;}
a.badge-success:hover, a.badge-success:focus{color: #fff;background-color: #078b64;}
a.badge-success:focus, a.badge-success.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);}
.badge-info{color: #fff;background-color: #5578eb;}
a.badge-info:hover, a.badge-info:focus{color: #fff;background-color: #2754e6;}
a.badge-info:focus, a.badge-info.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);}
.badge-warning{color: #212529;background-color: #ffb822;}
a.badge-warning:hover, a.badge-warning:focus{color: #212529;background-color: #eea200;}
a.badge-warning:focus, a.badge-warning.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);}
.badge-danger{color: #fff;background-color: #fd397a;}
a.badge-danger:hover, a.badge-danger:focus{color: #fff;background-color: #fc0758;}
a.badge-danger:focus, a.badge-danger.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);}
.badge-light{color: #212529;background-color: #f8f9fa;}
a.badge-light:hover, a.badge-light:focus{color: #212529;background-color: #dae0e5;}
a.badge-light:focus, a.badge-light.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);}
.badge-dark{color: #fff;background-color: #343a40;}
a.badge-dark:hover, a.badge-dark:focus{color: #fff;background-color: #1d2124;}
a.badge-dark:focus, a.badge-dark.focus{outline: 0;-webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);}
.jumbotron{padding: 2rem 1rem;margin-bottom: 2rem;background-color: #e9ecef;border-radius: 0.3rem;}
@media (min-width: 576px){
.jumbotron{padding: 4rem 2rem;}
}
.jumbotron-fluid{padding-right: 0;padding-left: 0;border-radius: 0;}
.alert{position: relative;padding: 0.75rem 1.25rem;margin-bottom: 1rem;border: 1px solid transparent;border-radius: 0.25rem;}
.alert-heading{color: inherit;}
.alert-link{font-weight: 700;}
.alert-dismissible{padding-right: 4rem;}
.alert-dismissible .close{position: absolute;top: 0;right: 0;padding: 0.75rem 1.25rem;color: inherit;}
.alert-primary{color: #2e3673;background-color: #dee1f8;border-color: #d0d4f5;}
.alert-primary hr{border-top-color: #bbc1f1;}
.alert-primary .alert-link{color: #1f254f;}
.alert-secondary{color: #75757c;background-color: #f9f9fc;border-color: #f7f7fb;}
.alert-secondary hr{border-top-color: #e6e6f3;}
.alert-secondary .alert-link{color: #5c5c62;}
.alert-success{color: #056146;background-color: #cef1e7;border-color: #baecdd;}
.alert-success hr{border-top-color: #a6e6d3;}
.alert-success .alert-link{color: #033123;}
.alert-info{color: #2c3e7a;background-color: #dde4fb;border-color: #cfd9f9;}
.alert-info hr{border-top-color: #b8c7f6;}
.alert-info .alert-link{color: #1e2b55;}
.alert-warning{color: #856012;background-color: #fff1d3;border-color: #ffebc1;}
.alert-warning hr{border-top-color: #ffe3a8;}
.alert-warning .alert-link{color: #58400c;}
.alert-danger{color: #841e3f;background-color: #ffd7e4;border-color: #fec8da;}
.alert-danger hr{border-top-color: #feafc9;}
.alert-danger .alert-link{color: #5a152b;}
.alert-light{color: #818182;background-color: #fefefe;border-color: #fdfdfe;}
.alert-light hr{border-top-color: #ececf6;}
.alert-light .alert-link{color: #686868;}
.alert-dark{color: #1b1e21;background-color: #d6d8d9;border-color: #c6c8ca;}
.alert-dark hr{border-top-color: #b9bbbe;}
.alert-dark .alert-link{color: #040505;}
@-webkit-keyframes progress-bar-stripes{
from{background-position: 1rem 0;}
to{background-position: 0 0;}
}
@keyframes progress-bar-stripes{
from{background-position: 1rem 0;}
to{background-position: 0 0;}
}
.progress{display: -webkit-box;display: -ms-flexbox;display: flex;height: 1rem;overflow: hidden;font-size: 0.75rem;background-color: kt-get-color();border-radius: 0.25rem;}
.progress-bar{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;color: #fff;text-align: center;white-space: nowrap;background-color: #5867dd;-webkit-transition: width 0.6s ease;transition: width 0.6s ease;}
@media (prefers-reduced-motion: reduce){
.progress-bar{-webkit-transition: none;transition: none;}
}
.progress-bar-striped{background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);background-size: 1rem 1rem;}
.progress-bar-animated{-webkit-animation: progress-bar-stripes 1s linear infinite;animation: progress-bar-stripes 1s linear infinite;}
@media (prefers-reduced-motion: reduce){
.progress-bar-animated{-webkit-animation: none;animation: none;}
}
.media{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;}
.media-body{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.list-group{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;padding-left: 0;margin-bottom: 0;}
.list-group-item-action{width: 100%;color: #495057;text-align: inherit;}
.list-group-item-action:hover, .list-group-item-action:focus{z-index: 1;color: #495057;text-decoration: none;background-color: #ebedf2;}
.list-group-item-action:active{color: #212529;background-color: #e9ecef;}
.list-group-item{position: relative;display: block;padding: 0.75rem 1.25rem;margin-bottom: -1px;background-color: #fff;border: 1px solid #ebedf2;}
.list-group-item:first-child{border-top-left-radius: 0.25rem;border-top-right-radius: 0.25rem;}
.list-group-item:last-child{margin-bottom: 0;border-bottom-right-radius: 0.25rem;border-bottom-left-radius: 0.25rem;}
.list-group-item.disabled, .list-group-item:disabled{color: #6c757d;pointer-events: none;background-color: #fff;}
.list-group-item.active{z-index: 2;color: #fff;background-color: #5867dd;border-color: #5867dd;}
.list-group-horizontal{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.list-group-horizontal .list-group-item{margin-right: -1px;margin-bottom: 0;}
.list-group-horizontal .list-group-item:first-child{border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;border-top-right-radius: 0;}
.list-group-horizontal .list-group-item:last-child{margin-right: 0;border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;border-bottom-left-radius: 0;}
@media (min-width: 576px){
.list-group-horizontal-sm{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.list-group-horizontal-sm .list-group-item{margin-right: -1px;margin-bottom: 0;}
.list-group-horizontal-sm .list-group-item:first-child{border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;border-top-right-radius: 0;}
.list-group-horizontal-sm .list-group-item:last-child{margin-right: 0;border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;border-bottom-left-radius: 0;}
}
@media (min-width: 768px){
.list-group-horizontal-md{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.list-group-horizontal-md .list-group-item{margin-right: -1px;margin-bottom: 0;}
.list-group-horizontal-md .list-group-item:first-child{border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;border-top-right-radius: 0;}
.list-group-horizontal-md .list-group-item:last-child{margin-right: 0;border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;border-bottom-left-radius: 0;}
}
@media (min-width: 1024px){
.list-group-horizontal-lg{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.list-group-horizontal-lg .list-group-item{margin-right: -1px;margin-bottom: 0;}
.list-group-horizontal-lg .list-group-item:first-child{border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;border-top-right-radius: 0;}
.list-group-horizontal-lg .list-group-item:last-child{margin-right: 0;border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;border-bottom-left-radius: 0;}
}
@media (min-width: 1399px){
.list-group-horizontal-xl{-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.list-group-horizontal-xl .list-group-item{margin-right: -1px;margin-bottom: 0;}
.list-group-horizontal-xl .list-group-item:first-child{border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;border-top-right-radius: 0;}
.list-group-horizontal-xl .list-group-item:last-child{margin-right: 0;border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;border-bottom-left-radius: 0;}
}
.list-group-flush .list-group-item{border-right: 0;border-left: 0;border-radius: 0;}
.list-group-flush .list-group-item:last-child{margin-bottom: -1px;}
.list-group-flush:first-child .list-group-item:first-child{border-top: 0;}
.list-group-flush:last-child .list-group-item:last-child{margin-bottom: 0;border-bottom: 0;}
.list-group-item-primary{color: #2e3673;background-color: #d0d4f5;}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus{color: #2e3673;background-color: #bbc1f1;}
.list-group-item-primary.list-group-item-action.active{color: #fff;background-color: #2e3673;border-color: #2e3673;}
.list-group-item-secondary{color: #75757c;background-color: #f7f7fb;}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus{color: #75757c;background-color: #e6e6f3;}
.list-group-item-secondary.list-group-item-action.active{color: #fff;background-color: #75757c;border-color: #75757c;}
.list-group-item-success{color: #056146;background-color: #baecdd;}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus{color: #056146;background-color: #a6e6d3;}
.list-group-item-success.list-group-item-action.active{color: #fff;background-color: #056146;border-color: #056146;}
.list-group-item-info{color: #2c3e7a;background-color: #cfd9f9;}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus{color: #2c3e7a;background-color: #b8c7f6;}
.list-group-item-info.list-group-item-action.active{color: #fff;background-color: #2c3e7a;border-color: #2c3e7a;}
.list-group-item-warning{color: #856012;background-color: #ffebc1;}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus{color: #856012;background-color: #ffe3a8;}
.list-group-item-warning.list-group-item-action.active{color: #fff;background-color: #856012;border-color: #856012;}
.list-group-item-danger{color: #841e3f;background-color: #fec8da;}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus{color: #841e3f;background-color: #feafc9;}
.list-group-item-danger.list-group-item-action.active{color: #fff;background-color: #841e3f;border-color: #841e3f;}
.list-group-item-light{color: #818182;background-color: #fdfdfe;}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus{color: #818182;background-color: #ececf6;}
.list-group-item-light.list-group-item-action.active{color: #fff;background-color: #818182;border-color: #818182;}
.list-group-item-dark{color: #1b1e21;background-color: #c6c8ca;}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus{color: #1b1e21;background-color: #b9bbbe;}
.list-group-item-dark.list-group-item-action.active{color: #fff;background-color: #1b1e21;border-color: #1b1e21;}
.close{float: right;font-size: 1.5rem;font-weight: 700;line-height: 1;color: #000;text-shadow: 0 1px 0 #fff;opacity: .5;}
.close:hover{color: #000;text-decoration: none;}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus{opacity: .75;}
button.close{padding: 0;background-color: transparent;border: 0;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
a.close.disabled{pointer-events: none;}
.toast{max-width: 350px;overflow: hidden;font-size: 0.875rem;background-color: rgba(255, 255, 255, 0.85);background-clip: padding-box;border: 1px solid rgba(0, 0, 0, 0.1);-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);-webkit-backdrop-filter: blur(10px);backdrop-filter: blur(10px);opacity: 0;border-radius: 0.25rem;}
.toast:not(:last-child){
margin-bottom: 0.75rem;}
.toast.showing{opacity: 1;}
.toast.show{display: block;opacity: 1;}
.toast.hide{display: none;}
.toast-header{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0.25rem 0.75rem;color: #6c7293;background-color: #fff;background-clip: padding-box;border-bottom: 1px solid #ebedf2;}
.toast-body{padding: 0.75rem;}
.modal-open{overflow: hidden;}
.modal-open .modal{overflow-x: hidden;overflow-y: auto;}
.modal{position: fixed;top: 0;left: 0;z-index: 1050;display: none;width: 100%;height: 100%;overflow: hidden;outline: 0;}
.modal-dialog{position: relative;width: auto;margin: 0.5rem;pointer-events: none;}
.modal.fade .modal-dialog{-webkit-transition: -webkit-transform 0.3s ease-out;transition: -webkit-transform 0.3s ease-out;transition: transform 0.3s ease-out;transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;-webkit-transform: translate(0, -50px);transform: translate(0, -50px);}
@media (prefers-reduced-motion: reduce){
.modal.fade .modal-dialog{-webkit-transition: none;transition: none;}
}
.modal.show .modal-dialog{-webkit-transform: none;transform: none;}
.modal-dialog-scrollable{display: -webkit-box;display: -ms-flexbox;display: flex;max-height: calc(100% - 1rem);}
.modal-dialog-scrollable .modal-content{max-height: calc(100vh - 1rem);overflow: hidden;}
.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer{-ms-flex-negative: 0;flex-shrink: 0;}
.modal-dialog-scrollable .modal-body{overflow-y: auto;}
.modal-dialog-centered{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;min-height: calc(100% - 1rem);}
.modal-dialog-centered::before{display: block;height: calc(100vh - 1rem);content: "";}
.modal-dialog-centered.modal-dialog-scrollable{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;height: 100%;}
.modal-dialog-centered.modal-dialog-scrollable .modal-content{max-height: none;}
.modal-dialog-centered.modal-dialog-scrollable::before{content: none;}
.modal-content{position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;width: 100%;pointer-events: auto;background-color: #fff;background-clip: padding-box;border: 1px solid #ebedf2;border-radius: 0.3rem;outline: 0;}
.modal-backdrop{position: fixed;top: 0;left: 0;z-index: 1040;width: 100vw;height: 100vh;background-color: #000;}
.modal-backdrop.fade{opacity: 0;}
.modal-backdrop.show{opacity: 0.5;}
.modal-header{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 1.25rem;border-bottom: 1px solid #ebedf2;border-top-left-radius: 0.3rem;border-top-right-radius: 0.3rem;}
.modal-header .close{padding: 1.25rem;margin: -1rem -1rem -1rem auto;}
.modal-title{margin-bottom: 0;line-height: 1.5;}
.modal-body{position: relative;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;padding: 1.25rem;}
.modal-footer{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;padding: 1.25rem;border-top: 1px solid #ebedf2;border-bottom-right-radius: 0.3rem;border-bottom-left-radius: 0.3rem;}
.modal-footer > :not(:first-child){
margin-left: .25rem;}
.modal-footer > :not(:last-child){
margin-right: .25rem;}
.modal-scrollbar-measure{position: absolute;top: -9999px;width: 50px;height: 50px;overflow: scroll;}
@media (min-width: 576px){
.modal-dialog{max-width: 500px;margin: 1.75rem auto;}
.modal-dialog-scrollable{max-height: calc(100% - 3.5rem);}
.modal-dialog-scrollable .modal-content{max-height: calc(100vh - 3.5rem);}
.modal-dialog-centered{min-height: calc(100% - 3.5rem);}
.modal-dialog-centered::before{height: calc(100vh - 3.5rem);}
.modal-sm{max-width: 300px;}
}
@media (min-width: 1024px){
.modal-lg, .modal-xl{max-width: 800px;}
}
@media (min-width: 1399px){
.modal-xl{max-width: 1140px;}
}
.tooltip{position: absolute;z-index: 1070;display: block;margin: 0;font-family: "Poppins";font-style: normal;font-weight: 400;line-height: 1.5;text-align: left;text-align: start;text-decoration: none;text-shadow: none;text-transform: none;letter-spacing: normal;word-break: normal;word-spacing: normal;white-space: normal;line-break: auto;font-size: 0.9rem;word-wrap: break-word;opacity: 0;}
.tooltip.show{opacity: 1;}
.tooltip .arrow{position: absolute;display: block;width: 0.8rem;height: 0.4rem;}
.tooltip .arrow::before{position: absolute;content: "";border-color: transparent;border-style: solid;}
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"]{padding: 0.4rem 0;}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow{bottom: 0;}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before{top: 0;border-width: 0.4rem 0.4rem 0;border-top-color: #ffffff;}
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"]{padding: 0 0.4rem;}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow{left: 0;width: 0.4rem;height: 0.8rem;}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before{right: 0;border-width: 0.4rem 0.4rem 0.4rem 0;border-right-color: #ffffff;}
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"]{padding: 0.4rem 0;}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow{top: 0;}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before{bottom: 0;border-width: 0 0.4rem 0.4rem;border-bottom-color: #ffffff;}
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"]{padding: 0 0.4rem;}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow{right: 0;width: 0.4rem;height: 0.8rem;}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before{left: 0;border-width: 0.4rem 0 0.4rem 0.4rem;border-left-color: #ffffff;}
.tooltip-inner{max-width: 200px;padding: 0.75rem 1rem;color: #6c7293;text-align: center;background-color: #ffffff;border-radius: 3px;}
.popover{position: absolute;top: 0;left: 0;z-index: 1060;display: block;max-width: 276px;font-family: "Poppins";font-style: normal;font-weight: 400;line-height: 1.5;text-align: left;text-align: start;text-decoration: none;text-shadow: none;text-transform: none;letter-spacing: normal;word-break: normal;word-spacing: normal;white-space: normal;line-break: auto;font-size: 0.9rem;word-wrap: break-word;background-color: #fff;background-clip: padding-box;border: 1px solid #ffffff;border-radius: 3px;}
.popover .arrow{position: absolute;display: block;width: 1rem;height: 0.5rem;margin: 0 0.3rem;}
.popover .arrow::before, .popover .arrow::after{position: absolute;display: block;content: "";border-color: transparent;border-style: solid;}
.bs-popover-top, .bs-popover-auto[x-placement^="top"]{margin-bottom: 0.5rem;}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow{bottom: calc((0.5rem + 1px) * -1);}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before{bottom: 0;border-width: 0.5rem 0.5rem 0;border-top-color: white;}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after{bottom: 1px;border-width: 0.5rem 0.5rem 0;border-top-color: #fff;}
.bs-popover-right, .bs-popover-auto[x-placement^="right"]{margin-left: 0.5rem;}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow{left: calc((0.5rem + 1px) * -1);width: 0.5rem;height: 1rem;margin: 0.3rem 0;}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before{left: 0;border-width: 0.5rem 0.5rem 0.5rem 0;border-right-color: white;}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after{left: 1px;border-width: 0.5rem 0.5rem 0.5rem 0;border-right-color: #fff;}
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"]{margin-top: 0.5rem;}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow{top: calc((0.5rem + 1px) * -1);}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before{top: 0;border-width: 0 0.5rem 0.5rem 0.5rem;border-bottom-color: white;}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after{top: 1px;border-width: 0 0.5rem 0.5rem 0.5rem;border-bottom-color: #fff;}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before{position: absolute;top: 0;left: 50%;display: block;width: 1rem;margin-left: -0.5rem;content: "";border-bottom: 1px solid #ffffff;}
.bs-popover-left, .bs-popover-auto[x-placement^="left"]{margin-right: 0.5rem;}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow{right: calc((0.5rem + 1px) * -1);width: 0.5rem;height: 1rem;margin: 0.3rem 0;}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before{right: 0;border-width: 0.5rem 0 0.5rem 0.5rem;border-left-color: white;}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after{right: 1px;border-width: 0.5rem 0 0.5rem 0.5rem;border-left-color: #fff;}
.popover-header{padding: 1rem 1.25rem;margin-bottom: 0;font-size: 1rem;color: #6c7293;background-color: #ffffff;border-bottom: 1px solid #f2f2f2;border-top-left-radius: calc(0.3rem - 1px);border-top-right-radius: calc(0.3rem - 1px);}
.popover-header:empty{display: none;}
.popover-body{padding: 1rem 1.25rem;color: #a7abc3;}
.carousel{position: relative;}
.carousel.pointer-event{-ms-touch-action: pan-y;touch-action: pan-y;}
.carousel-inner{position: relative;width: 100%;overflow: hidden;}
.carousel-inner::after{display: block;clear: both;content: "";}
.carousel-item{position: relative;display: none;float: left;width: 100%;margin-right: -100%;-webkit-backface-visibility: hidden;backface-visibility: hidden;-webkit-transition: -webkit-transform 0.6s ease-in-out;transition: -webkit-transform 0.6s ease-in-out;transition: transform 0.6s ease-in-out;transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;}
@media (prefers-reduced-motion: reduce){
.carousel-item{-webkit-transition: none;transition: none;}
}
.carousel-item.active, .carousel-item-next, .carousel-item-prev{display: block;}
.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right{-webkit-transform: translateX(100%);transform: translateX(100%);}
.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left{-webkit-transform: translateX(-100%);transform: translateX(-100%);}
.carousel-fade .carousel-item{opacity: 0;-webkit-transition-property: opacity;transition-property: opacity;-webkit-transform: none;transform: none;}
.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right{z-index: 1;opacity: 1;}
.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right{z-index: 0;opacity: 0;-webkit-transition: 0s 0.6s opacity;transition: 0s 0.6s opacity;}
@media (prefers-reduced-motion: reduce){
.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right{-webkit-transition: none;transition: none;}
}
.carousel-control-prev, .carousel-control-next{position: absolute;top: 0;bottom: 0;z-index: 1;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;width: 15%;color: #fff;text-align: center;opacity: 0.5;-webkit-transition: opacity 0.15s ease;transition: opacity 0.15s ease;}
@media (prefers-reduced-motion: reduce){
.carousel-control-prev, .carousel-control-next{-webkit-transition: none;transition: none;}
}
.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus{color: #fff;text-decoration: none;outline: 0;opacity: 0.9;}
.carousel-control-prev{left: 0;}
.carousel-control-next{right: 0;}
.carousel-control-prev-icon, .carousel-control-next-icon{display: inline-block;width: 20px;height: 20px;background: no-repeat 50% / 100% 100%;}
.carousel-control-prev-icon{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");}
.carousel-control-next-icon{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");}
.carousel-indicators{position: absolute;right: 0;bottom: 0;left: 0;z-index: 15;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;padding-left: 0;margin-right: 15%;margin-left: 15%;list-style: none;}
.carousel-indicators li{-webkit-box-sizing: content-box;box-sizing: content-box;-webkit-box-flex: 0;-ms-flex: 0 1 auto;flex: 0 1 auto;width: 30px;height: 3px;margin-right: 3px;margin-left: 3px;text-indent: -999px;cursor: pointer;background-color: #fff;background-clip: padding-box;border-top: 10px solid transparent;border-bottom: 10px solid transparent;opacity: .5;-webkit-transition: opacity 0.6s ease;transition: opacity 0.6s ease;}
@media (prefers-reduced-motion: reduce){
.carousel-indicators li{-webkit-transition: none;transition: none;}
}
.carousel-indicators .active{opacity: 1;}
.carousel-caption{position: absolute;right: 15%;bottom: 20px;left: 15%;z-index: 10;padding-top: 20px;padding-bottom: 20px;color: #fff;text-align: center;}
@-webkit-keyframes spinner-border{to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes spinner-border{to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
.spinner-border{display: inline-block;width: 2rem;height: 2rem;vertical-align: text-bottom;border: 0.25em solid currentColor;border-right-color: transparent;border-radius: 50%;-webkit-animation: spinner-border .75s linear infinite;animation: spinner-border .75s linear infinite;}
.spinner-border-sm{width: 1rem;height: 1rem;border-width: 0.2em;}
@-webkit-keyframes spinner-grow{0%{-webkit-transform: scale(0);transform: scale(0);}
50%{opacity: 1;}
}
@keyframes spinner-grow{0%{-webkit-transform: scale(0);transform: scale(0);}
50%{opacity: 1;}
}
.spinner-grow{display: inline-block;width: 2rem;height: 2rem;vertical-align: text-bottom;background-color: currentColor;border-radius: 50%;opacity: 0;-webkit-animation: spinner-grow .75s linear infinite;animation: spinner-grow .75s linear infinite;}
.spinner-grow-sm{width: 1rem;height: 1rem;}
.align-baseline{vertical-align: baseline !important;}
.align-top{vertical-align: top !important;}
.align-middle{vertical-align: middle !important;}
.align-bottom{vertical-align: bottom !important;}
.align-text-bottom{vertical-align: text-bottom !important;}
.align-text-top{vertical-align: text-top !important;}
.bg-primary{background-color: #5867dd !important;}
a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus{background-color: #2e40d4 !important;}
.bg-secondary{background-color: #e1e1ef !important;}
a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus{background-color: #c0c0dd !important;}
.bg-success{background-color: #0abb87 !important;}
a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus{background-color: #078b64 !important;}
.bg-info{background-color: #5578eb !important;}
a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus{background-color: #2754e6 !important;}
.bg-warning{background-color: #ffb822 !important;}
a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus{background-color: #eea200 !important;}
.bg-danger{background-color: #fd397a !important;}
a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus{background-color: #fc0758 !important;}
.bg-light{background-color: #f8f9fa !important;}
a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus{background-color: #dae0e5 !important;}
.bg-dark{background-color: #343a40 !important;}
a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus{background-color: #1d2124 !important;}
.bg-white{background-color: #fff !important;}
.bg-transparent{background-color: transparent !important;}
.border{border: 1px solid #dee2e6 !important;}
.border-top{border-top: 1px solid #dee2e6 !important;}
.border-right{border-right: 1px solid #dee2e6 !important;}
.border-bottom{border-bottom: 1px solid #dee2e6 !important;}
.border-left{border-left: 1px solid #dee2e6 !important;}
.border-0{border: 0 !important;}
.border-top-0{border-top: 0 !important;}
.border-right-0{border-right: 0 !important;}
.border-bottom-0{border-bottom: 0 !important;}
.border-left-0{border-left: 0 !important;}
.border-primary{border-color: #5867dd !important;}
.border-secondary{border-color: #e1e1ef !important;}
.border-success{border-color: #0abb87 !important;}
.border-info{border-color: #5578eb !important;}
.border-warning{border-color: #ffb822 !important;}
.border-danger{border-color: #fd397a !important;}
.border-light{border-color: #f8f9fa !important;}
.border-dark{border-color: #343a40 !important;}
.border-white{border-color: #fff !important;}
.rounded-sm{border-radius: 0.2rem !important;}
.rounded{border-radius: 0.25rem !important;}
.rounded-top{border-top-left-radius: 0.25rem !important;border-top-right-radius: 0.25rem !important;}
.rounded-right{border-top-right-radius: 0.25rem !important;border-bottom-right-radius: 0.25rem !important;}
.rounded-bottom{border-bottom-right-radius: 0.25rem !important;border-bottom-left-radius: 0.25rem !important;}
.rounded-left{border-top-left-radius: 0.25rem !important;border-bottom-left-radius: 0.25rem !important;}
.rounded-lg{border-radius: 0.3rem !important;}
.rounded-circle{border-radius: 50% !important;}
.rounded-pill{border-radius: 50rem !important;}
.rounded-0{border-radius: 0 !important;}
.clearfix::after{display: block;clear: both;content: "";}
.d-none{display: none !important;}
.d-inline{display: inline !important;}
.d-inline-block{display: inline-block !important;}
.d-block{display: block !important;}
.d-table{display: table !important;}
.d-table-row{display: table-row !important;}
.d-table-cell{display: table-cell !important;}
.d-flex{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;}
.d-inline-flex{display: -webkit-inline-box !important;display: -ms-inline-flexbox !important;display: inline-flex !important;}
@media (min-width: 576px){
.d-sm-none{display: none !important;}
.d-sm-inline{display: inline !important;}
.d-sm-inline-block{display: inline-block !important;}
.d-sm-block{display: block !important;}
.d-sm-table{display: table !important;}
.d-sm-table-row{display: table-row !important;}
.d-sm-table-cell{display: table-cell !important;}
.d-sm-flex{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;}
.d-sm-inline-flex{display: -webkit-inline-box !important;display: -ms-inline-flexbox !important;display: inline-flex !important;}
}
@media (min-width: 768px){
.d-md-none{display: none !important;}
.d-md-inline{display: inline !important;}
.d-md-inline-block{display: inline-block !important;}
.d-md-block{display: block !important;}
.d-md-table{display: table !important;}
.d-md-table-row{display: table-row !important;}
.d-md-table-cell{display: table-cell !important;}
.d-md-flex{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;}
.d-md-inline-flex{display: -webkit-inline-box !important;display: -ms-inline-flexbox !important;display: inline-flex !important;}
}
@media (min-width: 1024px){
.d-lg-none{display: none !important;}
.d-lg-inline{display: inline !important;}
.d-lg-inline-block{display: inline-block !important;}
.d-lg-block{display: block !important;}
.d-lg-table{display: table !important;}
.d-lg-table-row{display: table-row !important;}
.d-lg-table-cell{display: table-cell !important;}
.d-lg-flex{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;}
.d-lg-inline-flex{display: -webkit-inline-box !important;display: -ms-inline-flexbox !important;display: inline-flex !important;}
}
@media (min-width: 1399px){
.d-xl-none{display: none !important;}
.d-xl-inline{display: inline !important;}
.d-xl-inline-block{display: inline-block !important;}
.d-xl-block{display: block !important;}
.d-xl-table{display: table !important;}
.d-xl-table-row{display: table-row !important;}
.d-xl-table-cell{display: table-cell !important;}
.d-xl-flex{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;}
.d-xl-inline-flex{display: -webkit-inline-box !important;display: -ms-inline-flexbox !important;display: inline-flex !important;}
}
@media print{.d-print-none{display: none !important;}
.d-print-inline{display: inline !important;}
.d-print-inline-block{display: inline-block !important;}
.d-print-block{display: block !important;}
.d-print-table{display: table !important;}
.d-print-table-row{display: table-row !important;}
.d-print-table-cell{display: table-cell !important;}
.d-print-flex{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;}
.d-print-inline-flex{display: -webkit-inline-box !important;display: -ms-inline-flexbox !important;display: inline-flex !important;}
}
.embed-responsive{position: relative;display: block;width: 100%;padding: 0;overflow: hidden;}
.embed-responsive::before{display: block;content: "";}
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video{position: absolute;top: 0;bottom: 0;left: 0;width: 100%;height: 100%;border: 0;}
.embed-responsive-21by9::before{padding-top: 42.85714%;}
.embed-responsive-16by9::before{padding-top: 56.25%;}
.embed-responsive-4by3::before{padding-top: 75%;}
.embed-responsive-1by1::before{padding-top: 100%;}
.flex-row{-webkit-box-orient: horizontal !important;-webkit-box-direction: normal !important;-ms-flex-direction: row !important;flex-direction: row !important;}
.flex-column{-webkit-box-orient: vertical !important;-webkit-box-direction: normal !important;-ms-flex-direction: column !important;flex-direction: column !important;}
.flex-row-reverse{-webkit-box-orient: horizontal !important;-webkit-box-direction: reverse !important;-ms-flex-direction: row-reverse !important;flex-direction: row-reverse !important;}
.flex-column-reverse{-webkit-box-orient: vertical !important;-webkit-box-direction: reverse !important;-ms-flex-direction: column-reverse !important;flex-direction: column-reverse !important;}
.flex-wrap{-ms-flex-wrap: wrap !important;flex-wrap: wrap !important;}
.flex-nowrap{-ms-flex-wrap: nowrap !important;flex-wrap: nowrap !important;}
.flex-wrap-reverse{-ms-flex-wrap: wrap-reverse !important;flex-wrap: wrap-reverse !important;}
.flex-fill{-webkit-box-flex: 1 !important;-ms-flex: 1 1 auto !important;flex: 1 1 auto !important;}
.flex-grow-0{-webkit-box-flex: 0 !important;-ms-flex-positive: 0 !important;flex-grow: 0 !important;}
.flex-grow-1{-webkit-box-flex: 1 !important;-ms-flex-positive: 1 !important;flex-grow: 1 !important;}
.flex-shrink-0{-ms-flex-negative: 0 !important;flex-shrink: 0 !important;}
.flex-shrink-1{-ms-flex-negative: 1 !important;flex-shrink: 1 !important;}
.justify-content-start{-webkit-box-pack: start !important;-ms-flex-pack: start !important;justify-content: flex-start !important;}
.justify-content-end{-webkit-box-pack: end !important;-ms-flex-pack: end !important;justify-content: flex-end !important;}
.justify-content-center{-webkit-box-pack: center !important;-ms-flex-pack: center !important;justify-content: center !important;}
.justify-content-between{-webkit-box-pack: justify !important;-ms-flex-pack: justify !important;justify-content: space-between !important;}
.justify-content-around{-ms-flex-pack: distribute !important;justify-content: space-around !important;}
.align-items-start{-webkit-box-align: start !important;-ms-flex-align: start !important;align-items: flex-start !important;}
.align-items-end{-webkit-box-align: end !important;-ms-flex-align: end !important;align-items: flex-end !important;}
.align-items-center{-webkit-box-align: center !important;-ms-flex-align: center !important;align-items: center !important;}
.align-items-baseline{-webkit-box-align: baseline !important;-ms-flex-align: baseline !important;align-items: baseline !important;}
.align-items-stretch{-webkit-box-align: stretch !important;-ms-flex-align: stretch !important;align-items: stretch !important;}
.align-content-start{-ms-flex-line-pack: start !important;align-content: flex-start !important;}
.align-content-end{-ms-flex-line-pack: end !important;align-content: flex-end !important;}
.align-content-center{-ms-flex-line-pack: center !important;align-content: center !important;}
.align-content-between{-ms-flex-line-pack: justify !important;align-content: space-between !important;}
.align-content-around{-ms-flex-line-pack: distribute !important;align-content: space-around !important;}
.align-content-stretch{-ms-flex-line-pack: stretch !important;align-content: stretch !important;}
.align-self-auto{-ms-flex-item-align: auto !important;align-self: auto !important;}
.align-self-start{-ms-flex-item-align: start !important;align-self: flex-start !important;}
.align-self-end{-ms-flex-item-align: end !important;align-self: flex-end !important;}
.align-self-center{-ms-flex-item-align: center !important;align-self: center !important;}
.align-self-baseline{-ms-flex-item-align: baseline !important;align-self: baseline !important;}
.align-self-stretch{-ms-flex-item-align: stretch !important;align-self: stretch !important;}
@media (min-width: 576px){
.flex-sm-row{-webkit-box-orient: horizontal !important;-webkit-box-direction: normal !important;-ms-flex-direction: row !important;flex-direction: row !important;}
.flex-sm-column{-webkit-box-orient: vertical !important;-webkit-box-direction: normal !important;-ms-flex-direction: column !important;flex-direction: column !important;}
.flex-sm-row-reverse{-webkit-box-orient: horizontal !important;-webkit-box-direction: reverse !important;-ms-flex-direction: row-reverse !important;flex-direction: row-reverse !important;}
.flex-sm-column-reverse{-webkit-box-orient: vertical !important;-webkit-box-direction: reverse !important;-ms-flex-direction: column-reverse !important;flex-direction: column-reverse !important;}
.flex-sm-wrap{-ms-flex-wrap: wrap !important;flex-wrap: wrap !important;}
.flex-sm-nowrap{-ms-flex-wrap: nowrap !important;flex-wrap: nowrap !important;}
.flex-sm-wrap-reverse{-ms-flex-wrap: wrap-reverse !important;flex-wrap: wrap-reverse !important;}
.flex-sm-fill{-webkit-box-flex: 1 !important;-ms-flex: 1 1 auto !important;flex: 1 1 auto !important;}
.flex-sm-grow-0{-webkit-box-flex: 0 !important;-ms-flex-positive: 0 !important;flex-grow: 0 !important;}
.flex-sm-grow-1{-webkit-box-flex: 1 !important;-ms-flex-positive: 1 !important;flex-grow: 1 !important;}
.flex-sm-shrink-0{-ms-flex-negative: 0 !important;flex-shrink: 0 !important;}
.flex-sm-shrink-1{-ms-flex-negative: 1 !important;flex-shrink: 1 !important;}
.justify-content-sm-start{-webkit-box-pack: start !important;-ms-flex-pack: start !important;justify-content: flex-start !important;}
.justify-content-sm-end{-webkit-box-pack: end !important;-ms-flex-pack: end !important;justify-content: flex-end !important;}
.justify-content-sm-center{-webkit-box-pack: center !important;-ms-flex-pack: center !important;justify-content: center !important;}
.justify-content-sm-between{-webkit-box-pack: justify !important;-ms-flex-pack: justify !important;justify-content: space-between !important;}
.justify-content-sm-around{-ms-flex-pack: distribute !important;justify-content: space-around !important;}
.align-items-sm-start{-webkit-box-align: start !important;-ms-flex-align: start !important;align-items: flex-start !important;}
.align-items-sm-end{-webkit-box-align: end !important;-ms-flex-align: end !important;align-items: flex-end !important;}
.align-items-sm-center{-webkit-box-align: center !important;-ms-flex-align: center !important;align-items: center !important;}
.align-items-sm-baseline{-webkit-box-align: baseline !important;-ms-flex-align: baseline !important;align-items: baseline !important;}
.align-items-sm-stretch{-webkit-box-align: stretch !important;-ms-flex-align: stretch !important;align-items: stretch !important;}
.align-content-sm-start{-ms-flex-line-pack: start !important;align-content: flex-start !important;}
.align-content-sm-end{-ms-flex-line-pack: end !important;align-content: flex-end !important;}
.align-content-sm-center{-ms-flex-line-pack: center !important;align-content: center !important;}
.align-content-sm-between{-ms-flex-line-pack: justify !important;align-content: space-between !important;}
.align-content-sm-around{-ms-flex-line-pack: distribute !important;align-content: space-around !important;}
.align-content-sm-stretch{-ms-flex-line-pack: stretch !important;align-content: stretch !important;}
.align-self-sm-auto{-ms-flex-item-align: auto !important;align-self: auto !important;}
.align-self-sm-start{-ms-flex-item-align: start !important;align-self: flex-start !important;}
.align-self-sm-end{-ms-flex-item-align: end !important;align-self: flex-end !important;}
.align-self-sm-center{-ms-flex-item-align: center !important;align-self: center !important;}
.align-self-sm-baseline{-ms-flex-item-align: baseline !important;align-self: baseline !important;}
.align-self-sm-stretch{-ms-flex-item-align: stretch !important;align-self: stretch !important;}
}
@media (min-width: 768px){
.flex-md-row{-webkit-box-orient: horizontal !important;-webkit-box-direction: normal !important;-ms-flex-direction: row !important;flex-direction: row !important;}
.flex-md-column{-webkit-box-orient: vertical !important;-webkit-box-direction: normal !important;-ms-flex-direction: column !important;flex-direction: column !important;}
.flex-md-row-reverse{-webkit-box-orient: horizontal !important;-webkit-box-direction: reverse !important;-ms-flex-direction: row-reverse !important;flex-direction: row-reverse !important;}
.flex-md-column-reverse{-webkit-box-orient: vertical !important;-webkit-box-direction: reverse !important;-ms-flex-direction: column-reverse !important;flex-direction: column-reverse !important;}
.flex-md-wrap{-ms-flex-wrap: wrap !important;flex-wrap: wrap !important;}
.flex-md-nowrap{-ms-flex-wrap: nowrap !important;flex-wrap: nowrap !important;}
.flex-md-wrap-reverse{-ms-flex-wrap: wrap-reverse !important;flex-wrap: wrap-reverse !important;}
.flex-md-fill{-webkit-box-flex: 1 !important;-ms-flex: 1 1 auto !important;flex: 1 1 auto !important;}
.flex-md-grow-0{-webkit-box-flex: 0 !important;-ms-flex-positive: 0 !important;flex-grow: 0 !important;}
.flex-md-grow-1{-webkit-box-flex: 1 !important;-ms-flex-positive: 1 !important;flex-grow: 1 !important;}
.flex-md-shrink-0{-ms-flex-negative: 0 !important;flex-shrink: 0 !important;}
.flex-md-shrink-1{-ms-flex-negative: 1 !important;flex-shrink: 1 !important;}
.justify-content-md-start{-webkit-box-pack: start !important;-ms-flex-pack: start !important;justify-content: flex-start !important;}
.justify-content-md-end{-webkit-box-pack: end !important;-ms-flex-pack: end !important;justify-content: flex-end !important;}
.justify-content-md-center{-webkit-box-pack: center !important;-ms-flex-pack: center !important;justify-content: center !important;}
.justify-content-md-between{-webkit-box-pack: justify !important;-ms-flex-pack: justify !important;justify-content: space-between !important;}
.justify-content-md-around{-ms-flex-pack: distribute !important;justify-content: space-around !important;}
.align-items-md-start{-webkit-box-align: start !important;-ms-flex-align: start !important;align-items: flex-start !important;}
.align-items-md-end{-webkit-box-align: end !important;-ms-flex-align: end !important;align-items: flex-end !important;}
.align-items-md-center{-webkit-box-align: center !important;-ms-flex-align: center !important;align-items: center !important;}
.align-items-md-baseline{-webkit-box-align: baseline !important;-ms-flex-align: baseline !important;align-items: baseline !important;}
.align-items-md-stretch{-webkit-box-align: stretch !important;-ms-flex-align: stretch !important;align-items: stretch !important;}
.align-content-md-start{-ms-flex-line-pack: start !important;align-content: flex-start !important;}
.align-content-md-end{-ms-flex-line-pack: end !important;align-content: flex-end !important;}
.align-content-md-center{-ms-flex-line-pack: center !important;align-content: center !important;}
.align-content-md-between{-ms-flex-line-pack: justify !important;align-content: space-between !important;}
.align-content-md-around{-ms-flex-line-pack: distribute !important;align-content: space-around !important;}
.align-content-md-stretch{-ms-flex-line-pack: stretch !important;align-content: stretch !important;}
.align-self-md-auto{-ms-flex-item-align: auto !important;align-self: auto !important;}
.align-self-md-start{-ms-flex-item-align: start !important;align-self: flex-start !important;}
.align-self-md-end{-ms-flex-item-align: end !important;align-self: flex-end !important;}
.align-self-md-center{-ms-flex-item-align: center !important;align-self: center !important;}
.align-self-md-baseline{-ms-flex-item-align: baseline !important;align-self: baseline !important;}
.align-self-md-stretch{-ms-flex-item-align: stretch !important;align-self: stretch !important;}
}
@media (min-width: 1024px){
.flex-lg-row{-webkit-box-orient: horizontal !important;-webkit-box-direction: normal !important;-ms-flex-direction: row !important;flex-direction: row !important;}
.flex-lg-column{-webkit-box-orient: vertical !important;-webkit-box-direction: normal !important;-ms-flex-direction: column !important;flex-direction: column !important;}
.flex-lg-row-reverse{-webkit-box-orient: horizontal !important;-webkit-box-direction: reverse !important;-ms-flex-direction: row-reverse !important;flex-direction: row-reverse !important;}
.flex-lg-column-reverse{-webkit-box-orient: vertical !important;-webkit-box-direction: reverse !important;-ms-flex-direction: column-reverse !important;flex-direction: column-reverse !important;}
.flex-lg-wrap{-ms-flex-wrap: wrap !important;flex-wrap: wrap !important;}
.flex-lg-nowrap{-ms-flex-wrap: nowrap !important;flex-wrap: nowrap !important;}
.flex-lg-wrap-reverse{-ms-flex-wrap: wrap-reverse !important;flex-wrap: wrap-reverse !important;}
.flex-lg-fill{-webkit-box-flex: 1 !important;-ms-flex: 1 1 auto !important;flex: 1 1 auto !important;}
.flex-lg-grow-0{-webkit-box-flex: 0 !important;-ms-flex-positive: 0 !important;flex-grow: 0 !important;}
.flex-lg-grow-1{-webkit-box-flex: 1 !important;-ms-flex-positive: 1 !important;flex-grow: 1 !important;}
.flex-lg-shrink-0{-ms-flex-negative: 0 !important;flex-shrink: 0 !important;}
.flex-lg-shrink-1{-ms-flex-negative: 1 !important;flex-shrink: 1 !important;}
.justify-content-lg-start{-webkit-box-pack: start !important;-ms-flex-pack: start !important;justify-content: flex-start !important;}
.justify-content-lg-end{-webkit-box-pack: end !important;-ms-flex-pack: end !important;justify-content: flex-end !important;}
.justify-content-lg-center{-webkit-box-pack: center !important;-ms-flex-pack: center !important;justify-content: center !important;}
.justify-content-lg-between{-webkit-box-pack: justify !important;-ms-flex-pack: justify !important;justify-content: space-between !important;}
.justify-content-lg-around{-ms-flex-pack: distribute !important;justify-content: space-around !important;}
.align-items-lg-start{-webkit-box-align: start !important;-ms-flex-align: start !important;align-items: flex-start !important;}
.align-items-lg-end{-webkit-box-align: end !important;-ms-flex-align: end !important;align-items: flex-end !important;}
.align-items-lg-center{-webkit-box-align: center !important;-ms-flex-align: center !important;align-items: center !important;}
.align-items-lg-baseline{-webkit-box-align: baseline !important;-ms-flex-align: baseline !important;align-items: baseline !important;}
.align-items-lg-stretch{-webkit-box-align: stretch !important;-ms-flex-align: stretch !important;align-items: stretch !important;}
.align-content-lg-start{-ms-flex-line-pack: start !important;align-content: flex-start !important;}
.align-content-lg-end{-ms-flex-line-pack: end !important;align-content: flex-end !important;}
.align-content-lg-center{-ms-flex-line-pack: center !important;align-content: center !important;}
.align-content-lg-between{-ms-flex-line-pack: justify !important;align-content: space-between !important;}
.align-content-lg-around{-ms-flex-line-pack: distribute !important;align-content: space-around !important;}
.align-content-lg-stretch{-ms-flex-line-pack: stretch !important;align-content: stretch !important;}
.align-self-lg-auto{-ms-flex-item-align: auto !important;align-self: auto !important;}
.align-self-lg-start{-ms-flex-item-align: start !important;align-self: flex-start !important;}
.align-self-lg-end{-ms-flex-item-align: end !important;align-self: flex-end !important;}
.align-self-lg-center{-ms-flex-item-align: center !important;align-self: center !important;}
.align-self-lg-baseline{-ms-flex-item-align: baseline !important;align-self: baseline !important;}
.align-self-lg-stretch{-ms-flex-item-align: stretch !important;align-self: stretch !important;}
}
@media (min-width: 1399px){
.flex-xl-row{-webkit-box-orient: horizontal !important;-webkit-box-direction: normal !important;-ms-flex-direction: row !important;flex-direction: row !important;}
.flex-xl-column{-webkit-box-orient: vertical !important;-webkit-box-direction: normal !important;-ms-flex-direction: column !important;flex-direction: column !important;}
.flex-xl-row-reverse{-webkit-box-orient: horizontal !important;-webkit-box-direction: reverse !important;-ms-flex-direction: row-reverse !important;flex-direction: row-reverse !important;}
.flex-xl-column-reverse{-webkit-box-orient: vertical !important;-webkit-box-direction: reverse !important;-ms-flex-direction: column-reverse !important;flex-direction: column-reverse !important;}
.flex-xl-wrap{-ms-flex-wrap: wrap !important;flex-wrap: wrap !important;}
.flex-xl-nowrap{-ms-flex-wrap: nowrap !important;flex-wrap: nowrap !important;}
.flex-xl-wrap-reverse{-ms-flex-wrap: wrap-reverse !important;flex-wrap: wrap-reverse !important;}
.flex-xl-fill{-webkit-box-flex: 1 !important;-ms-flex: 1 1 auto !important;flex: 1 1 auto !important;}
.flex-xl-grow-0{-webkit-box-flex: 0 !important;-ms-flex-positive: 0 !important;flex-grow: 0 !important;}
.flex-xl-grow-1{-webkit-box-flex: 1 !important;-ms-flex-positive: 1 !important;flex-grow: 1 !important;}
.flex-xl-shrink-0{-ms-flex-negative: 0 !important;flex-shrink: 0 !important;}
.flex-xl-shrink-1{-ms-flex-negative: 1 !important;flex-shrink: 1 !important;}
.justify-content-xl-start{-webkit-box-pack: start !important;-ms-flex-pack: start !important;justify-content: flex-start !important;}
.justify-content-xl-end{-webkit-box-pack: end !important;-ms-flex-pack: end !important;justify-content: flex-end !important;}
.justify-content-xl-center{-webkit-box-pack: center !important;-ms-flex-pack: center !important;justify-content: center !important;}
.justify-content-xl-between{-webkit-box-pack: justify !important;-ms-flex-pack: justify !important;justify-content: space-between !important;}
.justify-content-xl-around{-ms-flex-pack: distribute !important;justify-content: space-around !important;}
.align-items-xl-start{-webkit-box-align: start !important;-ms-flex-align: start !important;align-items: flex-start !important;}
.align-items-xl-end{-webkit-box-align: end !important;-ms-flex-align: end !important;align-items: flex-end !important;}
.align-items-xl-center{-webkit-box-align: center !important;-ms-flex-align: center !important;align-items: center !important;}
.align-items-xl-baseline{-webkit-box-align: baseline !important;-ms-flex-align: baseline !important;align-items: baseline !important;}
.align-items-xl-stretch{-webkit-box-align: stretch !important;-ms-flex-align: stretch !important;align-items: stretch !important;}
.align-content-xl-start{-ms-flex-line-pack: start !important;align-content: flex-start !important;}
.align-content-xl-end{-ms-flex-line-pack: end !important;align-content: flex-end !important;}
.align-content-xl-center{-ms-flex-line-pack: center !important;align-content: center !important;}
.align-content-xl-between{-ms-flex-line-pack: justify !important;align-content: space-between !important;}
.align-content-xl-around{-ms-flex-line-pack: distribute !important;align-content: space-around !important;}
.align-content-xl-stretch{-ms-flex-line-pack: stretch !important;align-content: stretch !important;}
.align-self-xl-auto{-ms-flex-item-align: auto !important;align-self: auto !important;}
.align-self-xl-start{-ms-flex-item-align: start !important;align-self: flex-start !important;}
.align-self-xl-end{-ms-flex-item-align: end !important;align-self: flex-end !important;}
.align-self-xl-center{-ms-flex-item-align: center !important;align-self: center !important;}
.align-self-xl-baseline{-ms-flex-item-align: baseline !important;align-self: baseline !important;}
.align-self-xl-stretch{-ms-flex-item-align: stretch !important;align-self: stretch !important;}
}
.float-left{float: left !important;}
.float-right{float: right !important;}
.float-none{float: none !important;}
@media (min-width: 576px){
.float-sm-left{float: left !important;}
.float-sm-right{float: right !important;}
.float-sm-none{float: none !important;}
}
@media (min-width: 768px){
.float-md-left{float: left !important;}
.float-md-right{float: right !important;}
.float-md-none{float: none !important;}
}
@media (min-width: 1024px){
.float-lg-left{float: left !important;}
.float-lg-right{float: right !important;}
.float-lg-none{float: none !important;}
}
@media (min-width: 1399px){
.float-xl-left{float: left !important;}
.float-xl-right{float: right !important;}
.float-xl-none{float: none !important;}
}
.overflow-auto{overflow: auto !important;}
.overflow-hidden{overflow: hidden !important;}
.position-static{position: static !important;}
.position-relative{position: relative !important;}
.position-absolute{position: absolute !important;}
.position-fixed{position: fixed !important;}
.position-sticky{position: -webkit-sticky !important;position: sticky !important;}
.fixed-top{position: fixed;top: 0;right: 0;left: 0;z-index: 1030;}
.fixed-bottom{position: fixed;right: 0;bottom: 0;left: 0;z-index: 1030;}
@supports ((position: -webkit-sticky) or (position: sticky)){
.sticky-top{position: -webkit-sticky;position: sticky;top: 0;z-index: 1020;}
}
.sr-only{position: absolute;width: 1px;height: 1px;padding: 0;overflow: hidden;clip: rect(0, 0, 0, 0);white-space: nowrap;border: 0;}
.sr-only-focusable:active, .sr-only-focusable:focus{position: static;width: auto;height: auto;overflow: visible;clip: auto;white-space: normal;}
.shadow-sm{-webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;}
.shadow{-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;}
.shadow-lg{-webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;}
.shadow-none{-webkit-box-shadow: none !important;box-shadow: none !important;}
.w-25{width: 25% !important;}
.w-50{width: 50% !important;}
.w-75{width: 75% !important;}
.w-100{width: 100% !important;}
.w-auto{width: auto !important;}
.h-25{height: 25% !important;}
.h-50{height: 50% !important;}
.h-75{height: 75% !important;}
.h-100{height: 100% !important;}
.h-auto{height: auto !important;}
.mw-100{max-width: 100% !important;}
.mh-100{max-height: 100% !important;}
.min-vw-100{min-width: 100vw !important;}
.min-vh-100{min-height: 100vh !important;}
.vw-100{width: 100vw !important;}
.vh-100{height: 100vh !important;}
.stretched-link::after{position: absolute;top: 0;right: 0;bottom: 0;left: 0;z-index: 1;pointer-events: auto;content: "";background-color: rgba(0, 0, 0, 0);}
.m-0{margin: 0 !important;}
.mt-0, .my-0{margin-top: 0 !important;}
.mr-0, .mx-0{margin-right: 0 !important;}
.mb-0, .my-0{margin-bottom: 0 !important;}
.ml-0, .mx-0{margin-left: 0 !important;}
.m-1{margin: 0.25rem !important;}
.mt-1, .my-1{margin-top: 0.25rem !important;}
.mr-1, .mx-1{margin-right: 0.25rem !important;}
.mb-1, .my-1{margin-bottom: 0.25rem !important;}
.ml-1, .mx-1{margin-left: 0.25rem !important;}
.m-2{margin: 0.5rem !important;}
.mt-2, .my-2{margin-top: 0.5rem !important;}
.mr-2, .mx-2{margin-right: 0.5rem !important;}
.mb-2, .my-2{margin-bottom: 0.5rem !important;}
.ml-2, .mx-2{margin-left: 0.5rem !important;}
.m-3{margin: 1rem !important;}
.mt-3, .my-3{margin-top: 1rem !important;}
.mr-3, .mx-3{margin-right: 1rem !important;}
.mb-3, .my-3{margin-bottom: 1rem !important;}
.ml-3, .mx-3{margin-left: 1rem !important;}
.m-4{margin: 1.5rem !important;}
.mt-4, .my-4{margin-top: 1.5rem !important;}
.mr-4, .mx-4{margin-right: 1.5rem !important;}
.mb-4, .my-4{margin-bottom: 1.5rem !important;}
.ml-4, .mx-4{margin-left: 1.5rem !important;}
.m-5{margin: 3rem !important;}
.mt-5, .my-5{margin-top: 3rem !important;}
.mr-5, .mx-5{margin-right: 3rem !important;}
.mb-5, .my-5{margin-bottom: 3rem !important;}
.ml-5, .mx-5{margin-left: 3rem !important;}
.p-0{padding: 0 !important;}
.pt-0, .py-0{padding-top: 0 !important;}
.pr-0, .px-0{padding-right: 0 !important;}
.pb-0, .py-0{padding-bottom: 0 !important;}
.pl-0, .px-0{padding-left: 0 !important;}
.p-1{padding: 0.25rem !important;}
.pt-1, .py-1{padding-top: 0.25rem !important;}
.pr-1, .px-1{padding-right: 0.25rem !important;}
.pb-1, .py-1{padding-bottom: 0.25rem !important;}
.pl-1, .px-1{padding-left: 0.25rem !important;}
.p-2{padding: 0.5rem !important;}
.pt-2, .py-2{padding-top: 0.5rem !important;}
.pr-2, .px-2{padding-right: 0.5rem !important;}
.pb-2, .py-2{padding-bottom: 0.5rem !important;}
.pl-2, .px-2{padding-left: 0.5rem !important;}
.p-3{padding: 1rem !important;}
.pt-3, .py-3{padding-top: 1rem !important;}
.pr-3, .px-3{padding-right: 1rem !important;}
.pb-3, .py-3{padding-bottom: 1rem !important;}
.pl-3, .px-3{padding-left: 1rem !important;}
.p-4{padding: 1.5rem !important;}
.pt-4, .py-4{padding-top: 1.5rem !important;}
.pr-4, .px-4{padding-right: 1.5rem !important;}
.pb-4, .py-4{padding-bottom: 1.5rem !important;}
.pl-4, .px-4{padding-left: 1.5rem !important;}
.p-5{padding: 3rem !important;}
.pt-5, .py-5{padding-top: 3rem !important;}
.pr-5, .px-5{padding-right: 3rem !important;}
.pb-5, .py-5{padding-bottom: 3rem !important;}
.pl-5, .px-5{padding-left: 3rem !important;}
.m-n1{margin: -0.25rem !important;}
.mt-n1, .my-n1{margin-top: -0.25rem !important;}
.mr-n1, .mx-n1{margin-right: -0.25rem !important;}
.mb-n1, .my-n1{margin-bottom: -0.25rem !important;}
.ml-n1, .mx-n1{margin-left: -0.25rem !important;}
.m-n2{margin: -0.5rem !important;}
.mt-n2, .my-n2{margin-top: -0.5rem !important;}
.mr-n2, .mx-n2{margin-right: -0.5rem !important;}
.mb-n2, .my-n2{margin-bottom: -0.5rem !important;}
.ml-n2, .mx-n2{margin-left: -0.5rem !important;}
.m-n3{margin: -1rem !important;}
.mt-n3, .my-n3{margin-top: -1rem !important;}
.mr-n3, .mx-n3{margin-right: -1rem !important;}
.mb-n3, .my-n3{margin-bottom: -1rem !important;}
.ml-n3, .mx-n3{margin-left: -1rem !important;}
.m-n4{margin: -1.5rem !important;}
.mt-n4, .my-n4{margin-top: -1.5rem !important;}
.mr-n4, .mx-n4{margin-right: -1.5rem !important;}
.mb-n4, .my-n4{margin-bottom: -1.5rem !important;}
.ml-n4, .mx-n4{margin-left: -1.5rem !important;}
.m-n5{margin: -3rem !important;}
.mt-n5, .my-n5{margin-top: -3rem !important;}
.mr-n5, .mx-n5{margin-right: -3rem !important;}
.mb-n5, .my-n5{margin-bottom: -3rem !important;}
.ml-n5, .mx-n5{margin-left: -3rem !important;}
.m-auto{margin: auto !important;}
.mt-auto, .my-auto{margin-top: auto !important;}
.mr-auto, .mx-auto{margin-right: auto !important;}
.mb-auto, .my-auto{margin-bottom: auto !important;}
.ml-auto, .mx-auto{margin-left: auto !important;}
@media (min-width: 576px){
.m-sm-0{margin: 0 !important;}
.mt-sm-0, .my-sm-0{margin-top: 0 !important;}
.mr-sm-0, .mx-sm-0{margin-right: 0 !important;}
.mb-sm-0, .my-sm-0{margin-bottom: 0 !important;}
.ml-sm-0, .mx-sm-0{margin-left: 0 !important;}
.m-sm-1{margin: 0.25rem !important;}
.mt-sm-1, .my-sm-1{margin-top: 0.25rem !important;}
.mr-sm-1, .mx-sm-1{margin-right: 0.25rem !important;}
.mb-sm-1, .my-sm-1{margin-bottom: 0.25rem !important;}
.ml-sm-1, .mx-sm-1{margin-left: 0.25rem !important;}
.m-sm-2{margin: 0.5rem !important;}
.mt-sm-2, .my-sm-2{margin-top: 0.5rem !important;}
.mr-sm-2, .mx-sm-2{margin-right: 0.5rem !important;}
.mb-sm-2, .my-sm-2{margin-bottom: 0.5rem !important;}
.ml-sm-2, .mx-sm-2{margin-left: 0.5rem !important;}
.m-sm-3{margin: 1rem !important;}
.mt-sm-3, .my-sm-3{margin-top: 1rem !important;}
.mr-sm-3, .mx-sm-3{margin-right: 1rem !important;}
.mb-sm-3, .my-sm-3{margin-bottom: 1rem !important;}
.ml-sm-3, .mx-sm-3{margin-left: 1rem !important;}
.m-sm-4{margin: 1.5rem !important;}
.mt-sm-4, .my-sm-4{margin-top: 1.5rem !important;}
.mr-sm-4, .mx-sm-4{margin-right: 1.5rem !important;}
.mb-sm-4, .my-sm-4{margin-bottom: 1.5rem !important;}
.ml-sm-4, .mx-sm-4{margin-left: 1.5rem !important;}
.m-sm-5{margin: 3rem !important;}
.mt-sm-5, .my-sm-5{margin-top: 3rem !important;}
.mr-sm-5, .mx-sm-5{margin-right: 3rem !important;}
.mb-sm-5, .my-sm-5{margin-bottom: 3rem !important;}
.ml-sm-5, .mx-sm-5{margin-left: 3rem !important;}
.p-sm-0{padding: 0 !important;}
.pt-sm-0, .py-sm-0{padding-top: 0 !important;}
.pr-sm-0, .px-sm-0{padding-right: 0 !important;}
.pb-sm-0, .py-sm-0{padding-bottom: 0 !important;}
.pl-sm-0, .px-sm-0{padding-left: 0 !important;}
.p-sm-1{padding: 0.25rem !important;}
.pt-sm-1, .py-sm-1{padding-top: 0.25rem !important;}
.pr-sm-1, .px-sm-1{padding-right: 0.25rem !important;}
.pb-sm-1, .py-sm-1{padding-bottom: 0.25rem !important;}
.pl-sm-1, .px-sm-1{padding-left: 0.25rem !important;}
.p-sm-2{padding: 0.5rem !important;}
.pt-sm-2, .py-sm-2{padding-top: 0.5rem !important;}
.pr-sm-2, .px-sm-2{padding-right: 0.5rem !important;}
.pb-sm-2, .py-sm-2{padding-bottom: 0.5rem !important;}
.pl-sm-2, .px-sm-2{padding-left: 0.5rem !important;}
.p-sm-3{padding: 1rem !important;}
.pt-sm-3, .py-sm-3{padding-top: 1rem !important;}
.pr-sm-3, .px-sm-3{padding-right: 1rem !important;}
.pb-sm-3, .py-sm-3{padding-bottom: 1rem !important;}
.pl-sm-3, .px-sm-3{padding-left: 1rem !important;}
.p-sm-4{padding: 1.5rem !important;}
.pt-sm-4, .py-sm-4{padding-top: 1.5rem !important;}
.pr-sm-4, .px-sm-4{padding-right: 1.5rem !important;}
.pb-sm-4, .py-sm-4{padding-bottom: 1.5rem !important;}
.pl-sm-4, .px-sm-4{padding-left: 1.5rem !important;}
.p-sm-5{padding: 3rem !important;}
.pt-sm-5, .py-sm-5{padding-top: 3rem !important;}
.pr-sm-5, .px-sm-5{padding-right: 3rem !important;}
.pb-sm-5, .py-sm-5{padding-bottom: 3rem !important;}
.pl-sm-5, .px-sm-5{padding-left: 3rem !important;}
.m-sm-n1{margin: -0.25rem !important;}
.mt-sm-n1, .my-sm-n1{margin-top: -0.25rem !important;}
.mr-sm-n1, .mx-sm-n1{margin-right: -0.25rem !important;}
.mb-sm-n1, .my-sm-n1{margin-bottom: -0.25rem !important;}
.ml-sm-n1, .mx-sm-n1{margin-left: -0.25rem !important;}
.m-sm-n2{margin: -0.5rem !important;}
.mt-sm-n2, .my-sm-n2{margin-top: -0.5rem !important;}
.mr-sm-n2, .mx-sm-n2{margin-right: -0.5rem !important;}
.mb-sm-n2, .my-sm-n2{margin-bottom: -0.5rem !important;}
.ml-sm-n2, .mx-sm-n2{margin-left: -0.5rem !important;}
.m-sm-n3{margin: -1rem !important;}
.mt-sm-n3, .my-sm-n3{margin-top: -1rem !important;}
.mr-sm-n3, .mx-sm-n3{margin-right: -1rem !important;}
.mb-sm-n3, .my-sm-n3{margin-bottom: -1rem !important;}
.ml-sm-n3, .mx-sm-n3{margin-left: -1rem !important;}
.m-sm-n4{margin: -1.5rem !important;}
.mt-sm-n4, .my-sm-n4{margin-top: -1.5rem !important;}
.mr-sm-n4, .mx-sm-n4{margin-right: -1.5rem !important;}
.mb-sm-n4, .my-sm-n4{margin-bottom: -1.5rem !important;}
.ml-sm-n4, .mx-sm-n4{margin-left: -1.5rem !important;}
.m-sm-n5{margin: -3rem !important;}
.mt-sm-n5, .my-sm-n5{margin-top: -3rem !important;}
.mr-sm-n5, .mx-sm-n5{margin-right: -3rem !important;}
.mb-sm-n5, .my-sm-n5{margin-bottom: -3rem !important;}
.ml-sm-n5, .mx-sm-n5{margin-left: -3rem !important;}
.m-sm-auto{margin: auto !important;}
.mt-sm-auto, .my-sm-auto{margin-top: auto !important;}
.mr-sm-auto, .mx-sm-auto{margin-right: auto !important;}
.mb-sm-auto, .my-sm-auto{margin-bottom: auto !important;}
.ml-sm-auto, .mx-sm-auto{margin-left: auto !important;}
}
@media (min-width: 768px){
.m-md-0{margin: 0 !important;}
.mt-md-0, .my-md-0{margin-top: 0 !important;}
.mr-md-0, .mx-md-0{margin-right: 0 !important;}
.mb-md-0, .my-md-0{margin-bottom: 0 !important;}
.ml-md-0, .mx-md-0{margin-left: 0 !important;}
.m-md-1{margin: 0.25rem !important;}
.mt-md-1, .my-md-1{margin-top: 0.25rem !important;}
.mr-md-1, .mx-md-1{margin-right: 0.25rem !important;}
.mb-md-1, .my-md-1{margin-bottom: 0.25rem !important;}
.ml-md-1, .mx-md-1{margin-left: 0.25rem !important;}
.m-md-2{margin: 0.5rem !important;}
.mt-md-2, .my-md-2{margin-top: 0.5rem !important;}
.mr-md-2, .mx-md-2{margin-right: 0.5rem !important;}
.mb-md-2, .my-md-2{margin-bottom: 0.5rem !important;}
.ml-md-2, .mx-md-2{margin-left: 0.5rem !important;}
.m-md-3{margin: 1rem !important;}
.mt-md-3, .my-md-3{margin-top: 1rem !important;}
.mr-md-3, .mx-md-3{margin-right: 1rem !important;}
.mb-md-3, .my-md-3{margin-bottom: 1rem !important;}
.ml-md-3, .mx-md-3{margin-left: 1rem !important;}
.m-md-4{margin: 1.5rem !important;}
.mt-md-4, .my-md-4{margin-top: 1.5rem !important;}
.mr-md-4, .mx-md-4{margin-right: 1.5rem !important;}
.mb-md-4, .my-md-4{margin-bottom: 1.5rem !important;}
.ml-md-4, .mx-md-4{margin-left: 1.5rem !important;}
.m-md-5{margin: 3rem !important;}
.mt-md-5, .my-md-5{margin-top: 3rem !important;}
.mr-md-5, .mx-md-5{margin-right: 3rem !important;}
.mb-md-5, .my-md-5{margin-bottom: 3rem !important;}
.ml-md-5, .mx-md-5{margin-left: 3rem !important;}
.p-md-0{padding: 0 !important;}
.pt-md-0, .py-md-0{padding-top: 0 !important;}
.pr-md-0, .px-md-0{padding-right: 0 !important;}
.pb-md-0, .py-md-0{padding-bottom: 0 !important;}
.pl-md-0, .px-md-0{padding-left: 0 !important;}
.p-md-1{padding: 0.25rem !important;}
.pt-md-1, .py-md-1{padding-top: 0.25rem !important;}
.pr-md-1, .px-md-1{padding-right: 0.25rem !important;}
.pb-md-1, .py-md-1{padding-bottom: 0.25rem !important;}
.pl-md-1, .px-md-1{padding-left: 0.25rem !important;}
.p-md-2{padding: 0.5rem !important;}
.pt-md-2, .py-md-2{padding-top: 0.5rem !important;}
.pr-md-2, .px-md-2{padding-right: 0.5rem !important;}
.pb-md-2, .py-md-2{padding-bottom: 0.5rem !important;}
.pl-md-2, .px-md-2{padding-left: 0.5rem !important;}
.p-md-3{padding: 1rem !important;}
.pt-md-3, .py-md-3{padding-top: 1rem !important;}
.pr-md-3, .px-md-3{padding-right: 1rem !important;}
.pb-md-3, .py-md-3{padding-bottom: 1rem !important;}
.pl-md-3, .px-md-3{padding-left: 1rem !important;}
.p-md-4{padding: 1.5rem !important;}
.pt-md-4, .py-md-4{padding-top: 1.5rem !important;}
.pr-md-4, .px-md-4{padding-right: 1.5rem !important;}
.pb-md-4, .py-md-4{padding-bottom: 1.5rem !important;}
.pl-md-4, .px-md-4{padding-left: 1.5rem !important;}
.p-md-5{padding: 3rem !important;}
.pt-md-5, .py-md-5{padding-top: 3rem !important;}
.pr-md-5, .px-md-5{padding-right: 3rem !important;}
.pb-md-5, .py-md-5{padding-bottom: 3rem !important;}
.pl-md-5, .px-md-5{padding-left: 3rem !important;}
.m-md-n1{margin: -0.25rem !important;}
.mt-md-n1, .my-md-n1{margin-top: -0.25rem !important;}
.mr-md-n1, .mx-md-n1{margin-right: -0.25rem !important;}
.mb-md-n1, .my-md-n1{margin-bottom: -0.25rem !important;}
.ml-md-n1, .mx-md-n1{margin-left: -0.25rem !important;}
.m-md-n2{margin: -0.5rem !important;}
.mt-md-n2, .my-md-n2{margin-top: -0.5rem !important;}
.mr-md-n2, .mx-md-n2{margin-right: -0.5rem !important;}
.mb-md-n2, .my-md-n2{margin-bottom: -0.5rem !important;}
.ml-md-n2, .mx-md-n2{margin-left: -0.5rem !important;}
.m-md-n3{margin: -1rem !important;}
.mt-md-n3, .my-md-n3{margin-top: -1rem !important;}
.mr-md-n3, .mx-md-n3{margin-right: -1rem !important;}
.mb-md-n3, .my-md-n3{margin-bottom: -1rem !important;}
.ml-md-n3, .mx-md-n3{margin-left: -1rem !important;}
.m-md-n4{margin: -1.5rem !important;}
.mt-md-n4, .my-md-n4{margin-top: -1.5rem !important;}
.mr-md-n4, .mx-md-n4{margin-right: -1.5rem !important;}
.mb-md-n4, .my-md-n4{margin-bottom: -1.5rem !important;}
.ml-md-n4, .mx-md-n4{margin-left: -1.5rem !important;}
.m-md-n5{margin: -3rem !important;}
.mt-md-n5, .my-md-n5{margin-top: -3rem !important;}
.mr-md-n5, .mx-md-n5{margin-right: -3rem !important;}
.mb-md-n5, .my-md-n5{margin-bottom: -3rem !important;}
.ml-md-n5, .mx-md-n5{margin-left: -3rem !important;}
.m-md-auto{margin: auto !important;}
.mt-md-auto, .my-md-auto{margin-top: auto !important;}
.mr-md-auto, .mx-md-auto{margin-right: auto !important;}
.mb-md-auto, .my-md-auto{margin-bottom: auto !important;}
.ml-md-auto, .mx-md-auto{margin-left: auto !important;}
}
@media (min-width: 1024px){
.m-lg-0{margin: 0 !important;}
.mt-lg-0, .my-lg-0{margin-top: 0 !important;}
.mr-lg-0, .mx-lg-0{margin-right: 0 !important;}
.mb-lg-0, .my-lg-0{margin-bottom: 0 !important;}
.ml-lg-0, .mx-lg-0{margin-left: 0 !important;}
.m-lg-1{margin: 0.25rem !important;}
.mt-lg-1, .my-lg-1{margin-top: 0.25rem !important;}
.mr-lg-1, .mx-lg-1{margin-right: 0.25rem !important;}
.mb-lg-1, .my-lg-1{margin-bottom: 0.25rem !important;}
.ml-lg-1, .mx-lg-1{margin-left: 0.25rem !important;}
.m-lg-2{margin: 0.5rem !important;}
.mt-lg-2, .my-lg-2{margin-top: 0.5rem !important;}
.mr-lg-2, .mx-lg-2{margin-right: 0.5rem !important;}
.mb-lg-2, .my-lg-2{margin-bottom: 0.5rem !important;}
.ml-lg-2, .mx-lg-2{margin-left: 0.5rem !important;}
.m-lg-3{margin: 1rem !important;}
.mt-lg-3, .my-lg-3{margin-top: 1rem !important;}
.mr-lg-3, .mx-lg-3{margin-right: 1rem !important;}
.mb-lg-3, .my-lg-3{margin-bottom: 1rem !important;}
.ml-lg-3, .mx-lg-3{margin-left: 1rem !important;}
.m-lg-4{margin: 1.5rem !important;}
.mt-lg-4, .my-lg-4{margin-top: 1.5rem !important;}
.mr-lg-4, .mx-lg-4{margin-right: 1.5rem !important;}
.mb-lg-4, .my-lg-4{margin-bottom: 1.5rem !important;}
.ml-lg-4, .mx-lg-4{margin-left: 1.5rem !important;}
.m-lg-5{margin: 3rem !important;}
.mt-lg-5, .my-lg-5{margin-top: 3rem !important;}
.mr-lg-5, .mx-lg-5{margin-right: 3rem !important;}
.mb-lg-5, .my-lg-5{margin-bottom: 3rem !important;}
.ml-lg-5, .mx-lg-5{margin-left: 3rem !important;}
.p-lg-0{padding: 0 !important;}
.pt-lg-0, .py-lg-0{padding-top: 0 !important;}
.pr-lg-0, .px-lg-0{padding-right: 0 !important;}
.pb-lg-0, .py-lg-0{padding-bottom: 0 !important;}
.pl-lg-0, .px-lg-0{padding-left: 0 !important;}
.p-lg-1{padding: 0.25rem !important;}
.pt-lg-1, .py-lg-1{padding-top: 0.25rem !important;}
.pr-lg-1, .px-lg-1{padding-right: 0.25rem !important;}
.pb-lg-1, .py-lg-1{padding-bottom: 0.25rem !important;}
.pl-lg-1, .px-lg-1{padding-left: 0.25rem !important;}
.p-lg-2{padding: 0.5rem !important;}
.pt-lg-2, .py-lg-2{padding-top: 0.5rem !important;}
.pr-lg-2, .px-lg-2{padding-right: 0.5rem !important;}
.pb-lg-2, .py-lg-2{padding-bottom: 0.5rem !important;}
.pl-lg-2, .px-lg-2{padding-left: 0.5rem !important;}
.p-lg-3{padding: 1rem !important;}
.pt-lg-3, .py-lg-3{padding-top: 1rem !important;}
.pr-lg-3, .px-lg-3{padding-right: 1rem !important;}
.pb-lg-3, .py-lg-3{padding-bottom: 1rem !important;}
.pl-lg-3, .px-lg-3{padding-left: 1rem !important;}
.p-lg-4{padding: 1.5rem !important;}
.pt-lg-4, .py-lg-4{padding-top: 1.5rem !important;}
.pr-lg-4, .px-lg-4{padding-right: 1.5rem !important;}
.pb-lg-4, .py-lg-4{padding-bottom: 1.5rem !important;}
.pl-lg-4, .px-lg-4{padding-left: 1.5rem !important;}
.p-lg-5{padding: 3rem !important;}
.pt-lg-5, .py-lg-5{padding-top: 3rem !important;}
.pr-lg-5, .px-lg-5{padding-right: 3rem !important;}
.pb-lg-5, .py-lg-5{padding-bottom: 3rem !important;}
.pl-lg-5, .px-lg-5{padding-left: 3rem !important;}
.m-lg-n1{margin: -0.25rem !important;}
.mt-lg-n1, .my-lg-n1{margin-top: -0.25rem !important;}
.mr-lg-n1, .mx-lg-n1{margin-right: -0.25rem !important;}
.mb-lg-n1, .my-lg-n1{margin-bottom: -0.25rem !important;}
.ml-lg-n1, .mx-lg-n1{margin-left: -0.25rem !important;}
.m-lg-n2{margin: -0.5rem !important;}
.mt-lg-n2, .my-lg-n2{margin-top: -0.5rem !important;}
.mr-lg-n2, .mx-lg-n2{margin-right: -0.5rem !important;}
.mb-lg-n2, .my-lg-n2{margin-bottom: -0.5rem !important;}
.ml-lg-n2, .mx-lg-n2{margin-left: -0.5rem !important;}
.m-lg-n3{margin: -1rem !important;}
.mt-lg-n3, .my-lg-n3{margin-top: -1rem !important;}
.mr-lg-n3, .mx-lg-n3{margin-right: -1rem !important;}
.mb-lg-n3, .my-lg-n3{margin-bottom: -1rem !important;}
.ml-lg-n3, .mx-lg-n3{margin-left: -1rem !important;}
.m-lg-n4{margin: -1.5rem !important;}
.mt-lg-n4, .my-lg-n4{margin-top: -1.5rem !important;}
.mr-lg-n4, .mx-lg-n4{margin-right: -1.5rem !important;}
.mb-lg-n4, .my-lg-n4{margin-bottom: -1.5rem !important;}
.ml-lg-n4, .mx-lg-n4{margin-left: -1.5rem !important;}
.m-lg-n5{margin: -3rem !important;}
.mt-lg-n5, .my-lg-n5{margin-top: -3rem !important;}
.mr-lg-n5, .mx-lg-n5{margin-right: -3rem !important;}
.mb-lg-n5, .my-lg-n5{margin-bottom: -3rem !important;}
.ml-lg-n5, .mx-lg-n5{margin-left: -3rem !important;}
.m-lg-auto{margin: auto !important;}
.mt-lg-auto, .my-lg-auto{margin-top: auto !important;}
.mr-lg-auto, .mx-lg-auto{margin-right: auto !important;}
.mb-lg-auto, .my-lg-auto{margin-bottom: auto !important;}
.ml-lg-auto, .mx-lg-auto{margin-left: auto !important;}
}
@media (min-width: 1399px){
.m-xl-0{margin: 0 !important;}
.mt-xl-0, .my-xl-0{margin-top: 0 !important;}
.mr-xl-0, .mx-xl-0{margin-right: 0 !important;}
.mb-xl-0, .my-xl-0{margin-bottom: 0 !important;}
.ml-xl-0, .mx-xl-0{margin-left: 0 !important;}
.m-xl-1{margin: 0.25rem !important;}
.mt-xl-1, .my-xl-1{margin-top: 0.25rem !important;}
.mr-xl-1, .mx-xl-1{margin-right: 0.25rem !important;}
.mb-xl-1, .my-xl-1{margin-bottom: 0.25rem !important;}
.ml-xl-1, .mx-xl-1{margin-left: 0.25rem !important;}
.m-xl-2{margin: 0.5rem !important;}
.mt-xl-2, .my-xl-2{margin-top: 0.5rem !important;}
.mr-xl-2, .mx-xl-2{margin-right: 0.5rem !important;}
.mb-xl-2, .my-xl-2{margin-bottom: 0.5rem !important;}
.ml-xl-2, .mx-xl-2{margin-left: 0.5rem !important;}
.m-xl-3{margin: 1rem !important;}
.mt-xl-3, .my-xl-3{margin-top: 1rem !important;}
.mr-xl-3, .mx-xl-3{margin-right: 1rem !important;}
.mb-xl-3, .my-xl-3{margin-bottom: 1rem !important;}
.ml-xl-3, .mx-xl-3{margin-left: 1rem !important;}
.m-xl-4{margin: 1.5rem !important;}
.mt-xl-4, .my-xl-4{margin-top: 1.5rem !important;}
.mr-xl-4, .mx-xl-4{margin-right: 1.5rem !important;}
.mb-xl-4, .my-xl-4{margin-bottom: 1.5rem !important;}
.ml-xl-4, .mx-xl-4{margin-left: 1.5rem !important;}
.m-xl-5{margin: 3rem !important;}
.mt-xl-5, .my-xl-5{margin-top: 3rem !important;}
.mr-xl-5, .mx-xl-5{margin-right: 3rem !important;}
.mb-xl-5, .my-xl-5{margin-bottom: 3rem !important;}
.ml-xl-5, .mx-xl-5{margin-left: 3rem !important;}
.p-xl-0{padding: 0 !important;}
.pt-xl-0, .py-xl-0{padding-top: 0 !important;}
.pr-xl-0, .px-xl-0{padding-right: 0 !important;}
.pb-xl-0, .py-xl-0{padding-bottom: 0 !important;}
.pl-xl-0, .px-xl-0{padding-left: 0 !important;}
.p-xl-1{padding: 0.25rem !important;}
.pt-xl-1, .py-xl-1{padding-top: 0.25rem !important;}
.pr-xl-1, .px-xl-1{padding-right: 0.25rem !important;}
.pb-xl-1, .py-xl-1{padding-bottom: 0.25rem !important;}
.pl-xl-1, .px-xl-1{padding-left: 0.25rem !important;}
.p-xl-2{padding: 0.5rem !important;}
.pt-xl-2, .py-xl-2{padding-top: 0.5rem !important;}
.pr-xl-2, .px-xl-2{padding-right: 0.5rem !important;}
.pb-xl-2, .py-xl-2{padding-bottom: 0.5rem !important;}
.pl-xl-2, .px-xl-2{padding-left: 0.5rem !important;}
.p-xl-3{padding: 1rem !important;}
.pt-xl-3, .py-xl-3{padding-top: 1rem !important;}
.pr-xl-3, .px-xl-3{padding-right: 1rem !important;}
.pb-xl-3, .py-xl-3{padding-bottom: 1rem !important;}
.pl-xl-3, .px-xl-3{padding-left: 1rem !important;}
.p-xl-4{padding: 1.5rem !important;}
.pt-xl-4, .py-xl-4{padding-top: 1.5rem !important;}
.pr-xl-4, .px-xl-4{padding-right: 1.5rem !important;}
.pb-xl-4, .py-xl-4{padding-bottom: 1.5rem !important;}
.pl-xl-4, .px-xl-4{padding-left: 1.5rem !important;}
.p-xl-5{padding: 3rem !important;}
.pt-xl-5, .py-xl-5{padding-top: 3rem !important;}
.pr-xl-5, .px-xl-5{padding-right: 3rem !important;}
.pb-xl-5, .py-xl-5{padding-bottom: 3rem !important;}
.pl-xl-5, .px-xl-5{padding-left: 3rem !important;}
.m-xl-n1{margin: -0.25rem !important;}
.mt-xl-n1, .my-xl-n1{margin-top: -0.25rem !important;}
.mr-xl-n1, .mx-xl-n1{margin-right: -0.25rem !important;}
.mb-xl-n1, .my-xl-n1{margin-bottom: -0.25rem !important;}
.ml-xl-n1, .mx-xl-n1{margin-left: -0.25rem !important;}
.m-xl-n2{margin: -0.5rem !important;}
.mt-xl-n2, .my-xl-n2{margin-top: -0.5rem !important;}
.mr-xl-n2, .mx-xl-n2{margin-right: -0.5rem !important;}
.mb-xl-n2, .my-xl-n2{margin-bottom: -0.5rem !important;}
.ml-xl-n2, .mx-xl-n2{margin-left: -0.5rem !important;}
.m-xl-n3{margin: -1rem !important;}
.mt-xl-n3, .my-xl-n3{margin-top: -1rem !important;}
.mr-xl-n3, .mx-xl-n3{margin-right: -1rem !important;}
.mb-xl-n3, .my-xl-n3{margin-bottom: -1rem !important;}
.ml-xl-n3, .mx-xl-n3{margin-left: -1rem !important;}
.m-xl-n4{margin: -1.5rem !important;}
.mt-xl-n4, .my-xl-n4{margin-top: -1.5rem !important;}
.mr-xl-n4, .mx-xl-n4{margin-right: -1.5rem !important;}
.mb-xl-n4, .my-xl-n4{margin-bottom: -1.5rem !important;}
.ml-xl-n4, .mx-xl-n4{margin-left: -1.5rem !important;}
.m-xl-n5{margin: -3rem !important;}
.mt-xl-n5, .my-xl-n5{margin-top: -3rem !important;}
.mr-xl-n5, .mx-xl-n5{margin-right: -3rem !important;}
.mb-xl-n5, .my-xl-n5{margin-bottom: -3rem !important;}
.ml-xl-n5, .mx-xl-n5{margin-left: -3rem !important;}
.m-xl-auto{margin: auto !important;}
.mt-xl-auto, .my-xl-auto{margin-top: auto !important;}
.mr-xl-auto, .mx-xl-auto{margin-right: auto !important;}
.mb-xl-auto, .my-xl-auto{margin-bottom: auto !important;}
.ml-xl-auto, .mx-xl-auto{margin-left: auto !important;}
}
.text-monospace{font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;}
.text-justify{text-align: justify !important;}
.text-wrap{white-space: normal !important;}
.text-nowrap{white-space: nowrap !important;}
.text-truncate{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.text-left{text-align: left !important;}
.text-right{text-align: right !important;}
.text-center{text-align: center !important;}
@media (min-width: 576px){
.text-sm-left{text-align: left !important;}
.text-sm-right{text-align: right !important;}
.text-sm-center{text-align: center !important;}
}
@media (min-width: 768px){
.text-md-left{text-align: left !important;}
.text-md-right{text-align: right !important;}
.text-md-center{text-align: center !important;}
}
@media (min-width: 1024px){
.text-lg-left{text-align: left !important;}
.text-lg-right{text-align: right !important;}
.text-lg-center{text-align: center !important;}
}
@media (min-width: 1399px){
.text-xl-left{text-align: left !important;}
.text-xl-right{text-align: right !important;}
.text-xl-center{text-align: center !important;}
}
.text-lowercase{text-transform: lowercase !important;}
.text-uppercase{text-transform: uppercase !important;}
.text-capitalize{text-transform: capitalize !important;}
.font-weight-light{font-weight: 300 !important;}
.font-weight-lighter{font-weight: lighter !important;}
.font-weight-normal{font-weight: 400 !important;}
.font-weight-bold{font-weight: 700 !important;}
.font-weight-bolder{font-weight: bolder !important;}
.font-italic{font-style: italic !important;}
.text-white{color: #fff !important;}
.text-primary{color: #5867dd !important;}
a.text-primary:hover, a.text-primary:focus{color: #2739c1 !important;}
.text-secondary{color: #e1e1ef !important;}
a.text-secondary:hover, a.text-secondary:focus{color: #afafd4 !important;}
.text-success{color: #0abb87 !important;}
a.text-success:hover, a.text-success:focus{color: #067253 !important;}
.text-info{color: #5578eb !important;}
a.text-info:hover, a.text-info:focus{color: #1a46da !important;}
.text-warning{color: #ffb822 !important;}
a.text-warning:hover, a.text-warning:focus{color: #d59000 !important;}
.text-danger{color: #fd397a !important;}
a.text-danger:hover, a.text-danger:focus{color: #e7024e !important;}
.text-light{color: #f8f9fa !important;}
a.text-light:hover, a.text-light:focus{color: #cbd3da !important;}
.text-dark{color: #343a40 !important;}
a.text-dark:hover, a.text-dark:focus{color: #121416 !important;}
.text-body{color: #212529 !important;}
.text-muted{color: #a7abc3 !important;}
.text-black-50{color: rgba(0, 0, 0, 0.5) !important;}
.text-white-50{color: rgba(255, 255, 255, 0.5) !important;}
.text-hide{font: 0/0 a;color: transparent;text-shadow: none;background-color: transparent;border: 0;}
.text-decoration-none{text-decoration: none !important;}
.text-break{word-break: break-word !important;overflow-wrap: break-word !important;}
.text-reset{color: inherit !important;}
.visible{visibility: visible !important;}
.invisible{visibility: hidden !important;}
@media print{*, *::before, *::after{text-shadow: none !important;-webkit-box-shadow: none !important;box-shadow: none !important;}
a:not(.btn){
text-decoration: underline;}
abbr[title]::after{content: " (" attr(title) ")";}
pre{white-space: pre-wrap !important;}
pre, blockquote{border: 1px solid #adb5bd;page-break-inside: avoid;}
thead{display: table-header-group;}
tr, img{page-break-inside: avoid;}
p, h2, h3{orphans: 3;widows: 3;}
h2, h3{page-break-after: avoid;}
@page{size: a3;}
body{min-width: 1024px !important;}
.container{min-width: 1024px !important;}
.navbar{display: none;}
.badge{border: 1px solid #000;}
.table{border-collapse: collapse !important;}
.table td, .table th{background-color: #fff !important;}
.table-bordered th, .table-bordered td{border: 1px solid #dee2e6 !important;}
.table-dark{color: inherit;}
.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody{border-color: #ebedf2;}
.table .thead-dark th{color: inherit;border-color: #ebedf2;}
}
.accordion .card{margin: 0;}
.accordion .card .card-header{cursor: pointer;margin: 0;padding: 0;border-bottom: 0;}
.accordion .card .card-header .card-title{margin: 0;font-size: 1.1rem;font-weight: 500;padding: 1rem 1rem;color: #5d78ff;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.accordion .card .card-header .card-title > i{margin-right: 1rem;font-size: 1.4rem;color: #5d78ff;}
.accordion .card .card-header .card-title.collapsed{color: #6c7293;}
.accordion .card .card-header .card-title.collapsed > i{color: #a7abc3;}
.accordion .card .card-header .card-title:after{right: 1rem;}
.accordion .card .card-body{padding: 1.25rem;font-size: 1rem;}
.accordion.accordion-light .card{border: 0;border-top: 1px solid #eeeef4;margin: 0;}
.accordion.accordion-light .card .card-header{cursor: pointer;padding: 0;margin: 0;background-color: transparent;border: 0;}
.accordion.accordion-light .card .card-header .card-title{margin: 0;font-size: 1.1rem;padding: 1rem 1rem 1rem 0;font-weight: 500;color: #5d78ff;}
.accordion.accordion-light .card .card-header .card-title.collapsed{color: #a7abc3;}
.accordion.accordion-light .card .card-header .card-title.collapsed:after{color: #a7abc3;}
.accordion.accordion-light .card .card-header .card-title:after{right: 0;}
.accordion.accordion-light .card .card-body{padding: 0;margin-bottom: 1.25rem;font-size: 1rem;}
.accordion.accordion-light .card:first-child{border-top: 0;}
.accordion.accordion-light .card:first-child .card-header{margin: 0;}
.accordion.accordion-light .card:last-child{margin-bottom: 1.25rem;}
.accordion.accordion-light .card:last-child .card-body{padding: 0;margin: 0;}
.accordion.accordion-outline--padded{padding: 1.7rem;}
.accordion.accordion-outline .card{margin-bottom: 1.25rem;border: 0;}
.accordion.accordion-outline .card .card-header{padding: 0;background-color: transparent;}
.accordion.accordion-outline .card .card-header .card-title{font-size: 1.1rem;padding: 1.25rem;border-color: #c3cdff;border-top-width: 2px;border-right-width: 2px;border-bottom-width: 0;padding-bottom: calc(1.5rem + 2px);border-left-width: 2px;border-style: solid;border-top-left-radius: 4px !important;border-top-right-radius: 4px !important;}
.accordion.accordion-outline .card .card-header .card-title:after{position: absolute;right: 1.25rem;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";font-size: 1.7rem;font-weight: bold;color: #5d78ff;}
.accordion.accordion-outline .card .card-header .card-title.collapsed{color: #464457;border-color: #efeff0;border-bottom-width: 2px;padding-bottom: 1.5rem;border-bottom-left-radius: 4px !important;border-bottom-right-radius: 4px !important;}
.accordion.accordion-outline .card .card-header .card-title.collapsed:after{content: "";color: #efeff0;}
.accordion.accordion-outline .card .card-body-wrapper > .card-body{padding: 0 2.7rem 1.7rem 1.7rem;font-size: 1.1rem;font-weight: 400;border-color: #c3cdff;border-top-width: 0;border-right-width: 2px;border-bottom-width: 2px;border-left-width: 2px;border-style: solid;border-bottom-left-radius: 4px !important;border-bottom-right-radius: 4px !important;color: #8d8c9b;}
.accordion.accordion-outline .card .card-body-wrapper > .card-body > p:last-child{margin-bottom: 0;}
.accordion.accordion-outline .card:last-child{margin-bottom: 0;}
.accordion.accordion-solid .card{margin-bottom: 1.5rem;border: 0;}
.accordion.accordion-solid .card:last-child{margin-bottom: 0;}
.accordion.accordion-solid .card .card-header{padding: 0;background-color: transparent;}
.accordion.accordion-solid .card .card-header .card-title{font-size: 1.1rem;padding: 1.25rem;background-color: #f7f8fa;border-radius: 4px;}
.accordion.accordion-solid .card .card-header .card-title.collapsed{border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
.accordion.accordion-solid .card .card-body{border: 1px solid #f7f8fa;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
.accordion.accordion-toggle-arrow .card .card-header .card-title{color: #5d78ff;position: relative;}
.accordion.accordion-toggle-arrow .card .card-header .card-title:after{position: absolute;font-size: 1rem;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";color: #5d78ff;}
.accordion.accordion-toggle-arrow .card .card-header .card-title.collapsed{color: #a7abc3;}
.accordion.accordion-toggle-arrow .card .card-header .card-title.collapsed:after{color: #a7abc3;content: "";}
.accordion.accordion-toggle-plus .card .card-header .card-title{color: #5d78ff;position: relative;}
.accordion.accordion-toggle-plus .card .card-header .card-title:after{position: absolute;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";font-size: 1.2rem;color: #5d78ff;}
.accordion.accordion-toggle-plus .card .card-header .card-title.collapsed{color: #a7abc3;}
.accordion.accordion-toggle-plus .card .card-header .card-title.collapsed:after{color: #a7abc3;content: "";}
.alert{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;padding: 1rem 2rem;border-radius: 3px;margin: 0 0 20px 0;}
.alert .alert-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0 1.25rem 0 0;}
.alert .alert-icon i{font-size: 2.4rem;}
.alert .alert-icon.alert-icon-top{-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;-ms-flex-line-pack: stretch;align-content: stretch;}
.alert .alert-text{-ms-flex-item-align: center;align-self: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.alert .alert-close{padding: 0 0 0 1rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.alert .alert-close .close{text-shadow: none !important;}
.alert .alert-close i{opacity: 0.8;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.alert .alert-close i:hover{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;opacity: 1;}
.alert.alert-elevate{-webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);}
.alert.alert-brand{background: #5d78ff;border: 1px solid #5d78ff;color: #ffffff;}
.alert.alert-brand .alert-icon i{color: #ffffff;}
.alert.alert-brand .alert-text{color: #ffffff;}
.alert.alert-brand .alert-text hr{border-top-color: #ffffff;opacity: 0.4;}
.alert.alert-brand .alert-close i{color: #ffffff;}
.alert.alert-outline-brand{border: 1px solid #5d78ff;color: #5d78ff;}
.alert.alert-outline-brand .alert-icon i{color: #5d78ff;}
.alert.alert-outline-brand .alert-text{color: #5d78ff;}
.alert.alert-outline-brand .alert-close i{color: #5d78ff;}
.alert.alert-solid-brand{background: rgba(93, 120, 255, 0.1);border-color: transparent;color: #5d78ff;}
.alert.alert-solid-brand .alert-icon i{color: #5d78ff;}
.alert.alert-solid-brand .alert-text{color: #5d78ff;}
.alert.alert-solid-brand .alert-close i{color: #5d78ff;}
.alert.alert-light{background: #ffffff;border: 1px solid #ffffff;color: #282a3c;}
.alert.alert-light .alert-icon i{color: #282a3c;}
.alert.alert-light .alert-text{color: #282a3c;}
.alert.alert-light .alert-text hr{border-top-color: #282a3c;opacity: 0.4;}
.alert.alert-light .alert-close i{color: #282a3c;}
.alert.alert-outline-light{border: 1px solid #ffffff;color: #ffffff;}
.alert.alert-outline-light .alert-icon i{color: #ffffff;}
.alert.alert-outline-light .alert-text{color: #ffffff;}
.alert.alert-outline-light .alert-close i{color: #ffffff;}
.alert.alert-solid-light{background: rgba(255, 255, 255, 0.1);border-color: transparent;color: #ffffff;}
.alert.alert-solid-light .alert-icon i{color: #ffffff;}
.alert.alert-solid-light .alert-text{color: #ffffff;}
.alert.alert-solid-light .alert-close i{color: #ffffff;}
.alert.alert-dark{background: #282a3c;border: 1px solid #282a3c;color: #ffffff;}
.alert.alert-dark .alert-icon i{color: #ffffff;}
.alert.alert-dark .alert-text{color: #ffffff;}
.alert.alert-dark .alert-text hr{border-top-color: #ffffff;opacity: 0.4;}
.alert.alert-dark .alert-close i{color: #ffffff;}
.alert.alert-outline-dark{border: 1px solid #282a3c;color: #282a3c;}
.alert.alert-outline-dark .alert-icon i{color: #282a3c;}
.alert.alert-outline-dark .alert-text{color: #282a3c;}
.alert.alert-outline-dark .alert-close i{color: #282a3c;}
.alert.alert-solid-dark{background: rgba(40, 42, 60, 0.1);border-color: transparent;color: #282a3c;}
.alert.alert-solid-dark .alert-icon i{color: #282a3c;}
.alert.alert-solid-dark .alert-text{color: #282a3c;}
.alert.alert-solid-dark .alert-close i{color: #282a3c;}
.alert.alert-primary{background: #5867dd;border: 1px solid #5867dd;color: #ffffff;}
.alert.alert-primary .alert-icon i{color: #ffffff;}
.alert.alert-primary .alert-text{color: #ffffff;}
.alert.alert-primary .alert-text hr{border-top-color: #ffffff;opacity: 0.4;}
.alert.alert-primary .alert-close i{color: #ffffff;}
.alert.alert-outline-primary{border: 1px solid #5867dd;color: #5867dd;}
.alert.alert-outline-primary .alert-icon i{color: #5867dd;}
.alert.alert-outline-primary .alert-text{color: #5867dd;}
.alert.alert-outline-primary .alert-close i{color: #5867dd;}
.alert.alert-solid-primary{background: rgba(88, 103, 221, 0.1);border-color: transparent;color: #5867dd;}
.alert.alert-solid-primary .alert-icon i{color: #5867dd;}
.alert.alert-solid-primary .alert-text{color: #5867dd;}
.alert.alert-solid-primary .alert-close i{color: #5867dd;}
.alert.alert-success{background: #0abb87;border: 1px solid #0abb87;color: #ffffff;}
.alert.alert-success .alert-icon i{color: #ffffff;}
.alert.alert-success .alert-text{color: #ffffff;}
.alert.alert-success .alert-text hr{border-top-color: #ffffff;opacity: 0.4;}
.alert.alert-success .alert-close i{color: #ffffff;}
.alert.alert-outline-success{border: 1px solid #0abb87;color: #0abb87;}
.alert.alert-outline-success .alert-icon i{color: #0abb87;}
.alert.alert-outline-success .alert-text{color: #0abb87;}
.alert.alert-outline-success .alert-close i{color: #0abb87;}
.alert.alert-solid-success{background: rgba(10, 187, 135, 0.1);border-color: transparent;color: #0abb87;}
.alert.alert-solid-success .alert-icon i{color: #0abb87;}
.alert.alert-solid-success .alert-text{color: #0abb87;}
.alert.alert-solid-success .alert-close i{color: #0abb87;}
.alert.alert-info{background: #5578eb;border: 1px solid #5578eb;color: #ffffff;}
.alert.alert-info .alert-icon i{color: #ffffff;}
.alert.alert-info .alert-text{color: #ffffff;}
.alert.alert-info .alert-text hr{border-top-color: #ffffff;opacity: 0.4;}
.alert.alert-info .alert-close i{color: #ffffff;}
.alert.alert-outline-info{border: 1px solid #5578eb;color: #5578eb;}
.alert.alert-outline-info .alert-icon i{color: #5578eb;}
.alert.alert-outline-info .alert-text{color: #5578eb;}
.alert.alert-outline-info .alert-close i{color: #5578eb;}
.alert.alert-solid-info{background: rgba(85, 120, 235, 0.1);border-color: transparent;color: #5578eb;}
.alert.alert-solid-info .alert-icon i{color: #5578eb;}
.alert.alert-solid-info .alert-text{color: #5578eb;}
.alert.alert-solid-info .alert-close i{color: #5578eb;}
.alert.alert-warning{background: #ffb822;border: 1px solid #ffb822;color: #111111;}
.alert.alert-warning .alert-icon i{color: #111111;}
.alert.alert-warning .alert-text{color: #111111;}
.alert.alert-warning .alert-text hr{border-top-color: #111111;opacity: 0.4;}
.alert.alert-warning .alert-close i{color: #111111;}
.alert.alert-outline-warning{border: 1px solid #ffb822;color: #ffb822;}
.alert.alert-outline-warning .alert-icon i{color: #ffb822;}
.alert.alert-outline-warning .alert-text{color: #ffb822;}
.alert.alert-outline-warning .alert-close i{color: #ffb822;}
.alert.alert-solid-warning{background: rgba(255, 184, 34, 0.1);border-color: transparent;color: #ffb822;}
.alert.alert-solid-warning .alert-icon i{color: #ffb822;}
.alert.alert-solid-warning .alert-text{color: #ffb822;}
.alert.alert-solid-warning .alert-close i{color: #ffb822;}
.alert.alert-danger{background: #fd397a;border: 1px solid #fd397a;color: #ffffff;}
.alert.alert-danger .alert-icon i{color: #ffffff;}
.alert.alert-danger .alert-text{color: #ffffff;}
.alert.alert-danger .alert-text hr{border-top-color: #ffffff;opacity: 0.4;}
.alert.alert-danger .alert-close i{color: #ffffff;}
.alert.alert-outline-danger{border: 1px solid #fd397a;color: #fd397a;}
.alert.alert-outline-danger .alert-icon i{color: #fd397a;}
.alert.alert-outline-danger .alert-text{color: #fd397a;}
.alert.alert-outline-danger .alert-close i{color: #fd397a;}
.alert.alert-solid-danger{background: rgba(253, 57, 122, 0.1);border-color: transparent;color: #fd397a;}
.alert.alert-solid-danger .alert-icon i{color: #fd397a;}
.alert.alert-solid-danger .alert-text{color: #fd397a;}
.alert.alert-solid-danger .alert-close i{color: #fd397a;}
.alert.alert-secondary .alert-icon i{color: #a7abc3;}
.alert.alert-secondary .alert-text{color: #6c7293;}
.alert.alert--marginless{margin: 0;}
.alert.alert-bold .alert-text{font-weight: 500;}
.badge{font-weight: 400;}
.btn{background: transparent;outline: none !important;vertical-align: middle;}
.btn:focus:not(.btn-elevate){
-webkit-box-shadow: none !important;box-shadow: none !important;}
.btn.btn-clean{border-color: transparent;background: transparent;color: #93a2dd;}
.btn.btn-clean i{color: #93a2dd;}
.show > .btn.btn-clean, .btn.btn-clean.active, .btn.btn-clean:active, .btn.btn-clean:hover{color: #5d78ff;background: #f0f3ff;}
.show > .btn.btn-clean i, .btn.btn-clean.active i, .btn.btn-clean:active i, .btn.btn-clean:hover i{color: #5d78ff;}
.btn.btn-default{border: 0;background: transparent;color: #a7abc3;border: 1px solid #e8ecfa;}
.btn.btn-default i{color: #93a2dd;}
.show > .btn.btn-default, .btn.btn-default.active, .btn.btn-default:active, .btn.btn-default:hover{color: #ffffff;background: #5d78ff;border-color: #5d78ff;}
.show > .btn.btn-default i, .btn.btn-default.active i, .btn.btn-default:active i, .btn.btn-default:hover i{color: #ffffff;}
.btn-secondary{background-color: transparent;border: 1px solid #e2e5ec;color: #6c7293;}
.show > .btn-secondary.dropdown-toggle, .btn-secondary:focus, .btn-secondary.active, .btn-secondary:active, .btn-secondary:hover{color: #6c7293;border-color: #e2e5ec;background-color: #f4f5f8;}
.btn-brand{color: #fff;background-color: #5d78ff;border-color: #5d78ff;color: #ffffff;}
.btn-brand:hover{color: #fff;background-color: #3758ff;border-color: #2a4eff;}
.btn-brand:focus, .btn-brand.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5);box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5);}
.btn-brand.disabled, .btn-brand:disabled{color: #fff;background-color: #5d78ff;border-color: #5d78ff;}
.btn-brand:not(:disabled):not(.disabled):active, .btn-brand:not(:disabled):not(.disabled).active, .show > .btn-brand.dropdown-toggle{color: #fff;background-color: #2a4eff;border-color: #1d43ff;}
.btn-brand:not(:disabled):not(.disabled):active:focus, .btn-brand:not(:disabled):not(.disabled).active:focus, .show > .btn-brand.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5);box-shadow: 0 0 0 0.2rem rgba(117, 140, 255, 0.5);}
.btn-brand + .btn.dropdown-toggle{position: relative;}
.btn-brand + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-brand + .btn.dropdown-toggle:hover:before, .btn-brand + .btn.dropdown-toggle:focus:before, .btn-brand + .btn.dropdown-toggle.active:before{display: none;}
.btn-light{color: #212529;background-color: #ffffff;border-color: #ffffff;color: #282a3c;}
.btn-light:hover{color: #212529;background-color: #ececec;border-color: #e6e6e6;}
.btn-light:focus, .btn-light.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);}
.btn-light.disabled, .btn-light:disabled{color: #212529;background-color: #ffffff;border-color: #ffffff;}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle{color: #212529;background-color: #e6e6e6;border-color: #dfdfdf;}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);}
.show > .btn-light.dropdown-toggle, .btn-light:focus, .btn-light.active, .btn-light:active, .btn-light:hover{background-color: #f7f8fa;border-color: #f7f8fa;}
.btn-light + .btn.dropdown-toggle{position: relative;}
.btn-light + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #282a3c;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-light + .btn.dropdown-toggle:hover:before, .btn-light + .btn.dropdown-toggle:focus:before, .btn-light + .btn.dropdown-toggle.active:before{display: none;}
.btn-dark{color: #fff;background-color: #282a3c;border-color: #282a3c;color: #ffffff;}
.btn-dark:hover{color: #fff;background-color: #191a25;border-color: #14151d;}
.btn-dark:focus, .btn-dark.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(72, 74, 89, 0.5);box-shadow: 0 0 0 0.2rem rgba(72, 74, 89, 0.5);}
.btn-dark.disabled, .btn-dark:disabled{color: #fff;background-color: #282a3c;border-color: #282a3c;}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle{color: #fff;background-color: #14151d;border-color: #0f0f16;}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(72, 74, 89, 0.5);box-shadow: 0 0 0 0.2rem rgba(72, 74, 89, 0.5);}
.btn-dark + .btn.dropdown-toggle{position: relative;}
.btn-dark + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-dark + .btn.dropdown-toggle:hover:before, .btn-dark + .btn.dropdown-toggle:focus:before, .btn-dark + .btn.dropdown-toggle.active:before{display: none;}
.btn-primary{color: #fff;background-color: #5867dd;border-color: #5867dd;color: #ffffff;}
.btn-primary:hover{color: #fff;background-color: #384ad7;border-color: #2e40d4;}
.btn-primary:focus, .btn-primary.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);}
.btn-primary.disabled, .btn-primary:disabled{color: #fff;background-color: #5867dd;border-color: #5867dd;}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{color: #fff;background-color: #2e40d4;border-color: #293ccc;}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);box-shadow: 0 0 0 0.2rem rgba(113, 126, 226, 0.5);}
.btn-primary + .btn.dropdown-toggle{position: relative;}
.btn-primary + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-primary + .btn.dropdown-toggle:hover:before, .btn-primary + .btn.dropdown-toggle:focus:before, .btn-primary + .btn.dropdown-toggle.active:before{display: none;}
.btn-success{color: #fff;background-color: #0abb87;border-color: #0abb87;color: #ffffff;}
.btn-success:hover{color: #fff;background-color: #08976d;border-color: #078b64;}
.btn-success:focus, .btn-success.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);}
.btn-success.disabled, .btn-success:disabled{color: #fff;background-color: #0abb87;border-color: #0abb87;}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle{color: #fff;background-color: #078b64;border-color: #077e5b;}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);box-shadow: 0 0 0 0.2rem rgba(47, 197, 153, 0.5);}
.btn-success + .btn.dropdown-toggle{position: relative;}
.btn-success + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-success + .btn.dropdown-toggle:hover:before, .btn-success + .btn.dropdown-toggle:focus:before, .btn-success + .btn.dropdown-toggle.active:before{display: none;}
.btn-info{color: #fff;background-color: #5578eb;border-color: #5578eb;color: #ffffff;}
.btn-info:hover{color: #fff;background-color: #335de7;border-color: #2754e6;}
.btn-info:focus, .btn-info.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);}
.btn-info.disabled, .btn-info:disabled{color: #fff;background-color: #5578eb;border-color: #5578eb;}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle{color: #fff;background-color: #2754e6;border-color: #1c4be4;}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);box-shadow: 0 0 0 0.2rem rgba(111, 140, 238, 0.5);}
.btn-info + .btn.dropdown-toggle{position: relative;}
.btn-info + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-info + .btn.dropdown-toggle:hover:before, .btn-info + .btn.dropdown-toggle:focus:before, .btn-info + .btn.dropdown-toggle.active:before{display: none;}
.btn-warning{color: #212529;background-color: #ffb822;border-color: #ffb822;color: #111111;}
.btn-warning:hover{color: #212529;background-color: #fbaa00;border-color: #eea200;}
.btn-warning:focus, .btn-warning.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);}
.btn-warning.disabled, .btn-warning:disabled{color: #212529;background-color: #ffb822;border-color: #ffb822;}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle{color: #212529;background-color: #eea200;border-color: #e19900;}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);box-shadow: 0 0 0 0.2rem rgba(222, 162, 35, 0.5);}
.btn-warning + .btn.dropdown-toggle{position: relative;}
.btn-warning + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #111111;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-warning + .btn.dropdown-toggle:hover:before, .btn-warning + .btn.dropdown-toggle:focus:before, .btn-warning + .btn.dropdown-toggle.active:before{display: none;}
.btn-danger{color: #fff;background-color: #fd397a;border-color: #fd397a;color: #ffffff;}
.btn-danger:hover{color: #fff;background-color: #fd1361;border-color: #fc0758;}
.btn-danger:focus, .btn-danger.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);}
.btn-danger.disabled, .btn-danger:disabled{color: #fff;background-color: #fd397a;border-color: #fd397a;}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle{color: #fff;background-color: #fc0758;border-color: #f40252;}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 87, 142, 0.5);}
.btn-danger + .btn.dropdown-toggle{position: relative;}
.btn-danger + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-danger + .btn.dropdown-toggle:hover:before, .btn-danger + .btn.dropdown-toggle:focus:before, .btn-danger + .btn.dropdown-toggle.active:before{display: none;}
.btn-facebook{color: #fff;background-color: #3b5998;border-color: #3b5998;color: #ffffff;}
.btn-facebook:hover{color: #fff;background-color: #30497c;border-color: #2d4373;}
.btn-facebook:focus, .btn-facebook.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);}
.btn-facebook.disabled, .btn-facebook:disabled{color: #fff;background-color: #3b5998;border-color: #3b5998;}
.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show > .btn-facebook.dropdown-toggle{color: #fff;background-color: #2d4373;border-color: #293e6a;}
.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show > .btn-facebook.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);}
.btn-facebook + .btn.dropdown-toggle{position: relative;}
.btn-facebook + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-facebook + .btn.dropdown-toggle:hover:before, .btn-facebook + .btn.dropdown-toggle:focus:before, .btn-facebook + .btn.dropdown-toggle.active:before{display: none;}
.btn-google{color: #fff;background-color: #dc4e41;border-color: #dc4e41;color: #ffffff;}
.btn-google:hover{color: #fff;background-color: #d03526;border-color: #c63224;}
.btn-google:focus, .btn-google.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);}
.btn-google.disabled, .btn-google:disabled{color: #fff;background-color: #dc4e41;border-color: #dc4e41;}
.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active, .show > .btn-google.dropdown-toggle{color: #fff;background-color: #c63224;border-color: #bb2f22;}
.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus, .show > .btn-google.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);box-shadow: 0 0 0 0.2rem rgba(225, 105, 94, 0.5);}
.btn-google + .btn.dropdown-toggle{position: relative;}
.btn-google + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-google + .btn.dropdown-toggle:hover:before, .btn-google + .btn.dropdown-toggle:focus:before, .btn-google + .btn.dropdown-toggle.active:before{display: none;}
.btn-twitter{color: #212529;background-color: #4AB3F4;border-color: #4AB3F4;color: #ffffff;}
.btn-twitter:hover{color: #fff;background-color: #26a4f2;border-color: #1a9ff1;}
.btn-twitter:focus, .btn-twitter.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(68, 158, 214, 0.5);box-shadow: 0 0 0 0.2rem rgba(68, 158, 214, 0.5);}
.btn-twitter.disabled, .btn-twitter:disabled{color: #212529;background-color: #4AB3F4;border-color: #4AB3F4;}
.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active, .show > .btn-twitter.dropdown-toggle{color: #fff;background-color: #1a9ff1;border-color: #0f9af0;}
.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus, .show > .btn-twitter.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(68, 158, 214, 0.5);box-shadow: 0 0 0 0.2rem rgba(68, 158, 214, 0.5);}
.btn-twitter + .btn.dropdown-toggle{position: relative;}
.btn-twitter + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-twitter + .btn.dropdown-toggle:hover:before, .btn-twitter + .btn.dropdown-toggle:focus:before, .btn-twitter + .btn.dropdown-toggle.active:before{display: none;}
.btn-instagram{color: #fff;background-color: #517fa4;border-color: #517fa4;color: #ffffff;}
.btn-instagram:hover{color: #fff;background-color: #446b8a;border-color: #406582;}
.btn-instagram:focus, .btn-instagram.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(107, 146, 178, 0.5);box-shadow: 0 0 0 0.2rem rgba(107, 146, 178, 0.5);}
.btn-instagram.disabled, .btn-instagram:disabled{color: #fff;background-color: #517fa4;border-color: #517fa4;}
.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active, .show > .btn-instagram.dropdown-toggle{color: #fff;background-color: #406582;border-color: #3c5e79;}
.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus, .show > .btn-instagram.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(107, 146, 178, 0.5);box-shadow: 0 0 0 0.2rem rgba(107, 146, 178, 0.5);}
.btn-instagram + .btn.dropdown-toggle{position: relative;}
.btn-instagram + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-instagram + .btn.dropdown-toggle:hover:before, .btn-instagram + .btn.dropdown-toggle:focus:before, .btn-instagram + .btn.dropdown-toggle.active:before{display: none;}
.btn-youtube{color: #fff;background-color: #b31217;border-color: #b31217;color: #ffffff;}
.btn-youtube:hover{color: #fff;background-color: #900f13;border-color: #850d11;}
.btn-youtube:focus, .btn-youtube.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(190, 54, 58, 0.5);box-shadow: 0 0 0 0.2rem rgba(190, 54, 58, 0.5);}
.btn-youtube.disabled, .btn-youtube:disabled{color: #fff;background-color: #b31217;border-color: #b31217;}
.btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active, .show > .btn-youtube.dropdown-toggle{color: #fff;background-color: #850d11;border-color: #790c10;}
.btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus, .show > .btn-youtube.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(190, 54, 58, 0.5);box-shadow: 0 0 0 0.2rem rgba(190, 54, 58, 0.5);}
.btn-youtube + .btn.dropdown-toggle{position: relative;}
.btn-youtube + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-youtube + .btn.dropdown-toggle:hover:before, .btn-youtube + .btn.dropdown-toggle:focus:before, .btn-youtube + .btn.dropdown-toggle.active:before{display: none;}
.btn-linkedin{color: #fff;background-color: #0077b5;border-color: #0077b5;color: #ffffff;}
.btn-linkedin:hover{color: #fff;background-color: #005e8f;border-color: #005582;}
.btn-linkedin:focus, .btn-linkedin.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(38, 139, 192, 0.5);box-shadow: 0 0 0 0.2rem rgba(38, 139, 192, 0.5);}
.btn-linkedin.disabled, .btn-linkedin:disabled{color: #fff;background-color: #0077b5;border-color: #0077b5;}
.btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active, .show > .btn-linkedin.dropdown-toggle{color: #fff;background-color: #005582;border-color: #004d75;}
.btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus, .show > .btn-linkedin.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(38, 139, 192, 0.5);box-shadow: 0 0 0 0.2rem rgba(38, 139, 192, 0.5);}
.btn-linkedin + .btn.dropdown-toggle{position: relative;}
.btn-linkedin + .btn.dropdown-toggle:before{display: block;content: ' ';border-left: 1px solid #ffffff;position: absolute;top: 3px;left: -1px;bottom: 3px;opacity: 0.3;}
.btn-linkedin + .btn.dropdown-toggle:hover:before, .btn-linkedin + .btn.dropdown-toggle:focus:before, .btn-linkedin + .btn.dropdown-toggle.active:before{display: none;}
.btn-outline-brand{color: #5d78ff;border-color: #5d78ff;}
.btn-outline-brand:hover{color: #ffffff;background-color: #5d78ff;border-color: #5d78ff;}
.btn-outline-brand:focus, .btn-outline-brand.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(93, 120, 255, 0.5);box-shadow: 0 0 0 0.2rem rgba(93, 120, 255, 0.5);}
.btn-outline-brand.disabled, .btn-outline-brand:disabled{color: #5d78ff;background-color: transparent;}
.btn-outline-brand:not(:disabled):not(.disabled):active, .btn-outline-brand:not(:disabled):not(.disabled).active, .show > .btn-outline-brand.dropdown-toggle{color: #fff;background-color: #5d78ff;border-color: #5d78ff;}
.btn-outline-brand:not(:disabled):not(.disabled):active:focus, .btn-outline-brand:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-brand.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(93, 120, 255, 0.5);box-shadow: 0 0 0 0.2rem rgba(93, 120, 255, 0.5);}
.btn-outline-light{color: #ffffff;border-color: #ffffff;}
.btn-outline-light:hover{color: #282a3c;background-color: #ffffff;border-color: #ffffff;}
.btn-outline-light:focus, .btn-outline-light.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);}
.btn-outline-light.disabled, .btn-outline-light:disabled{color: #ffffff;background-color: transparent;}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle{color: #212529;background-color: #ffffff;border-color: #ffffff;}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);}
.btn-outline-dark{color: #282a3c;border-color: #282a3c;}
.btn-outline-dark:hover{color: #ffffff;background-color: #282a3c;border-color: #282a3c;}
.btn-outline-dark:focus, .btn-outline-dark.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(40, 42, 60, 0.5);box-shadow: 0 0 0 0.2rem rgba(40, 42, 60, 0.5);}
.btn-outline-dark.disabled, .btn-outline-dark:disabled{color: #282a3c;background-color: transparent;}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle{color: #fff;background-color: #282a3c;border-color: #282a3c;}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(40, 42, 60, 0.5);box-shadow: 0 0 0 0.2rem rgba(40, 42, 60, 0.5);}
.btn-outline-primary{color: #5867dd;border-color: #5867dd;}
.btn-outline-primary:hover{color: #ffffff;background-color: #5867dd;border-color: #5867dd;}
.btn-outline-primary:focus, .btn-outline-primary.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);}
.btn-outline-primary.disabled, .btn-outline-primary:disabled{color: #5867dd;background-color: transparent;}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle{color: #fff;background-color: #5867dd;border-color: #5867dd;}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);box-shadow: 0 0 0 0.2rem rgba(88, 103, 221, 0.5);}
.btn-outline-success{color: #0abb87;border-color: #0abb87;}
.btn-outline-success:hover{color: #ffffff;background-color: #0abb87;border-color: #0abb87;}
.btn-outline-success:focus, .btn-outline-success.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);}
.btn-outline-success.disabled, .btn-outline-success:disabled{color: #0abb87;background-color: transparent;}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle{color: #fff;background-color: #0abb87;border-color: #0abb87;}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);}
.btn-outline-info{color: #5578eb;border-color: #5578eb;}
.btn-outline-info:hover{color: #ffffff;background-color: #5578eb;border-color: #5578eb;}
.btn-outline-info:focus, .btn-outline-info.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);}
.btn-outline-info.disabled, .btn-outline-info:disabled{color: #5578eb;background-color: transparent;}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle{color: #fff;background-color: #5578eb;border-color: #5578eb;}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);box-shadow: 0 0 0 0.2rem rgba(85, 120, 235, 0.5);}
.btn-outline-warning{color: #ffb822;border-color: #ffb822;}
.btn-outline-warning:hover{color: #111111;background-color: #ffb822;border-color: #ffb822;}
.btn-outline-warning:focus, .btn-outline-warning.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);}
.btn-outline-warning.disabled, .btn-outline-warning:disabled{color: #ffb822;background-color: transparent;}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle{color: #212529;background-color: #ffb822;border-color: #ffb822;}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);}
.btn-outline-danger{color: #fd397a;border-color: #fd397a;}
.btn-outline-danger:hover{color: #ffffff;background-color: #fd397a;border-color: #fd397a;}
.btn-outline-danger:focus, .btn-outline-danger.focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);}
.btn-outline-danger.disabled, .btn-outline-danger:disabled{color: #fd397a;background-color: transparent;}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle{color: #fff;background-color: #fd397a;border-color: #fd397a;}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus{-webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);}
.show > .btn-hover-brand, .btn-hover-brand.active, .btn-hover-brand:active, .btn-hover-brand:hover{color: #ffffff;background-color: #5d78ff;border-color: #5d78ff;}
.btn-outline-hover-brand.btn-clean{border: 1px solid transparent;}
.show > .btn-outline-hover-brand, .btn-outline-hover-brand.active, .btn-outline-hover-brand:active, .btn-outline-hover-brand:hover{color: #5d78ff;border-color: #5d78ff;background-color: transparent;}
.show > .btn-hover-light, .btn-hover-light.active, .btn-hover-light:active, .btn-hover-light:hover{color: #282a3c;background-color: #ffffff;border-color: #ffffff;}
.btn-outline-hover-light.btn-clean{border: 1px solid transparent;}
.show > .btn-outline-hover-light, .btn-outline-hover-light.active, .btn-outline-hover-light:active, .btn-outline-hover-light:hover{color: #ffffff;border-color: #ffffff;background-color: transparent;}
.show > .btn-hover-dark, .btn-hover-dark.active, .btn-hover-dark:active, .btn-hover-dark:hover{color: #ffffff;background-color: #282a3c;border-color: #282a3c;}
.btn-outline-hover-dark.btn-clean{border: 1px solid transparent;}
.show > .btn-outline-hover-dark, .btn-outline-hover-dark.active, .btn-outline-hover-dark:active, .btn-outline-hover-dark:hover{color: #282a3c;border-color: #282a3c;background-color: transparent;}
.show > .btn-hover-primary, .btn-hover-primary.active, .btn-hover-primary:active, .btn-hover-primary:hover{color: #ffffff;background-color: #5867dd;border-color: #5867dd;}
.btn-outline-hover-primary.btn-clean{border: 1px solid transparent;}
.show > .btn-outline-hover-primary, .btn-outline-hover-primary.active, .btn-outline-hover-primary:active, .btn-outline-hover-primary:hover{color: #5867dd;border-color: #5867dd;background-color: transparent;}
.show > .btn-hover-success, .btn-hover-success.active, .btn-hover-success:active, .btn-hover-success:hover{color: #ffffff;background-color: #0abb87;border-color: #0abb87;}
.btn-outline-hover-success.btn-clean{border: 1px solid transparent;}
.show > .btn-outline-hover-success, .btn-outline-hover-success.active, .btn-outline-hover-success:active, .btn-outline-hover-success:hover{color: #0abb87;border-color: #0abb87;background-color: transparent;}
.show > .btn-hover-info, .btn-hover-info.active, .btn-hover-info:active, .btn-hover-info:hover{color: #ffffff;background-color: #5578eb;border-color: #5578eb;}
.btn-outline-hover-info.btn-clean{border: 1px solid transparent;}
.show > .btn-outline-hover-info, .btn-outline-hover-info.active, .btn-outline-hover-info:active, .btn-outline-hover-info:hover{color: #5578eb;border-color: #5578eb;background-color: transparent;}
.show > .btn-hover-warning, .btn-hover-warning.active, .btn-hover-warning:active, .btn-hover-warning:hover{color: #111111;background-color: #ffb822;border-color: #ffb822;}
.btn-outline-hover-warning.btn-clean{border: 1px solid transparent;}
.show > .btn-outline-hover-warning, .btn-outline-hover-warning.active, .btn-outline-hover-warning:active, .btn-outline-hover-warning:hover{color: #ffb822;border-color: #ffb822;background-color: transparent;}
.show > .btn-hover-danger, .btn-hover-danger.active, .btn-hover-danger:active, .btn-hover-danger:hover{color: #ffffff;background-color: #fd397a;border-color: #fd397a;}
.btn-outline-hover-danger.btn-clean{border: 1px solid transparent;}
.show > .btn-outline-hover-danger, .btn-outline-hover-danger.active, .btn-outline-hover-danger:active, .btn-outline-hover-danger:hover{color: #fd397a;border-color: #fd397a;background-color: transparent;}
.btn-font-brand{color: #5d78ff;}
.show > .btn-font-hover-brand, .btn-font-hover-brand.active, .btn-font-hover-brand:active, .btn-font-hover-brand:hover{color: #5d78ff;}
.btn-font-light{color: #ffffff;}
.show > .btn-font-hover-light, .btn-font-hover-light.active, .btn-font-hover-light:active, .btn-font-hover-light:hover{color: #ffffff;}
.btn-font-dark{color: #282a3c;}
.show > .btn-font-hover-dark, .btn-font-hover-dark.active, .btn-font-hover-dark:active, .btn-font-hover-dark:hover{color: #282a3c;}
.btn-font-primary{color: #5867dd;}
.show > .btn-font-hover-primary, .btn-font-hover-primary.active, .btn-font-hover-primary:active, .btn-font-hover-primary:hover{color: #5867dd;}
.btn-font-success{color: #0abb87;}
.show > .btn-font-hover-success, .btn-font-hover-success.active, .btn-font-hover-success:active, .btn-font-hover-success:hover{color: #0abb87;}
.btn-font-info{color: #5578eb;}
.show > .btn-font-hover-info, .btn-font-hover-info.active, .btn-font-hover-info:active, .btn-font-hover-info:hover{color: #5578eb;}
.btn-font-warning{color: #ffb822;}
.show > .btn-font-hover-warning, .btn-font-hover-warning.active, .btn-font-hover-warning:active, .btn-font-hover-warning:hover{color: #ffb822;}
.btn-font-danger{color: #fd397a;}
.show > .btn-font-hover-danger, .btn-font-hover-danger.active, .btn-font-hover-danger:active, .btn-font-hover-danger:hover{color: #fd397a;}
.btn.btn-outline-secondary{color: #494988;}
.show > .btn.btn-outline-secondary, .btn.btn-outline-secondary.active, .btn.btn-outline-secondary:active, .btn.btn-outline-secondary:hover{background-color: #e1e1ef;}
.btn.btn-elevate{-webkit-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.btn.btn-elevate.btn-elevate-air:hover{-webkit-transform: translate(0, -4px);transform: translate(0, -4px);}
.btn.btn-brand.btn-elevate{-webkit-box-shadow: 0px 4px 16px 0px rgba(93, 120, 255, 0.15);box-shadow: 0px 4px 16px 0px rgba(93, 120, 255, 0.15);}
.show > .btn.btn-brand.btn-elevate, .btn.btn-brand.btn-elevate:focus, .btn.btn-brand.btn-elevate.active, .btn.btn-brand.btn-elevate:active, .btn.btn-brand.btn-elevate:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(93, 120, 255, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(93, 120, 255, 0.25) !important;}
.show > .btn.btn-brand.btn-elevate.btn-sm, .btn-group-sm.show > .btn.btn-brand.btn-elevate, .btn.btn-brand.btn-elevate:focus.btn-sm, .btn-group-sm > .btn.btn-brand.btn-elevate:focus, .btn.btn-brand.btn-elevate.active.btn-sm, .btn-group-sm > .btn.btn-brand.btn-elevate.active, .btn.btn-brand.btn-elevate:active.btn-sm, .btn-group-sm > .btn.btn-brand.btn-elevate:active, .btn.btn-brand.btn-elevate:hover.btn-sm, .btn-group-sm > .btn.btn-brand.btn-elevate:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(93, 120, 255, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(93, 120, 255, 0.25) !important;}
.show > .btn.btn-brand.btn-elevate-hover, .btn.btn-brand.btn-elevate-hover.active, .btn.btn-brand.btn-elevate-hover:focus, .btn.btn-brand.btn-elevate-hover:active, .btn.btn-brand.btn-elevate-hover:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(93, 120, 255, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(93, 120, 255, 0.25) !important;}
.show > .btn.btn-brand.btn-elevate-hover.btn-sm, .btn-group-sm.show > .btn.btn-brand.btn-elevate-hover, .btn.btn-brand.btn-elevate-hover.active.btn-sm, .btn-group-sm > .btn.btn-brand.btn-elevate-hover.active, .btn.btn-brand.btn-elevate-hover:focus.btn-sm, .btn-group-sm > .btn.btn-brand.btn-elevate-hover:focus, .btn.btn-brand.btn-elevate-hover:active.btn-sm, .btn-group-sm > .btn.btn-brand.btn-elevate-hover:active, .btn.btn-brand.btn-elevate-hover:hover.btn-sm, .btn-group-sm > .btn.btn-brand.btn-elevate-hover:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(93, 120, 255, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(93, 120, 255, 0.25) !important;}
.btn.btn-light.btn-elevate{-webkit-box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.15);box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.15);}
.show > .btn.btn-light.btn-elevate, .btn.btn-light.btn-elevate:focus, .btn.btn-light.btn-elevate.active, .btn.btn-light.btn-elevate:active, .btn.btn-light.btn-elevate:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(153, 153, 153, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(153, 153, 153, 0.25) !important;background-color: transparent;border-color: transparent;}
.show > .btn.btn-light.btn-elevate.btn-sm, .btn-group-sm.show > .btn.btn-light.btn-elevate, .btn.btn-light.btn-elevate:focus.btn-sm, .btn-group-sm > .btn.btn-light.btn-elevate:focus, .btn.btn-light.btn-elevate.active.btn-sm, .btn-group-sm > .btn.btn-light.btn-elevate.active, .btn.btn-light.btn-elevate:active.btn-sm, .btn-group-sm > .btn.btn-light.btn-elevate:active, .btn.btn-light.btn-elevate:hover.btn-sm, .btn-group-sm > .btn.btn-light.btn-elevate:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(153, 153, 153, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(153, 153, 153, 0.25) !important;}
.show > .btn.btn-light.btn-elevate-hover, .btn.btn-light.btn-elevate-hover.active, .btn.btn-light.btn-elevate-hover:focus, .btn.btn-light.btn-elevate-hover:active, .btn.btn-light.btn-elevate-hover:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(153, 153, 153, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(153, 153, 153, 0.25) !important;background-color: transparent;border-color: transparent;}
.show > .btn.btn-light.btn-elevate-hover.btn-sm, .btn-group-sm.show > .btn.btn-light.btn-elevate-hover, .btn.btn-light.btn-elevate-hover.active.btn-sm, .btn-group-sm > .btn.btn-light.btn-elevate-hover.active, .btn.btn-light.btn-elevate-hover:focus.btn-sm, .btn-group-sm > .btn.btn-light.btn-elevate-hover:focus, .btn.btn-light.btn-elevate-hover:active.btn-sm, .btn-group-sm > .btn.btn-light.btn-elevate-hover:active, .btn.btn-light.btn-elevate-hover:hover.btn-sm, .btn-group-sm > .btn.btn-light.btn-elevate-hover:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(153, 153, 153, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(153, 153, 153, 0.25) !important;}
.btn.btn-dark.btn-elevate{-webkit-box-shadow: 0px 4px 16px 0px rgba(40, 42, 60, 0.15);box-shadow: 0px 4px 16px 0px rgba(40, 42, 60, 0.15);}
.show > .btn.btn-dark.btn-elevate, .btn.btn-dark.btn-elevate:focus, .btn.btn-dark.btn-elevate.active, .btn.btn-dark.btn-elevate:active, .btn.btn-dark.btn-elevate:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(40, 42, 60, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(40, 42, 60, 0.25) !important;}
.show > .btn.btn-dark.btn-elevate.btn-sm, .btn-group-sm.show > .btn.btn-dark.btn-elevate, .btn.btn-dark.btn-elevate:focus.btn-sm, .btn-group-sm > .btn.btn-dark.btn-elevate:focus, .btn.btn-dark.btn-elevate.active.btn-sm, .btn-group-sm > .btn.btn-dark.btn-elevate.active, .btn.btn-dark.btn-elevate:active.btn-sm, .btn-group-sm > .btn.btn-dark.btn-elevate:active, .btn.btn-dark.btn-elevate:hover.btn-sm, .btn-group-sm > .btn.btn-dark.btn-elevate:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(40, 42, 60, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(40, 42, 60, 0.25) !important;}
.show > .btn.btn-dark.btn-elevate-hover, .btn.btn-dark.btn-elevate-hover.active, .btn.btn-dark.btn-elevate-hover:focus, .btn.btn-dark.btn-elevate-hover:active, .btn.btn-dark.btn-elevate-hover:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(40, 42, 60, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(40, 42, 60, 0.25) !important;}
.show > .btn.btn-dark.btn-elevate-hover.btn-sm, .btn-group-sm.show > .btn.btn-dark.btn-elevate-hover, .btn.btn-dark.btn-elevate-hover.active.btn-sm, .btn-group-sm > .btn.btn-dark.btn-elevate-hover.active, .btn.btn-dark.btn-elevate-hover:focus.btn-sm, .btn-group-sm > .btn.btn-dark.btn-elevate-hover:focus, .btn.btn-dark.btn-elevate-hover:active.btn-sm, .btn-group-sm > .btn.btn-dark.btn-elevate-hover:active, .btn.btn-dark.btn-elevate-hover:hover.btn-sm, .btn-group-sm > .btn.btn-dark.btn-elevate-hover:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(40, 42, 60, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(40, 42, 60, 0.25) !important;}
.btn.btn-primary.btn-elevate{-webkit-box-shadow: 0px 4px 16px 0px rgba(88, 103, 221, 0.15);box-shadow: 0px 4px 16px 0px rgba(88, 103, 221, 0.15);}
.show > .btn.btn-primary.btn-elevate, .btn.btn-primary.btn-elevate:focus, .btn.btn-primary.btn-elevate.active, .btn.btn-primary.btn-elevate:active, .btn.btn-primary.btn-elevate:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(88, 103, 221, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(88, 103, 221, 0.25) !important;}
.show > .btn.btn-primary.btn-elevate.btn-sm, .btn-group-sm.show > .btn.btn-primary.btn-elevate, .btn.btn-primary.btn-elevate:focus.btn-sm, .btn-group-sm > .btn.btn-primary.btn-elevate:focus, .btn.btn-primary.btn-elevate.active.btn-sm, .btn-group-sm > .btn.btn-primary.btn-elevate.active, .btn.btn-primary.btn-elevate:active.btn-sm, .btn-group-sm > .btn.btn-primary.btn-elevate:active, .btn.btn-primary.btn-elevate:hover.btn-sm, .btn-group-sm > .btn.btn-primary.btn-elevate:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(88, 103, 221, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(88, 103, 221, 0.25) !important;}
.show > .btn.btn-primary.btn-elevate-hover, .btn.btn-primary.btn-elevate-hover.active, .btn.btn-primary.btn-elevate-hover:focus, .btn.btn-primary.btn-elevate-hover:active, .btn.btn-primary.btn-elevate-hover:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(88, 103, 221, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(88, 103, 221, 0.25) !important;}
.show > .btn.btn-primary.btn-elevate-hover.btn-sm, .btn-group-sm.show > .btn.btn-primary.btn-elevate-hover, .btn.btn-primary.btn-elevate-hover.active.btn-sm, .btn-group-sm > .btn.btn-primary.btn-elevate-hover.active, .btn.btn-primary.btn-elevate-hover:focus.btn-sm, .btn-group-sm > .btn.btn-primary.btn-elevate-hover:focus, .btn.btn-primary.btn-elevate-hover:active.btn-sm, .btn-group-sm > .btn.btn-primary.btn-elevate-hover:active, .btn.btn-primary.btn-elevate-hover:hover.btn-sm, .btn-group-sm > .btn.btn-primary.btn-elevate-hover:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(88, 103, 221, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(88, 103, 221, 0.25) !important;}
.btn.btn-success.btn-elevate{-webkit-box-shadow: 0px 4px 16px 0px rgba(10, 187, 135, 0.15);box-shadow: 0px 4px 16px 0px rgba(10, 187, 135, 0.15);}
.show > .btn.btn-success.btn-elevate, .btn.btn-success.btn-elevate:focus, .btn.btn-success.btn-elevate.active, .btn.btn-success.btn-elevate:active, .btn.btn-success.btn-elevate:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(10, 187, 135, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(10, 187, 135, 0.25) !important;}
.show > .btn.btn-success.btn-elevate.btn-sm, .btn-group-sm.show > .btn.btn-success.btn-elevate, .btn.btn-success.btn-elevate:focus.btn-sm, .btn-group-sm > .btn.btn-success.btn-elevate:focus, .btn.btn-success.btn-elevate.active.btn-sm, .btn-group-sm > .btn.btn-success.btn-elevate.active, .btn.btn-success.btn-elevate:active.btn-sm, .btn-group-sm > .btn.btn-success.btn-elevate:active, .btn.btn-success.btn-elevate:hover.btn-sm, .btn-group-sm > .btn.btn-success.btn-elevate:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(10, 187, 135, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(10, 187, 135, 0.25) !important;}
.show > .btn.btn-success.btn-elevate-hover, .btn.btn-success.btn-elevate-hover.active, .btn.btn-success.btn-elevate-hover:focus, .btn.btn-success.btn-elevate-hover:active, .btn.btn-success.btn-elevate-hover:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(10, 187, 135, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(10, 187, 135, 0.25) !important;}
.show > .btn.btn-success.btn-elevate-hover.btn-sm, .btn-group-sm.show > .btn.btn-success.btn-elevate-hover, .btn.btn-success.btn-elevate-hover.active.btn-sm, .btn-group-sm > .btn.btn-success.btn-elevate-hover.active, .btn.btn-success.btn-elevate-hover:focus.btn-sm, .btn-group-sm > .btn.btn-success.btn-elevate-hover:focus, .btn.btn-success.btn-elevate-hover:active.btn-sm, .btn-group-sm > .btn.btn-success.btn-elevate-hover:active, .btn.btn-success.btn-elevate-hover:hover.btn-sm, .btn-group-sm > .btn.btn-success.btn-elevate-hover:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(10, 187, 135, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(10, 187, 135, 0.25) !important;}
.btn.btn-info.btn-elevate{-webkit-box-shadow: 0px 4px 16px 0px rgba(85, 120, 235, 0.15);box-shadow: 0px 4px 16px 0px rgba(85, 120, 235, 0.15);}
.show > .btn.btn-info.btn-elevate, .btn.btn-info.btn-elevate:focus, .btn.btn-info.btn-elevate.active, .btn.btn-info.btn-elevate:active, .btn.btn-info.btn-elevate:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(85, 120, 235, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(85, 120, 235, 0.25) !important;}
.show > .btn.btn-info.btn-elevate.btn-sm, .btn-group-sm.show > .btn.btn-info.btn-elevate, .btn.btn-info.btn-elevate:focus.btn-sm, .btn-group-sm > .btn.btn-info.btn-elevate:focus, .btn.btn-info.btn-elevate.active.btn-sm, .btn-group-sm > .btn.btn-info.btn-elevate.active, .btn.btn-info.btn-elevate:active.btn-sm, .btn-group-sm > .btn.btn-info.btn-elevate:active, .btn.btn-info.btn-elevate:hover.btn-sm, .btn-group-sm > .btn.btn-info.btn-elevate:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(85, 120, 235, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(85, 120, 235, 0.25) !important;}
.show > .btn.btn-info.btn-elevate-hover, .btn.btn-info.btn-elevate-hover.active, .btn.btn-info.btn-elevate-hover:focus, .btn.btn-info.btn-elevate-hover:active, .btn.btn-info.btn-elevate-hover:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(85, 120, 235, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(85, 120, 235, 0.25) !important;}
.show > .btn.btn-info.btn-elevate-hover.btn-sm, .btn-group-sm.show > .btn.btn-info.btn-elevate-hover, .btn.btn-info.btn-elevate-hover.active.btn-sm, .btn-group-sm > .btn.btn-info.btn-elevate-hover.active, .btn.btn-info.btn-elevate-hover:focus.btn-sm, .btn-group-sm > .btn.btn-info.btn-elevate-hover:focus, .btn.btn-info.btn-elevate-hover:active.btn-sm, .btn-group-sm > .btn.btn-info.btn-elevate-hover:active, .btn.btn-info.btn-elevate-hover:hover.btn-sm, .btn-group-sm > .btn.btn-info.btn-elevate-hover:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(85, 120, 235, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(85, 120, 235, 0.25) !important;}
.btn.btn-warning.btn-elevate{-webkit-box-shadow: 0px 4px 16px 0px rgba(255, 184, 34, 0.15);box-shadow: 0px 4px 16px 0px rgba(255, 184, 34, 0.15);}
.show > .btn.btn-warning.btn-elevate, .btn.btn-warning.btn-elevate:focus, .btn.btn-warning.btn-elevate.active, .btn.btn-warning.btn-elevate:active, .btn.btn-warning.btn-elevate:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(255, 184, 34, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(255, 184, 34, 0.25) !important;}
.show > .btn.btn-warning.btn-elevate.btn-sm, .btn-group-sm.show > .btn.btn-warning.btn-elevate, .btn.btn-warning.btn-elevate:focus.btn-sm, .btn-group-sm > .btn.btn-warning.btn-elevate:focus, .btn.btn-warning.btn-elevate.active.btn-sm, .btn-group-sm > .btn.btn-warning.btn-elevate.active, .btn.btn-warning.btn-elevate:active.btn-sm, .btn-group-sm > .btn.btn-warning.btn-elevate:active, .btn.btn-warning.btn-elevate:hover.btn-sm, .btn-group-sm > .btn.btn-warning.btn-elevate:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(255, 184, 34, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(255, 184, 34, 0.25) !important;}
.show > .btn.btn-warning.btn-elevate-hover, .btn.btn-warning.btn-elevate-hover.active, .btn.btn-warning.btn-elevate-hover:focus, .btn.btn-warning.btn-elevate-hover:active, .btn.btn-warning.btn-elevate-hover:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(255, 184, 34, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(255, 184, 34, 0.25) !important;}
.show > .btn.btn-warning.btn-elevate-hover.btn-sm, .btn-group-sm.show > .btn.btn-warning.btn-elevate-hover, .btn.btn-warning.btn-elevate-hover.active.btn-sm, .btn-group-sm > .btn.btn-warning.btn-elevate-hover.active, .btn.btn-warning.btn-elevate-hover:focus.btn-sm, .btn-group-sm > .btn.btn-warning.btn-elevate-hover:focus, .btn.btn-warning.btn-elevate-hover:active.btn-sm, .btn-group-sm > .btn.btn-warning.btn-elevate-hover:active, .btn.btn-warning.btn-elevate-hover:hover.btn-sm, .btn-group-sm > .btn.btn-warning.btn-elevate-hover:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(255, 184, 34, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(255, 184, 34, 0.25) !important;}
.btn.btn-danger.btn-elevate{-webkit-box-shadow: 0px 4px 16px 0px rgba(253, 57, 122, 0.15);box-shadow: 0px 4px 16px 0px rgba(253, 57, 122, 0.15);}
.show > .btn.btn-danger.btn-elevate, .btn.btn-danger.btn-elevate:focus, .btn.btn-danger.btn-elevate.active, .btn.btn-danger.btn-elevate:active, .btn.btn-danger.btn-elevate:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(253, 57, 122, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(253, 57, 122, 0.25) !important;}
.show > .btn.btn-danger.btn-elevate.btn-sm, .btn-group-sm.show > .btn.btn-danger.btn-elevate, .btn.btn-danger.btn-elevate:focus.btn-sm, .btn-group-sm > .btn.btn-danger.btn-elevate:focus, .btn.btn-danger.btn-elevate.active.btn-sm, .btn-group-sm > .btn.btn-danger.btn-elevate.active, .btn.btn-danger.btn-elevate:active.btn-sm, .btn-group-sm > .btn.btn-danger.btn-elevate:active, .btn.btn-danger.btn-elevate:hover.btn-sm, .btn-group-sm > .btn.btn-danger.btn-elevate:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(253, 57, 122, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(253, 57, 122, 0.25) !important;}
.show > .btn.btn-danger.btn-elevate-hover, .btn.btn-danger.btn-elevate-hover.active, .btn.btn-danger.btn-elevate-hover:focus, .btn.btn-danger.btn-elevate-hover:active, .btn.btn-danger.btn-elevate-hover:hover{-webkit-box-shadow: 0px 9px 16px 0px rgba(253, 57, 122, 0.25) !important;box-shadow: 0px 9px 16px 0px rgba(253, 57, 122, 0.25) !important;}
.show > .btn.btn-danger.btn-elevate-hover.btn-sm, .btn-group-sm.show > .btn.btn-danger.btn-elevate-hover, .btn.btn-danger.btn-elevate-hover.active.btn-sm, .btn-group-sm > .btn.btn-danger.btn-elevate-hover.active, .btn.btn-danger.btn-elevate-hover:focus.btn-sm, .btn-group-sm > .btn.btn-danger.btn-elevate-hover:focus, .btn.btn-danger.btn-elevate-hover:active.btn-sm, .btn-group-sm > .btn.btn-danger.btn-elevate-hover:active, .btn.btn-danger.btn-elevate-hover:hover.btn-sm, .btn-group-sm > .btn.btn-danger.btn-elevate-hover:hover{-webkit-box-shadow: 0px 6px 16px 0px rgba(253, 57, 122, 0.25) !important;box-shadow: 0px 6px 16px 0px rgba(253, 57, 122, 0.25) !important;}
.btn.btn-square{border-radius: 0;}
.btn.btn-pill{border-radius: 2rem;}
.btn.btn-upper{text-transform: uppercase;}
.btn.btn-bold{font-weight: 600;}
.btn.btn-bolder{font-weight: 500;}
.btn.btn-boldest{font-weight: 700;}
.btn.btn-thin{font-weight: 400;}
.btn.btn-lower{text-transform: lowercase;}
.btn.btn-wide{padding-left: 2.25rem;padding-right: 2.25rem;}
.btn.btn-wider{padding-left: 2.75rem;padding-right: 2.75rem;}
.btn.btn-widest{padding-left: 3rem;padding-right: 3rem;}
.btn.btn-tall{padding-top: 0.85rem;padding-bottom: 0.85rem;}
.btn.btn-taller{padding-top: 1.15rem;padding-bottom: 1.15rem;}
.btn.btn-tallest{padding-top: 1.35rem;padding-bottom: 1.35rem;}
.btn.btn-font-sm{font-size: 0.85rem;}
.btn.btn-font-md{font-size: 1rem;}
.btn.btn-font-lg{font-size: 1.1rem;}
.btn{-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.btn [class^="la-"], .btn [class*=" la-"]{font-size: 1.5rem;}
.btn [class^="fa-"], .btn [class*=" fa-"]{font-size: 1.2rem;}
.btn [class^="flaticon-"], .btn [class*=" flaticon-"], .btn [class^="flaticon2-"], .btn [class*=" flaticon2-"]{font-size: 1.2rem;}
.btn i{padding-right: 0.5rem;vertical-align: middle;line-height: 0;}
.btn.btn-sm [class^="la-"], .btn-group-sm > .btn [class^="la-"], .btn.btn-sm [class*=" la-"], .btn-group-sm > .btn [class*=" la-"]{font-size: 1.3rem;}
.btn.btn-sm [class^="fa-"], .btn-group-sm > .btn [class^="fa-"], .btn.btn-sm [class*=" fa-"], .btn-group-sm > .btn [class*=" fa-"]{font-size: 1rem;}
.btn.btn-sm [class^="flaticon-"], .btn-group-sm > .btn [class^="flaticon-"], .btn.btn-sm [class*=" flaticon-"], .btn-group-sm > .btn [class*=" flaticon-"], .btn.btn-sm [class^="flaticon2-"], .btn-group-sm > .btn [class^="flaticon2-"], .btn.btn-sm [class*=" flaticon2-"], .btn-group-sm > .btn [class*=" flaticon2-"]{font-size: 1rem;}
.btn.btn-sm i, .btn-group-sm > .btn i{padding-right: 0.35rem;}
.btn.btn-lg [class^="la-"], .btn-group-lg > .btn [class^="la-"], .btn.btn-lg [class*=" la-"], .btn-group-lg > .btn [class*=" la-"]{font-size: 1.7rem;}
.btn.btn-lg [class^="fa-"], .btn-group-lg > .btn [class^="fa-"], .btn.btn-lg [class*=" fa-"], .btn-group-lg > .btn [class*=" fa-"]{font-size: 1.4rem;}
.btn.btn-lg [class^="flaticon-"], .btn-group-lg > .btn [class^="flaticon-"], .btn.btn-lg [class*=" flaticon-"], .btn-group-lg > .btn [class*=" flaticon-"], .btn.btn-lg [class^="flaticon2-"], .btn-group-lg > .btn [class^="flaticon2-"], .btn.btn-lg [class*=" flaticon2-"], .btn-group-lg > .btn [class*=" flaticon2-"]{font-size: 1.3rem;}
.btn.btn-lg i, .btn-group-lg > .btn i{padding-right: 0.75rem;}
.btn.btn-label{background-color: #f0f3ff;color: #5d78ff;cursor: text !important;}
.btn.btn-link, a.btn.btn-label, button.btn.btn-label, input.btn.btn-label{cursor: pointer !important;}
.btn.btn-link:focus, .btn.btn-link:hover, .btn.btn-link.active, a.btn.btn-label:focus, a.btn.btn-label:hover, a.btn.btn-label.active, button.btn.btn-label:focus, button.btn.btn-label:hover, button.btn.btn-label.active, input.btn.btn-label:focus, input.btn.btn-label:hover, input.btn.btn-label.active{background-color: #5d78ff;color: #ffffff;}
.btn.btn-label-brand{background-color: rgba(93, 120, 255, 0.1);color: #5d78ff;cursor: text !important;}
.btn.btn-label-brand-o2{background-color: rgba(93, 120, 255, 0.2);color: #5d78ff;cursor: text !important;}
a.btn.btn-label-brand, button.btn.btn-label-brand, input.btn.btn-label-brand{cursor: pointer !important;}
a.btn.btn-label-brand:focus, a.btn.btn-label-brand:hover, a.btn.btn-label-brand.active, button.btn.btn-label-brand:focus, button.btn.btn-label-brand:hover, button.btn.btn-label-brand.active, input.btn.btn-label-brand:focus, input.btn.btn-label-brand:hover, input.btn.btn-label-brand.active{background-color: #5d78ff;color: #ffffff;}
.btn.btn-label-light{background-color: rgba(255, 255, 255, 0.1);color: #ffffff;cursor: text !important;}
.btn.btn-label-light-o2{background-color: rgba(255, 255, 255, 0.2);color: #ffffff;cursor: text !important;}
a.btn.btn-label-light, button.btn.btn-label-light, input.btn.btn-label-light{cursor: pointer !important;}
a.btn.btn-label-light:focus, a.btn.btn-label-light:hover, a.btn.btn-label-light.active, button.btn.btn-label-light:focus, button.btn.btn-label-light:hover, button.btn.btn-label-light.active, input.btn.btn-label-light:focus, input.btn.btn-label-light:hover, input.btn.btn-label-light.active{background-color: #ffffff;color: #282a3c;}
.btn.btn-label-dark{background-color: rgba(40, 42, 60, 0.1);color: #282a3c;cursor: text !important;}
.btn.btn-label-dark-o2{background-color: rgba(40, 42, 60, 0.2);color: #282a3c;cursor: text !important;}
a.btn.btn-label-dark, button.btn.btn-label-dark, input.btn.btn-label-dark{cursor: pointer !important;}
a.btn.btn-label-dark:focus, a.btn.btn-label-dark:hover, a.btn.btn-label-dark.active, button.btn.btn-label-dark:focus, button.btn.btn-label-dark:hover, button.btn.btn-label-dark.active, input.btn.btn-label-dark:focus, input.btn.btn-label-dark:hover, input.btn.btn-label-dark.active{background-color: #282a3c;color: #ffffff;}
.btn.btn-label-primary{background-color: rgba(88, 103, 221, 0.1);color: #5867dd;cursor: text !important;}
.btn.btn-label-primary-o2{background-color: rgba(88, 103, 221, 0.2);color: #5867dd;cursor: text !important;}
a.btn.btn-label-primary, button.btn.btn-label-primary, input.btn.btn-label-primary{cursor: pointer !important;}
a.btn.btn-label-primary:focus, a.btn.btn-label-primary:hover, a.btn.btn-label-primary.active, button.btn.btn-label-primary:focus, button.btn.btn-label-primary:hover, button.btn.btn-label-primary.active, input.btn.btn-label-primary:focus, input.btn.btn-label-primary:hover, input.btn.btn-label-primary.active{background-color: #5867dd;color: #ffffff;}
.btn.btn-label-success{background-color: rgba(10, 187, 135, 0.1);color: #0abb87;cursor: text !important;}
.btn.btn-label-success-o2{background-color: rgba(10, 187, 135, 0.2);color: #0abb87;cursor: text !important;}
a.btn.btn-label-success, button.btn.btn-label-success, input.btn.btn-label-success{cursor: pointer !important;}
a.btn.btn-label-success:focus, a.btn.btn-label-success:hover, a.btn.btn-label-success.active, button.btn.btn-label-success:focus, button.btn.btn-label-success:hover, button.btn.btn-label-success.active, input.btn.btn-label-success:focus, input.btn.btn-label-success:hover, input.btn.btn-label-success.active{background-color: #0abb87;color: #ffffff;}
.btn.btn-label-info{background-color: rgba(85, 120, 235, 0.1);color: #5578eb;cursor: text !important;}
.btn.btn-label-info-o2{background-color: rgba(85, 120, 235, 0.2);color: #5578eb;cursor: text !important;}
a.btn.btn-label-info, button.btn.btn-label-info, input.btn.btn-label-info{cursor: pointer !important;}
a.btn.btn-label-info:focus, a.btn.btn-label-info:hover, a.btn.btn-label-info.active, button.btn.btn-label-info:focus, button.btn.btn-label-info:hover, button.btn.btn-label-info.active, input.btn.btn-label-info:focus, input.btn.btn-label-info:hover, input.btn.btn-label-info.active{background-color: #5578eb;color: #ffffff;}
.btn.btn-label-warning{background-color: rgba(255, 184, 34, 0.1);color: #ffb822;cursor: text !important;}
.btn.btn-label-warning-o2{background-color: rgba(255, 184, 34, 0.2);color: #ffb822;cursor: text !important;}
a.btn.btn-label-warning, button.btn.btn-label-warning, input.btn.btn-label-warning{cursor: pointer !important;}
a.btn.btn-label-warning:focus, a.btn.btn-label-warning:hover, a.btn.btn-label-warning.active, button.btn.btn-label-warning:focus, button.btn.btn-label-warning:hover, button.btn.btn-label-warning.active, input.btn.btn-label-warning:focus, input.btn.btn-label-warning:hover, input.btn.btn-label-warning.active{background-color: #ffb822;color: #111111;}
.btn.btn-label-danger{background-color: rgba(253, 57, 122, 0.1);color: #fd397a;cursor: text !important;}
.btn.btn-label-danger-o2{background-color: rgba(253, 57, 122, 0.2);color: #fd397a;cursor: text !important;}
a.btn.btn-label-danger, button.btn.btn-label-danger, input.btn.btn-label-danger{cursor: pointer !important;}
a.btn.btn-label-danger:focus, a.btn.btn-label-danger:hover, a.btn.btn-label-danger.active, button.btn.btn-label-danger:focus, button.btn.btn-label-danger:hover, button.btn.btn-label-danger.active, input.btn.btn-label-danger:focus, input.btn.btn-label-danger:hover, input.btn.btn-label-danger.active{background-color: #fd397a;color: #ffffff;}
.btn.btn-label-facebook{background-color: rgba(59, 89, 152, 0.1);color: #3b5998;cursor: text !important;}
.btn.btn-label-facebook-o2{background-color: rgba(59, 89, 152, 0.2);color: #3b5998;cursor: text !important;}
a.btn.btn-label-facebook, button.btn.btn-label-facebook, input.btn.btn-label-facebook{cursor: pointer !important;}
a.btn.btn-label-facebook:focus, a.btn.btn-label-facebook:hover, a.btn.btn-label-facebook.active, button.btn.btn-label-facebook:focus, button.btn.btn-label-facebook:hover, button.btn.btn-label-facebook.active, input.btn.btn-label-facebook:focus, input.btn.btn-label-facebook:hover, input.btn.btn-label-facebook.active{background-color: #3b5998;color: #ffffff;}
.btn.btn-label-google{background-color: rgba(220, 78, 65, 0.1);color: #dc4e41;cursor: text !important;}
.btn.btn-label-google-o2{background-color: rgba(220, 78, 65, 0.2);color: #dc4e41;cursor: text !important;}
a.btn.btn-label-google, button.btn.btn-label-google, input.btn.btn-label-google{cursor: pointer !important;}
a.btn.btn-label-google:focus, a.btn.btn-label-google:hover, a.btn.btn-label-google.active, button.btn.btn-label-google:focus, button.btn.btn-label-google:hover, button.btn.btn-label-google.active, input.btn.btn-label-google:focus, input.btn.btn-label-google:hover, input.btn.btn-label-google.active{background-color: #dc4e41;color: #ffffff;}
.btn.btn-label-twitter{background-color: rgba(74, 179, 244, 0.1);color: #4AB3F4;cursor: text !important;}
.btn.btn-label-twitter-o2{background-color: rgba(74, 179, 244, 0.2);color: #4AB3F4;cursor: text !important;}
a.btn.btn-label-twitter, button.btn.btn-label-twitter, input.btn.btn-label-twitter{cursor: pointer !important;}
a.btn.btn-label-twitter:focus, a.btn.btn-label-twitter:hover, a.btn.btn-label-twitter.active, button.btn.btn-label-twitter:focus, button.btn.btn-label-twitter:hover, button.btn.btn-label-twitter.active, input.btn.btn-label-twitter:focus, input.btn.btn-label-twitter:hover, input.btn.btn-label-twitter.active{background-color: #4AB3F4;color: #ffffff;}
.btn.btn-label-instagram{background-color: rgba(81, 127, 164, 0.1);color: #517fa4;cursor: text !important;}
.btn.btn-label-instagram-o2{background-color: rgba(81, 127, 164, 0.2);color: #517fa4;cursor: text !important;}
a.btn.btn-label-instagram, button.btn.btn-label-instagram, input.btn.btn-label-instagram{cursor: pointer !important;}
a.btn.btn-label-instagram:focus, a.btn.btn-label-instagram:hover, a.btn.btn-label-instagram.active, button.btn.btn-label-instagram:focus, button.btn.btn-label-instagram:hover, button.btn.btn-label-instagram.active, input.btn.btn-label-instagram:focus, input.btn.btn-label-instagram:hover, input.btn.btn-label-instagram.active{background-color: #517fa4;color: #ffffff;}
.btn.btn-label-youtube{background-color: rgba(179, 18, 23, 0.1);color: #b31217;cursor: text !important;}
.btn.btn-label-youtube-o2{background-color: rgba(179, 18, 23, 0.2);color: #b31217;cursor: text !important;}
a.btn.btn-label-youtube, button.btn.btn-label-youtube, input.btn.btn-label-youtube{cursor: pointer !important;}
a.btn.btn-label-youtube:focus, a.btn.btn-label-youtube:hover, a.btn.btn-label-youtube.active, button.btn.btn-label-youtube:focus, button.btn.btn-label-youtube:hover, button.btn.btn-label-youtube.active, input.btn.btn-label-youtube:focus, input.btn.btn-label-youtube:hover, input.btn.btn-label-youtube.active{background-color: #b31217;color: #ffffff;}
.btn.btn-label-linkedin{background-color: rgba(0, 119, 181, 0.1);color: #0077b5;cursor: text !important;}
.btn.btn-label-linkedin-o2{background-color: rgba(0, 119, 181, 0.2);color: #0077b5;cursor: text !important;}
a.btn.btn-label-linkedin, button.btn.btn-label-linkedin, input.btn.btn-label-linkedin{cursor: pointer !important;}
a.btn.btn-label-linkedin:focus, a.btn.btn-label-linkedin:hover, a.btn.btn-label-linkedin.active, button.btn.btn-label-linkedin:focus, button.btn.btn-label-linkedin:hover, button.btn.btn-label-linkedin.active, input.btn.btn-label-linkedin:focus, input.btn.btn-label-linkedin:hover, input.btn.btn-label-linkedin.active{background-color: #0077b5;color: #ffffff;}
.btn.btn-icon{display: -webkit-inline-box;display: -ms-inline-flexbox;display: inline-flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;padding: 0;}
.btn.btn-icon.btn-circle{border-radius: 50%;}
.btn.btn-icon i{padding: 0;margin: 0;line-height: 0;}
.btn.btn-icon [class^="socicon-"], .btn.btn-icon [class*=" socicon-"]{line-height: 1;}
.btn.btn-icon{height: 3rem;width: 3rem;}
.btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon{height: 2.5rem;width: 2.5rem;}
.btn.btn-icon.btn-lg, .btn-group-lg > .btn.btn-icon{height: 4rem;width: 4rem;}
.btn.btn-icon.btn-icon-sm [class^="la-"], .btn.btn-icon.btn-icon-sm [class*=" la-"]{font-size: 1.25rem;}
.btn.btn-icon.btn-icon-sm [class^="fa-"], .btn.btn-icon.btn-icon-sm [class*=" fa-"]{font-size: 1.15rem;}
.btn.btn-icon.btn-icon-sm [class^="flaticon-"], .btn.btn-icon.btn-icon-sm [class*=" flaticon-"], .btn.btn-icon.btn-icon-sm [class^="flaticon2-"], .btn.btn-icon.btn-icon-sm [class*=" flaticon2-"]{font-size: 1.15rem;}
.btn.btn-icon.btn-icon-md [class^="la-"], .btn.btn-icon.btn-icon-md [class*=" la-"]{font-size: 1.3rem;}
.btn.btn-icon.btn-icon-md [class^="fa-"], .btn.btn-icon.btn-icon-md [class*=" fa-"]{font-size: 1.2rem;}
.btn.btn-icon.btn-icon-md [class^="flaticon-"], .btn.btn-icon.btn-icon-md [class*=" flaticon-"], .btn.btn-icon.btn-icon-md [class^="flaticon2-"], .btn.btn-icon.btn-icon-md [class*=" flaticon2-"]{font-size: 1.2rem;}
.btn.btn-icon.btn-icon-lg [class^="la-"], .btn.btn-icon.btn-icon-lg [class*=" la-"]{font-size: 1.4rem;}
.btn.btn-icon.btn-icon-lg [class^="fa-"], .btn.btn-icon.btn-icon-lg [class*=" fa-"]{font-size: 1.4rem;}
.btn.btn-icon.btn-icon-lg [class^="flaticon-"], .btn.btn-icon.btn-icon-lg [class*=" flaticon-"], .btn.btn-icon.btn-icon-lg [class^="flaticon2-"], .btn.btn-icon.btn-icon-lg [class*=" flaticon2-"]{font-size: 1.4rem;}
.btn.btn-icon.btn-brand i{color: #ffffff;}
.btn.btn-icon.btn-brand:focus i, .btn.btn-icon.btn-brand:hover i, .btn.btn-icon.btn-brand.active i{color: #ffffff;}
.btn.btn-icon.btn-light i{color: #282a3c;}
.btn.btn-icon.btn-light:focus i, .btn.btn-icon.btn-light:hover i, .btn.btn-icon.btn-light.active i{color: #282a3c;}
.btn.btn-icon.btn-dark i{color: #ffffff;}
.btn.btn-icon.btn-dark:focus i, .btn.btn-icon.btn-dark:hover i, .btn.btn-icon.btn-dark.active i{color: #ffffff;}
.btn.btn-icon.btn-primary i{color: #ffffff;}
.btn.btn-icon.btn-primary:focus i, .btn.btn-icon.btn-primary:hover i, .btn.btn-icon.btn-primary.active i{color: #ffffff;}
.btn.btn-icon.btn-success i{color: #ffffff;}
.btn.btn-icon.btn-success:focus i, .btn.btn-icon.btn-success:hover i, .btn.btn-icon.btn-success.active i{color: #ffffff;}
.btn.btn-icon.btn-info i{color: #ffffff;}
.btn.btn-icon.btn-info:focus i, .btn.btn-icon.btn-info:hover i, .btn.btn-icon.btn-info.active i{color: #ffffff;}
.btn.btn-icon.btn-warning i{color: #111111;}
.btn.btn-icon.btn-warning:focus i, .btn.btn-icon.btn-warning:hover i, .btn.btn-icon.btn-warning.active i{color: #111111;}
.btn.btn-icon.btn-danger i{color: #ffffff;}
.btn.btn-icon.btn-danger:focus i, .btn.btn-icon.btn-danger:hover i, .btn.btn-icon.btn-danger.active i{color: #ffffff;}
.btn-icon-h{height: 3rem;}
.btn-icon-h.btn-sm, .btn-group-sm > .btn-icon-h.btn{height: 2.25rem;}
.btn-icon-h.btn-lg, .btn-group-lg > .btn-icon-h.btn{height: 4rem;}
.input-group-append .btn.btn-icon, .input-group-prepend .btn.btn-icon{height: auto;}
.btn.kt-spinner:not(.kt-spinner--center){
padding-left: 3rem;}
.btn.kt-spinner:not(.kt-spinner--center):before{left: 1rem;}
.btn.kt-spinner.kt-spinner--right{padding-left: 1rem;padding-right: 3rem;}
.btn.kt-spinner.kt-spinner--right:before{left: auto;right: 1rem;}
.btn.kt-spinner.kt-spinner--sm:not(.kt-spinner--center){
padding-left: 2.5rem;}
.btn.kt-spinner.kt-spinner--sm:not(.kt-spinner--center):before{left: 1rem;}
.btn.kt-spinner.kt-spinner--sm.kt-spinner--right{padding-left: 1rem;padding-right: 2.5rem;}
.btn.kt-spinner.kt-spinner--sm.kt-spinner--right:before{left: auto;right: 1rem;}
.btn.kt-spinner.kt-spinner--lg:not(.kt-spinner--center){
padding-left: 3.5rem;}
.btn.kt-spinner.kt-spinner--lg:not(.kt-spinner--center):before{left: 1rem;}
.btn.kt-spinner.kt-spinner--lg.kt-spinner--right{padding-left: 1rem;padding-right: 3.5rem;}
.btn.kt-spinner.kt-spinner--lg.kt-spinner--right:before{left: auto;right: 1rem;}
.btn.btn-glow{-webkit-box-shadow: 0px 2px 14px 2px rgba(255, 255, 255, 0.1);box-shadow: 0px 2px 14px 2px rgba(255, 255, 255, 0.1);}
.show > .btn.btn-glow, .btn.btn-glow.active, .btn.btn-glow:active, .btn.btn-glow:hover{-webkit-box-shadow: 0px 2px 14px 2px rgba(255, 255, 255, 0.3);box-shadow: 0px 2px 14px 2px rgba(255, 255, 255, 0.3);}
.btn-group.btn-pill{border-radius: 2rem;overflow: hidden;}
.btn-group.btn-pill .btn-brand{border-width: 1px;border-style: solid;}
.btn-group.btn-pill .btn-light{border-width: 1px;border-style: solid;}
.btn-group.btn-pill .btn-dark{border-width: 1px;border-style: solid;}
.btn-group.btn-pill .btn-primary{border-width: 1px;border-style: solid;}
.btn-group.btn-pill .btn-success{border-width: 1px;border-style: solid;}
.btn-group.btn-pill .btn-info{border-width: 1px;border-style: solid;}
.btn-group.btn-pill .btn-warning{border-width: 1px;border-style: solid;}
.btn-group.btn-pill .btn-danger{border-width: 1px;border-style: solid;}
.btn-group.btn-elevated{-webkit-box-shadow: 0px 0px 11px 0px rgba(56, 36, 99, 0.08);box-shadow: 0px 0px 11px 0px rgba(56, 36, 99, 0.08);}
@media (max-width: 768px){
.btn-group.btn-pill{border-radius: 0;}
.btn-group.btn-pill .nav{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.btn-group.btn-pill .btn{margin-bottom: 0.5rem;border-radius: 2rem;}
}
code{background-color: #f7f8fa;padding: 0.15rem 0.25rem;border-radius: 4px;}
.dropdown-menu{border: 0 !important;margin: 0;border-radius: 0;min-width: 14rem;padding: 0;-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);padding: 1rem 0;border-radius: 4px;left: 0/*rtl:ignore*/;}
.dropdown-menu.dropdown-menu-fit{padding: 0;}
.dropdown-menu.dropdown-menu-fit-bottom{padding-bottom: 0;}
.dropdown-menu.dropdown-menu-fit-top{padding-top: 0;}
.dropdown-menu.dropdown-menu-fit:not(.dropdown-menu-top-unround) > div:first-of-type{border-top-left-radius: 4px;border-top-right-radius: 4px;}
.dropdown-menu.dropdown-menu-fit:not(.dropdown-menu-top-unround) form{border-top-left-radius: 4px;border-top-right-radius: 4px;}
.dropdown-menu.dropdown-menu-top-unround{border-top-left-radius: 0;border-top-right-radius: 0;}
.dropdown-menu:before, .dropdown-menu:after{display: none !important;}
.dropdown-menu > li > a, .dropdown-menu > .dropdown-item{outline: none !important;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding: 0.7rem 1.2rem;}
.dropdown-menu > li > a [class^="la-"], .dropdown-menu > li > a [class*=" la-"], .dropdown-menu > .dropdown-item [class^="la-"], .dropdown-menu > .dropdown-item [class*=" la-"]{font-size: 1.4rem;}
.dropdown-menu > li > a [class^="fa-"], .dropdown-menu > li > a [class*=" fa-"], .dropdown-menu > .dropdown-item [class^="fa-"], .dropdown-menu > .dropdown-item [class*=" fa-"]{font-size: 1.4rem;}
.dropdown-menu > li > a [class^="flaticon-"], .dropdown-menu > li > a [class*=" flaticon-"], .dropdown-menu > li > a [class^="flaticon2-"], .dropdown-menu > li > a [class*=" flaticon2-"], .dropdown-menu > .dropdown-item [class^="flaticon-"], .dropdown-menu > .dropdown-item [class*=" flaticon-"], .dropdown-menu > .dropdown-item [class^="flaticon2-"], .dropdown-menu > .dropdown-item [class*=" flaticon2-"]{font-size: 1.3rem;}
.dropdown-menu > li > a > i, .dropdown-menu > .dropdown-item > i{margin-right: 0.75rem;color: #a7abc3;}
.dropdown-menu > li > a > i:before, .dropdown-menu > .dropdown-item > i:before{line-height: 0;vertical-align: middle;}
.dropdown-menu .dropdown-divider{border-top: 1px solid #ebedf2;}
.dropdown-menu .dropdown-menu:not(.daterangepicker){
}
.dropdown-menu.dropdown-menu-sm{width: 240px;}
.dropdown-menu.dropdown-menu-md{width: 260px;}
.dropdown-menu.dropdown-menu-lg{width: 320px;}
.dropdown-menu.dropdown-menu-xl{width: 380px;}
.dropdown-menu.dropdown-menu-anim{-webkit-animation: dropdown-menu-fade-in .3s ease 1, dropdown-menu-move-up .3s ease-out 1;animation: dropdown-menu-fade-in .3s ease 1, dropdown-menu-move-up .3s ease-out 1;}
.dropup .dropdown-menu.dropdown-menu-anim, .dropdown-menu .dropdown-menu-anim-down.dropdown-menu-anim{-webkit-animation: dropdown-menu-fade-in .3s ease 1, dropdown-menu-move-down .3s ease-out 1;animation: dropdown-menu-fade-in .3s ease 1, dropdown-menu-move-down .3s ease-out 1;}
@media (max-width: 768px){
.dropdown-menu.dropdown-menu-xl, .dropdown-menu.dropdown-menu-lg, .dropdown-menu.dropdown-menu-md{width: 300px;}
}
.nav-link.dropdown-toggle:after, .btn.dropdown-toggle:after{text-align: center;display: inline;border: 0;font-size: 0.6rem;line-height: 0;vertical-align: middle;position: relative;margin-left: 0.5rem/*rtl:ignore*/;opacity: 0.7;font-family: Flaticon2;font-style: normal;font-weight: normal;font-variant: normal;line-height: 1;text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";}
.nav-link.dropdown-toggle.dropdown-toggle-split:after, .btn.dropdown-toggle.dropdown-toggle-split:after{right: 1px;}
.dropdown.dropdown-inline{display: inline-block;}
.dropup .nav-link.dropdown-toggle:after, .dropup .btn.dropdown-toggle:after{font-family: Flaticon2;font-style: normal;font-weight: normal;font-variant: normal;line-height: 1;text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";}
.btn-group.dropright .nav-link.dropdown-toggle:before, .btn-group.dropright .btn.dropdown-toggle:before{display: none;}
.btn-group.dropright .nav-link.dropdown-toggle:after, .btn-group.dropright .btn.dropdown-toggle:after{font-family: Flaticon2;font-style: normal;font-weight: normal;font-variant: normal;line-height: 1;text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";}
.btn-group.dropleft .nav-link.dropdown-toggle:before, .btn-group.dropleft .btn.dropdown-toggle:before{display: none;}
.btn-group.dropleft .nav-link.dropdown-toggle:after, .btn-group.dropleft .btn.dropdown-toggle:after{font-family: Flaticon2;font-style: normal;font-weight: normal;font-variant: normal;line-height: 1;text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";}
@-webkit-keyframes dropdown-menu-fade-in{
from{opacity: 0;}
to{opacity: 1;}
}
@keyframes dropdown-menu-fade-in{
from{opacity: 0;}
to{opacity: 1;}
}
@-webkit-keyframes dropdown-menu-move-down{
from{margin-top: -10px;}
to{margin-top: 0;}
}
@keyframes dropdown-menu-move-down{
from{margin-top: -10px;}
to{margin-top: 0;}
}
@-webkit-keyframes dropdown-menu-move-up{
from{margin-top: 10px;}
to{margin-top: 0;}
}
@keyframes dropdown-menu-move-up{
from{margin-top: 10px;}
to{margin-top: 0;}
}
.form-group{margin-bottom: 2rem;}
.form-group.form-group-marginless, .form-group.form-group-last{margin-bottom: 0px;}
.form-group label{font-size: 1rem;font-weight: 400;}
.form-group .invalid-feedback, .form-group .valid-feedback{font-weight: 400;}
.form-group .form-text{font-size: 0.9rem;}
.form-group.form-group-md{margin-bottom: 1rem;}
.form-group.form-group-sm{margin-bottom: 0.5rem;}
.form-group.form-group-xs{margin-bottom: 0.25rem;}
.form-group.form-group-last{margin-bottom: 0rem;}
.form-control:focus, .form-control:active{-webkit-box-shadow: none !important;box-shadow: none !important;}
.form-control[readonly]{background-color: #ffffff;}
.form-control.form-control-pill{border-radius: 50px;}
.custom-select{-webkit-appearance: none;-moz-appearance: none;appearance: none;}
.custom-file{width: 100%;}
.custom-file-input:focus ~ .custom-file-label{-webkit-box-shadow: none !important;box-shadow: none !important;}
.custom-file-label{text-align: left;}
.custom-file-label:after{float: left;}
.input-group [class^="la-"], .input-group [class*=" la-"]{font-size: 1.4rem;}
.input-group [class^="fa-"], .input-group [class*=" fa-"]{font-size: 1.2rem;}
.input-group [class^="flaticon-"], .input-group [class*=" flaticon-"], .input-group [class^="flaticon2-"], .input-group [class*=" flaticon2-"]{font-size: 1.2rem;}
.input-group i{color: #a7abc3;line-height: 0;}
.input-group .form-control.is-valid + .input-group-append, .input-group .form-control.is-invalid + .input-group-append{margin-left: 0;}
.input-group .input-group-prepend + .form-control.is-valid, .input-group .input-group-prepend + .form-control.is-invalid{margin-left: 1px;}
.validated .valid-feedback, .validated .invalid-feedback{display: block;}
.row.row-no-padding{margin-left: 0;margin-right: 0;}
.row.row-no-padding > div{padding-left: 0;padding-right: 0;}
.row.row-full-height{height: 100%;}
.row .col-stretch{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.row[class*="row-col-separator-"] > div{border-bottom: 1px solid #ebedf2;}
.row[class*="row-col-separator-"] > div:last-child{border-bottom: 0;}
@media (min-width: 1px){
.row.row-col-separator-xs > div{border-bottom: 0;border-right: 1px solid #ebedf2;}
.row.row-col-separator-xs > div:last-child{border-right: 0;}
}
@media (min-width: 577px){
.row.row-col-separator-sm > div{border-bottom: 0;border-right: 1px solid #ebedf2;}
.row.row-col-separator-sm > div:last-child{border-right: 0;}
}
@media (min-width: 769px){
.row.row-col-separator-md > div{border-bottom: 0;border-right: 1px solid #ebedf2;}
.row.row-col-separator-md > div:last-child{border-right: 0;}
}
@media (min-width: 1025px){
.row.row-col-separator-lg > div{border-bottom: 0;border-right: 1px solid #ebedf2;}
.row.row-col-separator-lg > div:last-child{border-right: 0;}
}
@media (min-width: 1400px){
.row.row-col-separator-xl > div{border-bottom: 0;border-right: 1px solid #ebedf2;}
.row.row-col-separator-xl > div:last-child{border-right: 0;}
}
.modal .modal-content{border-radius: 4px;}
.modal .modal-content .modal-header .modal-title{font-weight: 500;font-size: 1.3rem;color: #464457;}
.modal .modal-content .modal-header .modal-title small{font-weight: 400;font-size: 0.9rem;color: #a7abc3;}
.modal .modal-content .modal-header .close{outline: none !important;color: #a7abc3;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;-webkit-transition: all 0.3s;transition: all 0.3s;}
.modal .modal-content .modal-header .close:before{content: "";}
.modal .modal-content .modal-header .close:hover{-webkit-transition: all 0.3s;transition: all 0.3s;}
.modal .modal-content .modal-header .close:before{font-size: 1.3rem;}
.modal .modal-content .modal-header .close span{display: none;}
.modal .modal-content .modal-header .close:hover{color: #5d78ff;}
.modal.modal-sticky-bottom-right{padding: 0 !important;max-width: 500px;height: auto;position: fixed;left: auto;top: auto;bottom: 25px;right: 25px;margin: 0;-webkit-box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);border-radius: 4px;}
@media (max-width: 1024px){
.modal.modal-sticky-bottom-right{bottom: 10px;right: 10px;}
}
@media (max-width: 768px){
.modal.modal-sticky-bottom-right{max-width: 90%;}
}
.modal.modal-sticky-bottom-right .modal-dialog{position: static;max-width: 500px;width: auto;margin: 0;border-radius: 4px;}
.modal.modal-sticky-bottom-right .modal-dialog .modal-content{border: 0;border-radius: 4px;}
.modal-open{overflow: auto !important;padding: 0 !important;}
.modal-body.modal-body-fit{padding: 0;}
.modal-body.modal-body-x-fit{padding-left: 0;padding-right: 0;}
.modal-body.modal-body-y-fit{padding-top: 0;padding-bottom: 0;}
@media (min-width: 1400px){
.modal-dialog.modal-xl{max-width: 1299px;}
}
.pagination.pagination--grid{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;margin: 0;}
.pagination.pagination--grid .page-item .page-link{background-color: transparent;border: 0;font-weight: 400;color: #928eaa;border-radius: 5px;width: 2.25rem;height: 2.25rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;font-size: 1.2rem;margin: 0 0.1rem;-webkit-transition: all 0.3s;transition: all 0.3s;}
.pagination.pagination--grid .page-item.active .page-link, .pagination.pagination--grid .page-item:hover .page-link, .pagination.pagination--grid .page-item:focus .page-link{background-color: #5d78ff;color: #ffffff;}
.popover{-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);}
.popover .popover-header{font-size: 1rem;font-weight: 500;border-bottom: 1px solid #ebedf2;}
.progress{background-color: #ebedf2;}
.progress .progress-bar{-webkit-transition: all 0.5s ease;transition: all 0.5s ease;}
.progress.progress-sm{height: 6px;}
.progress.progress-sm .progress-bar{border-radius: 3px;}
.progress.progress-lg{height: 20px;}
.progress.progress-lg .progress-bar{border-radius: 4px;}
.table thead th, .table thead td{font-weight: 500;border-bottom-width: 1px;padding-top: 1rem;padding-bottom: 1rem;}
.table tr.table-active td, .table tr.table-active th{color: #ffffff;}
.table.table-head-noborder thead th, .table.table-head-noborder thead td{border-top: 0;}
.table.table-head-solid thead th, .table.table-head-solid thead td{background-color: #f7f8fa;}
.table:not(.table-bordered) thead th, .table:not(.table-bordered) thead td{border-top: 0;}
.nav-pills, .nav-tabs{margin: 0 0 25px 0;}
.nav-pills .nav-item .nav-link, .nav-tabs .nav-item .nav-link{padding: 0.75rem 1.25rem;font-size: 1rem;font-weight: 400;-webkit-transition: all 0.3s;transition: all 0.3s;font-size: 1rem;font-weight: 400;}
.nav-pills .nav-item .nav-link [class^="la-"], .nav-pills .nav-item .nav-link [class*=" la-"], .nav-tabs .nav-item .nav-link [class^="la-"], .nav-tabs .nav-item .nav-link [class*=" la-"]{font-size: 1.3rem;}
.nav-pills .nav-item .nav-link [class^="fa-"], .nav-pills .nav-item .nav-link [class*=" fa-"], .nav-tabs .nav-item .nav-link [class^="fa-"], .nav-tabs .nav-item .nav-link [class*=" fa-"]{font-size: 1.2rem;}
.nav-pills .nav-item .nav-link [class^="flaticon-"], .nav-pills .nav-item .nav-link [class*=" flaticon-"], .nav-pills .nav-item .nav-link [class^="flaticon2-"], .nav-pills .nav-item .nav-link [class*=" flaticon2-"], .nav-tabs .nav-item .nav-link [class^="flaticon-"], .nav-tabs .nav-item .nav-link [class*=" flaticon-"], .nav-tabs .nav-item .nav-link [class^="flaticon2-"], .nav-tabs .nav-item .nav-link [class*=" flaticon2-"]{font-size: 1.4rem;}
.nav-pills .nav-item .nav-link i, .nav-tabs .nav-item .nav-link i{vertical-align: middle;line-height: 0;display: inline-block;margin-right: 0.5rem;}
.nav-pills .nav-item .nav-link.active, .nav-pills .nav-item .nav-link:active, .nav-pills .nav-item .nav-link:hover, .nav-tabs .nav-item .nav-link.active, .nav-tabs .nav-item .nav-link:active, .nav-tabs .nav-item .nav-link:hover{-webkit-transition: all 0.3s;transition: all 0.3s;}
.nav-tabs .nav-item .nav-link{color: #5d78ff;}
.nav-tabs .nav-item .nav-link i{color: #5d78ff;}
.nav-tabs .nav-item .nav-link.active, .nav-tabs .nav-item .nav-link:active, .nav-tabs .nav-item .nav-link:hover{color: #5d78ff;}
.nav-tabs .nav-item .nav-link.active i, .nav-tabs .nav-item .nav-link:active i, .nav-tabs .nav-item .nav-link:hover i{color: #5d78ff;}
.nav-tabs .nav-item .nav-link.disabled{color: #a7abc3;}
.nav-tabs .nav-item .nav-link.disabled i{color: #a7abc3;}
.nav-tabs .nav-item.show > .nav-link{color: #5d78ff;}
.nav-tabs .nav-item.show > .nav-link i{color: #5d78ff;}
.nav-pills .nav-item{margin-right: 0.5rem;}
.nav-pills .nav-item:last-child{margin-right: 0;}
.nav-pills .nav-item .nav-link{color: #6c7293;}
.nav-pills .nav-item .nav-link:active, .nav-pills .nav-item .nav-link.active, .nav-pills .nav-item .nav-link.active:hover{background-color: #5d78ff;color: #ffffff;}
.nav-pills .nav-item.show > .nav-link{background-color: #5d78ff;color: #ffffff;}
.nav-pills.nav-pills-sm .nav-item .nav-link{font-size: 0.9rem;padding: 0.5rem 1rem;}
.nav-pills.nav-pills-bold .nav-item .nav-link{font-weight: 500;}
.nav-pills.nav-pills-label .nav-item .nav-link:active, .nav-pills.nav-pills-label .nav-item .nav-link.active, .nav-pills.nav-pills-label .nav-item .nav-link.active:hover{background-color: rgba(93, 120, 255, 0.1);color: #5d78ff;}
.nav-pills.nav-pills-label .nav-item.show > .nav-link{background-color: rgba(93, 120, 255, 0.1);color: #5d78ff;}
.nav-fit{margin: 0 !important;}
.nav-pills.nav-tabs-btn{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.nav-pills.nav-tabs-btn:before, .nav-pills.nav-tabs-btn:after{content: " ";display: table;}
.nav-pills.nav-tabs-btn:after{clear: both;}
.nav-pills.nav-tabs-btn .nav-item{max-width: 100%;min-width: 75px;margin: 0.5rem;-webkit-box-shadow: rgba(69, 65, 78, 0.06) 0px 1px 15px 1px;box-shadow: rgba(69, 65, 78, 0.06) 0px 1px 15px 1px;border-radius: 0.5rem;text-align: center;}
.nav-pills.nav-tabs-btn .nav-item .nav-link{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0.85rem 0.75rem;}
.nav-pills.nav-tabs-btn .nav-item .nav-link .nav-link-icon{display: block;padding-bottom: 0.5rem;}
.nav-pills.nav-tabs-btn .nav-item .nav-link .nav-link-icon i{margin: 0;font-size: 1.3rem;}
.nav-pills.nav-tabs-btn .nav-item .nav-link .nav-link-title{display: block;font-weight: 500;}
.nav-pills.nav-tabs-btn .nav-link{border: 0;padding: 12px 0;font-size: 1rem;font-weight: 400;}
.nav-pills.nav-tabs-btn .nav-link [class^="la-"], .nav-pills.nav-tabs-btn .nav-link [class*=" la-"]{font-size: 1.3rem;}
.nav-pills.nav-tabs-btn .nav-link [class^="fa-"], .nav-pills.nav-tabs-btn .nav-link [class*=" fa-"]{font-size: 1.2rem;}
.nav-pills.nav-tabs-btn .nav-link [class^="flaticon-"], .nav-pills.nav-tabs-btn .nav-link [class*=" flaticon-"], .nav-pills.nav-tabs-btn .nav-link [class^="flaticon2-"], .nav-pills.nav-tabs-btn .nav-link [class*=" flaticon2-"]{font-size: 1.4rem;}
.nav-pills.nav-tabs-btn .nav-link i{vertical-align: middle;line-height: 0;display: inline-block;margin-right: 0.5rem;}
.nav-pills.nav-tabs-btn.nav-tabs-btn-2x a.nav-link{border-bottom-width: 2px !important;}
.nav-pills.nav-tabs-btn.nav-tabs-btn-left{float: left;}
.nav-pills.nav-tabs-btn.nav-tabs-btn-right{float: right;}
.kt-portlet--tabs .nav-pills.nav-tabs-btn{margin: 0 0 -1px 0;}
.kt-portlet--tabs .nav-pills.nav-tabs-btn.nav-tabs-btn-2x{margin: 0 0 -2px 0;}
.kt-portlet--tabs .nav-pills.nav-tabs-btn .nav-item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-portlet--tabs .nav-pills.nav-tabs-btn .nav-item .nav-link{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.nav-pills.nav-tabs-btn.nav-tabs-bold .nav-item .nav-link{font-weight: 500;}
.nav-pills.nav-tabs-btn a.nav-link.active .nav-link-icon i{color: #fff;}
.nav-pills.nav-tabs-btn a.nav-link.active .nav-link-title{color: #fff;}
.nav-pills.nav-tabs-btn a.nav-link .nav-link-icon i{color: #adb1c7;}
.nav-pills.nav-tabs-btn a.nav-link .nav-link-title{color: #a7abc3;}
.nav-pills.nav-tabs-btn.nav-pills-btn-brand a.nav-link.active{background-color: #5d78ff;}
.nav-pills.nav-tabs-btn.nav-pills-btn-brand a.nav-link.active .nav-link-icon i{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-brand a.nav-link.active .nav-link-title{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-light a.nav-link.active{background-color: #ffffff;}
.nav-pills.nav-tabs-btn.nav-pills-btn-light a.nav-link.active .nav-link-icon i{color: #282a3c !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-light a.nav-link.active .nav-link-title{color: #282a3c !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-dark a.nav-link.active{background-color: #282a3c;}
.nav-pills.nav-tabs-btn.nav-pills-btn-dark a.nav-link.active .nav-link-icon i{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-dark a.nav-link.active .nav-link-title{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-primary a.nav-link.active{background-color: #5867dd;}
.nav-pills.nav-tabs-btn.nav-pills-btn-primary a.nav-link.active .nav-link-icon i{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-primary a.nav-link.active .nav-link-title{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-success a.nav-link.active{background-color: #0abb87;}
.nav-pills.nav-tabs-btn.nav-pills-btn-success a.nav-link.active .nav-link-icon i{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-success a.nav-link.active .nav-link-title{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-info a.nav-link.active{background-color: #5578eb;}
.nav-pills.nav-tabs-btn.nav-pills-btn-info a.nav-link.active .nav-link-icon i{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-info a.nav-link.active .nav-link-title{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-warning a.nav-link.active{background-color: #ffb822;}
.nav-pills.nav-tabs-btn.nav-pills-btn-warning a.nav-link.active .nav-link-icon i{color: #111111 !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-warning a.nav-link.active .nav-link-title{color: #111111 !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-danger a.nav-link.active{background-color: #fd397a;}
.nav-pills.nav-tabs-btn.nav-pills-btn-danger a.nav-link.active .nav-link-icon i{color: #ffffff !important;}
.nav-pills.nav-tabs-btn.nav-pills-btn-danger a.nav-link.active .nav-link-title{color: #ffffff !important;}
@media (max-width: 768px){
.nav-pills.nav-tabs-btn .nav .nav-item{max-width: 100%;width: 6.5rem;margin: 0.6rem;}
.nav-pills.nav-tabs-btn .nav .nav-item .nav-link .nav-link-icon i{font-size: 1.2rem;}
.nav-pills.nav-tabs-btn .nav .nav-item .nav-link .nav-link-title{font-size: 1rem;}
}
.nav-tabs.nav-tabs-line{margin: 0 0 25px 0;}
.nav-tabs.nav-tabs-line:before, .nav-tabs.nav-tabs-line:after{content: " ";display: table;}
.nav-tabs.nav-tabs-line:after{clear: both;}
.nav-tabs.nav-tabs-line .nav-item{margin-right: 20px;margin-bottom: -1px;}
@media (max-width: 1399px){
.nav-tabs.nav-tabs-line .nav-item{margin-right: 15px;}
}
.nav-tabs.nav-tabs-line .nav-item:last-child{margin-right: 0;}
.nav-tabs.nav-tabs-line .nav-link{border: 0;border-bottom: 1px solid transparent;padding: 12px 0;font-size: 1rem;font-weight: 400;}
.nav-tabs.nav-tabs-line .nav-link [class^="la-"], .nav-tabs.nav-tabs-line .nav-link [class*=" la-"]{font-size: 1.3rem;}
.nav-tabs.nav-tabs-line .nav-link [class^="fa-"], .nav-tabs.nav-tabs-line .nav-link [class*=" fa-"]{font-size: 1.2rem;}
.nav-tabs.nav-tabs-line .nav-link [class^="flaticon-"], .nav-tabs.nav-tabs-line .nav-link [class*=" flaticon-"], .nav-tabs.nav-tabs-line .nav-link [class^="flaticon2-"], .nav-tabs.nav-tabs-line .nav-link [class*=" flaticon2-"]{font-size: 1.2rem;}
.nav-tabs.nav-tabs-line .nav-link i{vertical-align: middle;line-height: 0;display: inline-block;margin-right: 0.5rem;}
.nav-tabs.nav-tabs-line .nav-link svg{margin-right: 0.5rem;}
.nav-tabs.nav-tabs-line.nav-tabs-line-left{float: left;}
.nav-tabs.nav-tabs-line.nav-tabs-line-right{float: right;}
.kt-portlet--tabs .nav-tabs.nav-tabs-line{margin: 0 0 -1px 0.5rem;border-color: transparent !important;}
.kt-portlet--tabs .nav-tabs.nav-tabs-line.nav-tabs-line-2x{margin: 0 0 -2px 0;}
.kt-portlet--tabs .nav-tabs.nav-tabs-line .nav-item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-portlet--tabs .nav-tabs.nav-tabs-line .nav-item .nav-link{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.nav-tabs.nav-tabs-line.nav-tabs-bold .nav-item .nav-link{font-weight: 500;}
.nav-tabs.nav-tabs-line.nav-tabs-bolder .nav-item .nav-link{font-weight: 600;}
.nav-tabs.nav-tabs-line.nav-tabs-lg .nav-item{margin-right: 30px;}
@media (max-width: 1399px){
.nav-tabs.nav-tabs-line.nav-tabs-lg .nav-item{margin-right: 15px;}
}
.nav-tabs.nav-tabs-line.nav-tabs-lg .nav-item:last-child{margin-right: 0;}
.nav-tabs.nav-tabs-line.nav-tabs-lg .nav-item .nav-link{padding: 12px 0;font-size: 1rem;}
.nav-tabs.nav-tabs-line.nav-tabs-line-2x{border-bottom-width: 2px !important;}
.nav-tabs.nav-tabs-line.nav-tabs-line-2x .nav-item{margin-bottom: -2px;}
.nav-tabs.nav-tabs-line.nav-tabs-line-2x a.nav-link{border-bottom-width: 2px !important;}
.nav-tabs.nav-tabs-line.nav-tabs-line-3x{border-bottom-width: 3px !important;}
.nav-tabs.nav-tabs-line.nav-tabs-line-3x .nav-item{margin-bottom: -3px;}
.nav-tabs.nav-tabs-line.nav-tabs-line-3x a.nav-link{border-bottom-width: 3px !important;}
.nav-tabs.nav-tabs-line.nav-tabs-space-lg .nav-item{margin-right: 40px;}
.nav-tabs.nav-tabs-line.nav-tabs-space-lg .nav-item:last-child{margin-right: 0;}
@media (max-width: 1024px){
.nav-tabs.nav-tabs-line.nav-tabs-space-lg .nav-item{margin-right: 20px;}
.nav-tabs.nav-tabs-line.nav-tabs-space-lg .nav-item:last-child{margin-right: 0;}
}
.nav-tabs.nav-tabs-line.nav-tabs-space-xl .nav-item{margin-right: 50px;}
.nav-tabs.nav-tabs-line.nav-tabs-space-xl .nav-item:last-child{margin-right: 0;}
@media (max-width: 1024px){
.nav-tabs.nav-tabs-line.nav-tabs-space-xl .nav-item{margin-right: 20px;}
.nav-tabs.nav-tabs-line.nav-tabs-space-xl .nav-item:last-child{margin-right: 0;}
}
.nav-tabs.nav-tabs-line{border-bottom: 1px solid #ebedf2;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link, .nav-tabs.nav-tabs-line a.nav-link{background-color: transparent;color: #6c7293;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link i, .nav-tabs.nav-tabs-line a.nav-link i{color: #adb1c7;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link:disabled, .nav-tabs.nav-tabs-line a.nav-link:disabled{opacity: 0.7;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link g [fill], .nav-tabs.nav-tabs-line a.nav-link g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #a7abc3;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link:hover g [fill], .nav-tabs.nav-tabs-line a.nav-link:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line a.nav-link:hover, .nav-tabs.nav-tabs-line a.nav-link.active{background-color: transparent;color: #6c7293;border-bottom: 1px solid #5d78ff;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line a.nav-link:hover > i, .nav-tabs.nav-tabs-line a.nav-link.active > i{color: #5d78ff;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link:hover g [fill], .nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link.active g [fill], .nav-tabs.nav-tabs-line a.nav-link:hover g [fill], .nav-tabs.nav-tabs-line a.nav-link.active g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link:hover:hover g [fill], .nav-tabs.nav-tabs-line.nav.nav-tabs .nav-link.active:hover g [fill], .nav-tabs.nav-tabs-line a.nav-link:hover:hover g [fill], .nav-tabs.nav-tabs-line a.nav-link.active:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-brand a.nav-link:hover{border-bottom: 1px solid #5d78ff;}
.nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-brand a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-brand a.nav-link.active{color: #5d78ff;border-bottom: 1px solid #5d78ff;}
.nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line.nav-tabs-line-brand a.nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-brand a.nav-link.active > i{color: #778dff;}
.nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav-tabs-line-2x, .nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav-tabs-line-3x{border-bottom-color: rgba(93, 120, 255, 0.1);}
.nav-tabs.nav-tabs-line.nav-tabs-line-light.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-light a.nav-link:hover{border-bottom: 1px solid #ffffff;}
.nav-tabs.nav-tabs-line.nav-tabs-line-light.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-light.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-light a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-light a.nav-link.active{color: #ffffff;border-bottom: 1px solid #ffffff;}
.nav-tabs.nav-tabs-line.nav-tabs-line-light.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-light.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line.nav-tabs-line-light a.nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-light a.nav-link.active > i{color: white;}
.nav-tabs.nav-tabs-line.nav-tabs-line-light.nav-tabs-line-2x, .nav-tabs.nav-tabs-line.nav-tabs-line-light.nav-tabs-line-3x{border-bottom-color: rgba(255, 255, 255, 0.1);}
.nav-tabs.nav-tabs-line.nav-tabs-line-dark.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-dark a.nav-link:hover{border-bottom: 1px solid #282a3c;}
.nav-tabs.nav-tabs-line.nav-tabs-line-dark.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-dark.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-dark a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-dark a.nav-link.active{color: #282a3c;border-bottom: 1px solid #282a3c;}
.nav-tabs.nav-tabs-line.nav-tabs-line-dark.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-dark.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line.nav-tabs-line-dark a.nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-dark a.nav-link.active > i{color: #32354b;}
.nav-tabs.nav-tabs-line.nav-tabs-line-dark.nav-tabs-line-2x, .nav-tabs.nav-tabs-line.nav-tabs-line-dark.nav-tabs-line-3x{border-bottom-color: rgba(40, 42, 60, 0.1);}
.nav-tabs.nav-tabs-line.nav-tabs-line-primary.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-primary a.nav-link:hover{border-bottom: 1px solid #5867dd;}
.nav-tabs.nav-tabs-line.nav-tabs-line-primary.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-primary.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-primary a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-primary a.nav-link.active{color: #5867dd;border-bottom: 1px solid #5867dd;}
.nav-tabs.nav-tabs-line.nav-tabs-line-primary.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-primary.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line.nav-tabs-line-primary a.nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-primary a.nav-link.active > i{color: #6d7ae1;}
.nav-tabs.nav-tabs-line.nav-tabs-line-primary.nav-tabs-line-2x, .nav-tabs.nav-tabs-line.nav-tabs-line-primary.nav-tabs-line-3x{border-bottom-color: rgba(88, 103, 221, 0.1);}
.nav-tabs.nav-tabs-line.nav-tabs-line-success.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-success a.nav-link:hover{border-bottom: 1px solid #0abb87;}
.nav-tabs.nav-tabs-line.nav-tabs-line-success.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-success.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-success a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-success a.nav-link.active{color: #0abb87;border-bottom: 1px solid #0abb87;}
.nav-tabs.nav-tabs-line.nav-tabs-line-success.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-success.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line.nav-tabs-line-success a.nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-success a.nav-link.active > i{color: #0bd398;}
.nav-tabs.nav-tabs-line.nav-tabs-line-success.nav-tabs-line-2x, .nav-tabs.nav-tabs-line.nav-tabs-line-success.nav-tabs-line-3x{border-bottom-color: rgba(10, 187, 135, 0.1);}
.nav-tabs.nav-tabs-line.nav-tabs-line-info.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-info a.nav-link:hover{border-bottom: 1px solid #5578eb;}
.nav-tabs.nav-tabs-line.nav-tabs-line-info.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-info.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-info a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-info a.nav-link.active{color: #5578eb;border-bottom: 1px solid #5578eb;}
.nav-tabs.nav-tabs-line.nav-tabs-line-info.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-info.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line.nav-tabs-line-info a.nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-info a.nav-link.active > i{color: #6c8aee;}
.nav-tabs.nav-tabs-line.nav-tabs-line-info.nav-tabs-line-2x, .nav-tabs.nav-tabs-line.nav-tabs-line-info.nav-tabs-line-3x{border-bottom-color: rgba(85, 120, 235, 0.1);}
.nav-tabs.nav-tabs-line.nav-tabs-line-warning.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-warning a.nav-link:hover{border-bottom: 1px solid #ffb822;}
.nav-tabs.nav-tabs-line.nav-tabs-line-warning.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-warning.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-warning a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-warning a.nav-link.active{color: #ffb822;border-bottom: 1px solid #ffb822;}
.nav-tabs.nav-tabs-line.nav-tabs-line-warning.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-warning.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line.nav-tabs-line-warning a.nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-warning a.nav-link.active > i{color: #ffc03c;}
.nav-tabs.nav-tabs-line.nav-tabs-line-warning.nav-tabs-line-2x, .nav-tabs.nav-tabs-line.nav-tabs-line-warning.nav-tabs-line-3x{border-bottom-color: rgba(255, 184, 34, 0.1);}
.nav-tabs.nav-tabs-line.nav-tabs-line-danger.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-danger a.nav-link:hover{border-bottom: 1px solid #fd397a;}
.nav-tabs.nav-tabs-line.nav-tabs-line-danger.nav.nav-tabs .nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-danger.nav.nav-tabs .nav-link.active, .nav-tabs.nav-tabs-line.nav-tabs-line-danger a.nav-link:hover, .nav-tabs.nav-tabs-line.nav-tabs-line-danger a.nav-link.active{color: #fd397a;border-bottom: 1px solid #fd397a;}
.nav-tabs.nav-tabs-line.nav-tabs-line-danger.nav.nav-tabs .nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-danger.nav.nav-tabs .nav-link.active > i, .nav-tabs.nav-tabs-line.nav-tabs-line-danger a.nav-link:hover > i, .nav-tabs.nav-tabs-line.nav-tabs-line-danger a.nav-link.active > i{color: #fd528b;}
.nav-tabs.nav-tabs-line.nav-tabs-line-danger.nav-tabs-line-2x, .nav-tabs.nav-tabs-line.nav-tabs-line-danger.nav-tabs-line-3x{border-bottom-color: rgba(253, 57, 122, 0.1);}
.toast.toast-custom{display: none;position: absolute;z-index: 195;top: 2rem;right: 2rem;width: 350px;border: 0;}
.toast.toast-custom.toast-left{left: 2rem;right: auto;}
.toast.toast-custom.toast-bottom{top: auto;bottom: 2rem;}
.toast.toast-custom.toast-top{bottom: auto;top: 2rem;}
.toast.toast-custom.toast-center-v{top: 45%;bottom: auto;}
.toast.toast-custom.toast-center-h{right: auto;left: 50%;margin-left: -175px;}
.toast.toast-custom .toast-header{padding: 0.5rem 1rem;}
.toast.toast-custom .toast-body{padding: 0.5rem 1rem;font-size: 0.9rem;}
.toast.toast-custom.show{display: block;}
.toast.toast-custom.toast-fill{background-color: #fff;}
.toast .toast-title{font-size: 0.9rem;font-weight: 500;color: #6c7293;margin-right: auto !important;}
.toast .toast-time{margin-left: 0.5rem;font-size: 0.85rem;font-weight: 400;color: #6c7293;}
.toast .toast-icon{margin-right: 0.5rem;font-size: 1.2rem;color: #a7abc3;}
.toast .toast-close{-webkit-appearance: none;-moz-appearance: none;appearance: none;-webkit-box-shadow: none;box-shadow: none;border-radius: none;border: none;cursor: pointer;background: none;outline: none !important;margin: 0;padding: 0;margin-left: 1rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.toast .toast-close i{font-size: 1.1rem;color: #a7abc3;}
.toast .toast-close i:before{line-height: 0;vertical-align: middle;}
.toast .toast-close:hover i{color: #5d78ff;}
.tooltip .tooltip-inner{-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);}
.tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow{}
.tooltip.bs-tooltip-left .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before{}
.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow{}
.tooltip.bs-tooltip-right .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before{}
.tooltip.tooltop-auto-width .tooltip-inner{white-space: nowrap;max-width: none;}
.tooltip.tooltip-dark .tooltip-inner{color: #fff;background: #2c2e3e;}
.tooltip.tooltip-dark.bs-tooltip-top .arrow::before, .tooltip.tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before{border-top-color: #2c2e3e;}
.tooltip.tooltip-dark.bs-tooltip-right .arrow::before, .tooltip.tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before{border-right-color: #2c2e3e/*rtl:ignore*/;}
.tooltip.tooltip-dark.bs-tooltip-bottom .arrow::before, .tooltip.tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before{border-bottom-color: #2c2e3e;}
.tooltip.tooltip-dark.bs-tooltip-left .arrow::before, .tooltip.tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before{border-left-color: #2c2e3e/*rtl:ignore*/;}
.tooltip.tooltip-brand .tooltip-inner{color: #ffffff;background: #5d78ff;}
.tooltip.tooltip-brand.bs-tooltip-top .arrow::before, .tooltip.tooltip-brand.bs-tooltip-auto[x-placement^="top"] .arrow::before{border-top-color: #5d78ff;}
.tooltip.tooltip-brand.bs-tooltip-right .arrow::before, .tooltip.tooltip-brand.bs-tooltip-auto[x-placement^="right"] .arrow::before{border-right-color: #5d78ff/*rtl:ignore*/;}
.tooltip.tooltip-brand.bs-tooltip-bottom .arrow::before, .tooltip.tooltip-brand.bs-tooltip-auto[x-placement^="bottom"] .arrow::before{border-bottom-color: #5d78ff;}
.tooltip.tooltip-brand.bs-tooltip-left .arrow::before, .tooltip.tooltip-brand.bs-tooltip-auto[x-placement^="left"] .arrow::before{border-left-color: #5d78ff/*rtl:ignore*/;}
.kt-page--loading *{-webkit-transition: none !important;transition: none !important;}
.kt-page-loader{background: #fff;position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index: 1000;display: none;}
.kt-page--loading .kt-page-loader{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-page-loader.kt-page-loader--non-block{background: transparent;display: none;position: fixed;top: 50%;left: 50%;right: auto;bottom: auto;width: auto;}
.kt-page--loading-non-block .kt-page-loader.kt-page-loader--non-block{display: inline-block;}
.kt-page-loader.kt-page-loader--logo{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-page-loader.kt-page-loader--logo img{display: block;}
.kt-page-loader.kt-page-loader--logo .kt-spinner{margin-top: 3rem;}
.kt-page-loader.kt-page-loader--logo .kt-spinner:before{width: 26px;height: 26px;margin-top: -13px;margin-left: -13px;}
html{font-family: sans-serif;-moz-text-size-adjust: 100%;text-size-adjust: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);}
html, body{height: 100%;margin: 0px;padding: 0px;font-size: 13px;font-weight: 300;font-family: Poppins;-ms-text-size-adjust: 100%;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
html a:hover, html a:active, html a:focus, body a:hover, body a:active, body a:focus{text-decoration: none !important;}
html a, html button, body a, body button{outline: none !important;}
body{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;color: #646c9a;}
router-outlet{display: none;}
canvas{-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;}
::-webkit-scrollbar-button{display: none;}
@media (min-width: 769px) and (max-width: 1024px){
html, body{font-size: 12px;}
}
@media (max-width: 768px){
html, body{font-size: 12px;}
}
.kt-animate-fade-in-up{-webkit-animation: kt-animate-fade-in-up .5s;animation: kt-animate-fade-in-up .5s;}
.kt-animate-fade-out{-webkit-animation: kt-animate-fade-out .5s;animation: kt-animate-fade-out .5s;}
.kt-animate-fade-in{-webkit-animation: kt-animate-fade-in .5s;animation: kt-animate-fade-in .5s;}
.kt-animate-blink{-webkit-animation: kt-animate-blink 1s step-start 0s infinite;animation: kt-animate-blink 1s step-start 0s infinite;-webkit-animation-fill-mode: initial;animation-fill-mode: initial;}
.kt-animate-shake{-webkit-animation: kt-animate-shake 0.1s ease-in 0.1s infinite alternate;animation: kt-animate-shake 0.1s ease-in 0.1s infinite alternate;-webkit-animation-fill-mode: initial;animation-fill-mode: initial;}
.kt-animate-pause{-webkit-animation-play-state: paused;animation-play-state: paused;}
.kt-notransition{-webkit-transition: none !important;transition: none !important;}
@-webkit-keyframes kt-animate-fade-out{
from{opacity: 1;}
to{opacity: 0;}
}
@keyframes kt-animate-fade-out{
from{opacity: 1;}
to{opacity: 0;}
}
@-webkit-keyframes kt-animate-fade-in{
from{opacity: 0;}
to{opacity: 1;}
}
@keyframes kt-animate-fade-in{
from{opacity: 0;}
to{opacity: 1;}
}
@-webkit-keyframes kt-animate-fade-in-up{
from{opacity: 0;-webkit-transform: translateY(15px);transform: translateY(15px);}
to{opacity: 1;-webkit-transform: translateY(0px);transform: translateY(0px);}
}
@keyframes kt-animate-fade-in-up{
from{opacity: 0;-webkit-transform: translateY(15px);transform: translateY(15px);}
to{opacity: 1;-webkit-transform: translateY(0px);transform: translateY(0px);}
}
@-webkit-keyframes kt-animate-fade-out-up{
from{opacity: 1;-webkit-transform: translateY(15px);transform: translateY(15px);}
to{opacity: 0;-webkit-transform: translateY(0px);transform: translateY(0px);}
}
@keyframes kt-animate-fade-out-up{
from{opacity: 1;-webkit-transform: translateY(15px);transform: translateY(15px);}
to{opacity: 0;-webkit-transform: translateY(0px);transform: translateY(0px);}
}
@-webkit-keyframes kt-animate-blink{50%{opacity: 0.0;}
}
@keyframes kt-animate-blink{50%{opacity: 0.0;}
}
@-webkit-keyframes kt-animate-shake{
from{-webkit-transform: rotate(13deg);transform: rotate(13deg);}
to{-webkit-transform-origin: center center;-webkit-transform: rotate(-13deg);transform: rotate(-13deg);}
}
@keyframes kt-animate-shake{
from{-webkit-transform: rotate(13deg);transform: rotate(13deg);}
to{-webkit-transform-origin: center center;-webkit-transform: rotate(-13deg);transform: rotate(-13deg);}
}
.kt-margin-0{margin: 0px !important;}
.kt-margin-t-0{margin-top: 0px !important;}
.kt-margin-b-0{margin-bottom: 0px !important;}
.kt-margin-l-0{margin-left: 0px !important;}
.kt-margin-r-0{margin-right: 0px !important;}
.kt-padding-0{padding: 0px !important;}
.kt-padding-t-0{padding-top: 0px !important;}
.kt-padding-b-0{padding-bottom: 0px !important;}
.kt-padding-l-0{padding-left: 0px !important;}
.kt-padding-r-0{padding-right: 0px !important;}
.kt-m0{margin: 0px !important;}
.kt-mt-0{margin-top: 0px !important;}
.kt-mb-0{margin-bottom: 0px !important;}
.kt-ml-0{margin-left: 0px !important;}
.kt-mr-0{margin-right: 0px !important;}
.kt-p0{padding: 0px !important;}
.kt-pt0{padding-top: 0px !important;}
.kt-pb-0{padding-bottom: 0px !important;}
.kt-pl0{padding-left: 0px !important;}
.kt-pr0{padding-right: 0px !important;}
.kt-margin-5{margin: 5px !important;}
.kt-margin-t-5{margin-top: 5px !important;}
.kt-margin-b-5{margin-bottom: 5px !important;}
.kt-margin-l-5{margin-left: 5px !important;}
.kt-margin-r-5{margin-right: 5px !important;}
.kt-padding-5{padding: 5px !important;}
.kt-padding-t-5{padding-top: 5px !important;}
.kt-padding-b-5{padding-bottom: 5px !important;}
.kt-padding-l-5{padding-left: 5px !important;}
.kt-padding-r-5{padding-right: 5px !important;}
.kt-m5{margin: 5px !important;}
.kt-mt-5{margin-top: 5px !important;}
.kt-mb-5{margin-bottom: 5px !important;}
.kt-ml-5{margin-left: 5px !important;}
.kt-mr-5{margin-right: 5px !important;}
.kt-p5{padding: 5px !important;}
.kt-pt5{padding-top: 5px !important;}
.kt-pb-5{padding-bottom: 5px !important;}
.kt-pl5{padding-left: 5px !important;}
.kt-pr5{padding-right: 5px !important;}
.kt-margin-10{margin: 10px !important;}
.kt-margin-t-10{margin-top: 10px !important;}
.kt-margin-b-10{margin-bottom: 10px !important;}
.kt-margin-l-10{margin-left: 10px !important;}
.kt-margin-r-10{margin-right: 10px !important;}
.kt-padding-10{padding: 10px !important;}
.kt-padding-t-10{padding-top: 10px !important;}
.kt-padding-b-10{padding-bottom: 10px !important;}
.kt-padding-l-10{padding-left: 10px !important;}
.kt-padding-r-10{padding-right: 10px !important;}
.kt-m10{margin: 10px !important;}
.kt-mt-10{margin-top: 10px !important;}
.kt-mb-10{margin-bottom: 10px !important;}
.kt-ml-10{margin-left: 10px !important;}
.kt-mr-10{margin-right: 10px !important;}
.kt-p10{padding: 10px !important;}
.kt-pt10{padding-top: 10px !important;}
.kt-pb-10{padding-bottom: 10px !important;}
.kt-pl10{padding-left: 10px !important;}
.kt-pr10{padding-right: 10px !important;}
.kt-margin-15{margin: 15px !important;}
.kt-margin-t-15{margin-top: 15px !important;}
.kt-margin-b-15{margin-bottom: 15px !important;}
.kt-margin-l-15{margin-left: 15px !important;}
.kt-margin-r-15{margin-right: 15px !important;}
.kt-padding-15{padding: 15px !important;}
.kt-padding-t-15{padding-top: 15px !important;}
.kt-padding-b-15{padding-bottom: 15px !important;}
.kt-padding-l-15{padding-left: 15px !important;}
.kt-padding-r-15{padding-right: 15px !important;}
.kt-m15{margin: 15px !important;}
.kt-mt-15{margin-top: 15px !important;}
.kt-mb-15{margin-bottom: 15px !important;}
.kt-ml-15{margin-left: 15px !important;}
.kt-mr-15{margin-right: 15px !important;}
.kt-p15{padding: 15px !important;}
.kt-pt15{padding-top: 15px !important;}
.kt-pb-15{padding-bottom: 15px !important;}
.kt-pl15{padding-left: 15px !important;}
.kt-pr15{padding-right: 15px !important;}
.kt-margin-20{margin: 20px !important;}
.kt-margin-t-20{margin-top: 20px !important;}
.kt-margin-b-20{margin-bottom: 20px !important;}
.kt-margin-l-20{margin-left: 20px !important;}
.kt-margin-r-20{margin-right: 20px !important;}
.kt-padding-20{padding: 20px !important;}
.kt-padding-t-20{padding-top: 20px !important;}
.kt-padding-b-20{padding-bottom: 20px !important;}
.kt-padding-l-20{padding-left: 20px !important;}
.kt-padding-r-20{padding-right: 20px !important;}
.kt-m20{margin: 20px !important;}
.kt-mt-20{margin-top: 20px !important;}
.kt-mb-20{margin-bottom: 20px !important;}
.kt-ml-20{margin-left: 20px !important;}
.kt-mr-20{margin-right: 20px !important;}
.kt-p20{padding: 20px !important;}
.kt-pt20{padding-top: 20px !important;}
.kt-pb-20{padding-bottom: 20px !important;}
.kt-pl20{padding-left: 20px !important;}
.kt-pr20{padding-right: 20px !important;}
.kt-margin-25{margin: 25px !important;}
.kt-margin-t-25{margin-top: 25px !important;}
.kt-margin-b-25{margin-bottom: 25px !important;}
.kt-margin-l-25{margin-left: 25px !important;}
.kt-margin-r-25{margin-right: 25px !important;}
.kt-padding-25{padding: 25px !important;}
.kt-padding-t-25{padding-top: 25px !important;}
.kt-padding-b-25{padding-bottom: 25px !important;}
.kt-padding-l-25{padding-left: 25px !important;}
.kt-padding-r-25{padding-right: 25px !important;}
.kt-m25{margin: 25px !important;}
.kt-mt-25{margin-top: 25px !important;}
.kt-mb-25{margin-bottom: 25px !important;}
.kt-ml-25{margin-left: 25px !important;}
.kt-mr-25{margin-right: 25px !important;}
.kt-p25{padding: 25px !important;}
.kt-pt25{padding-top: 25px !important;}
.kt-pb-25{padding-bottom: 25px !important;}
.kt-pl25{padding-left: 25px !important;}
.kt-pr25{padding-right: 25px !important;}
.kt-margin-30{margin: 30px !important;}
.kt-margin-t-30{margin-top: 30px !important;}
.kt-margin-b-30{margin-bottom: 30px !important;}
.kt-margin-l-30{margin-left: 30px !important;}
.kt-margin-r-30{margin-right: 30px !important;}
.kt-padding-30{padding: 30px !important;}
.kt-padding-t-30{padding-top: 30px !important;}
.kt-padding-b-30{padding-bottom: 30px !important;}
.kt-padding-l-30{padding-left: 30px !important;}
.kt-padding-r-30{padding-right: 30px !important;}
.kt-m30{margin: 30px !important;}
.kt-mt-30{margin-top: 30px !important;}
.kt-mb-30{margin-bottom: 30px !important;}
.kt-ml-30{margin-left: 30px !important;}
.kt-mr-30{margin-right: 30px !important;}
.kt-p30{padding: 30px !important;}
.kt-pt30{padding-top: 30px !important;}
.kt-pb-30{padding-bottom: 30px !important;}
.kt-pl30{padding-left: 30px !important;}
.kt-pr30{padding-right: 30px !important;}
.kt-margin-35{margin: 35px !important;}
.kt-margin-t-35{margin-top: 35px !important;}
.kt-margin-b-35{margin-bottom: 35px !important;}
.kt-margin-l-35{margin-left: 35px !important;}
.kt-margin-r-35{margin-right: 35px !important;}
.kt-padding-35{padding: 35px !important;}
.kt-padding-t-35{padding-top: 35px !important;}
.kt-padding-b-35{padding-bottom: 35px !important;}
.kt-padding-l-35{padding-left: 35px !important;}
.kt-padding-r-35{padding-right: 35px !important;}
.kt-m35{margin: 35px !important;}
.kt-mt-35{margin-top: 35px !important;}
.kt-mb-35{margin-bottom: 35px !important;}
.kt-ml-35{margin-left: 35px !important;}
.kt-mr-35{margin-right: 35px !important;}
.kt-p35{padding: 35px !important;}
.kt-pt35{padding-top: 35px !important;}
.kt-pb-35{padding-bottom: 35px !important;}
.kt-pl35{padding-left: 35px !important;}
.kt-pr35{padding-right: 35px !important;}
.kt-margin-40{margin: 40px !important;}
.kt-margin-t-40{margin-top: 40px !important;}
.kt-margin-b-40{margin-bottom: 40px !important;}
.kt-margin-l-40{margin-left: 40px !important;}
.kt-margin-r-40{margin-right: 40px !important;}
.kt-padding-40{padding: 40px !important;}
.kt-padding-t-40{padding-top: 40px !important;}
.kt-padding-b-40{padding-bottom: 40px !important;}
.kt-padding-l-40{padding-left: 40px !important;}
.kt-padding-r-40{padding-right: 40px !important;}
.kt-m40{margin: 40px !important;}
.kt-mt-40{margin-top: 40px !important;}
.kt-mb-40{margin-bottom: 40px !important;}
.kt-ml-40{margin-left: 40px !important;}
.kt-mr-40{margin-right: 40px !important;}
.kt-p40{padding: 40px !important;}
.kt-pt40{padding-top: 40px !important;}
.kt-pb-40{padding-bottom: 40px !important;}
.kt-pl40{padding-left: 40px !important;}
.kt-pr40{padding-right: 40px !important;}
.kt-margin-45{margin: 45px !important;}
.kt-margin-t-45{margin-top: 45px !important;}
.kt-margin-b-45{margin-bottom: 45px !important;}
.kt-margin-l-45{margin-left: 45px !important;}
.kt-margin-r-45{margin-right: 45px !important;}
.kt-padding-45{padding: 45px !important;}
.kt-padding-t-45{padding-top: 45px !important;}
.kt-padding-b-45{padding-bottom: 45px !important;}
.kt-padding-l-45{padding-left: 45px !important;}
.kt-padding-r-45{padding-right: 45px !important;}
.kt-m45{margin: 45px !important;}
.kt-mt-45{margin-top: 45px !important;}
.kt-mb-45{margin-bottom: 45px !important;}
.kt-ml-45{margin-left: 45px !important;}
.kt-mr-45{margin-right: 45px !important;}
.kt-p45{padding: 45px !important;}
.kt-pt45{padding-top: 45px !important;}
.kt-pb-45{padding-bottom: 45px !important;}
.kt-pl45{padding-left: 45px !important;}
.kt-pr45{padding-right: 45px !important;}
.kt-margin-50{margin: 50px !important;}
.kt-margin-t-50{margin-top: 50px !important;}
.kt-margin-b-50{margin-bottom: 50px !important;}
.kt-margin-l-50{margin-left: 50px !important;}
.kt-margin-r-50{margin-right: 50px !important;}
.kt-padding-50{padding: 50px !important;}
.kt-padding-t-50{padding-top: 50px !important;}
.kt-padding-b-50{padding-bottom: 50px !important;}
.kt-padding-l-50{padding-left: 50px !important;}
.kt-padding-r-50{padding-right: 50px !important;}
.kt-m50{margin: 50px !important;}
.kt-mt-50{margin-top: 50px !important;}
.kt-mb-50{margin-bottom: 50px !important;}
.kt-ml-50{margin-left: 50px !important;}
.kt-mr-50{margin-right: 50px !important;}
.kt-p50{padding: 50px !important;}
.kt-pt50{padding-top: 50px !important;}
.kt-pb-50{padding-bottom: 50px !important;}
.kt-pl50{padding-left: 50px !important;}
.kt-pr50{padding-right: 50px !important;}
.kt-margin-55{margin: 55px !important;}
.kt-margin-t-55{margin-top: 55px !important;}
.kt-margin-b-55{margin-bottom: 55px !important;}
.kt-margin-l-55{margin-left: 55px !important;}
.kt-margin-r-55{margin-right: 55px !important;}
.kt-padding-55{padding: 55px !important;}
.kt-padding-t-55{padding-top: 55px !important;}
.kt-padding-b-55{padding-bottom: 55px !important;}
.kt-padding-l-55{padding-left: 55px !important;}
.kt-padding-r-55{padding-right: 55px !important;}
.kt-m55{margin: 55px !important;}
.kt-mt-55{margin-top: 55px !important;}
.kt-mb-55{margin-bottom: 55px !important;}
.kt-ml-55{margin-left: 55px !important;}
.kt-mr-55{margin-right: 55px !important;}
.kt-p55{padding: 55px !important;}
.kt-pt55{padding-top: 55px !important;}
.kt-pb-55{padding-bottom: 55px !important;}
.kt-pl55{padding-left: 55px !important;}
.kt-pr55{padding-right: 55px !important;}
.kt-margin-60{margin: 60px !important;}
.kt-margin-t-60{margin-top: 60px !important;}
.kt-margin-b-60{margin-bottom: 60px !important;}
.kt-margin-l-60{margin-left: 60px !important;}
.kt-margin-r-60{margin-right: 60px !important;}
.kt-padding-60{padding: 60px !important;}
.kt-padding-t-60{padding-top: 60px !important;}
.kt-padding-b-60{padding-bottom: 60px !important;}
.kt-padding-l-60{padding-left: 60px !important;}
.kt-padding-r-60{padding-right: 60px !important;}
.kt-m60{margin: 60px !important;}
.kt-mt-60{margin-top: 60px !important;}
.kt-mb-60{margin-bottom: 60px !important;}
.kt-ml-60{margin-left: 60px !important;}
.kt-mr-60{margin-right: 60px !important;}
.kt-p60{padding: 60px !important;}
.kt-pt60{padding-top: 60px !important;}
.kt-pb-60{padding-bottom: 60px !important;}
.kt-pl60{padding-left: 60px !important;}
.kt-pr60{padding-right: 60px !important;}
.kt-margin-65{margin: 65px !important;}
.kt-margin-t-65{margin-top: 65px !important;}
.kt-margin-b-65{margin-bottom: 65px !important;}
.kt-margin-l-65{margin-left: 65px !important;}
.kt-margin-r-65{margin-right: 65px !important;}
.kt-padding-65{padding: 65px !important;}
.kt-padding-t-65{padding-top: 65px !important;}
.kt-padding-b-65{padding-bottom: 65px !important;}
.kt-padding-l-65{padding-left: 65px !important;}
.kt-padding-r-65{padding-right: 65px !important;}
.kt-m65{margin: 65px !important;}
.kt-mt-65{margin-top: 65px !important;}
.kt-mb-65{margin-bottom: 65px !important;}
.kt-ml-65{margin-left: 65px !important;}
.kt-mr-65{margin-right: 65px !important;}
.kt-p65{padding: 65px !important;}
.kt-pt65{padding-top: 65px !important;}
.kt-pb-65{padding-bottom: 65px !important;}
.kt-pl65{padding-left: 65px !important;}
.kt-pr65{padding-right: 65px !important;}
.kt-margin-70{margin: 70px !important;}
.kt-margin-t-70{margin-top: 70px !important;}
.kt-margin-b-70{margin-bottom: 70px !important;}
.kt-margin-l-70{margin-left: 70px !important;}
.kt-margin-r-70{margin-right: 70px !important;}
.kt-padding-70{padding: 70px !important;}
.kt-padding-t-70{padding-top: 70px !important;}
.kt-padding-b-70{padding-bottom: 70px !important;}
.kt-padding-l-70{padding-left: 70px !important;}
.kt-padding-r-70{padding-right: 70px !important;}
.kt-m70{margin: 70px !important;}
.kt-mt-70{margin-top: 70px !important;}
.kt-mb-70{margin-bottom: 70px !important;}
.kt-ml-70{margin-left: 70px !important;}
.kt-mr-70{margin-right: 70px !important;}
.kt-p70{padding: 70px !important;}
.kt-pt70{padding-top: 70px !important;}
.kt-pb-70{padding-bottom: 70px !important;}
.kt-pl70{padding-left: 70px !important;}
.kt-pr70{padding-right: 70px !important;}
.kt-margin-75{margin: 75px !important;}
.kt-margin-t-75{margin-top: 75px !important;}
.kt-margin-b-75{margin-bottom: 75px !important;}
.kt-margin-l-75{margin-left: 75px !important;}
.kt-margin-r-75{margin-right: 75px !important;}
.kt-padding-75{padding: 75px !important;}
.kt-padding-t-75{padding-top: 75px !important;}
.kt-padding-b-75{padding-bottom: 75px !important;}
.kt-padding-l-75{padding-left: 75px !important;}
.kt-padding-r-75{padding-right: 75px !important;}
.kt-m75{margin: 75px !important;}
.kt-mt-75{margin-top: 75px !important;}
.kt-mb-75{margin-bottom: 75px !important;}
.kt-ml-75{margin-left: 75px !important;}
.kt-mr-75{margin-right: 75px !important;}
.kt-p75{padding: 75px !important;}
.kt-pt75{padding-top: 75px !important;}
.kt-pb-75{padding-bottom: 75px !important;}
.kt-pl75{padding-left: 75px !important;}
.kt-pr75{padding-right: 75px !important;}
.kt-margin-80{margin: 80px !important;}
.kt-margin-t-80{margin-top: 80px !important;}
.kt-margin-b-80{margin-bottom: 80px !important;}
.kt-margin-l-80{margin-left: 80px !important;}
.kt-margin-r-80{margin-right: 80px !important;}
.kt-padding-80{padding: 80px !important;}
.kt-padding-t-80{padding-top: 80px !important;}
.kt-padding-b-80{padding-bottom: 80px !important;}
.kt-padding-l-80{padding-left: 80px !important;}
.kt-padding-r-80{padding-right: 80px !important;}
.kt-m80{margin: 80px !important;}
.kt-mt-80{margin-top: 80px !important;}
.kt-mb-80{margin-bottom: 80px !important;}
.kt-ml-80{margin-left: 80px !important;}
.kt-mr-80{margin-right: 80px !important;}
.kt-p80{padding: 80px !important;}
.kt-pt80{padding-top: 80px !important;}
.kt-pb-80{padding-bottom: 80px !important;}
.kt-pl80{padding-left: 80px !important;}
.kt-pr80{padding-right: 80px !important;}
.kt-margin-85{margin: 85px !important;}
.kt-margin-t-85{margin-top: 85px !important;}
.kt-margin-b-85{margin-bottom: 85px !important;}
.kt-margin-l-85{margin-left: 85px !important;}
.kt-margin-r-85{margin-right: 85px !important;}
.kt-padding-85{padding: 85px !important;}
.kt-padding-t-85{padding-top: 85px !important;}
.kt-padding-b-85{padding-bottom: 85px !important;}
.kt-padding-l-85{padding-left: 85px !important;}
.kt-padding-r-85{padding-right: 85px !important;}
.kt-m85{margin: 85px !important;}
.kt-mt-85{margin-top: 85px !important;}
.kt-mb-85{margin-bottom: 85px !important;}
.kt-ml-85{margin-left: 85px !important;}
.kt-mr-85{margin-right: 85px !important;}
.kt-p85{padding: 85px !important;}
.kt-pt85{padding-top: 85px !important;}
.kt-pb-85{padding-bottom: 85px !important;}
.kt-pl85{padding-left: 85px !important;}
.kt-pr85{padding-right: 85px !important;}
.kt-margin-90{margin: 90px !important;}
.kt-margin-t-90{margin-top: 90px !important;}
.kt-margin-b-90{margin-bottom: 90px !important;}
.kt-margin-l-90{margin-left: 90px !important;}
.kt-margin-r-90{margin-right: 90px !important;}
.kt-padding-90{padding: 90px !important;}
.kt-padding-t-90{padding-top: 90px !important;}
.kt-padding-b-90{padding-bottom: 90px !important;}
.kt-padding-l-90{padding-left: 90px !important;}
.kt-padding-r-90{padding-right: 90px !important;}
.kt-m90{margin: 90px !important;}
.kt-mt-90{margin-top: 90px !important;}
.kt-mb-90{margin-bottom: 90px !important;}
.kt-ml-90{margin-left: 90px !important;}
.kt-mr-90{margin-right: 90px !important;}
.kt-p90{padding: 90px !important;}
.kt-pt90{padding-top: 90px !important;}
.kt-pb-90{padding-bottom: 90px !important;}
.kt-pl90{padding-left: 90px !important;}
.kt-pr90{padding-right: 90px !important;}
.kt-margin-95{margin: 95px !important;}
.kt-margin-t-95{margin-top: 95px !important;}
.kt-margin-b-95{margin-bottom: 95px !important;}
.kt-margin-l-95{margin-left: 95px !important;}
.kt-margin-r-95{margin-right: 95px !important;}
.kt-padding-95{padding: 95px !important;}
.kt-padding-t-95{padding-top: 95px !important;}
.kt-padding-b-95{padding-bottom: 95px !important;}
.kt-padding-l-95{padding-left: 95px !important;}
.kt-padding-r-95{padding-right: 95px !important;}
.kt-m95{margin: 95px !important;}
.kt-mt-95{margin-top: 95px !important;}
.kt-mb-95{margin-bottom: 95px !important;}
.kt-ml-95{margin-left: 95px !important;}
.kt-mr-95{margin-right: 95px !important;}
.kt-p95{padding: 95px !important;}
.kt-pt95{padding-top: 95px !important;}
.kt-pb-95{padding-bottom: 95px !important;}
.kt-pl95{padding-left: 95px !important;}
.kt-pr95{padding-right: 95px !important;}
.kt-margin-100{margin: 100px !important;}
.kt-margin-t-100{margin-top: 100px !important;}
.kt-margin-b-100{margin-bottom: 100px !important;}
.kt-margin-l-100{margin-left: 100px !important;}
.kt-margin-r-100{margin-right: 100px !important;}
.kt-padding-100{padding: 100px !important;}
.kt-padding-t-100{padding-top: 100px !important;}
.kt-padding-b-100{padding-bottom: 100px !important;}
.kt-padding-l-100{padding-left: 100px !important;}
.kt-padding-r-100{padding-right: 100px !important;}
.kt-m100{margin: 100px !important;}
.kt-mt-100{margin-top: 100px !important;}
.kt-mb-100{margin-bottom: 100px !important;}
.kt-ml-100{margin-left: 100px !important;}
.kt-mr-100{margin-right: 100px !important;}
.kt-p100{padding: 100px !important;}
.kt-pt100{padding-top: 100px !important;}
.kt-pb-100{padding-bottom: 100px !important;}
.kt-pl100{padding-left: 100px !important;}
.kt-pr100{padding-right: 100px !important;}
@media (min-width: 1025px){
.kt-margin-0-desktop{margin: 0px !important;}
.kt-margin-t-0-desktop{margin-top: 0px !important;}
.kt-margin-b-0-desktop{margin-bottom: 0px !important;}
.kt-margin-l-0-desktop{margin-left: 0px !important;}
.kt-margin-r-0-desktop{margin-right: 0px !important;}
.kt-padding-0-desktop{padding: 0px !important;}
.kt-padding-t-0-desktop{padding-top: 0px !important;}
.kt-padding-b-0-desktop{padding-bottom: 0px !important;}
.kt-padding-l-0-desktop{padding-left: 0px !important;}
.kt-padding-r-0-desktop{padding-right: 0px !important;}
.kt-m0-desktop{margin: 0px !important;}
.kt-mt-0-desktop{margin-top: 0px !important;}
.kt-mb-0-desktop{margin-bottom: 0px !important;}
.kt-ml-0-desktop{margin-left: 0px !important;}
.kt-mr-0-desktop{margin-right: 0px !important;}
.kt-p0-desktop{padding: 0px !important;}
.kt-pt0-desktop{padding-top: 0px !important;}
.kt-pb-0-desktop{padding-bottom: 0px !important;}
.kt-pl0-desktop{padding-left: 0px !important;}
.kt-pr0-desktop{padding-right: 0px !important;}
.kt-margin-5-desktop{margin: 5px !important;}
.kt-margin-t-5-desktop{margin-top: 5px !important;}
.kt-margin-b-5-desktop{margin-bottom: 5px !important;}
.kt-margin-l-5-desktop{margin-left: 5px !important;}
.kt-margin-r-5-desktop{margin-right: 5px !important;}
.kt-padding-5-desktop{padding: 5px !important;}
.kt-padding-t-5-desktop{padding-top: 5px !important;}
.kt-padding-b-5-desktop{padding-bottom: 5px !important;}
.kt-padding-l-5-desktop{padding-left: 5px !important;}
.kt-padding-r-5-desktop{padding-right: 5px !important;}
.kt-m5-desktop{margin: 5px !important;}
.kt-mt-5-desktop{margin-top: 5px !important;}
.kt-mb-5-desktop{margin-bottom: 5px !important;}
.kt-ml-5-desktop{margin-left: 5px !important;}
.kt-mr-5-desktop{margin-right: 5px !important;}
.kt-p5-desktop{padding: 5px !important;}
.kt-pt5-desktop{padding-top: 5px !important;}
.kt-pb-5-desktop{padding-bottom: 5px !important;}
.kt-pl5-desktop{padding-left: 5px !important;}
.kt-pr5-desktop{padding-right: 5px !important;}
.kt-margin-10-desktop{margin: 10px !important;}
.kt-margin-t-10-desktop{margin-top: 10px !important;}
.kt-margin-b-10-desktop{margin-bottom: 10px !important;}
.kt-margin-l-10-desktop{margin-left: 10px !important;}
.kt-margin-r-10-desktop{margin-right: 10px !important;}
.kt-padding-10-desktop{padding: 10px !important;}
.kt-padding-t-10-desktop{padding-top: 10px !important;}
.kt-padding-b-10-desktop{padding-bottom: 10px !important;}
.kt-padding-l-10-desktop{padding-left: 10px !important;}
.kt-padding-r-10-desktop{padding-right: 10px !important;}
.kt-m10-desktop{margin: 10px !important;}
.kt-mt-10-desktop{margin-top: 10px !important;}
.kt-mb-10-desktop{margin-bottom: 10px !important;}
.kt-ml-10-desktop{margin-left: 10px !important;}
.kt-mr-10-desktop{margin-right: 10px !important;}
.kt-p10-desktop{padding: 10px !important;}
.kt-pt10-desktop{padding-top: 10px !important;}
.kt-pb-10-desktop{padding-bottom: 10px !important;}
.kt-pl10-desktop{padding-left: 10px !important;}
.kt-pr10-desktop{padding-right: 10px !important;}
.kt-margin-15-desktop{margin: 15px !important;}
.kt-margin-t-15-desktop{margin-top: 15px !important;}
.kt-margin-b-15-desktop{margin-bottom: 15px !important;}
.kt-margin-l-15-desktop{margin-left: 15px !important;}
.kt-margin-r-15-desktop{margin-right: 15px !important;}
.kt-padding-15-desktop{padding: 15px !important;}
.kt-padding-t-15-desktop{padding-top: 15px !important;}
.kt-padding-b-15-desktop{padding-bottom: 15px !important;}
.kt-padding-l-15-desktop{padding-left: 15px !important;}
.kt-padding-r-15-desktop{padding-right: 15px !important;}
.kt-m15-desktop{margin: 15px !important;}
.kt-mt-15-desktop{margin-top: 15px !important;}
.kt-mb-15-desktop{margin-bottom: 15px !important;}
.kt-ml-15-desktop{margin-left: 15px !important;}
.kt-mr-15-desktop{margin-right: 15px !important;}
.kt-p15-desktop{padding: 15px !important;}
.kt-pt15-desktop{padding-top: 15px !important;}
.kt-pb-15-desktop{padding-bottom: 15px !important;}
.kt-pl15-desktop{padding-left: 15px !important;}
.kt-pr15-desktop{padding-right: 15px !important;}
.kt-margin-20-desktop{margin: 20px !important;}
.kt-margin-t-20-desktop{margin-top: 20px !important;}
.kt-margin-b-20-desktop{margin-bottom: 20px !important;}
.kt-margin-l-20-desktop{margin-left: 20px !important;}
.kt-margin-r-20-desktop{margin-right: 20px !important;}
.kt-padding-20-desktop{padding: 20px !important;}
.kt-padding-t-20-desktop{padding-top: 20px !important;}
.kt-padding-b-20-desktop{padding-bottom: 20px !important;}
.kt-padding-l-20-desktop{padding-left: 20px !important;}
.kt-padding-r-20-desktop{padding-right: 20px !important;}
.kt-m20-desktop{margin: 20px !important;}
.kt-mt-20-desktop{margin-top: 20px !important;}
.kt-mb-20-desktop{margin-bottom: 20px !important;}
.kt-ml-20-desktop{margin-left: 20px !important;}
.kt-mr-20-desktop{margin-right: 20px !important;}
.kt-p20-desktop{padding: 20px !important;}
.kt-pt20-desktop{padding-top: 20px !important;}
.kt-pb-20-desktop{padding-bottom: 20px !important;}
.kt-pl20-desktop{padding-left: 20px !important;}
.kt-pr20-desktop{padding-right: 20px !important;}
.kt-margin-25-desktop{margin: 25px !important;}
.kt-margin-t-25-desktop{margin-top: 25px !important;}
.kt-margin-b-25-desktop{margin-bottom: 25px !important;}
.kt-margin-l-25-desktop{margin-left: 25px !important;}
.kt-margin-r-25-desktop{margin-right: 25px !important;}
.kt-padding-25-desktop{padding: 25px !important;}
.kt-padding-t-25-desktop{padding-top: 25px !important;}
.kt-padding-b-25-desktop{padding-bottom: 25px !important;}
.kt-padding-l-25-desktop{padding-left: 25px !important;}
.kt-padding-r-25-desktop{padding-right: 25px !important;}
.kt-m25-desktop{margin: 25px !important;}
.kt-mt-25-desktop{margin-top: 25px !important;}
.kt-mb-25-desktop{margin-bottom: 25px !important;}
.kt-ml-25-desktop{margin-left: 25px !important;}
.kt-mr-25-desktop{margin-right: 25px !important;}
.kt-p25-desktop{padding: 25px !important;}
.kt-pt25-desktop{padding-top: 25px !important;}
.kt-pb-25-desktop{padding-bottom: 25px !important;}
.kt-pl25-desktop{padding-left: 25px !important;}
.kt-pr25-desktop{padding-right: 25px !important;}
.kt-margin-30-desktop{margin: 30px !important;}
.kt-margin-t-30-desktop{margin-top: 30px !important;}
.kt-margin-b-30-desktop{margin-bottom: 30px !important;}
.kt-margin-l-30-desktop{margin-left: 30px !important;}
.kt-margin-r-30-desktop{margin-right: 30px !important;}
.kt-padding-30-desktop{padding: 30px !important;}
.kt-padding-t-30-desktop{padding-top: 30px !important;}
.kt-padding-b-30-desktop{padding-bottom: 30px !important;}
.kt-padding-l-30-desktop{padding-left: 30px !important;}
.kt-padding-r-30-desktop{padding-right: 30px !important;}
.kt-m30-desktop{margin: 30px !important;}
.kt-mt-30-desktop{margin-top: 30px !important;}
.kt-mb-30-desktop{margin-bottom: 30px !important;}
.kt-ml-30-desktop{margin-left: 30px !important;}
.kt-mr-30-desktop{margin-right: 30px !important;}
.kt-p30-desktop{padding: 30px !important;}
.kt-pt30-desktop{padding-top: 30px !important;}
.kt-pb-30-desktop{padding-bottom: 30px !important;}
.kt-pl30-desktop{padding-left: 30px !important;}
.kt-pr30-desktop{padding-right: 30px !important;}
.kt-margin-35-desktop{margin: 35px !important;}
.kt-margin-t-35-desktop{margin-top: 35px !important;}
.kt-margin-b-35-desktop{margin-bottom: 35px !important;}
.kt-margin-l-35-desktop{margin-left: 35px !important;}
.kt-margin-r-35-desktop{margin-right: 35px !important;}
.kt-padding-35-desktop{padding: 35px !important;}
.kt-padding-t-35-desktop{padding-top: 35px !important;}
.kt-padding-b-35-desktop{padding-bottom: 35px !important;}
.kt-padding-l-35-desktop{padding-left: 35px !important;}
.kt-padding-r-35-desktop{padding-right: 35px !important;}
.kt-m35-desktop{margin: 35px !important;}
.kt-mt-35-desktop{margin-top: 35px !important;}
.kt-mb-35-desktop{margin-bottom: 35px !important;}
.kt-ml-35-desktop{margin-left: 35px !important;}
.kt-mr-35-desktop{margin-right: 35px !important;}
.kt-p35-desktop{padding: 35px !important;}
.kt-pt35-desktop{padding-top: 35px !important;}
.kt-pb-35-desktop{padding-bottom: 35px !important;}
.kt-pl35-desktop{padding-left: 35px !important;}
.kt-pr35-desktop{padding-right: 35px !important;}
.kt-margin-40-desktop{margin: 40px !important;}
.kt-margin-t-40-desktop{margin-top: 40px !important;}
.kt-margin-b-40-desktop{margin-bottom: 40px !important;}
.kt-margin-l-40-desktop{margin-left: 40px !important;}
.kt-margin-r-40-desktop{margin-right: 40px !important;}
.kt-padding-40-desktop{padding: 40px !important;}
.kt-padding-t-40-desktop{padding-top: 40px !important;}
.kt-padding-b-40-desktop{padding-bottom: 40px !important;}
.kt-padding-l-40-desktop{padding-left: 40px !important;}
.kt-padding-r-40-desktop{padding-right: 40px !important;}
.kt-m40-desktop{margin: 40px !important;}
.kt-mt-40-desktop{margin-top: 40px !important;}
.kt-mb-40-desktop{margin-bottom: 40px !important;}
.kt-ml-40-desktop{margin-left: 40px !important;}
.kt-mr-40-desktop{margin-right: 40px !important;}
.kt-p40-desktop{padding: 40px !important;}
.kt-pt40-desktop{padding-top: 40px !important;}
.kt-pb-40-desktop{padding-bottom: 40px !important;}
.kt-pl40-desktop{padding-left: 40px !important;}
.kt-pr40-desktop{padding-right: 40px !important;}
.kt-margin-45-desktop{margin: 45px !important;}
.kt-margin-t-45-desktop{margin-top: 45px !important;}
.kt-margin-b-45-desktop{margin-bottom: 45px !important;}
.kt-margin-l-45-desktop{margin-left: 45px !important;}
.kt-margin-r-45-desktop{margin-right: 45px !important;}
.kt-padding-45-desktop{padding: 45px !important;}
.kt-padding-t-45-desktop{padding-top: 45px !important;}
.kt-padding-b-45-desktop{padding-bottom: 45px !important;}
.kt-padding-l-45-desktop{padding-left: 45px !important;}
.kt-padding-r-45-desktop{padding-right: 45px !important;}
.kt-m45-desktop{margin: 45px !important;}
.kt-mt-45-desktop{margin-top: 45px !important;}
.kt-mb-45-desktop{margin-bottom: 45px !important;}
.kt-ml-45-desktop{margin-left: 45px !important;}
.kt-mr-45-desktop{margin-right: 45px !important;}
.kt-p45-desktop{padding: 45px !important;}
.kt-pt45-desktop{padding-top: 45px !important;}
.kt-pb-45-desktop{padding-bottom: 45px !important;}
.kt-pl45-desktop{padding-left: 45px !important;}
.kt-pr45-desktop{padding-right: 45px !important;}
.kt-margin-50-desktop{margin: 50px !important;}
.kt-margin-t-50-desktop{margin-top: 50px !important;}
.kt-margin-b-50-desktop{margin-bottom: 50px !important;}
.kt-margin-l-50-desktop{margin-left: 50px !important;}
.kt-margin-r-50-desktop{margin-right: 50px !important;}
.kt-padding-50-desktop{padding: 50px !important;}
.kt-padding-t-50-desktop{padding-top: 50px !important;}
.kt-padding-b-50-desktop{padding-bottom: 50px !important;}
.kt-padding-l-50-desktop{padding-left: 50px !important;}
.kt-padding-r-50-desktop{padding-right: 50px !important;}
.kt-m50-desktop{margin: 50px !important;}
.kt-mt-50-desktop{margin-top: 50px !important;}
.kt-mb-50-desktop{margin-bottom: 50px !important;}
.kt-ml-50-desktop{margin-left: 50px !important;}
.kt-mr-50-desktop{margin-right: 50px !important;}
.kt-p50-desktop{padding: 50px !important;}
.kt-pt50-desktop{padding-top: 50px !important;}
.kt-pb-50-desktop{padding-bottom: 50px !important;}
.kt-pl50-desktop{padding-left: 50px !important;}
.kt-pr50-desktop{padding-right: 50px !important;}
.kt-margin-55-desktop{margin: 55px !important;}
.kt-margin-t-55-desktop{margin-top: 55px !important;}
.kt-margin-b-55-desktop{margin-bottom: 55px !important;}
.kt-margin-l-55-desktop{margin-left: 55px !important;}
.kt-margin-r-55-desktop{margin-right: 55px !important;}
.kt-padding-55-desktop{padding: 55px !important;}
.kt-padding-t-55-desktop{padding-top: 55px !important;}
.kt-padding-b-55-desktop{padding-bottom: 55px !important;}
.kt-padding-l-55-desktop{padding-left: 55px !important;}
.kt-padding-r-55-desktop{padding-right: 55px !important;}
.kt-m55-desktop{margin: 55px !important;}
.kt-mt-55-desktop{margin-top: 55px !important;}
.kt-mb-55-desktop{margin-bottom: 55px !important;}
.kt-ml-55-desktop{margin-left: 55px !important;}
.kt-mr-55-desktop{margin-right: 55px !important;}
.kt-p55-desktop{padding: 55px !important;}
.kt-pt55-desktop{padding-top: 55px !important;}
.kt-pb-55-desktop{padding-bottom: 55px !important;}
.kt-pl55-desktop{padding-left: 55px !important;}
.kt-pr55-desktop{padding-right: 55px !important;}
.kt-margin-60-desktop{margin: 60px !important;}
.kt-margin-t-60-desktop{margin-top: 60px !important;}
.kt-margin-b-60-desktop{margin-bottom: 60px !important;}
.kt-margin-l-60-desktop{margin-left: 60px !important;}
.kt-margin-r-60-desktop{margin-right: 60px !important;}
.kt-padding-60-desktop{padding: 60px !important;}
.kt-padding-t-60-desktop{padding-top: 60px !important;}
.kt-padding-b-60-desktop{padding-bottom: 60px !important;}
.kt-padding-l-60-desktop{padding-left: 60px !important;}
.kt-padding-r-60-desktop{padding-right: 60px !important;}
.kt-m60-desktop{margin: 60px !important;}
.kt-mt-60-desktop{margin-top: 60px !important;}
.kt-mb-60-desktop{margin-bottom: 60px !important;}
.kt-ml-60-desktop{margin-left: 60px !important;}
.kt-mr-60-desktop{margin-right: 60px !important;}
.kt-p60-desktop{padding: 60px !important;}
.kt-pt60-desktop{padding-top: 60px !important;}
.kt-pb-60-desktop{padding-bottom: 60px !important;}
.kt-pl60-desktop{padding-left: 60px !important;}
.kt-pr60-desktop{padding-right: 60px !important;}
.kt-margin-65-desktop{margin: 65px !important;}
.kt-margin-t-65-desktop{margin-top: 65px !important;}
.kt-margin-b-65-desktop{margin-bottom: 65px !important;}
.kt-margin-l-65-desktop{margin-left: 65px !important;}
.kt-margin-r-65-desktop{margin-right: 65px !important;}
.kt-padding-65-desktop{padding: 65px !important;}
.kt-padding-t-65-desktop{padding-top: 65px !important;}
.kt-padding-b-65-desktop{padding-bottom: 65px !important;}
.kt-padding-l-65-desktop{padding-left: 65px !important;}
.kt-padding-r-65-desktop{padding-right: 65px !important;}
.kt-m65-desktop{margin: 65px !important;}
.kt-mt-65-desktop{margin-top: 65px !important;}
.kt-mb-65-desktop{margin-bottom: 65px !important;}
.kt-ml-65-desktop{margin-left: 65px !important;}
.kt-mr-65-desktop{margin-right: 65px !important;}
.kt-p65-desktop{padding: 65px !important;}
.kt-pt65-desktop{padding-top: 65px !important;}
.kt-pb-65-desktop{padding-bottom: 65px !important;}
.kt-pl65-desktop{padding-left: 65px !important;}
.kt-pr65-desktop{padding-right: 65px !important;}
.kt-margin-70-desktop{margin: 70px !important;}
.kt-margin-t-70-desktop{margin-top: 70px !important;}
.kt-margin-b-70-desktop{margin-bottom: 70px !important;}
.kt-margin-l-70-desktop{margin-left: 70px !important;}
.kt-margin-r-70-desktop{margin-right: 70px !important;}
.kt-padding-70-desktop{padding: 70px !important;}
.kt-padding-t-70-desktop{padding-top: 70px !important;}
.kt-padding-b-70-desktop{padding-bottom: 70px !important;}
.kt-padding-l-70-desktop{padding-left: 70px !important;}
.kt-padding-r-70-desktop{padding-right: 70px !important;}
.kt-m70-desktop{margin: 70px !important;}
.kt-mt-70-desktop{margin-top: 70px !important;}
.kt-mb-70-desktop{margin-bottom: 70px !important;}
.kt-ml-70-desktop{margin-left: 70px !important;}
.kt-mr-70-desktop{margin-right: 70px !important;}
.kt-p70-desktop{padding: 70px !important;}
.kt-pt70-desktop{padding-top: 70px !important;}
.kt-pb-70-desktop{padding-bottom: 70px !important;}
.kt-pl70-desktop{padding-left: 70px !important;}
.kt-pr70-desktop{padding-right: 70px !important;}
.kt-margin-75-desktop{margin: 75px !important;}
.kt-margin-t-75-desktop{margin-top: 75px !important;}
.kt-margin-b-75-desktop{margin-bottom: 75px !important;}
.kt-margin-l-75-desktop{margin-left: 75px !important;}
.kt-margin-r-75-desktop{margin-right: 75px !important;}
.kt-padding-75-desktop{padding: 75px !important;}
.kt-padding-t-75-desktop{padding-top: 75px !important;}
.kt-padding-b-75-desktop{padding-bottom: 75px !important;}
.kt-padding-l-75-desktop{padding-left: 75px !important;}
.kt-padding-r-75-desktop{padding-right: 75px !important;}
.kt-m75-desktop{margin: 75px !important;}
.kt-mt-75-desktop{margin-top: 75px !important;}
.kt-mb-75-desktop{margin-bottom: 75px !important;}
.kt-ml-75-desktop{margin-left: 75px !important;}
.kt-mr-75-desktop{margin-right: 75px !important;}
.kt-p75-desktop{padding: 75px !important;}
.kt-pt75-desktop{padding-top: 75px !important;}
.kt-pb-75-desktop{padding-bottom: 75px !important;}
.kt-pl75-desktop{padding-left: 75px !important;}
.kt-pr75-desktop{padding-right: 75px !important;}
.kt-margin-80-desktop{margin: 80px !important;}
.kt-margin-t-80-desktop{margin-top: 80px !important;}
.kt-margin-b-80-desktop{margin-bottom: 80px !important;}
.kt-margin-l-80-desktop{margin-left: 80px !important;}
.kt-margin-r-80-desktop{margin-right: 80px !important;}
.kt-padding-80-desktop{padding: 80px !important;}
.kt-padding-t-80-desktop{padding-top: 80px !important;}
.kt-padding-b-80-desktop{padding-bottom: 80px !important;}
.kt-padding-l-80-desktop{padding-left: 80px !important;}
.kt-padding-r-80-desktop{padding-right: 80px !important;}
.kt-m80-desktop{margin: 80px !important;}
.kt-mt-80-desktop{margin-top: 80px !important;}
.kt-mb-80-desktop{margin-bottom: 80px !important;}
.kt-ml-80-desktop{margin-left: 80px !important;}
.kt-mr-80-desktop{margin-right: 80px !important;}
.kt-p80-desktop{padding: 80px !important;}
.kt-pt80-desktop{padding-top: 80px !important;}
.kt-pb-80-desktop{padding-bottom: 80px !important;}
.kt-pl80-desktop{padding-left: 80px !important;}
.kt-pr80-desktop{padding-right: 80px !important;}
.kt-margin-85-desktop{margin: 85px !important;}
.kt-margin-t-85-desktop{margin-top: 85px !important;}
.kt-margin-b-85-desktop{margin-bottom: 85px !important;}
.kt-margin-l-85-desktop{margin-left: 85px !important;}
.kt-margin-r-85-desktop{margin-right: 85px !important;}
.kt-padding-85-desktop{padding: 85px !important;}
.kt-padding-t-85-desktop{padding-top: 85px !important;}
.kt-padding-b-85-desktop{padding-bottom: 85px !important;}
.kt-padding-l-85-desktop{padding-left: 85px !important;}
.kt-padding-r-85-desktop{padding-right: 85px !important;}
.kt-m85-desktop{margin: 85px !important;}
.kt-mt-85-desktop{margin-top: 85px !important;}
.kt-mb-85-desktop{margin-bottom: 85px !important;}
.kt-ml-85-desktop{margin-left: 85px !important;}
.kt-mr-85-desktop{margin-right: 85px !important;}
.kt-p85-desktop{padding: 85px !important;}
.kt-pt85-desktop{padding-top: 85px !important;}
.kt-pb-85-desktop{padding-bottom: 85px !important;}
.kt-pl85-desktop{padding-left: 85px !important;}
.kt-pr85-desktop{padding-right: 85px !important;}
.kt-margin-90-desktop{margin: 90px !important;}
.kt-margin-t-90-desktop{margin-top: 90px !important;}
.kt-margin-b-90-desktop{margin-bottom: 90px !important;}
.kt-margin-l-90-desktop{margin-left: 90px !important;}
.kt-margin-r-90-desktop{margin-right: 90px !important;}
.kt-padding-90-desktop{padding: 90px !important;}
.kt-padding-t-90-desktop{padding-top: 90px !important;}
.kt-padding-b-90-desktop{padding-bottom: 90px !important;}
.kt-padding-l-90-desktop{padding-left: 90px !important;}
.kt-padding-r-90-desktop{padding-right: 90px !important;}
.kt-m90-desktop{margin: 90px !important;}
.kt-mt-90-desktop{margin-top: 90px !important;}
.kt-mb-90-desktop{margin-bottom: 90px !important;}
.kt-ml-90-desktop{margin-left: 90px !important;}
.kt-mr-90-desktop{margin-right: 90px !important;}
.kt-p90-desktop{padding: 90px !important;}
.kt-pt90-desktop{padding-top: 90px !important;}
.kt-pb-90-desktop{padding-bottom: 90px !important;}
.kt-pl90-desktop{padding-left: 90px !important;}
.kt-pr90-desktop{padding-right: 90px !important;}
.kt-margin-95-desktop{margin: 95px !important;}
.kt-margin-t-95-desktop{margin-top: 95px !important;}
.kt-margin-b-95-desktop{margin-bottom: 95px !important;}
.kt-margin-l-95-desktop{margin-left: 95px !important;}
.kt-margin-r-95-desktop{margin-right: 95px !important;}
.kt-padding-95-desktop{padding: 95px !important;}
.kt-padding-t-95-desktop{padding-top: 95px !important;}
.kt-padding-b-95-desktop{padding-bottom: 95px !important;}
.kt-padding-l-95-desktop{padding-left: 95px !important;}
.kt-padding-r-95-desktop{padding-right: 95px !important;}
.kt-m95-desktop{margin: 95px !important;}
.kt-mt-95-desktop{margin-top: 95px !important;}
.kt-mb-95-desktop{margin-bottom: 95px !important;}
.kt-ml-95-desktop{margin-left: 95px !important;}
.kt-mr-95-desktop{margin-right: 95px !important;}
.kt-p95-desktop{padding: 95px !important;}
.kt-pt95-desktop{padding-top: 95px !important;}
.kt-pb-95-desktop{padding-bottom: 95px !important;}
.kt-pl95-desktop{padding-left: 95px !important;}
.kt-pr95-desktop{padding-right: 95px !important;}
.kt-margin-100-desktop{margin: 100px !important;}
.kt-margin-t-100-desktop{margin-top: 100px !important;}
.kt-margin-b-100-desktop{margin-bottom: 100px !important;}
.kt-margin-l-100-desktop{margin-left: 100px !important;}
.kt-margin-r-100-desktop{margin-right: 100px !important;}
.kt-padding-100-desktop{padding: 100px !important;}
.kt-padding-t-100-desktop{padding-top: 100px !important;}
.kt-padding-b-100-desktop{padding-bottom: 100px !important;}
.kt-padding-l-100-desktop{padding-left: 100px !important;}
.kt-padding-r-100-desktop{padding-right: 100px !important;}
.kt-m100-desktop{margin: 100px !important;}
.kt-mt-100-desktop{margin-top: 100px !important;}
.kt-mb-100-desktop{margin-bottom: 100px !important;}
.kt-ml-100-desktop{margin-left: 100px !important;}
.kt-mr-100-desktop{margin-right: 100px !important;}
.kt-p100-desktop{padding: 100px !important;}
.kt-pt100-desktop{padding-top: 100px !important;}
.kt-pb-100-desktop{padding-bottom: 100px !important;}
.kt-pl100-desktop{padding-left: 100px !important;}
.kt-pr100-desktop{padding-right: 100px !important;}
}
@media (min-width: 769px) and (max-width: 1024px){
.kt-margin-0-tablet{margin: 0px !important;}
.kt-margin-t-0-tablet{margin-top: 0px !important;}
.kt-margin-b-0-tablet{margin-bottom: 0px !important;}
.kt-margin-l-0-tablet{margin-left: 0px !important;}
.kt-margin-r-0-tablet{margin-right: 0px !important;}
.kt-padding-0-tablet{padding: 0px !important;}
.kt-padding-t-0-tablet{padding-top: 0px !important;}
.kt-padding-b-0-tablet{padding-bottom: 0px !important;}
.kt-padding-l-0-tablet{padding-left: 0px !important;}
.kt-padding-r-0-tablet{padding-right: 0px !important;}
.kt-m0-tablet{margin: 0px !important;}
.kt-mt-0-tablet{margin-top: 0px !important;}
.kt-mb-0-tablet{margin-bottom: 0px !important;}
.kt-ml-0-tablet{margin-left: 0px !important;}
.kt-mr-0-tablet{margin-right: 0px !important;}
.kt-p0-tablet{padding: 0px !important;}
.kt-pt0-tablet{padding-top: 0px !important;}
.kt-pb-0-tablet{padding-bottom: 0px !important;}
.kt-pl0-tablet{padding-left: 0px !important;}
.kt-pr0-tablet{padding-right: 0px !important;}
.kt-margin-5-tablet{margin: 5px !important;}
.kt-margin-t-5-tablet{margin-top: 5px !important;}
.kt-margin-b-5-tablet{margin-bottom: 5px !important;}
.kt-margin-l-5-tablet{margin-left: 5px !important;}
.kt-margin-r-5-tablet{margin-right: 5px !important;}
.kt-padding-5-tablet{padding: 5px !important;}
.kt-padding-t-5-tablet{padding-top: 5px !important;}
.kt-padding-b-5-tablet{padding-bottom: 5px !important;}
.kt-padding-l-5-tablet{padding-left: 5px !important;}
.kt-padding-r-5-tablet{padding-right: 5px !important;}
.kt-m5-tablet{margin: 5px !important;}
.kt-mt-5-tablet{margin-top: 5px !important;}
.kt-mb-5-tablet{margin-bottom: 5px !important;}
.kt-ml-5-tablet{margin-left: 5px !important;}
.kt-mr-5-tablet{margin-right: 5px !important;}
.kt-p5-tablet{padding: 5px !important;}
.kt-pt5-tablet{padding-top: 5px !important;}
.kt-pb-5-tablet{padding-bottom: 5px !important;}
.kt-pl5-tablet{padding-left: 5px !important;}
.kt-pr5-tablet{padding-right: 5px !important;}
.kt-margin-10-tablet{margin: 10px !important;}
.kt-margin-t-10-tablet{margin-top: 10px !important;}
.kt-margin-b-10-tablet{margin-bottom: 10px !important;}
.kt-margin-l-10-tablet{margin-left: 10px !important;}
.kt-margin-r-10-tablet{margin-right: 10px !important;}
.kt-padding-10-tablet{padding: 10px !important;}
.kt-padding-t-10-tablet{padding-top: 10px !important;}
.kt-padding-b-10-tablet{padding-bottom: 10px !important;}
.kt-padding-l-10-tablet{padding-left: 10px !important;}
.kt-padding-r-10-tablet{padding-right: 10px !important;}
.kt-m10-tablet{margin: 10px !important;}
.kt-mt-10-tablet{margin-top: 10px !important;}
.kt-mb-10-tablet{margin-bottom: 10px !important;}
.kt-ml-10-tablet{margin-left: 10px !important;}
.kt-mr-10-tablet{margin-right: 10px !important;}
.kt-p10-tablet{padding: 10px !important;}
.kt-pt10-tablet{padding-top: 10px !important;}
.kt-pb-10-tablet{padding-bottom: 10px !important;}
.kt-pl10-tablet{padding-left: 10px !important;}
.kt-pr10-tablet{padding-right: 10px !important;}
.kt-margin-15-tablet{margin: 15px !important;}
.kt-margin-t-15-tablet{margin-top: 15px !important;}
.kt-margin-b-15-tablet{margin-bottom: 15px !important;}
.kt-margin-l-15-tablet{margin-left: 15px !important;}
.kt-margin-r-15-tablet{margin-right: 15px !important;}
.kt-padding-15-tablet{padding: 15px !important;}
.kt-padding-t-15-tablet{padding-top: 15px !important;}
.kt-padding-b-15-tablet{padding-bottom: 15px !important;}
.kt-padding-l-15-tablet{padding-left: 15px !important;}
.kt-padding-r-15-tablet{padding-right: 15px !important;}
.kt-m15-tablet{margin: 15px !important;}
.kt-mt-15-tablet{margin-top: 15px !important;}
.kt-mb-15-tablet{margin-bottom: 15px !important;}
.kt-ml-15-tablet{margin-left: 15px !important;}
.kt-mr-15-tablet{margin-right: 15px !important;}
.kt-p15-tablet{padding: 15px !important;}
.kt-pt15-tablet{padding-top: 15px !important;}
.kt-pb-15-tablet{padding-bottom: 15px !important;}
.kt-pl15-tablet{padding-left: 15px !important;}
.kt-pr15-tablet{padding-right: 15px !important;}
.kt-margin-20-tablet{margin: 20px !important;}
.kt-margin-t-20-tablet{margin-top: 20px !important;}
.kt-margin-b-20-tablet{margin-bottom: 20px !important;}
.kt-margin-l-20-tablet{margin-left: 20px !important;}
.kt-margin-r-20-tablet{margin-right: 20px !important;}
.kt-padding-20-tablet{padding: 20px !important;}
.kt-padding-t-20-tablet{padding-top: 20px !important;}
.kt-padding-b-20-tablet{padding-bottom: 20px !important;}
.kt-padding-l-20-tablet{padding-left: 20px !important;}
.kt-padding-r-20-tablet{padding-right: 20px !important;}
.kt-m20-tablet{margin: 20px !important;}
.kt-mt-20-tablet{margin-top: 20px !important;}
.kt-mb-20-tablet{margin-bottom: 20px !important;}
.kt-ml-20-tablet{margin-left: 20px !important;}
.kt-mr-20-tablet{margin-right: 20px !important;}
.kt-p20-tablet{padding: 20px !important;}
.kt-pt20-tablet{padding-top: 20px !important;}
.kt-pb-20-tablet{padding-bottom: 20px !important;}
.kt-pl20-tablet{padding-left: 20px !important;}
.kt-pr20-tablet{padding-right: 20px !important;}
.kt-margin-25-tablet{margin: 25px !important;}
.kt-margin-t-25-tablet{margin-top: 25px !important;}
.kt-margin-b-25-tablet{margin-bottom: 25px !important;}
.kt-margin-l-25-tablet{margin-left: 25px !important;}
.kt-margin-r-25-tablet{margin-right: 25px !important;}
.kt-padding-25-tablet{padding: 25px !important;}
.kt-padding-t-25-tablet{padding-top: 25px !important;}
.kt-padding-b-25-tablet{padding-bottom: 25px !important;}
.kt-padding-l-25-tablet{padding-left: 25px !important;}
.kt-padding-r-25-tablet{padding-right: 25px !important;}
.kt-m25-tablet{margin: 25px !important;}
.kt-mt-25-tablet{margin-top: 25px !important;}
.kt-mb-25-tablet{margin-bottom: 25px !important;}
.kt-ml-25-tablet{margin-left: 25px !important;}
.kt-mr-25-tablet{margin-right: 25px !important;}
.kt-p25-tablet{padding: 25px !important;}
.kt-pt25-tablet{padding-top: 25px !important;}
.kt-pb-25-tablet{padding-bottom: 25px !important;}
.kt-pl25-tablet{padding-left: 25px !important;}
.kt-pr25-tablet{padding-right: 25px !important;}
.kt-margin-30-tablet{margin: 30px !important;}
.kt-margin-t-30-tablet{margin-top: 30px !important;}
.kt-margin-b-30-tablet{margin-bottom: 30px !important;}
.kt-margin-l-30-tablet{margin-left: 30px !important;}
.kt-margin-r-30-tablet{margin-right: 30px !important;}
.kt-padding-30-tablet{padding: 30px !important;}
.kt-padding-t-30-tablet{padding-top: 30px !important;}
.kt-padding-b-30-tablet{padding-bottom: 30px !important;}
.kt-padding-l-30-tablet{padding-left: 30px !important;}
.kt-padding-r-30-tablet{padding-right: 30px !important;}
.kt-m30-tablet{margin: 30px !important;}
.kt-mt-30-tablet{margin-top: 30px !important;}
.kt-mb-30-tablet{margin-bottom: 30px !important;}
.kt-ml-30-tablet{margin-left: 30px !important;}
.kt-mr-30-tablet{margin-right: 30px !important;}
.kt-p30-tablet{padding: 30px !important;}
.kt-pt30-tablet{padding-top: 30px !important;}
.kt-pb-30-tablet{padding-bottom: 30px !important;}
.kt-pl30-tablet{padding-left: 30px !important;}
.kt-pr30-tablet{padding-right: 30px !important;}
.kt-margin-35-tablet{margin: 35px !important;}
.kt-margin-t-35-tablet{margin-top: 35px !important;}
.kt-margin-b-35-tablet{margin-bottom: 35px !important;}
.kt-margin-l-35-tablet{margin-left: 35px !important;}
.kt-margin-r-35-tablet{margin-right: 35px !important;}
.kt-padding-35-tablet{padding: 35px !important;}
.kt-padding-t-35-tablet{padding-top: 35px !important;}
.kt-padding-b-35-tablet{padding-bottom: 35px !important;}
.kt-padding-l-35-tablet{padding-left: 35px !important;}
.kt-padding-r-35-tablet{padding-right: 35px !important;}
.kt-m35-tablet{margin: 35px !important;}
.kt-mt-35-tablet{margin-top: 35px !important;}
.kt-mb-35-tablet{margin-bottom: 35px !important;}
.kt-ml-35-tablet{margin-left: 35px !important;}
.kt-mr-35-tablet{margin-right: 35px !important;}
.kt-p35-tablet{padding: 35px !important;}
.kt-pt35-tablet{padding-top: 35px !important;}
.kt-pb-35-tablet{padding-bottom: 35px !important;}
.kt-pl35-tablet{padding-left: 35px !important;}
.kt-pr35-tablet{padding-right: 35px !important;}
.kt-margin-40-tablet{margin: 40px !important;}
.kt-margin-t-40-tablet{margin-top: 40px !important;}
.kt-margin-b-40-tablet{margin-bottom: 40px !important;}
.kt-margin-l-40-tablet{margin-left: 40px !important;}
.kt-margin-r-40-tablet{margin-right: 40px !important;}
.kt-padding-40-tablet{padding: 40px !important;}
.kt-padding-t-40-tablet{padding-top: 40px !important;}
.kt-padding-b-40-tablet{padding-bottom: 40px !important;}
.kt-padding-l-40-tablet{padding-left: 40px !important;}
.kt-padding-r-40-tablet{padding-right: 40px !important;}
.kt-m40-tablet{margin: 40px !important;}
.kt-mt-40-tablet{margin-top: 40px !important;}
.kt-mb-40-tablet{margin-bottom: 40px !important;}
.kt-ml-40-tablet{margin-left: 40px !important;}
.kt-mr-40-tablet{margin-right: 40px !important;}
.kt-p40-tablet{padding: 40px !important;}
.kt-pt40-tablet{padding-top: 40px !important;}
.kt-pb-40-tablet{padding-bottom: 40px !important;}
.kt-pl40-tablet{padding-left: 40px !important;}
.kt-pr40-tablet{padding-right: 40px !important;}
.kt-margin-45-tablet{margin: 45px !important;}
.kt-margin-t-45-tablet{margin-top: 45px !important;}
.kt-margin-b-45-tablet{margin-bottom: 45px !important;}
.kt-margin-l-45-tablet{margin-left: 45px !important;}
.kt-margin-r-45-tablet{margin-right: 45px !important;}
.kt-padding-45-tablet{padding: 45px !important;}
.kt-padding-t-45-tablet{padding-top: 45px !important;}
.kt-padding-b-45-tablet{padding-bottom: 45px !important;}
.kt-padding-l-45-tablet{padding-left: 45px !important;}
.kt-padding-r-45-tablet{padding-right: 45px !important;}
.kt-m45-tablet{margin: 45px !important;}
.kt-mt-45-tablet{margin-top: 45px !important;}
.kt-mb-45-tablet{margin-bottom: 45px !important;}
.kt-ml-45-tablet{margin-left: 45px !important;}
.kt-mr-45-tablet{margin-right: 45px !important;}
.kt-p45-tablet{padding: 45px !important;}
.kt-pt45-tablet{padding-top: 45px !important;}
.kt-pb-45-tablet{padding-bottom: 45px !important;}
.kt-pl45-tablet{padding-left: 45px !important;}
.kt-pr45-tablet{padding-right: 45px !important;}
.kt-margin-50-tablet{margin: 50px !important;}
.kt-margin-t-50-tablet{margin-top: 50px !important;}
.kt-margin-b-50-tablet{margin-bottom: 50px !important;}
.kt-margin-l-50-tablet{margin-left: 50px !important;}
.kt-margin-r-50-tablet{margin-right: 50px !important;}
.kt-padding-50-tablet{padding: 50px !important;}
.kt-padding-t-50-tablet{padding-top: 50px !important;}
.kt-padding-b-50-tablet{padding-bottom: 50px !important;}
.kt-padding-l-50-tablet{padding-left: 50px !important;}
.kt-padding-r-50-tablet{padding-right: 50px !important;}
.kt-m50-tablet{margin: 50px !important;}
.kt-mt-50-tablet{margin-top: 50px !important;}
.kt-mb-50-tablet{margin-bottom: 50px !important;}
.kt-ml-50-tablet{margin-left: 50px !important;}
.kt-mr-50-tablet{margin-right: 50px !important;}
.kt-p50-tablet{padding: 50px !important;}
.kt-pt50-tablet{padding-top: 50px !important;}
.kt-pb-50-tablet{padding-bottom: 50px !important;}
.kt-pl50-tablet{padding-left: 50px !important;}
.kt-pr50-tablet{padding-right: 50px !important;}
.kt-margin-55-tablet{margin: 55px !important;}
.kt-margin-t-55-tablet{margin-top: 55px !important;}
.kt-margin-b-55-tablet{margin-bottom: 55px !important;}
.kt-margin-l-55-tablet{margin-left: 55px !important;}
.kt-margin-r-55-tablet{margin-right: 55px !important;}
.kt-padding-55-tablet{padding: 55px !important;}
.kt-padding-t-55-tablet{padding-top: 55px !important;}
.kt-padding-b-55-tablet{padding-bottom: 55px !important;}
.kt-padding-l-55-tablet{padding-left: 55px !important;}
.kt-padding-r-55-tablet{padding-right: 55px !important;}
.kt-m55-tablet{margin: 55px !important;}
.kt-mt-55-tablet{margin-top: 55px !important;}
.kt-mb-55-tablet{margin-bottom: 55px !important;}
.kt-ml-55-tablet{margin-left: 55px !important;}
.kt-mr-55-tablet{margin-right: 55px !important;}
.kt-p55-tablet{padding: 55px !important;}
.kt-pt55-tablet{padding-top: 55px !important;}
.kt-pb-55-tablet{padding-bottom: 55px !important;}
.kt-pl55-tablet{padding-left: 55px !important;}
.kt-pr55-tablet{padding-right: 55px !important;}
.kt-margin-60-tablet{margin: 60px !important;}
.kt-margin-t-60-tablet{margin-top: 60px !important;}
.kt-margin-b-60-tablet{margin-bottom: 60px !important;}
.kt-margin-l-60-tablet{margin-left: 60px !important;}
.kt-margin-r-60-tablet{margin-right: 60px !important;}
.kt-padding-60-tablet{padding: 60px !important;}
.kt-padding-t-60-tablet{padding-top: 60px !important;}
.kt-padding-b-60-tablet{padding-bottom: 60px !important;}
.kt-padding-l-60-tablet{padding-left: 60px !important;}
.kt-padding-r-60-tablet{padding-right: 60px !important;}
.kt-m60-tablet{margin: 60px !important;}
.kt-mt-60-tablet{margin-top: 60px !important;}
.kt-mb-60-tablet{margin-bottom: 60px !important;}
.kt-ml-60-tablet{margin-left: 60px !important;}
.kt-mr-60-tablet{margin-right: 60px !important;}
.kt-p60-tablet{padding: 60px !important;}
.kt-pt60-tablet{padding-top: 60px !important;}
.kt-pb-60-tablet{padding-bottom: 60px !important;}
.kt-pl60-tablet{padding-left: 60px !important;}
.kt-pr60-tablet{padding-right: 60px !important;}
.kt-margin-65-tablet{margin: 65px !important;}
.kt-margin-t-65-tablet{margin-top: 65px !important;}
.kt-margin-b-65-tablet{margin-bottom: 65px !important;}
.kt-margin-l-65-tablet{margin-left: 65px !important;}
.kt-margin-r-65-tablet{margin-right: 65px !important;}
.kt-padding-65-tablet{padding: 65px !important;}
.kt-padding-t-65-tablet{padding-top: 65px !important;}
.kt-padding-b-65-tablet{padding-bottom: 65px !important;}
.kt-padding-l-65-tablet{padding-left: 65px !important;}
.kt-padding-r-65-tablet{padding-right: 65px !important;}
.kt-m65-tablet{margin: 65px !important;}
.kt-mt-65-tablet{margin-top: 65px !important;}
.kt-mb-65-tablet{margin-bottom: 65px !important;}
.kt-ml-65-tablet{margin-left: 65px !important;}
.kt-mr-65-tablet{margin-right: 65px !important;}
.kt-p65-tablet{padding: 65px !important;}
.kt-pt65-tablet{padding-top: 65px !important;}
.kt-pb-65-tablet{padding-bottom: 65px !important;}
.kt-pl65-tablet{padding-left: 65px !important;}
.kt-pr65-tablet{padding-right: 65px !important;}
.kt-margin-70-tablet{margin: 70px !important;}
.kt-margin-t-70-tablet{margin-top: 70px !important;}
.kt-margin-b-70-tablet{margin-bottom: 70px !important;}
.kt-margin-l-70-tablet{margin-left: 70px !important;}
.kt-margin-r-70-tablet{margin-right: 70px !important;}
.kt-padding-70-tablet{padding: 70px !important;}
.kt-padding-t-70-tablet{padding-top: 70px !important;}
.kt-padding-b-70-tablet{padding-bottom: 70px !important;}
.kt-padding-l-70-tablet{padding-left: 70px !important;}
.kt-padding-r-70-tablet{padding-right: 70px !important;}
.kt-m70-tablet{margin: 70px !important;}
.kt-mt-70-tablet{margin-top: 70px !important;}
.kt-mb-70-tablet{margin-bottom: 70px !important;}
.kt-ml-70-tablet{margin-left: 70px !important;}
.kt-mr-70-tablet{margin-right: 70px !important;}
.kt-p70-tablet{padding: 70px !important;}
.kt-pt70-tablet{padding-top: 70px !important;}
.kt-pb-70-tablet{padding-bottom: 70px !important;}
.kt-pl70-tablet{padding-left: 70px !important;}
.kt-pr70-tablet{padding-right: 70px !important;}
.kt-margin-75-tablet{margin: 75px !important;}
.kt-margin-t-75-tablet{margin-top: 75px !important;}
.kt-margin-b-75-tablet{margin-bottom: 75px !important;}
.kt-margin-l-75-tablet{margin-left: 75px !important;}
.kt-margin-r-75-tablet{margin-right: 75px !important;}
.kt-padding-75-tablet{padding: 75px !important;}
.kt-padding-t-75-tablet{padding-top: 75px !important;}
.kt-padding-b-75-tablet{padding-bottom: 75px !important;}
.kt-padding-l-75-tablet{padding-left: 75px !important;}
.kt-padding-r-75-tablet{padding-right: 75px !important;}
.kt-m75-tablet{margin: 75px !important;}
.kt-mt-75-tablet{margin-top: 75px !important;}
.kt-mb-75-tablet{margin-bottom: 75px !important;}
.kt-ml-75-tablet{margin-left: 75px !important;}
.kt-mr-75-tablet{margin-right: 75px !important;}
.kt-p75-tablet{padding: 75px !important;}
.kt-pt75-tablet{padding-top: 75px !important;}
.kt-pb-75-tablet{padding-bottom: 75px !important;}
.kt-pl75-tablet{padding-left: 75px !important;}
.kt-pr75-tablet{padding-right: 75px !important;}
.kt-margin-80-tablet{margin: 80px !important;}
.kt-margin-t-80-tablet{margin-top: 80px !important;}
.kt-margin-b-80-tablet{margin-bottom: 80px !important;}
.kt-margin-l-80-tablet{margin-left: 80px !important;}
.kt-margin-r-80-tablet{margin-right: 80px !important;}
.kt-padding-80-tablet{padding: 80px !important;}
.kt-padding-t-80-tablet{padding-top: 80px !important;}
.kt-padding-b-80-tablet{padding-bottom: 80px !important;}
.kt-padding-l-80-tablet{padding-left: 80px !important;}
.kt-padding-r-80-tablet{padding-right: 80px !important;}
.kt-m80-tablet{margin: 80px !important;}
.kt-mt-80-tablet{margin-top: 80px !important;}
.kt-mb-80-tablet{margin-bottom: 80px !important;}
.kt-ml-80-tablet{margin-left: 80px !important;}
.kt-mr-80-tablet{margin-right: 80px !important;}
.kt-p80-tablet{padding: 80px !important;}
.kt-pt80-tablet{padding-top: 80px !important;}
.kt-pb-80-tablet{padding-bottom: 80px !important;}
.kt-pl80-tablet{padding-left: 80px !important;}
.kt-pr80-tablet{padding-right: 80px !important;}
.kt-margin-85-tablet{margin: 85px !important;}
.kt-margin-t-85-tablet{margin-top: 85px !important;}
.kt-margin-b-85-tablet{margin-bottom: 85px !important;}
.kt-margin-l-85-tablet{margin-left: 85px !important;}
.kt-margin-r-85-tablet{margin-right: 85px !important;}
.kt-padding-85-tablet{padding: 85px !important;}
.kt-padding-t-85-tablet{padding-top: 85px !important;}
.kt-padding-b-85-tablet{padding-bottom: 85px !important;}
.kt-padding-l-85-tablet{padding-left: 85px !important;}
.kt-padding-r-85-tablet{padding-right: 85px !important;}
.kt-m85-tablet{margin: 85px !important;}
.kt-mt-85-tablet{margin-top: 85px !important;}
.kt-mb-85-tablet{margin-bottom: 85px !important;}
.kt-ml-85-tablet{margin-left: 85px !important;}
.kt-mr-85-tablet{margin-right: 85px !important;}
.kt-p85-tablet{padding: 85px !important;}
.kt-pt85-tablet{padding-top: 85px !important;}
.kt-pb-85-tablet{padding-bottom: 85px !important;}
.kt-pl85-tablet{padding-left: 85px !important;}
.kt-pr85-tablet{padding-right: 85px !important;}
.kt-margin-90-tablet{margin: 90px !important;}
.kt-margin-t-90-tablet{margin-top: 90px !important;}
.kt-margin-b-90-tablet{margin-bottom: 90px !important;}
.kt-margin-l-90-tablet{margin-left: 90px !important;}
.kt-margin-r-90-tablet{margin-right: 90px !important;}
.kt-padding-90-tablet{padding: 90px !important;}
.kt-padding-t-90-tablet{padding-top: 90px !important;}
.kt-padding-b-90-tablet{padding-bottom: 90px !important;}
.kt-padding-l-90-tablet{padding-left: 90px !important;}
.kt-padding-r-90-tablet{padding-right: 90px !important;}
.kt-m90-tablet{margin: 90px !important;}
.kt-mt-90-tablet{margin-top: 90px !important;}
.kt-mb-90-tablet{margin-bottom: 90px !important;}
.kt-ml-90-tablet{margin-left: 90px !important;}
.kt-mr-90-tablet{margin-right: 90px !important;}
.kt-p90-tablet{padding: 90px !important;}
.kt-pt90-tablet{padding-top: 90px !important;}
.kt-pb-90-tablet{padding-bottom: 90px !important;}
.kt-pl90-tablet{padding-left: 90px !important;}
.kt-pr90-tablet{padding-right: 90px !important;}
.kt-margin-95-tablet{margin: 95px !important;}
.kt-margin-t-95-tablet{margin-top: 95px !important;}
.kt-margin-b-95-tablet{margin-bottom: 95px !important;}
.kt-margin-l-95-tablet{margin-left: 95px !important;}
.kt-margin-r-95-tablet{margin-right: 95px !important;}
.kt-padding-95-tablet{padding: 95px !important;}
.kt-padding-t-95-tablet{padding-top: 95px !important;}
.kt-padding-b-95-tablet{padding-bottom: 95px !important;}
.kt-padding-l-95-tablet{padding-left: 95px !important;}
.kt-padding-r-95-tablet{padding-right: 95px !important;}
.kt-m95-tablet{margin: 95px !important;}
.kt-mt-95-tablet{margin-top: 95px !important;}
.kt-mb-95-tablet{margin-bottom: 95px !important;}
.kt-ml-95-tablet{margin-left: 95px !important;}
.kt-mr-95-tablet{margin-right: 95px !important;}
.kt-p95-tablet{padding: 95px !important;}
.kt-pt95-tablet{padding-top: 95px !important;}
.kt-pb-95-tablet{padding-bottom: 95px !important;}
.kt-pl95-tablet{padding-left: 95px !important;}
.kt-pr95-tablet{padding-right: 95px !important;}
.kt-margin-100-tablet{margin: 100px !important;}
.kt-margin-t-100-tablet{margin-top: 100px !important;}
.kt-margin-b-100-tablet{margin-bottom: 100px !important;}
.kt-margin-l-100-tablet{margin-left: 100px !important;}
.kt-margin-r-100-tablet{margin-right: 100px !important;}
.kt-padding-100-tablet{padding: 100px !important;}
.kt-padding-t-100-tablet{padding-top: 100px !important;}
.kt-padding-b-100-tablet{padding-bottom: 100px !important;}
.kt-padding-l-100-tablet{padding-left: 100px !important;}
.kt-padding-r-100-tablet{padding-right: 100px !important;}
.kt-m100-tablet{margin: 100px !important;}
.kt-mt-100-tablet{margin-top: 100px !important;}
.kt-mb-100-tablet{margin-bottom: 100px !important;}
.kt-ml-100-tablet{margin-left: 100px !important;}
.kt-mr-100-tablet{margin-right: 100px !important;}
.kt-p100-tablet{padding: 100px !important;}
.kt-pt100-tablet{padding-top: 100px !important;}
.kt-pb-100-tablet{padding-bottom: 100px !important;}
.kt-pl100-tablet{padding-left: 100px !important;}
.kt-pr100-tablet{padding-right: 100px !important;}
}
@media (max-width: 1024px){
.kt-margin-0-tablet-and-mobile{margin: 0px !important;}
.kt-margin-t-0-tablet-and-mobile{margin-top: 0px !important;}
.kt-margin-b-0-tablet-and-mobile{margin-bottom: 0px !important;}
.kt-margin-l-0-tablet-and-mobile{margin-left: 0px !important;}
.kt-margin-r-0-tablet-and-mobile{margin-right: 0px !important;}
.kt-padding-0-tablet-and-mobile{padding: 0px !important;}
.kt-padding-t-0-tablet-and-mobile{padding-top: 0px !important;}
.kt-padding-b-0-tablet-and-mobile{padding-bottom: 0px !important;}
.kt-padding-l-0-tablet-and-mobile{padding-left: 0px !important;}
.kt-padding-r-0-tablet-and-mobile{padding-right: 0px !important;}
.kt-m0-tablet-and-mobile{margin: 0px !important;}
.kt-mt-0-tablet-and-mobile{margin-top: 0px !important;}
.kt-mb-0-tablet-and-mobile{margin-bottom: 0px !important;}
.kt-ml-0-tablet-and-mobile{margin-left: 0px !important;}
.kt-mr-0-tablet-and-mobile{margin-right: 0px !important;}
.kt-p0-tablet-and-mobile{padding: 0px !important;}
.kt-pt0-tablet-and-mobile{padding-top: 0px !important;}
.kt-pb-0-tablet-and-mobile{padding-bottom: 0px !important;}
.kt-pl0-tablet-and-mobile{padding-left: 0px !important;}
.kt-pr0-tablet-and-mobile{padding-right: 0px !important;}
.kt-margin-5-tablet-and-mobile{margin: 5px !important;}
.kt-margin-t-5-tablet-and-mobile{margin-top: 5px !important;}
.kt-margin-b-5-tablet-and-mobile{margin-bottom: 5px !important;}
.kt-margin-l-5-tablet-and-mobile{margin-left: 5px !important;}
.kt-margin-r-5-tablet-and-mobile{margin-right: 5px !important;}
.kt-padding-5-tablet-and-mobile{padding: 5px !important;}
.kt-padding-t-5-tablet-and-mobile{padding-top: 5px !important;}
.kt-padding-b-5-tablet-and-mobile{padding-bottom: 5px !important;}
.kt-padding-l-5-tablet-and-mobile{padding-left: 5px !important;}
.kt-padding-r-5-tablet-and-mobile{padding-right: 5px !important;}
.kt-m5-tablet-and-mobile{margin: 5px !important;}
.kt-mt-5-tablet-and-mobile{margin-top: 5px !important;}
.kt-mb-5-tablet-and-mobile{margin-bottom: 5px !important;}
.kt-ml-5-tablet-and-mobile{margin-left: 5px !important;}
.kt-mr-5-tablet-and-mobile{margin-right: 5px !important;}
.kt-p5-tablet-and-mobile{padding: 5px !important;}
.kt-pt5-tablet-and-mobile{padding-top: 5px !important;}
.kt-pb-5-tablet-and-mobile{padding-bottom: 5px !important;}
.kt-pl5-tablet-and-mobile{padding-left: 5px !important;}
.kt-pr5-tablet-and-mobile{padding-right: 5px !important;}
.kt-margin-10-tablet-and-mobile{margin: 10px !important;}
.kt-margin-t-10-tablet-and-mobile{margin-top: 10px !important;}
.kt-margin-b-10-tablet-and-mobile{margin-bottom: 10px !important;}
.kt-margin-l-10-tablet-and-mobile{margin-left: 10px !important;}
.kt-margin-r-10-tablet-and-mobile{margin-right: 10px !important;}
.kt-padding-10-tablet-and-mobile{padding: 10px !important;}
.kt-padding-t-10-tablet-and-mobile{padding-top: 10px !important;}
.kt-padding-b-10-tablet-and-mobile{padding-bottom: 10px !important;}
.kt-padding-l-10-tablet-and-mobile{padding-left: 10px !important;}
.kt-padding-r-10-tablet-and-mobile{padding-right: 10px !important;}
.kt-m10-tablet-and-mobile{margin: 10px !important;}
.kt-mt-10-tablet-and-mobile{margin-top: 10px !important;}
.kt-mb-10-tablet-and-mobile{margin-bottom: 10px !important;}
.kt-ml-10-tablet-and-mobile{margin-left: 10px !important;}
.kt-mr-10-tablet-and-mobile{margin-right: 10px !important;}
.kt-p10-tablet-and-mobile{padding: 10px !important;}
.kt-pt10-tablet-and-mobile{padding-top: 10px !important;}
.kt-pb-10-tablet-and-mobile{padding-bottom: 10px !important;}
.kt-pl10-tablet-and-mobile{padding-left: 10px !important;}
.kt-pr10-tablet-and-mobile{padding-right: 10px !important;}
.kt-margin-15-tablet-and-mobile{margin: 15px !important;}
.kt-margin-t-15-tablet-and-mobile{margin-top: 15px !important;}
.kt-margin-b-15-tablet-and-mobile{margin-bottom: 15px !important;}
.kt-margin-l-15-tablet-and-mobile{margin-left: 15px !important;}
.kt-margin-r-15-tablet-and-mobile{margin-right: 15px !important;}
.kt-padding-15-tablet-and-mobile{padding: 15px !important;}
.kt-padding-t-15-tablet-and-mobile{padding-top: 15px !important;}
.kt-padding-b-15-tablet-and-mobile{padding-bottom: 15px !important;}
.kt-padding-l-15-tablet-and-mobile{padding-left: 15px !important;}
.kt-padding-r-15-tablet-and-mobile{padding-right: 15px !important;}
.kt-m15-tablet-and-mobile{margin: 15px !important;}
.kt-mt-15-tablet-and-mobile{margin-top: 15px !important;}
.kt-mb-15-tablet-and-mobile{margin-bottom: 15px !important;}
.kt-ml-15-tablet-and-mobile{margin-left: 15px !important;}
.kt-mr-15-tablet-and-mobile{margin-right: 15px !important;}
.kt-p15-tablet-and-mobile{padding: 15px !important;}
.kt-pt15-tablet-and-mobile{padding-top: 15px !important;}
.kt-pb-15-tablet-and-mobile{padding-bottom: 15px !important;}
.kt-pl15-tablet-and-mobile{padding-left: 15px !important;}
.kt-pr15-tablet-and-mobile{padding-right: 15px !important;}
.kt-margin-20-tablet-and-mobile{margin: 20px !important;}
.kt-margin-t-20-tablet-and-mobile{margin-top: 20px !important;}
.kt-margin-b-20-tablet-and-mobile{margin-bottom: 20px !important;}
.kt-margin-l-20-tablet-and-mobile{margin-left: 20px !important;}
.kt-margin-r-20-tablet-and-mobile{margin-right: 20px !important;}
.kt-padding-20-tablet-and-mobile{padding: 20px !important;}
.kt-padding-t-20-tablet-and-mobile{padding-top: 20px !important;}
.kt-padding-b-20-tablet-and-mobile{padding-bottom: 20px !important;}
.kt-padding-l-20-tablet-and-mobile{padding-left: 20px !important;}
.kt-padding-r-20-tablet-and-mobile{padding-right: 20px !important;}
.kt-m20-tablet-and-mobile{margin: 20px !important;}
.kt-mt-20-tablet-and-mobile{margin-top: 20px !important;}
.kt-mb-20-tablet-and-mobile{margin-bottom: 20px !important;}
.kt-ml-20-tablet-and-mobile{margin-left: 20px !important;}
.kt-mr-20-tablet-and-mobile{margin-right: 20px !important;}
.kt-p20-tablet-and-mobile{padding: 20px !important;}
.kt-pt20-tablet-and-mobile{padding-top: 20px !important;}
.kt-pb-20-tablet-and-mobile{padding-bottom: 20px !important;}
.kt-pl20-tablet-and-mobile{padding-left: 20px !important;}
.kt-pr20-tablet-and-mobile{padding-right: 20px !important;}
.kt-margin-25-tablet-and-mobile{margin: 25px !important;}
.kt-margin-t-25-tablet-and-mobile{margin-top: 25px !important;}
.kt-margin-b-25-tablet-and-mobile{margin-bottom: 25px !important;}
.kt-margin-l-25-tablet-and-mobile{margin-left: 25px !important;}
.kt-margin-r-25-tablet-and-mobile{margin-right: 25px !important;}
.kt-padding-25-tablet-and-mobile{padding: 25px !important;}
.kt-padding-t-25-tablet-and-mobile{padding-top: 25px !important;}
.kt-padding-b-25-tablet-and-mobile{padding-bottom: 25px !important;}
.kt-padding-l-25-tablet-and-mobile{padding-left: 25px !important;}
.kt-padding-r-25-tablet-and-mobile{padding-right: 25px !important;}
.kt-m25-tablet-and-mobile{margin: 25px !important;}
.kt-mt-25-tablet-and-mobile{margin-top: 25px !important;}
.kt-mb-25-tablet-and-mobile{margin-bottom: 25px !important;}
.kt-ml-25-tablet-and-mobile{margin-left: 25px !important;}
.kt-mr-25-tablet-and-mobile{margin-right: 25px !important;}
.kt-p25-tablet-and-mobile{padding: 25px !important;}
.kt-pt25-tablet-and-mobile{padding-top: 25px !important;}
.kt-pb-25-tablet-and-mobile{padding-bottom: 25px !important;}
.kt-pl25-tablet-and-mobile{padding-left: 25px !important;}
.kt-pr25-tablet-and-mobile{padding-right: 25px !important;}
.kt-margin-30-tablet-and-mobile{margin: 30px !important;}
.kt-margin-t-30-tablet-and-mobile{margin-top: 30px !important;}
.kt-margin-b-30-tablet-and-mobile{margin-bottom: 30px !important;}
.kt-margin-l-30-tablet-and-mobile{margin-left: 30px !important;}
.kt-margin-r-30-tablet-and-mobile{margin-right: 30px !important;}
.kt-padding-30-tablet-and-mobile{padding: 30px !important;}
.kt-padding-t-30-tablet-and-mobile{padding-top: 30px !important;}
.kt-padding-b-30-tablet-and-mobile{padding-bottom: 30px !important;}
.kt-padding-l-30-tablet-and-mobile{padding-left: 30px !important;}
.kt-padding-r-30-tablet-and-mobile{padding-right: 30px !important;}
.kt-m30-tablet-and-mobile{margin: 30px !important;}
.kt-mt-30-tablet-and-mobile{margin-top: 30px !important;}
.kt-mb-30-tablet-and-mobile{margin-bottom: 30px !important;}
.kt-ml-30-tablet-and-mobile{margin-left: 30px !important;}
.kt-mr-30-tablet-and-mobile{margin-right: 30px !important;}
.kt-p30-tablet-and-mobile{padding: 30px !important;}
.kt-pt30-tablet-and-mobile{padding-top: 30px !important;}
.kt-pb-30-tablet-and-mobile{padding-bottom: 30px !important;}
.kt-pl30-tablet-and-mobile{padding-left: 30px !important;}
.kt-pr30-tablet-and-mobile{padding-right: 30px !important;}
.kt-margin-35-tablet-and-mobile{margin: 35px !important;}
.kt-margin-t-35-tablet-and-mobile{margin-top: 35px !important;}
.kt-margin-b-35-tablet-and-mobile{margin-bottom: 35px !important;}
.kt-margin-l-35-tablet-and-mobile{margin-left: 35px !important;}
.kt-margin-r-35-tablet-and-mobile{margin-right: 35px !important;}
.kt-padding-35-tablet-and-mobile{padding: 35px !important;}
.kt-padding-t-35-tablet-and-mobile{padding-top: 35px !important;}
.kt-padding-b-35-tablet-and-mobile{padding-bottom: 35px !important;}
.kt-padding-l-35-tablet-and-mobile{padding-left: 35px !important;}
.kt-padding-r-35-tablet-and-mobile{padding-right: 35px !important;}
.kt-m35-tablet-and-mobile{margin: 35px !important;}
.kt-mt-35-tablet-and-mobile{margin-top: 35px !important;}
.kt-mb-35-tablet-and-mobile{margin-bottom: 35px !important;}
.kt-ml-35-tablet-and-mobile{margin-left: 35px !important;}
.kt-mr-35-tablet-and-mobile{margin-right: 35px !important;}
.kt-p35-tablet-and-mobile{padding: 35px !important;}
.kt-pt35-tablet-and-mobile{padding-top: 35px !important;}
.kt-pb-35-tablet-and-mobile{padding-bottom: 35px !important;}
.kt-pl35-tablet-and-mobile{padding-left: 35px !important;}
.kt-pr35-tablet-and-mobile{padding-right: 35px !important;}
.kt-margin-40-tablet-and-mobile{margin: 40px !important;}
.kt-margin-t-40-tablet-and-mobile{margin-top: 40px !important;}
.kt-margin-b-40-tablet-and-mobile{margin-bottom: 40px !important;}
.kt-margin-l-40-tablet-and-mobile{margin-left: 40px !important;}
.kt-margin-r-40-tablet-and-mobile{margin-right: 40px !important;}
.kt-padding-40-tablet-and-mobile{padding: 40px !important;}
.kt-padding-t-40-tablet-and-mobile{padding-top: 40px !important;}
.kt-padding-b-40-tablet-and-mobile{padding-bottom: 40px !important;}
.kt-padding-l-40-tablet-and-mobile{padding-left: 40px !important;}
.kt-padding-r-40-tablet-and-mobile{padding-right: 40px !important;}
.kt-m40-tablet-and-mobile{margin: 40px !important;}
.kt-mt-40-tablet-and-mobile{margin-top: 40px !important;}
.kt-mb-40-tablet-and-mobile{margin-bottom: 40px !important;}
.kt-ml-40-tablet-and-mobile{margin-left: 40px !important;}
.kt-mr-40-tablet-and-mobile{margin-right: 40px !important;}
.kt-p40-tablet-and-mobile{padding: 40px !important;}
.kt-pt40-tablet-and-mobile{padding-top: 40px !important;}
.kt-pb-40-tablet-and-mobile{padding-bottom: 40px !important;}
.kt-pl40-tablet-and-mobile{padding-left: 40px !important;}
.kt-pr40-tablet-and-mobile{padding-right: 40px !important;}
.kt-margin-45-tablet-and-mobile{margin: 45px !important;}
.kt-margin-t-45-tablet-and-mobile{margin-top: 45px !important;}
.kt-margin-b-45-tablet-and-mobile{margin-bottom: 45px !important;}
.kt-margin-l-45-tablet-and-mobile{margin-left: 45px !important;}
.kt-margin-r-45-tablet-and-mobile{margin-right: 45px !important;}
.kt-padding-45-tablet-and-mobile{padding: 45px !important;}
.kt-padding-t-45-tablet-and-mobile{padding-top: 45px !important;}
.kt-padding-b-45-tablet-and-mobile{padding-bottom: 45px !important;}
.kt-padding-l-45-tablet-and-mobile{padding-left: 45px !important;}
.kt-padding-r-45-tablet-and-mobile{padding-right: 45px !important;}
.kt-m45-tablet-and-mobile{margin: 45px !important;}
.kt-mt-45-tablet-and-mobile{margin-top: 45px !important;}
.kt-mb-45-tablet-and-mobile{margin-bottom: 45px !important;}
.kt-ml-45-tablet-and-mobile{margin-left: 45px !important;}
.kt-mr-45-tablet-and-mobile{margin-right: 45px !important;}
.kt-p45-tablet-and-mobile{padding: 45px !important;}
.kt-pt45-tablet-and-mobile{padding-top: 45px !important;}
.kt-pb-45-tablet-and-mobile{padding-bottom: 45px !important;}
.kt-pl45-tablet-and-mobile{padding-left: 45px !important;}
.kt-pr45-tablet-and-mobile{padding-right: 45px !important;}
.kt-margin-50-tablet-and-mobile{margin: 50px !important;}
.kt-margin-t-50-tablet-and-mobile{margin-top: 50px !important;}
.kt-margin-b-50-tablet-and-mobile{margin-bottom: 50px !important;}
.kt-margin-l-50-tablet-and-mobile{margin-left: 50px !important;}
.kt-margin-r-50-tablet-and-mobile{margin-right: 50px !important;}
.kt-padding-50-tablet-and-mobile{padding: 50px !important;}
.kt-padding-t-50-tablet-and-mobile{padding-top: 50px !important;}
.kt-padding-b-50-tablet-and-mobile{padding-bottom: 50px !important;}
.kt-padding-l-50-tablet-and-mobile{padding-left: 50px !important;}
.kt-padding-r-50-tablet-and-mobile{padding-right: 50px !important;}
.kt-m50-tablet-and-mobile{margin: 50px !important;}
.kt-mt-50-tablet-and-mobile{margin-top: 50px !important;}
.kt-mb-50-tablet-and-mobile{margin-bottom: 50px !important;}
.kt-ml-50-tablet-and-mobile{margin-left: 50px !important;}
.kt-mr-50-tablet-and-mobile{margin-right: 50px !important;}
.kt-p50-tablet-and-mobile{padding: 50px !important;}
.kt-pt50-tablet-and-mobile{padding-top: 50px !important;}
.kt-pb-50-tablet-and-mobile{padding-bottom: 50px !important;}
.kt-pl50-tablet-and-mobile{padding-left: 50px !important;}
.kt-pr50-tablet-and-mobile{padding-right: 50px !important;}
.kt-margin-55-tablet-and-mobile{margin: 55px !important;}
.kt-margin-t-55-tablet-and-mobile{margin-top: 55px !important;}
.kt-margin-b-55-tablet-and-mobile{margin-bottom: 55px !important;}
.kt-margin-l-55-tablet-and-mobile{margin-left: 55px !important;}
.kt-margin-r-55-tablet-and-mobile{margin-right: 55px !important;}
.kt-padding-55-tablet-and-mobile{padding: 55px !important;}
.kt-padding-t-55-tablet-and-mobile{padding-top: 55px !important;}
.kt-padding-b-55-tablet-and-mobile{padding-bottom: 55px !important;}
.kt-padding-l-55-tablet-and-mobile{padding-left: 55px !important;}
.kt-padding-r-55-tablet-and-mobile{padding-right: 55px !important;}
.kt-m55-tablet-and-mobile{margin: 55px !important;}
.kt-mt-55-tablet-and-mobile{margin-top: 55px !important;}
.kt-mb-55-tablet-and-mobile{margin-bottom: 55px !important;}
.kt-ml-55-tablet-and-mobile{margin-left: 55px !important;}
.kt-mr-55-tablet-and-mobile{margin-right: 55px !important;}
.kt-p55-tablet-and-mobile{padding: 55px !important;}
.kt-pt55-tablet-and-mobile{padding-top: 55px !important;}
.kt-pb-55-tablet-and-mobile{padding-bottom: 55px !important;}
.kt-pl55-tablet-and-mobile{padding-left: 55px !important;}
.kt-pr55-tablet-and-mobile{padding-right: 55px !important;}
.kt-margin-60-tablet-and-mobile{margin: 60px !important;}
.kt-margin-t-60-tablet-and-mobile{margin-top: 60px !important;}
.kt-margin-b-60-tablet-and-mobile{margin-bottom: 60px !important;}
.kt-margin-l-60-tablet-and-mobile{margin-left: 60px !important;}
.kt-margin-r-60-tablet-and-mobile{margin-right: 60px !important;}
.kt-padding-60-tablet-and-mobile{padding: 60px !important;}
.kt-padding-t-60-tablet-and-mobile{padding-top: 60px !important;}
.kt-padding-b-60-tablet-and-mobile{padding-bottom: 60px !important;}
.kt-padding-l-60-tablet-and-mobile{padding-left: 60px !important;}
.kt-padding-r-60-tablet-and-mobile{padding-right: 60px !important;}
.kt-m60-tablet-and-mobile{margin: 60px !important;}
.kt-mt-60-tablet-and-mobile{margin-top: 60px !important;}
.kt-mb-60-tablet-and-mobile{margin-bottom: 60px !important;}
.kt-ml-60-tablet-and-mobile{margin-left: 60px !important;}
.kt-mr-60-tablet-and-mobile{margin-right: 60px !important;}
.kt-p60-tablet-and-mobile{padding: 60px !important;}
.kt-pt60-tablet-and-mobile{padding-top: 60px !important;}
.kt-pb-60-tablet-and-mobile{padding-bottom: 60px !important;}
.kt-pl60-tablet-and-mobile{padding-left: 60px !important;}
.kt-pr60-tablet-and-mobile{padding-right: 60px !important;}
.kt-margin-65-tablet-and-mobile{margin: 65px !important;}
.kt-margin-t-65-tablet-and-mobile{margin-top: 65px !important;}
.kt-margin-b-65-tablet-and-mobile{margin-bottom: 65px !important;}
.kt-margin-l-65-tablet-and-mobile{margin-left: 65px !important;}
.kt-margin-r-65-tablet-and-mobile{margin-right: 65px !important;}
.kt-padding-65-tablet-and-mobile{padding: 65px !important;}
.kt-padding-t-65-tablet-and-mobile{padding-top: 65px !important;}
.kt-padding-b-65-tablet-and-mobile{padding-bottom: 65px !important;}
.kt-padding-l-65-tablet-and-mobile{padding-left: 65px !important;}
.kt-padding-r-65-tablet-and-mobile{padding-right: 65px !important;}
.kt-m65-tablet-and-mobile{margin: 65px !important;}
.kt-mt-65-tablet-and-mobile{margin-top: 65px !important;}
.kt-mb-65-tablet-and-mobile{margin-bottom: 65px !important;}
.kt-ml-65-tablet-and-mobile{margin-left: 65px !important;}
.kt-mr-65-tablet-and-mobile{margin-right: 65px !important;}
.kt-p65-tablet-and-mobile{padding: 65px !important;}
.kt-pt65-tablet-and-mobile{padding-top: 65px !important;}
.kt-pb-65-tablet-and-mobile{padding-bottom: 65px !important;}
.kt-pl65-tablet-and-mobile{padding-left: 65px !important;}
.kt-pr65-tablet-and-mobile{padding-right: 65px !important;}
.kt-margin-70-tablet-and-mobile{margin: 70px !important;}
.kt-margin-t-70-tablet-and-mobile{margin-top: 70px !important;}
.kt-margin-b-70-tablet-and-mobile{margin-bottom: 70px !important;}
.kt-margin-l-70-tablet-and-mobile{margin-left: 70px !important;}
.kt-margin-r-70-tablet-and-mobile{margin-right: 70px !important;}
.kt-padding-70-tablet-and-mobile{padding: 70px !important;}
.kt-padding-t-70-tablet-and-mobile{padding-top: 70px !important;}
.kt-padding-b-70-tablet-and-mobile{padding-bottom: 70px !important;}
.kt-padding-l-70-tablet-and-mobile{padding-left: 70px !important;}
.kt-padding-r-70-tablet-and-mobile{padding-right: 70px !important;}
.kt-m70-tablet-and-mobile{margin: 70px !important;}
.kt-mt-70-tablet-and-mobile{margin-top: 70px !important;}
.kt-mb-70-tablet-and-mobile{margin-bottom: 70px !important;}
.kt-ml-70-tablet-and-mobile{margin-left: 70px !important;}
.kt-mr-70-tablet-and-mobile{margin-right: 70px !important;}
.kt-p70-tablet-and-mobile{padding: 70px !important;}
.kt-pt70-tablet-and-mobile{padding-top: 70px !important;}
.kt-pb-70-tablet-and-mobile{padding-bottom: 70px !important;}
.kt-pl70-tablet-and-mobile{padding-left: 70px !important;}
.kt-pr70-tablet-and-mobile{padding-right: 70px !important;}
.kt-margin-75-tablet-and-mobile{margin: 75px !important;}
.kt-margin-t-75-tablet-and-mobile{margin-top: 75px !important;}
.kt-margin-b-75-tablet-and-mobile{margin-bottom: 75px !important;}
.kt-margin-l-75-tablet-and-mobile{margin-left: 75px !important;}
.kt-margin-r-75-tablet-and-mobile{margin-right: 75px !important;}
.kt-padding-75-tablet-and-mobile{padding: 75px !important;}
.kt-padding-t-75-tablet-and-mobile{padding-top: 75px !important;}
.kt-padding-b-75-tablet-and-mobile{padding-bottom: 75px !important;}
.kt-padding-l-75-tablet-and-mobile{padding-left: 75px !important;}
.kt-padding-r-75-tablet-and-mobile{padding-right: 75px !important;}
.kt-m75-tablet-and-mobile{margin: 75px !important;}
.kt-mt-75-tablet-and-mobile{margin-top: 75px !important;}
.kt-mb-75-tablet-and-mobile{margin-bottom: 75px !important;}
.kt-ml-75-tablet-and-mobile{margin-left: 75px !important;}
.kt-mr-75-tablet-and-mobile{margin-right: 75px !important;}
.kt-p75-tablet-and-mobile{padding: 75px !important;}
.kt-pt75-tablet-and-mobile{padding-top: 75px !important;}
.kt-pb-75-tablet-and-mobile{padding-bottom: 75px !important;}
.kt-pl75-tablet-and-mobile{padding-left: 75px !important;}
.kt-pr75-tablet-and-mobile{padding-right: 75px !important;}
.kt-margin-80-tablet-and-mobile{margin: 80px !important;}
.kt-margin-t-80-tablet-and-mobile{margin-top: 80px !important;}
.kt-margin-b-80-tablet-and-mobile{margin-bottom: 80px !important;}
.kt-margin-l-80-tablet-and-mobile{margin-left: 80px !important;}
.kt-margin-r-80-tablet-and-mobile{margin-right: 80px !important;}
.kt-padding-80-tablet-and-mobile{padding: 80px !important;}
.kt-padding-t-80-tablet-and-mobile{padding-top: 80px !important;}
.kt-padding-b-80-tablet-and-mobile{padding-bottom: 80px !important;}
.kt-padding-l-80-tablet-and-mobile{padding-left: 80px !important;}
.kt-padding-r-80-tablet-and-mobile{padding-right: 80px !important;}
.kt-m80-tablet-and-mobile{margin: 80px !important;}
.kt-mt-80-tablet-and-mobile{margin-top: 80px !important;}
.kt-mb-80-tablet-and-mobile{margin-bottom: 80px !important;}
.kt-ml-80-tablet-and-mobile{margin-left: 80px !important;}
.kt-mr-80-tablet-and-mobile{margin-right: 80px !important;}
.kt-p80-tablet-and-mobile{padding: 80px !important;}
.kt-pt80-tablet-and-mobile{padding-top: 80px !important;}
.kt-pb-80-tablet-and-mobile{padding-bottom: 80px !important;}
.kt-pl80-tablet-and-mobile{padding-left: 80px !important;}
.kt-pr80-tablet-and-mobile{padding-right: 80px !important;}
.kt-margin-85-tablet-and-mobile{margin: 85px !important;}
.kt-margin-t-85-tablet-and-mobile{margin-top: 85px !important;}
.kt-margin-b-85-tablet-and-mobile{margin-bottom: 85px !important;}
.kt-margin-l-85-tablet-and-mobile{margin-left: 85px !important;}
.kt-margin-r-85-tablet-and-mobile{margin-right: 85px !important;}
.kt-padding-85-tablet-and-mobile{padding: 85px !important;}
.kt-padding-t-85-tablet-and-mobile{padding-top: 85px !important;}
.kt-padding-b-85-tablet-and-mobile{padding-bottom: 85px !important;}
.kt-padding-l-85-tablet-and-mobile{padding-left: 85px !important;}
.kt-padding-r-85-tablet-and-mobile{padding-right: 85px !important;}
.kt-m85-tablet-and-mobile{margin: 85px !important;}
.kt-mt-85-tablet-and-mobile{margin-top: 85px !important;}
.kt-mb-85-tablet-and-mobile{margin-bottom: 85px !important;}
.kt-ml-85-tablet-and-mobile{margin-left: 85px !important;}
.kt-mr-85-tablet-and-mobile{margin-right: 85px !important;}
.kt-p85-tablet-and-mobile{padding: 85px !important;}
.kt-pt85-tablet-and-mobile{padding-top: 85px !important;}
.kt-pb-85-tablet-and-mobile{padding-bottom: 85px !important;}
.kt-pl85-tablet-and-mobile{padding-left: 85px !important;}
.kt-pr85-tablet-and-mobile{padding-right: 85px !important;}
.kt-margin-90-tablet-and-mobile{margin: 90px !important;}
.kt-margin-t-90-tablet-and-mobile{margin-top: 90px !important;}
.kt-margin-b-90-tablet-and-mobile{margin-bottom: 90px !important;}
.kt-margin-l-90-tablet-and-mobile{margin-left: 90px !important;}
.kt-margin-r-90-tablet-and-mobile{margin-right: 90px !important;}
.kt-padding-90-tablet-and-mobile{padding: 90px !important;}
.kt-padding-t-90-tablet-and-mobile{padding-top: 90px !important;}
.kt-padding-b-90-tablet-and-mobile{padding-bottom: 90px !important;}
.kt-padding-l-90-tablet-and-mobile{padding-left: 90px !important;}
.kt-padding-r-90-tablet-and-mobile{padding-right: 90px !important;}
.kt-m90-tablet-and-mobile{margin: 90px !important;}
.kt-mt-90-tablet-and-mobile{margin-top: 90px !important;}
.kt-mb-90-tablet-and-mobile{margin-bottom: 90px !important;}
.kt-ml-90-tablet-and-mobile{margin-left: 90px !important;}
.kt-mr-90-tablet-and-mobile{margin-right: 90px !important;}
.kt-p90-tablet-and-mobile{padding: 90px !important;}
.kt-pt90-tablet-and-mobile{padding-top: 90px !important;}
.kt-pb-90-tablet-and-mobile{padding-bottom: 90px !important;}
.kt-pl90-tablet-and-mobile{padding-left: 90px !important;}
.kt-pr90-tablet-and-mobile{padding-right: 90px !important;}
.kt-margin-95-tablet-and-mobile{margin: 95px !important;}
.kt-margin-t-95-tablet-and-mobile{margin-top: 95px !important;}
.kt-margin-b-95-tablet-and-mobile{margin-bottom: 95px !important;}
.kt-margin-l-95-tablet-and-mobile{margin-left: 95px !important;}
.kt-margin-r-95-tablet-and-mobile{margin-right: 95px !important;}
.kt-padding-95-tablet-and-mobile{padding: 95px !important;}
.kt-padding-t-95-tablet-and-mobile{padding-top: 95px !important;}
.kt-padding-b-95-tablet-and-mobile{padding-bottom: 95px !important;}
.kt-padding-l-95-tablet-and-mobile{padding-left: 95px !important;}
.kt-padding-r-95-tablet-and-mobile{padding-right: 95px !important;}
.kt-m95-tablet-and-mobile{margin: 95px !important;}
.kt-mt-95-tablet-and-mobile{margin-top: 95px !important;}
.kt-mb-95-tablet-and-mobile{margin-bottom: 95px !important;}
.kt-ml-95-tablet-and-mobile{margin-left: 95px !important;}
.kt-mr-95-tablet-and-mobile{margin-right: 95px !important;}
.kt-p95-tablet-and-mobile{padding: 95px !important;}
.kt-pt95-tablet-and-mobile{padding-top: 95px !important;}
.kt-pb-95-tablet-and-mobile{padding-bottom: 95px !important;}
.kt-pl95-tablet-and-mobile{padding-left: 95px !important;}
.kt-pr95-tablet-and-mobile{padding-right: 95px !important;}
.kt-margin-100-tablet-and-mobile{margin: 100px !important;}
.kt-margin-t-100-tablet-and-mobile{margin-top: 100px !important;}
.kt-margin-b-100-tablet-and-mobile{margin-bottom: 100px !important;}
.kt-margin-l-100-tablet-and-mobile{margin-left: 100px !important;}
.kt-margin-r-100-tablet-and-mobile{margin-right: 100px !important;}
.kt-padding-100-tablet-and-mobile{padding: 100px !important;}
.kt-padding-t-100-tablet-and-mobile{padding-top: 100px !important;}
.kt-padding-b-100-tablet-and-mobile{padding-bottom: 100px !important;}
.kt-padding-l-100-tablet-and-mobile{padding-left: 100px !important;}
.kt-padding-r-100-tablet-and-mobile{padding-right: 100px !important;}
.kt-m100-tablet-and-mobile{margin: 100px !important;}
.kt-mt-100-tablet-and-mobile{margin-top: 100px !important;}
.kt-mb-100-tablet-and-mobile{margin-bottom: 100px !important;}
.kt-ml-100-tablet-and-mobile{margin-left: 100px !important;}
.kt-mr-100-tablet-and-mobile{margin-right: 100px !important;}
.kt-p100-tablet-and-mobile{padding: 100px !important;}
.kt-pt100-tablet-and-mobile{padding-top: 100px !important;}
.kt-pb-100-tablet-and-mobile{padding-bottom: 100px !important;}
.kt-pl100-tablet-and-mobile{padding-left: 100px !important;}
.kt-pr100-tablet-and-mobile{padding-right: 100px !important;}
}
@media (max-width: 768px){
.kt-margin-0-mobile{margin: 0px !important;}
.kt-margin-t-0-mobile{margin-top: 0px !important;}
.kt-margin-b-0-mobile{margin-bottom: 0px !important;}
.kt-margin-l-0-mobile{margin-left: 0px !important;}
.kt-margin-r-0-mobile{margin-right: 0px !important;}
.kt-padding-0-mobile{padding: 0px !important;}
.kt-padding-t-0-mobile{padding-top: 0px !important;}
.kt-padding-b-0-mobile{padding-bottom: 0px !important;}
.kt-padding-l-0-mobile{padding-left: 0px !important;}
.kt-padding-r-0-mobile{padding-right: 0px !important;}
.kt-m0-mobile{margin: 0px !important;}
.kt-mt-0-mobile{margin-top: 0px !important;}
.kt-mb-0-mobile{margin-bottom: 0px !important;}
.kt-ml-0-mobile{margin-left: 0px !important;}
.kt-mr-0-mobile{margin-right: 0px !important;}
.kt-p0-mobile{padding: 0px !important;}
.kt-pt0-mobile{padding-top: 0px !important;}
.kt-pb-0-mobile{padding-bottom: 0px !important;}
.kt-pl0-mobile{padding-left: 0px !important;}
.kt-pr0-mobile{padding-right: 0px !important;}
.kt-margin-5-mobile{margin: 5px !important;}
.kt-margin-t-5-mobile{margin-top: 5px !important;}
.kt-margin-b-5-mobile{margin-bottom: 5px !important;}
.kt-margin-l-5-mobile{margin-left: 5px !important;}
.kt-margin-r-5-mobile{margin-right: 5px !important;}
.kt-padding-5-mobile{padding: 5px !important;}
.kt-padding-t-5-mobile{padding-top: 5px !important;}
.kt-padding-b-5-mobile{padding-bottom: 5px !important;}
.kt-padding-l-5-mobile{padding-left: 5px !important;}
.kt-padding-r-5-mobile{padding-right: 5px !important;}
.kt-m5-mobile{margin: 5px !important;}
.kt-mt-5-mobile{margin-top: 5px !important;}
.kt-mb-5-mobile{margin-bottom: 5px !important;}
.kt-ml-5-mobile{margin-left: 5px !important;}
.kt-mr-5-mobile{margin-right: 5px !important;}
.kt-p5-mobile{padding: 5px !important;}
.kt-pt5-mobile{padding-top: 5px !important;}
.kt-pb-5-mobile{padding-bottom: 5px !important;}
.kt-pl5-mobile{padding-left: 5px !important;}
.kt-pr5-mobile{padding-right: 5px !important;}
.kt-margin-10-mobile{margin: 10px !important;}
.kt-margin-t-10-mobile{margin-top: 10px !important;}
.kt-margin-b-10-mobile{margin-bottom: 10px !important;}
.kt-margin-l-10-mobile{margin-left: 10px !important;}
.kt-margin-r-10-mobile{margin-right: 10px !important;}
.kt-padding-10-mobile{padding: 10px !important;}
.kt-padding-t-10-mobile{padding-top: 10px !important;}
.kt-padding-b-10-mobile{padding-bottom: 10px !important;}
.kt-padding-l-10-mobile{padding-left: 10px !important;}
.kt-padding-r-10-mobile{padding-right: 10px !important;}
.kt-m10-mobile{margin: 10px !important;}
.kt-mt-10-mobile{margin-top: 10px !important;}
.kt-mb-10-mobile{margin-bottom: 10px !important;}
.kt-ml-10-mobile{margin-left: 10px !important;}
.kt-mr-10-mobile{margin-right: 10px !important;}
.kt-p10-mobile{padding: 10px !important;}
.kt-pt10-mobile{padding-top: 10px !important;}
.kt-pb-10-mobile{padding-bottom: 10px !important;}
.kt-pl10-mobile{padding-left: 10px !important;}
.kt-pr10-mobile{padding-right: 10px !important;}
.kt-margin-15-mobile{margin: 15px !important;}
.kt-margin-t-15-mobile{margin-top: 15px !important;}
.kt-margin-b-15-mobile{margin-bottom: 15px !important;}
.kt-margin-l-15-mobile{margin-left: 15px !important;}
.kt-margin-r-15-mobile{margin-right: 15px !important;}
.kt-padding-15-mobile{padding: 15px !important;}
.kt-padding-t-15-mobile{padding-top: 15px !important;}
.kt-padding-b-15-mobile{padding-bottom: 15px !important;}
.kt-padding-l-15-mobile{padding-left: 15px !important;}
.kt-padding-r-15-mobile{padding-right: 15px !important;}
.kt-m15-mobile{margin: 15px !important;}
.kt-mt-15-mobile{margin-top: 15px !important;}
.kt-mb-15-mobile{margin-bottom: 15px !important;}
.kt-ml-15-mobile{margin-left: 15px !important;}
.kt-mr-15-mobile{margin-right: 15px !important;}
.kt-p15-mobile{padding: 15px !important;}
.kt-pt15-mobile{padding-top: 15px !important;}
.kt-pb-15-mobile{padding-bottom: 15px !important;}
.kt-pl15-mobile{padding-left: 15px !important;}
.kt-pr15-mobile{padding-right: 15px !important;}
.kt-margin-20-mobile{margin: 20px !important;}
.kt-margin-t-20-mobile{margin-top: 20px !important;}
.kt-margin-b-20-mobile{margin-bottom: 20px !important;}
.kt-margin-l-20-mobile{margin-left: 20px !important;}
.kt-margin-r-20-mobile{margin-right: 20px !important;}
.kt-padding-20-mobile{padding: 20px !important;}
.kt-padding-t-20-mobile{padding-top: 20px !important;}
.kt-padding-b-20-mobile{padding-bottom: 20px !important;}
.kt-padding-l-20-mobile{padding-left: 20px !important;}
.kt-padding-r-20-mobile{padding-right: 20px !important;}
.kt-m20-mobile{margin: 20px !important;}
.kt-mt-20-mobile{margin-top: 20px !important;}
.kt-mb-20-mobile{margin-bottom: 20px !important;}
.kt-ml-20-mobile{margin-left: 20px !important;}
.kt-mr-20-mobile{margin-right: 20px !important;}
.kt-p20-mobile{padding: 20px !important;}
.kt-pt20-mobile{padding-top: 20px !important;}
.kt-pb-20-mobile{padding-bottom: 20px !important;}
.kt-pl20-mobile{padding-left: 20px !important;}
.kt-pr20-mobile{padding-right: 20px !important;}
.kt-margin-25-mobile{margin: 25px !important;}
.kt-margin-t-25-mobile{margin-top: 25px !important;}
.kt-margin-b-25-mobile{margin-bottom: 25px !important;}
.kt-margin-l-25-mobile{margin-left: 25px !important;}
.kt-margin-r-25-mobile{margin-right: 25px !important;}
.kt-padding-25-mobile{padding: 25px !important;}
.kt-padding-t-25-mobile{padding-top: 25px !important;}
.kt-padding-b-25-mobile{padding-bottom: 25px !important;}
.kt-padding-l-25-mobile{padding-left: 25px !important;}
.kt-padding-r-25-mobile{padding-right: 25px !important;}
.kt-m25-mobile{margin: 25px !important;}
.kt-mt-25-mobile{margin-top: 25px !important;}
.kt-mb-25-mobile{margin-bottom: 25px !important;}
.kt-ml-25-mobile{margin-left: 25px !important;}
.kt-mr-25-mobile{margin-right: 25px !important;}
.kt-p25-mobile{padding: 25px !important;}
.kt-pt25-mobile{padding-top: 25px !important;}
.kt-pb-25-mobile{padding-bottom: 25px !important;}
.kt-pl25-mobile{padding-left: 25px !important;}
.kt-pr25-mobile{padding-right: 25px !important;}
.kt-margin-30-mobile{margin: 30px !important;}
.kt-margin-t-30-mobile{margin-top: 30px !important;}
.kt-margin-b-30-mobile{margin-bottom: 30px !important;}
.kt-margin-l-30-mobile{margin-left: 30px !important;}
.kt-margin-r-30-mobile{margin-right: 30px !important;}
.kt-padding-30-mobile{padding: 30px !important;}
.kt-padding-t-30-mobile{padding-top: 30px !important;}
.kt-padding-b-30-mobile{padding-bottom: 30px !important;}
.kt-padding-l-30-mobile{padding-left: 30px !important;}
.kt-padding-r-30-mobile{padding-right: 30px !important;}
.kt-m30-mobile{margin: 30px !important;}
.kt-mt-30-mobile{margin-top: 30px !important;}
.kt-mb-30-mobile{margin-bottom: 30px !important;}
.kt-ml-30-mobile{margin-left: 30px !important;}
.kt-mr-30-mobile{margin-right: 30px !important;}
.kt-p30-mobile{padding: 30px !important;}
.kt-pt30-mobile{padding-top: 30px !important;}
.kt-pb-30-mobile{padding-bottom: 30px !important;}
.kt-pl30-mobile{padding-left: 30px !important;}
.kt-pr30-mobile{padding-right: 30px !important;}
.kt-margin-35-mobile{margin: 35px !important;}
.kt-margin-t-35-mobile{margin-top: 35px !important;}
.kt-margin-b-35-mobile{margin-bottom: 35px !important;}
.kt-margin-l-35-mobile{margin-left: 35px !important;}
.kt-margin-r-35-mobile{margin-right: 35px !important;}
.kt-padding-35-mobile{padding: 35px !important;}
.kt-padding-t-35-mobile{padding-top: 35px !important;}
.kt-padding-b-35-mobile{padding-bottom: 35px !important;}
.kt-padding-l-35-mobile{padding-left: 35px !important;}
.kt-padding-r-35-mobile{padding-right: 35px !important;}
.kt-m35-mobile{margin: 35px !important;}
.kt-mt-35-mobile{margin-top: 35px !important;}
.kt-mb-35-mobile{margin-bottom: 35px !important;}
.kt-ml-35-mobile{margin-left: 35px !important;}
.kt-mr-35-mobile{margin-right: 35px !important;}
.kt-p35-mobile{padding: 35px !important;}
.kt-pt35-mobile{padding-top: 35px !important;}
.kt-pb-35-mobile{padding-bottom: 35px !important;}
.kt-pl35-mobile{padding-left: 35px !important;}
.kt-pr35-mobile{padding-right: 35px !important;}
.kt-margin-40-mobile{margin: 40px !important;}
.kt-margin-t-40-mobile{margin-top: 40px !important;}
.kt-margin-b-40-mobile{margin-bottom: 40px !important;}
.kt-margin-l-40-mobile{margin-left: 40px !important;}
.kt-margin-r-40-mobile{margin-right: 40px !important;}
.kt-padding-40-mobile{padding: 40px !important;}
.kt-padding-t-40-mobile{padding-top: 40px !important;}
.kt-padding-b-40-mobile{padding-bottom: 40px !important;}
.kt-padding-l-40-mobile{padding-left: 40px !important;}
.kt-padding-r-40-mobile{padding-right: 40px !important;}
.kt-m40-mobile{margin: 40px !important;}
.kt-mt-40-mobile{margin-top: 40px !important;}
.kt-mb-40-mobile{margin-bottom: 40px !important;}
.kt-ml-40-mobile{margin-left: 40px !important;}
.kt-mr-40-mobile{margin-right: 40px !important;}
.kt-p40-mobile{padding: 40px !important;}
.kt-pt40-mobile{padding-top: 40px !important;}
.kt-pb-40-mobile{padding-bottom: 40px !important;}
.kt-pl40-mobile{padding-left: 40px !important;}
.kt-pr40-mobile{padding-right: 40px !important;}
.kt-margin-45-mobile{margin: 45px !important;}
.kt-margin-t-45-mobile{margin-top: 45px !important;}
.kt-margin-b-45-mobile{margin-bottom: 45px !important;}
.kt-margin-l-45-mobile{margin-left: 45px !important;}
.kt-margin-r-45-mobile{margin-right: 45px !important;}
.kt-padding-45-mobile{padding: 45px !important;}
.kt-padding-t-45-mobile{padding-top: 45px !important;}
.kt-padding-b-45-mobile{padding-bottom: 45px !important;}
.kt-padding-l-45-mobile{padding-left: 45px !important;}
.kt-padding-r-45-mobile{padding-right: 45px !important;}
.kt-m45-mobile{margin: 45px !important;}
.kt-mt-45-mobile{margin-top: 45px !important;}
.kt-mb-45-mobile{margin-bottom: 45px !important;}
.kt-ml-45-mobile{margin-left: 45px !important;}
.kt-mr-45-mobile{margin-right: 45px !important;}
.kt-p45-mobile{padding: 45px !important;}
.kt-pt45-mobile{padding-top: 45px !important;}
.kt-pb-45-mobile{padding-bottom: 45px !important;}
.kt-pl45-mobile{padding-left: 45px !important;}
.kt-pr45-mobile{padding-right: 45px !important;}
.kt-margin-50-mobile{margin: 50px !important;}
.kt-margin-t-50-mobile{margin-top: 50px !important;}
.kt-margin-b-50-mobile{margin-bottom: 50px !important;}
.kt-margin-l-50-mobile{margin-left: 50px !important;}
.kt-margin-r-50-mobile{margin-right: 50px !important;}
.kt-padding-50-mobile{padding: 50px !important;}
.kt-padding-t-50-mobile{padding-top: 50px !important;}
.kt-padding-b-50-mobile{padding-bottom: 50px !important;}
.kt-padding-l-50-mobile{padding-left: 50px !important;}
.kt-padding-r-50-mobile{padding-right: 50px !important;}
.kt-m50-mobile{margin: 50px !important;}
.kt-mt-50-mobile{margin-top: 50px !important;}
.kt-mb-50-mobile{margin-bottom: 50px !important;}
.kt-ml-50-mobile{margin-left: 50px !important;}
.kt-mr-50-mobile{margin-right: 50px !important;}
.kt-p50-mobile{padding: 50px !important;}
.kt-pt50-mobile{padding-top: 50px !important;}
.kt-pb-50-mobile{padding-bottom: 50px !important;}
.kt-pl50-mobile{padding-left: 50px !important;}
.kt-pr50-mobile{padding-right: 50px !important;}
.kt-margin-55-mobile{margin: 55px !important;}
.kt-margin-t-55-mobile{margin-top: 55px !important;}
.kt-margin-b-55-mobile{margin-bottom: 55px !important;}
.kt-margin-l-55-mobile{margin-left: 55px !important;}
.kt-margin-r-55-mobile{margin-right: 55px !important;}
.kt-padding-55-mobile{padding: 55px !important;}
.kt-padding-t-55-mobile{padding-top: 55px !important;}
.kt-padding-b-55-mobile{padding-bottom: 55px !important;}
.kt-padding-l-55-mobile{padding-left: 55px !important;}
.kt-padding-r-55-mobile{padding-right: 55px !important;}
.kt-m55-mobile{margin: 55px !important;}
.kt-mt-55-mobile{margin-top: 55px !important;}
.kt-mb-55-mobile{margin-bottom: 55px !important;}
.kt-ml-55-mobile{margin-left: 55px !important;}
.kt-mr-55-mobile{margin-right: 55px !important;}
.kt-p55-mobile{padding: 55px !important;}
.kt-pt55-mobile{padding-top: 55px !important;}
.kt-pb-55-mobile{padding-bottom: 55px !important;}
.kt-pl55-mobile{padding-left: 55px !important;}
.kt-pr55-mobile{padding-right: 55px !important;}
.kt-margin-60-mobile{margin: 60px !important;}
.kt-margin-t-60-mobile{margin-top: 60px !important;}
.kt-margin-b-60-mobile{margin-bottom: 60px !important;}
.kt-margin-l-60-mobile{margin-left: 60px !important;}
.kt-margin-r-60-mobile{margin-right: 60px !important;}
.kt-padding-60-mobile{padding: 60px !important;}
.kt-padding-t-60-mobile{padding-top: 60px !important;}
.kt-padding-b-60-mobile{padding-bottom: 60px !important;}
.kt-padding-l-60-mobile{padding-left: 60px !important;}
.kt-padding-r-60-mobile{padding-right: 60px !important;}
.kt-m60-mobile{margin: 60px !important;}
.kt-mt-60-mobile{margin-top: 60px !important;}
.kt-mb-60-mobile{margin-bottom: 60px !important;}
.kt-ml-60-mobile{margin-left: 60px !important;}
.kt-mr-60-mobile{margin-right: 60px !important;}
.kt-p60-mobile{padding: 60px !important;}
.kt-pt60-mobile{padding-top: 60px !important;}
.kt-pb-60-mobile{padding-bottom: 60px !important;}
.kt-pl60-mobile{padding-left: 60px !important;}
.kt-pr60-mobile{padding-right: 60px !important;}
.kt-margin-65-mobile{margin: 65px !important;}
.kt-margin-t-65-mobile{margin-top: 65px !important;}
.kt-margin-b-65-mobile{margin-bottom: 65px !important;}
.kt-margin-l-65-mobile{margin-left: 65px !important;}
.kt-margin-r-65-mobile{margin-right: 65px !important;}
.kt-padding-65-mobile{padding: 65px !important;}
.kt-padding-t-65-mobile{padding-top: 65px !important;}
.kt-padding-b-65-mobile{padding-bottom: 65px !important;}
.kt-padding-l-65-mobile{padding-left: 65px !important;}
.kt-padding-r-65-mobile{padding-right: 65px !important;}
.kt-m65-mobile{margin: 65px !important;}
.kt-mt-65-mobile{margin-top: 65px !important;}
.kt-mb-65-mobile{margin-bottom: 65px !important;}
.kt-ml-65-mobile{margin-left: 65px !important;}
.kt-mr-65-mobile{margin-right: 65px !important;}
.kt-p65-mobile{padding: 65px !important;}
.kt-pt65-mobile{padding-top: 65px !important;}
.kt-pb-65-mobile{padding-bottom: 65px !important;}
.kt-pl65-mobile{padding-left: 65px !important;}
.kt-pr65-mobile{padding-right: 65px !important;}
.kt-margin-70-mobile{margin: 70px !important;}
.kt-margin-t-70-mobile{margin-top: 70px !important;}
.kt-margin-b-70-mobile{margin-bottom: 70px !important;}
.kt-margin-l-70-mobile{margin-left: 70px !important;}
.kt-margin-r-70-mobile{margin-right: 70px !important;}
.kt-padding-70-mobile{padding: 70px !important;}
.kt-padding-t-70-mobile{padding-top: 70px !important;}
.kt-padding-b-70-mobile{padding-bottom: 70px !important;}
.kt-padding-l-70-mobile{padding-left: 70px !important;}
.kt-padding-r-70-mobile{padding-right: 70px !important;}
.kt-m70-mobile{margin: 70px !important;}
.kt-mt-70-mobile{margin-top: 70px !important;}
.kt-mb-70-mobile{margin-bottom: 70px !important;}
.kt-ml-70-mobile{margin-left: 70px !important;}
.kt-mr-70-mobile{margin-right: 70px !important;}
.kt-p70-mobile{padding: 70px !important;}
.kt-pt70-mobile{padding-top: 70px !important;}
.kt-pb-70-mobile{padding-bottom: 70px !important;}
.kt-pl70-mobile{padding-left: 70px !important;}
.kt-pr70-mobile{padding-right: 70px !important;}
.kt-margin-75-mobile{margin: 75px !important;}
.kt-margin-t-75-mobile{margin-top: 75px !important;}
.kt-margin-b-75-mobile{margin-bottom: 75px !important;}
.kt-margin-l-75-mobile{margin-left: 75px !important;}
.kt-margin-r-75-mobile{margin-right: 75px !important;}
.kt-padding-75-mobile{padding: 75px !important;}
.kt-padding-t-75-mobile{padding-top: 75px !important;}
.kt-padding-b-75-mobile{padding-bottom: 75px !important;}
.kt-padding-l-75-mobile{padding-left: 75px !important;}
.kt-padding-r-75-mobile{padding-right: 75px !important;}
.kt-m75-mobile{margin: 75px !important;}
.kt-mt-75-mobile{margin-top: 75px !important;}
.kt-mb-75-mobile{margin-bottom: 75px !important;}
.kt-ml-75-mobile{margin-left: 75px !important;}
.kt-mr-75-mobile{margin-right: 75px !important;}
.kt-p75-mobile{padding: 75px !important;}
.kt-pt75-mobile{padding-top: 75px !important;}
.kt-pb-75-mobile{padding-bottom: 75px !important;}
.kt-pl75-mobile{padding-left: 75px !important;}
.kt-pr75-mobile{padding-right: 75px !important;}
.kt-margin-80-mobile{margin: 80px !important;}
.kt-margin-t-80-mobile{margin-top: 80px !important;}
.kt-margin-b-80-mobile{margin-bottom: 80px !important;}
.kt-margin-l-80-mobile{margin-left: 80px !important;}
.kt-margin-r-80-mobile{margin-right: 80px !important;}
.kt-padding-80-mobile{padding: 80px !important;}
.kt-padding-t-80-mobile{padding-top: 80px !important;}
.kt-padding-b-80-mobile{padding-bottom: 80px !important;}
.kt-padding-l-80-mobile{padding-left: 80px !important;}
.kt-padding-r-80-mobile{padding-right: 80px !important;}
.kt-m80-mobile{margin: 80px !important;}
.kt-mt-80-mobile{margin-top: 80px !important;}
.kt-mb-80-mobile{margin-bottom: 80px !important;}
.kt-ml-80-mobile{margin-left: 80px !important;}
.kt-mr-80-mobile{margin-right: 80px !important;}
.kt-p80-mobile{padding: 80px !important;}
.kt-pt80-mobile{padding-top: 80px !important;}
.kt-pb-80-mobile{padding-bottom: 80px !important;}
.kt-pl80-mobile{padding-left: 80px !important;}
.kt-pr80-mobile{padding-right: 80px !important;}
.kt-margin-85-mobile{margin: 85px !important;}
.kt-margin-t-85-mobile{margin-top: 85px !important;}
.kt-margin-b-85-mobile{margin-bottom: 85px !important;}
.kt-margin-l-85-mobile{margin-left: 85px !important;}
.kt-margin-r-85-mobile{margin-right: 85px !important;}
.kt-padding-85-mobile{padding: 85px !important;}
.kt-padding-t-85-mobile{padding-top: 85px !important;}
.kt-padding-b-85-mobile{padding-bottom: 85px !important;}
.kt-padding-l-85-mobile{padding-left: 85px !important;}
.kt-padding-r-85-mobile{padding-right: 85px !important;}
.kt-m85-mobile{margin: 85px !important;}
.kt-mt-85-mobile{margin-top: 85px !important;}
.kt-mb-85-mobile{margin-bottom: 85px !important;}
.kt-ml-85-mobile{margin-left: 85px !important;}
.kt-mr-85-mobile{margin-right: 85px !important;}
.kt-p85-mobile{padding: 85px !important;}
.kt-pt85-mobile{padding-top: 85px !important;}
.kt-pb-85-mobile{padding-bottom: 85px !important;}
.kt-pl85-mobile{padding-left: 85px !important;}
.kt-pr85-mobile{padding-right: 85px !important;}
.kt-margin-90-mobile{margin: 90px !important;}
.kt-margin-t-90-mobile{margin-top: 90px !important;}
.kt-margin-b-90-mobile{margin-bottom: 90px !important;}
.kt-margin-l-90-mobile{margin-left: 90px !important;}
.kt-margin-r-90-mobile{margin-right: 90px !important;}
.kt-padding-90-mobile{padding: 90px !important;}
.kt-padding-t-90-mobile{padding-top: 90px !important;}
.kt-padding-b-90-mobile{padding-bottom: 90px !important;}
.kt-padding-l-90-mobile{padding-left: 90px !important;}
.kt-padding-r-90-mobile{padding-right: 90px !important;}
.kt-m90-mobile{margin: 90px !important;}
.kt-mt-90-mobile{margin-top: 90px !important;}
.kt-mb-90-mobile{margin-bottom: 90px !important;}
.kt-ml-90-mobile{margin-left: 90px !important;}
.kt-mr-90-mobile{margin-right: 90px !important;}
.kt-p90-mobile{padding: 90px !important;}
.kt-pt90-mobile{padding-top: 90px !important;}
.kt-pb-90-mobile{padding-bottom: 90px !important;}
.kt-pl90-mobile{padding-left: 90px !important;}
.kt-pr90-mobile{padding-right: 90px !important;}
.kt-margin-95-mobile{margin: 95px !important;}
.kt-margin-t-95-mobile{margin-top: 95px !important;}
.kt-margin-b-95-mobile{margin-bottom: 95px !important;}
.kt-margin-l-95-mobile{margin-left: 95px !important;}
.kt-margin-r-95-mobile{margin-right: 95px !important;}
.kt-padding-95-mobile{padding: 95px !important;}
.kt-padding-t-95-mobile{padding-top: 95px !important;}
.kt-padding-b-95-mobile{padding-bottom: 95px !important;}
.kt-padding-l-95-mobile{padding-left: 95px !important;}
.kt-padding-r-95-mobile{padding-right: 95px !important;}
.kt-m95-mobile{margin: 95px !important;}
.kt-mt-95-mobile{margin-top: 95px !important;}
.kt-mb-95-mobile{margin-bottom: 95px !important;}
.kt-ml-95-mobile{margin-left: 95px !important;}
.kt-mr-95-mobile{margin-right: 95px !important;}
.kt-p95-mobile{padding: 95px !important;}
.kt-pt95-mobile{padding-top: 95px !important;}
.kt-pb-95-mobile{padding-bottom: 95px !important;}
.kt-pl95-mobile{padding-left: 95px !important;}
.kt-pr95-mobile{padding-right: 95px !important;}
.kt-margin-100-mobile{margin: 100px !important;}
.kt-margin-t-100-mobile{margin-top: 100px !important;}
.kt-margin-b-100-mobile{margin-bottom: 100px !important;}
.kt-margin-l-100-mobile{margin-left: 100px !important;}
.kt-margin-r-100-mobile{margin-right: 100px !important;}
.kt-padding-100-mobile{padding: 100px !important;}
.kt-padding-t-100-mobile{padding-top: 100px !important;}
.kt-padding-b-100-mobile{padding-bottom: 100px !important;}
.kt-padding-l-100-mobile{padding-left: 100px !important;}
.kt-padding-r-100-mobile{padding-right: 100px !important;}
.kt-m100-mobile{margin: 100px !important;}
.kt-mt-100-mobile{margin-top: 100px !important;}
.kt-mb-100-mobile{margin-bottom: 100px !important;}
.kt-ml-100-mobile{margin-left: 100px !important;}
.kt-mr-100-mobile{margin-right: 100px !important;}
.kt-p100-mobile{padding: 100px !important;}
.kt-pt100-mobile{padding-top: 100px !important;}
.kt-pb-100-mobile{padding-bottom: 100px !important;}
.kt-pl100-mobile{padding-left: 100px !important;}
.kt-pr100-mobile{padding-right: 100px !important;}
}
.kt-opacity-1{opacity: 0.1;}
.kt-opacity-2{opacity: 0.2;}
.kt-opacity-3{opacity: 0.3;}
.kt-opacity-4{opacity: 0.4;}
.kt-opacity-5{opacity: 0.5;}
.kt-opacity-6{opacity: 0.6;}
.kt-opacity-7{opacity: 0.7;}
.kt-opacity-8{opacity: 0.8;}
.kt-opacity-9{opacity: 0.9;}
.kt-radius-100{border-radius: 100%;}
.kt-valign-top{vertical-align: top;}
.kt-space-5{display: block;height: 0;margin-bottom: 5px;}
.kt-space-10{display: block;height: 0;margin-bottom: 10px;}
.kt-space-15{display: block;height: 0;margin-bottom: 15px;}
.kt-space-20{display: block;height: 0;margin-bottom: 20px;}
.kt-space-30{display: block;height: 0;margin-bottom: 30px;}
.kt-space-40{display: block;height: 0;margin-bottom: 40px;}
.kt-valign-middle{vertical-align: middle;}
.kt-valign-bottom{vertical-align: bottom;}
.kt-block-center{margin-left: auto;margin-right: auto;}
.kt-align-right{text-align: right;}
.kt-align-left{text-align: left;}
.kt-align-center{text-align: center;}
.kt-pull-right{float: right;}
.kt-pull-left{float: left;}
.kt-img-rounded{border-radius: 50% !important;}
.kt-block-inline{display: inline-block;}
.kt-icon-middle:before{vertical-align: middle;}
.kt-hidden, .kt-hide{display: none !important;}
.kt-font-sm{font-size: 0.8rem !important;}
.kt-font-md{font-size: 0.9rem !important;}
.kt-font-lg{font-size: 1.2rem !important;}
.kt-font-xl{font-size: 1.5rem !important;}
.kt-clearfix:before, .kt-clearfix:after{content: " ";display: table;}
.kt-clearfix:after{clear: both;}
.kt-icon-sm{font-size: 0.75rem !important;}
.kt-icon-md{font-size: 0.9rem !important;}
.kt-icon-lg{font-size: 1.2rem !important;}
.kt-icon-xl{font-size: 1.4rem !important;}
.kt-marginless{margin: 0 !important;}
.kt-img-centered{text-align: center;}
.kt-font-transform-u{text-transform: uppercase;}
.kt-font-transform-l{text-transform: lowercase;}
.kt-font-transform-c{text-transform: capitalize;}
.kt-font-transform-i{text-transform: initial;}
.kt-font-regular{font-weight: 400 !important;}
.kt-font-bold{font-weight: 500 !important;}
.kt-font-bolder{font-weight: 600 !important;}
.kt-font-boldest{font-weight: 700 !important;}
b, strong{font-weight: bold;}
.kt-font-brand{color: #5d78ff !important;}
.kt-font-hover-brand:hover{color: #0029f6;}
.kt-font-inverse-brand{color: #ffffff !important;}
.kt-bg-brand{background-color: #5d78ff !important;}
.kt-bg-fill-brand{background-color: #5d78ff !important;color: #ffffff !important;}
.kt-bg-fill-brand > i{color: #ffffff !important;}
.kt-bg-light-brand{background-color: rgba(93, 120, 255, 0.1) !important;}
.kt-font-light{color: #ffffff !important;}
.kt-font-hover-light:hover{color: #cccccc;}
.kt-font-inverse-light{color: #282a3c !important;}
.kt-bg-light{background-color: #ffffff !important;}
.kt-bg-fill-light{background-color: #ffffff !important;color: #282a3c !important;}
.kt-bg-fill-light > i{color: #282a3c !important;}
.kt-bg-light-light{background-color: rgba(255, 255, 255, 0.1) !important;}
.kt-font-dark{color: #282a3c !important;}
.kt-font-hover-dark:hover{color: black;}
.kt-font-inverse-dark{color: #ffffff !important;}
.kt-bg-dark{background-color: #282a3c !important;}
.kt-bg-fill-dark{background-color: #282a3c !important;color: #ffffff !important;}
.kt-bg-fill-dark > i{color: #ffffff !important;}
.kt-bg-light-dark{background-color: rgba(40, 42, 60, 0.1) !important;}
.kt-font-primary{color: #5867dd !important;}
.kt-font-hover-primary:hover{color: #2332ac;}
.kt-font-inverse-primary{color: #ffffff !important;}
.kt-bg-primary{background-color: #5867dd !important;}
.kt-bg-fill-primary{background-color: #5867dd !important;color: #ffffff !important;}
.kt-bg-fill-primary > i{color: #ffffff !important;}
.kt-bg-light-primary{background-color: rgba(88, 103, 221, 0.1) !important;}
.kt-font-success{color: #0abb87 !important;}
.kt-font-hover-success:hover{color: #055a41;}
.kt-font-inverse-success{color: #ffffff !important;}
.kt-bg-success{background-color: #0abb87 !important;}
.kt-bg-fill-success{background-color: #0abb87 !important;color: #ffffff !important;}
.kt-bg-fill-success > i{color: #ffffff !important;}
.kt-bg-light-success{background-color: rgba(10, 187, 135, 0.1) !important;}
.kt-font-info{color: #5578eb !important;}
.kt-font-hover-info:hover{color: #173fc3;}
.kt-font-inverse-info{color: #ffffff !important;}
.kt-bg-info{background-color: #5578eb !important;}
.kt-bg-fill-info{background-color: #5578eb !important;color: #ffffff !important;}
.kt-bg-fill-info > i{color: #ffffff !important;}
.kt-bg-light-info{background-color: rgba(85, 120, 235, 0.1) !important;}
.kt-font-warning{color: #ffb822 !important;}
.kt-font-hover-warning:hover{color: #bb7f00;}
.kt-font-inverse-warning{color: #111111 !important;}
.kt-bg-warning{background-color: #ffb822 !important;}
.kt-bg-fill-warning{background-color: #ffb822 !important;color: #111111 !important;}
.kt-bg-fill-warning > i{color: #111111 !important;}
.kt-bg-light-warning{background-color: rgba(255, 184, 34, 0.1) !important;}
.kt-font-danger{color: #fd397a !important;}
.kt-font-hover-danger:hover{color: #ce0246;}
.kt-font-inverse-danger{color: #ffffff !important;}
.kt-bg-danger{background-color: #fd397a !important;}
.kt-bg-fill-danger{background-color: #fd397a !important;color: #ffffff !important;}
.kt-bg-fill-danger > i{color: #ffffff !important;}
.kt-bg-light-danger{background-color: rgba(253, 57, 122, 0.1) !important;}
.kt-label-font-color-1{color: #adb1c7 !important;}
.kt-label-bg-color-1{background-color: #adb1c7 !important;}
.kt-label-font-color-2{color: #a7abc3 !important;}
.kt-label-bg-color-2{background-color: #a7abc3 !important;}
.kt-label-font-color-3{color: #6c7293 !important;}
.kt-label-bg-color-3{background-color: #6c7293 !important;}
.kt-label-font-color-4{color: #464457 !important;}
.kt-label-bg-color-4{background-color: #464457 !important;}
.kt-shape-font-color-1{color: #f0f3ff !important;}
.kt-shape-bg-color-1{background-color: #f0f3ff !important;}
.kt-shape-font-color-2{color: #e8ecfa !important;}
.kt-shape-bg-color-2{background-color: #e8ecfa !important;}
.kt-shape-font-color-3{color: #93a2dd !important;}
.kt-shape-bg-color-3{background-color: #93a2dd !important;}
.kt-shape-font-color-4{color: #646c9a !important;}
.kt-shape-bg-color-4{background-color: #646c9a !important;}
.kt-visible-desktop, .kt-visible-desktop-inline-block, .kt-visible-desktop-inline, .kt-visible-desktop-table, .kt-visible-desktop-table-cell, .kt-visible-tablet, .kt-visible-tablet-inline-block, .kt-visible-tablet-inline, .kt-visible-tablet-table, .kt-visible-tablet-table-cell, .kt-visible-tablet-and-mobile, .kt-visible-tablet-and-mobile-inline-block, .kt-visible-tablet-and-mobile-inline, .kt-visible-tablet-and-mobile-table, .kt-visible-tablet-and-mobile-table-cell, .kt-visible-mobile, .kt-visible-mobile-inline-block, .kt-visible-mobile-inline, .kt-visible-mobile-table, .kt-visible-mobile-table-cell{display: none !important;}
@media (min-width: 1025px){
.kt-visible-desktop{display: block !important;}
.kt-visible-desktop-inline-block{display: inline-block !important;}
.kt-visible-desktop-inline{display: inline !important;}
.kt-visible-desktop-table{display: table !important;}
.kt-visible-desktop-table-cell{display: table-cell !important;}
.kt-hidden-desktop{display: none !important;}
}
@media (min-width: 769px) and (max-width: 1024px){
.kt-visible-tablet{display: block !important;}
.kt-visible-tablet-inline-block{display: inline-block !important;}
.kt-visible-tablet-inline{display: inline !important;}
.kt-visible-tablet-table{display: table !important;}
.kt-visible-tablet-table-cell{display: table-cell !important;}
.kt-hidden-tablet{display: none !important;}
}
@media (max-width: 1024px){
.kt-visible-tablet-and-mobile{display: block !important;}
.kt-visible-tablet-and-mobile-inline-block{display: inline-block !important;}
.kt-visible-tablet-and-mobile-inline{display: inline !important;}
.kt-visible-tablet-and-mobile-table{display: table !important;}
.kt-visible-tablet-and-mobile-table-cell{display: table-cell !important;}
.kt-hidden-tablet-and-mobile{display: none !important;}
}
@media (max-width: 768px){
.kt-visible-mobile{display: block !important;}
.kt-visible-mobile-inline-block{display: inline-block !important;}
.kt-visible-mobile-inline{display: inline !important;}
.kt-visible-mobile-table{display: table !important;}
.kt-visible-mobile-table-cell{display: table-cell !important;}
.kt-hidden-mobile{display: none !important;}
}
.kt-space-between{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.kt-rounded{border-radius: 4px !important;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile){
display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: none;flex: none;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid{-webkit-box-flex: 1;-ms-flex: 1 0 auto;flex: 1 0 auto;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--hor:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile){
display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile).kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid{-webkit-box-flex: 1;flex: 1 auto;-ms-flex: 1 0 0px;min-width: 0;}
@media screen\0{.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--fluid{min-width: none;}
}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--ver:not(.kt-grid--desktop):not(.kt-grid--desktop-and-tablet):not(.kt-grid--tablet):not(.kt-grid--tablet-and-mobile):not(.kt-grid--mobile) > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--center{-ms-flex-line-pack: center;align-content: center;}
.kt-grid.kt-grid--stretch{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-grid > .kt-grid__item{max-width: 100%;}
.kt-grid > .kt-grid__item.kt-grid__item--top{-ms-flex-item-align: start;align-self: flex-start;}
.kt-grid > .kt-grid__item.kt-grid__item--middle{-ms-flex-item-align: center;align-self: center;}
.kt-grid > .kt-grid__item.kt-grid__item--bottom{-ms-flex-item-align: end;align-self: flex-end;}
.kt-grid > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
@media (min-width: 1025px){
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: none;flex: none;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid-desktop{-webkit-box-flex: 1;-ms-flex: 1 0 auto;flex: 1 0 auto;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-1-desktop{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-2-desktop{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-3-desktop{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-4-desktop{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-5-desktop{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-6-desktop{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-7-desktop{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-8-desktop{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-9-desktop{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-10-desktop{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-11-desktop{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--hor-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-12-desktop{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid-desktop{-webkit-box-flex: 1;flex: 1 auto;-ms-flex: 1 0 0px;min-width: 0;}
}
@media screen\0  and (min-width: 1025px){
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--fluid-desktop{min-width: none;}
}
@media (min-width: 1025px){
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-1-desktop{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-2-desktop{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-3-desktop{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-4-desktop{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-5-desktop{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-6-desktop{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-7-desktop{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-8-desktop{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-9-desktop{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-10-desktop{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-11-desktop{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--ver-desktop.kt-grid--desktop > .kt-grid__item.kt-grid__item--order-12-desktop{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--center{-ms-flex-line-pack: center;align-content: center;}
.kt-grid.kt-grid--stretch{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-grid > .kt-grid__item{max-width: 100%;}
.kt-grid > .kt-grid__item.kt-grid__item--top{-ms-flex-item-align: start;align-self: flex-start;}
.kt-grid > .kt-grid__item.kt-grid__item--middle{-ms-flex-item-align: center;align-self: center;}
.kt-grid > .kt-grid__item.kt-grid__item--bottom{-ms-flex-item-align: end;align-self: flex-end;}
.kt-grid > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
}
@media (min-width: 1400px){
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: none;flex: none;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid-desktop-xl{-webkit-box-flex: 1;-ms-flex: 1 0 auto;flex: 1 0 auto;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-1-desktop-xl{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-2-desktop-xl{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-3-desktop-xl{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-4-desktop-xl{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-5-desktop-xl{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-6-desktop-xl{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-7-desktop-xl{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-8-desktop-xl{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-9-desktop-xl{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-10-desktop-xl{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-11-desktop-xl{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--hor-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-12-desktop-xl{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid-desktop-xl{-webkit-box-flex: 1;flex: 1 auto;-ms-flex: 1 0 0px;min-width: 0;}
}
@media screen\0  and (min-width: 1400px){
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--fluid-desktop-xl{min-width: none;}
}
@media (min-width: 1400px){
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-1-desktop-xl{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-2-desktop-xl{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-3-desktop-xl{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-4-desktop-xl{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-5-desktop-xl{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-6-desktop-xl{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-7-desktop-xl{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-8-desktop-xl{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-9-desktop-xl{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-10-desktop-xl{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-11-desktop-xl{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--ver-desktop-xl.kt-grid--desktop-xl > .kt-grid__item.kt-grid__item--order-12-desktop-xl{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--center{-ms-flex-line-pack: center;align-content: center;}
.kt-grid.kt-grid--stretch{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-grid > .kt-grid__item{max-width: 100%;}
.kt-grid > .kt-grid__item.kt-grid__item--top{-ms-flex-item-align: start;align-self: flex-start;}
.kt-grid > .kt-grid__item.kt-grid__item--middle{-ms-flex-item-align: center;align-self: center;}
.kt-grid > .kt-grid__item.kt-grid__item--bottom{-ms-flex-item-align: end;align-self: flex-end;}
.kt-grid > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
}
@media (min-width: 769px){
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: none;flex: none;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid-desktop-and-tablet{-webkit-box-flex: 1;-ms-flex: 1 0 auto;flex: 1 0 auto;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-1-desktop-and-tablet{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-2-desktop-and-tablet{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-3-desktop-and-tablet{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-4-desktop-and-tablet{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-5-desktop-and-tablet{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-6-desktop-and-tablet{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-7-desktop-and-tablet{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-8-desktop-and-tablet{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-9-desktop-and-tablet{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-10-desktop-and-tablet{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-11-desktop-and-tablet{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--hor-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-12-desktop-and-tablet{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid-desktop-and-tablet{-webkit-box-flex: 1;flex: 1 auto;-ms-flex: 1 0 0px;min-width: 0;}
}
@media screen\0  and (min-width: 769px){
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--fluid-desktop-and-tablet{min-width: none;}
}
@media (min-width: 769px){
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-1-desktop-and-tablet{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-2-desktop-and-tablet{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-3-desktop-and-tablet{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-4-desktop-and-tablet{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-5-desktop-and-tablet{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-6-desktop-and-tablet{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-7-desktop-and-tablet{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-8-desktop-and-tablet{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-9-desktop-and-tablet{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-10-desktop-and-tablet{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-11-desktop-and-tablet{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--ver-desktop-and-tablet.kt-grid--desktop-and-tablet > .kt-grid__item.kt-grid__item--order-12-desktop-and-tablet{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--center{-ms-flex-line-pack: center;align-content: center;}
.kt-grid.kt-grid--stretch{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-grid > .kt-grid__item{max-width: 100%;}
.kt-grid > .kt-grid__item.kt-grid__item--top{-ms-flex-item-align: start;align-self: flex-start;}
.kt-grid > .kt-grid__item.kt-grid__item--middle{-ms-flex-item-align: center;align-self: center;}
.kt-grid > .kt-grid__item.kt-grid__item--bottom{-ms-flex-item-align: end;align-self: flex-end;}
.kt-grid > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
}
@media (min-width: 769px) and (max-width: 1024px){
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: none;flex: none;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid-tablet{-webkit-box-flex: 1;-ms-flex: 1 0 auto;flex: 1 0 auto;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-1-tablet{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-2-tablet{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-3-tablet{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-4-tablet{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-5-tablet{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-6-tablet{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-7-tablet{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-8-tablet{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-9-tablet{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-10-tablet{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-11-tablet{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--hor-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-12-tablet{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid-tablet{-webkit-box-flex: 1;flex: 1 auto;-ms-flex: 1 0 0px;min-width: 0;}
}
@media screen\0  and (min-width: 769px) and (max-width: 1024px){
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--fluid-tablet{min-width: none;}
}
@media (min-width: 769px) and (max-width: 1024px){
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-1-tablet{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-2-tablet{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-3-tablet{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-4-tablet{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-5-tablet{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-6-tablet{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-7-tablet{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-8-tablet{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-9-tablet{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-10-tablet{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-11-tablet{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--ver-tablet.kt-grid--tablet > .kt-grid__item.kt-grid__item--order-12-tablet{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--center{-ms-flex-line-pack: center;align-content: center;}
.kt-grid.kt-grid--stretch{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-grid > .kt-grid__item{max-width: 100%;}
.kt-grid > .kt-grid__item.kt-grid__item--top{-ms-flex-item-align: start;align-self: flex-start;}
.kt-grid > .kt-grid__item.kt-grid__item--middle{-ms-flex-item-align: center;align-self: center;}
.kt-grid > .kt-grid__item.kt-grid__item--bottom{-ms-flex-item-align: end;align-self: flex-end;}
.kt-grid > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
}
@media (max-width: 1024px){
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: none;flex: none;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid-tablet-and-mobile{-webkit-box-flex: 1;-ms-flex: 1 0 auto;flex: 1 0 auto;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-1-tablet-and-mobile{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-2-tablet-and-mobile{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-3-tablet-and-mobile{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-4-tablet-and-mobile{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-5-tablet-and-mobile{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-6-tablet-and-mobile{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-7-tablet-and-mobile{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-8-tablet-and-mobile{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-9-tablet-and-mobile{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-10-tablet-and-mobile{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-11-tablet-and-mobile{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--hor-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-12-tablet-and-mobile{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid-tablet-and-mobile{-webkit-box-flex: 1;flex: 1 auto;-ms-flex: 1 0 0px;min-width: 0;}
}
@media screen\0  and (max-width: 1024px){
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--fluid-tablet-and-mobile{min-width: none;}
}
@media (max-width: 1024px){
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-1-tablet-and-mobile{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-2-tablet-and-mobile{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-3-tablet-and-mobile{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-4-tablet-and-mobile{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-5-tablet-and-mobile{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-6-tablet-and-mobile{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-7-tablet-and-mobile{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-8-tablet-and-mobile{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-9-tablet-and-mobile{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-10-tablet-and-mobile{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-11-tablet-and-mobile{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--ver-tablet-and-mobile.kt-grid--tablet-and-mobile > .kt-grid__item.kt-grid__item--order-12-tablet-and-mobile{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--center{-ms-flex-line-pack: center;align-content: center;}
.kt-grid.kt-grid--stretch{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-grid > .kt-grid__item{max-width: 100%;}
.kt-grid > .kt-grid__item.kt-grid__item--top{-ms-flex-item-align: start;align-self: flex-start;}
.kt-grid > .kt-grid__item.kt-grid__item--middle{-ms-flex-item-align: center;align-self: center;}
.kt-grid > .kt-grid__item.kt-grid__item--bottom{-ms-flex-item-align: end;align-self: flex-end;}
.kt-grid > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
}
@media (max-width: 768px){
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: none;flex: none;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid-mobile{-webkit-box-flex: 1;-ms-flex: 1 0 auto;flex: 1 0 auto;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-1-mobile{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-2-mobile{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-3-mobile{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-4-mobile{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-5-mobile{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-6-mobile{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-7-mobile{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-8-mobile{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-9-mobile{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-10-mobile{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-11-mobile{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--hor-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-12-mobile{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile.kt-grid--root{-webkit-box-flex: 1;flex: 1;-ms-flex: 1 0 0px;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item{-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid-mobile{-webkit-box-flex: 1;flex: 1 auto;-ms-flex: 1 0 0px;min-width: 0;}
}
@media screen\0  and (max-width: 768px){
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid, .kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--fluid-mobile{min-width: none;}
}
@media (max-width: 768px){
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-1-mobile{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-2-mobile{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-3-mobile{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-4-mobile{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-5-mobile{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-6-mobile{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-7-mobile{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-8-mobile{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-9-mobile{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-10-mobile{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-11-mobile{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid.kt-grid--ver-mobile.kt-grid--mobile > .kt-grid__item.kt-grid__item--order-12-mobile{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
.kt-grid.kt-grid--center{-ms-flex-line-pack: center;align-content: center;}
.kt-grid.kt-grid--stretch{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-grid > .kt-grid__item{max-width: 100%;}
.kt-grid > .kt-grid__item.kt-grid__item--top{-ms-flex-item-align: start;align-self: flex-start;}
.kt-grid > .kt-grid__item.kt-grid__item--middle{-ms-flex-item-align: center;align-self: center;}
.kt-grid > .kt-grid__item.kt-grid__item--bottom{-ms-flex-item-align: end;align-self: flex-end;}
.kt-grid > .kt-grid__item.kt-grid__item--order-1{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.kt-grid > .kt-grid__item.kt-grid__item--order-2{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-grid > .kt-grid__item.kt-grid__item--order-3{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
.kt-grid > .kt-grid__item.kt-grid__item--order-4{-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;}
.kt-grid > .kt-grid__item.kt-grid__item--order-5{-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;}
.kt-grid > .kt-grid__item.kt-grid__item--order-6{-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;}
.kt-grid > .kt-grid__item.kt-grid__item--order-7{-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;}
.kt-grid > .kt-grid__item.kt-grid__item--order-8{-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;}
.kt-grid > .kt-grid__item.kt-grid__item--order-9{-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}
.kt-grid > .kt-grid__item.kt-grid__item--order-10{-webkit-box-ordinal-group: 11;-ms-flex-order: 10;order: 10;}
.kt-grid > .kt-grid__item.kt-grid__item--order-11{-webkit-box-ordinal-group: 12;-ms-flex-order: 11;order: 11;}
.kt-grid > .kt-grid__item.kt-grid__item--order-12{-webkit-box-ordinal-group: 13;-ms-flex-order: 12;order: 12;}
}
.kt-portlet{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);background-color: #ffffff;margin-bottom: 20px;border-radius: 4px;}
.kt-page-content-white .kt-portlet{-webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);}
.kt-portlet .kt-portlet__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;position: relative;padding: 0 25px;border-bottom: 1px solid #ebedf2;min-height: 60px;border-top-left-radius: 4px;border-top-right-radius: 4px;}
.kt-portlet .kt-portlet__head.kt-portlet__head--noborder{border-bottom: 0;}
.kt-portlet .kt-portlet__head .kt-portlet__head-label{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-ms-flex-line-pack: flex-first;align-content: flex-first;}
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title{margin: 0;padding: 0;font-size: 1.2rem;font-weight: 500;color: #464457;}
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title small{font-weight: 300;padding-left: 0.5rem;font-size: 1rem;color: #a7abc3;}
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-icon{padding-right: 0.75rem;font-size: 1.3rem;color: #a7abc3;}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-ms-flex-line-pack: end;align-content: flex-end;}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .nav-pills, .kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .nav-tabs{margin: 0;}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .kt-portlet__head-wrapper{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-portlet .kt-portlet__head .kt-portlet__head-progress{position: absolute;left: 0;right: 0;}
.kt-portlet.kt-portlet--head-sm .kt-portlet__head{min-height: 50px;}
.kt-portlet.kt-portlet--head-lg .kt-portlet__head{min-height: 80px;}
.kt-portlet.kt-portlet--head-xl .kt-portlet__head{min-height: 100px;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head{height: 50px;min-height: 50px;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--sm{height: 40px;min-height: 40px;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--lg{height: 70px;min-height: 70px;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--xl{height: 90px;min-height: 90px;}
.kt-portlet.kt-portlet--head-overlay .kt-portlet__head{position: relative;z-index: 1;border: 0;height: 60px;}
.kt-portlet.kt-portlet--head-overlay .kt-portlet__body{margin-top: -60px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-sm .kt-portlet__head{height: 50px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-sm .kt-portlet__body{margin-top: -50px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__head{height: 80px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__body{margin-top: -80px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__head{height: 100px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__body{margin-top: -100px;}
.kt-portlet .kt-portlet__body{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;padding: 25px;border-radius: 4px;}
.kt-portlet .kt-portlet__body .kt-portlet__content{padding: 0;margin: 0;}
.kt-portlet .kt-portlet__body > .kt-datatable > .kt-datatable__table{border-radius: 4px;}
.kt-portlet .kt-portlet__foot{padding: 25px;border-top: 1px solid #ebedf2;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
.kt-portlet .kt-portlet__foot .kt-portlet__foot-wrapper{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--sm{padding-top: 10px;padding-bottom: 10px;}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--md{padding-top: 1rem;padding-bottom: 1rem;}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--no-border{border-top: 0;}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--top{border-top: 0;border-bottom: 1px solid #ebedf2;}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--solid{background-color: #f7f8fa;border-top: 0;}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--solid.kt-portlet__foot--top{border-bottom: 0;}
.kt-portlet.kt-portlet--bordered{-webkit-box-shadow: none;box-shadow: none;border: 1px solid #ebedf2;}
.kt-portlet.kt-portlet--unelevate{-webkit-box-shadow: none;box-shadow: none;}
.kt-portlet.kt-portlet--unround .kt-portlet__head{border-top-left-radius: 0;border-top-right-radius: 0;}
.kt-portlet.kt-portlet--unround .kt-portlet__body{border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
.kt-portlet.kt-portlet--unround .kt-portlet__foot{border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
.kt-portlet.kt-portlet--last{margin-bottom: 0;}
.kt-portlet .kt-portlet__body.kt-portlet__body--center{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-portlet .kt-portlet__body.kt-portlet__body--center-x{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-portlet .kt-portlet__body.kt-portlet__body--center-y{-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-portlet .kt-portlet__body .kt-portlet__body--hor-fit{margin-left: -25px;margin-right: -25px;}
.kt-portlet .kt-portlet__body.kt-portlet__body--stick-bottom{position: relative;bottom: 0;}
.kt-portlet .kt-portlet__body.kt-portlet__body--fluid{height: 100%;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;width: 100%;}
.kt-portlet .kt-portlet__body.kt-portlet__body--fill{-webkit-box-flex: 1 !important;-ms-flex-positive: 1 !important;flex-grow: 1 !important;}
.kt-portlet .kt-portlet__body.kt-portlet__body--unfill{-webkit-box-flex: 0 !important;-ms-flex-positive: 0 !important;flex-grow: 0 !important;}
.kt-portlet .kt-portlet__body.kt-portlet__body--fullheight{-webkit-box-flex: auto;-ms-flex-positive: auto;flex-grow: auto;}
.kt-portlet.kt-portlet--fit .kt-portlet__head{padding: 0;}
.kt-portlet.kt-portlet--fit .kt-portlet__body{padding: 0;}
.kt-portlet.kt-portlet--fit .kt-portlet__foot{padding: 0;}
.kt-portlet.kt-portlet--fit.kt-portlet--height-fluid-half{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-portlet.kt-portlet--fit.kt-portlet--height-fluid-half .kt-portlet__body{height: 100%;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-portlet.kt-portlet--contain{overflow: hidden;}
.kt-portlet .kt-portlet__head.kt-portlet__head--fit{padding: 0;}
.kt-portlet .kt-portlet__body.kt-portlet__body--fit{padding: 0;}
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-top{padding-top: 0 !important;}
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-bottom{padding-bottom: 0 !important;}
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-x, .kt-portlet .kt-portlet__body.kt-portlet__body--hor-fit{padding-left: 0;padding-right: 0;}
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-y{padding-top: 0;padding-bottom: 0;}
.kt-portlet .kt-portlet__foot.kt-portlet__foot--fit{padding: 0;}
.kt-portlet.kt-portlet--space{padding-left: 25px;padding-right: 25px;}
.kt-portlet.kt-portlet--space .kt-portlet__head, .kt-portlet.kt-portlet--space .kt-portlet__body, .kt-portlet.kt-portlet--space .kt-portlet__foot{padding-left: 0;padding-right: 0;}
.kt-portlet.kt-portlet--head-noborder .kt-portlet__head{border-bottom: 0;}
.kt-portlet.kt-portlet--head-noborder .kt-portlet__body{padding-top: 12.5px;}
.kt-portlet.kt-portlet--tabs .kt-portlet__head .kt-portlet__head-toolbar{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-portlet.kt-portlet--tabs-border-3x .kt-portlet__head{border-bottom: 3px solid #f7f8fa;}
.kt-portlet.kt-portlet--tabs-border-3x .kt-portlet__head .nav.nav-tabs{margin-bottom: -3px;}
.kt-portlet.kt-portlet--solid-brand{background: #5d78ff;}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head{color: #ffffff;border-bottom: 1px solid transparent;}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-title{color: #ffffff;}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-title small{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-icon i{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__body{padding-top: 5px;}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__wrapper, .kt-portlet.kt-portlet--solid-brand .kt-portlet__body{color: #ffffff;}
.kt-portlet.kt-portlet--solid-brand .kt-portlet__foot{color: #ffffff;border-top: 1px solid transparent;background: #3e5fff;}
.kt-portlet.kt-portlet--tabs-border-3x-brand .kt-portlet__head{border-bottom: 3px solid rgba(93, 120, 255, 0.1);}
.kt-portlet.kt-portlet--border-bottom-brand{border-bottom: 3px solid rgba(93, 120, 255, 0.2);}
.kt-portlet.kt-portlet--solid-light{background: #ffffff;}
.kt-portlet.kt-portlet--solid-light .kt-portlet__head{color: #282a3c;border-bottom: 1px solid transparent;}
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-title{color: #282a3c;}
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-title small{color: #1c1d2a;}
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-icon i{color: #1c1d2a;}
.kt-portlet.kt-portlet--solid-light .kt-portlet__body{padding-top: 5px;}
.kt-portlet.kt-portlet--solid-light .kt-portlet__wrapper, .kt-portlet.kt-portlet--solid-light .kt-portlet__body{color: #282a3c;}
.kt-portlet.kt-portlet--solid-light .kt-portlet__foot{color: #282a3c;border-top: 1px solid transparent;background: #f0f0f0;}
.kt-portlet.kt-portlet--tabs-border-3x-light .kt-portlet__head{border-bottom: 3px solid rgba(255, 255, 255, 0.1);}
.kt-portlet.kt-portlet--border-bottom-light{border-bottom: 3px solid rgba(255, 255, 255, 0.2);}
.kt-portlet.kt-portlet--solid-dark{background: #282a3c;}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head{color: #ffffff;border-bottom: 1px solid transparent;}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-title{color: #ffffff;}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-title small{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-icon i{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__body{padding-top: 5px;}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__wrapper, .kt-portlet.kt-portlet--solid-dark .kt-portlet__body{color: #ffffff;}
.kt-portlet.kt-portlet--solid-dark .kt-portlet__foot{color: #ffffff;border-top: 1px solid transparent;background: #1c1d2a;}
.kt-portlet.kt-portlet--tabs-border-3x-dark .kt-portlet__head{border-bottom: 3px solid rgba(40, 42, 60, 0.1);}
.kt-portlet.kt-portlet--border-bottom-dark{border-bottom: 3px solid rgba(40, 42, 60, 0.2);}
.kt-portlet.kt-portlet--solid-primary{background: #5867dd;}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head{color: #ffffff;border-bottom: 1px solid transparent;}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-title{color: #ffffff;}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-title small{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-icon i{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__body{padding-top: 5px;}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__wrapper, .kt-portlet.kt-portlet--solid-primary .kt-portlet__body{color: #ffffff;}
.kt-portlet.kt-portlet--solid-primary .kt-portlet__foot{color: #ffffff;border-top: 1px solid transparent;background: #3f50d8;}
.kt-portlet.kt-portlet--tabs-border-3x-primary .kt-portlet__head{border-bottom: 3px solid rgba(88, 103, 221, 0.1);}
.kt-portlet.kt-portlet--border-bottom-primary{border-bottom: 3px solid rgba(88, 103, 221, 0.2);}
.kt-portlet.kt-portlet--solid-success{background: #0abb87;}
.kt-portlet.kt-portlet--solid-success .kt-portlet__head{color: #ffffff;border-bottom: 1px solid transparent;}
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-title{color: #ffffff;}
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-title small{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-icon i{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-success .kt-portlet__body{padding-top: 5px;}
.kt-portlet.kt-portlet--solid-success .kt-portlet__wrapper, .kt-portlet.kt-portlet--solid-success .kt-portlet__body{color: #ffffff;}
.kt-portlet.kt-portlet--solid-success .kt-portlet__foot{color: #ffffff;border-top: 1px solid transparent;background: #089e72;}
.kt-portlet.kt-portlet--tabs-border-3x-success .kt-portlet__head{border-bottom: 3px solid rgba(10, 187, 135, 0.1);}
.kt-portlet.kt-portlet--border-bottom-success{border-bottom: 3px solid rgba(10, 187, 135, 0.2);}
.kt-portlet.kt-portlet--solid-info{background: #5578eb;}
.kt-portlet.kt-portlet--solid-info .kt-portlet__head{color: #ffffff;border-bottom: 1px solid transparent;}
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-title{color: #ffffff;}
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-title small{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-icon i{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-info .kt-portlet__body{padding-top: 5px;}
.kt-portlet.kt-portlet--solid-info .kt-portlet__wrapper, .kt-portlet.kt-portlet--solid-info .kt-portlet__body{color: #ffffff;}
.kt-portlet.kt-portlet--solid-info .kt-portlet__foot{color: #ffffff;border-top: 1px solid transparent;background: #3a62e8;}
.kt-portlet.kt-portlet--tabs-border-3x-info .kt-portlet__head{border-bottom: 3px solid rgba(85, 120, 235, 0.1);}
.kt-portlet.kt-portlet--border-bottom-info{border-bottom: 3px solid rgba(85, 120, 235, 0.2);}
.kt-portlet.kt-portlet--solid-warning{background: #ffb822;}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head{color: #111111;border-bottom: 1px solid transparent;}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-title{color: #111111;}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-title small{color: #020202;}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-icon i{color: #020202;}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__body{padding-top: 5px;}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__wrapper, .kt-portlet.kt-portlet--solid-warning .kt-portlet__body{color: #111111;}
.kt-portlet.kt-portlet--solid-warning .kt-portlet__foot{color: #111111;border-top: 1px solid transparent;background: #ffae03;}
.kt-portlet.kt-portlet--tabs-border-3x-warning .kt-portlet__head{border-bottom: 3px solid rgba(255, 184, 34, 0.1);}
.kt-portlet.kt-portlet--border-bottom-warning{border-bottom: 3px solid rgba(255, 184, 34, 0.2);}
.kt-portlet.kt-portlet--solid-danger{background: #fd397a;}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head{color: #ffffff;border-bottom: 1px solid transparent;}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-title{color: #ffffff;}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-title small{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-icon i{color: #f0f0f0;}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__body{padding-top: 5px;}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__wrapper, .kt-portlet.kt-portlet--solid-danger .kt-portlet__body{color: #ffffff;}
.kt-portlet.kt-portlet--solid-danger .kt-portlet__foot{color: #ffffff;border-top: 1px solid transparent;background: #fd1b66;}
.kt-portlet.kt-portlet--tabs-border-3x-danger .kt-portlet__head{border-bottom: 3px solid rgba(253, 57, 122, 0.1);}
.kt-portlet.kt-portlet--border-bottom-danger{border-bottom: 3px solid rgba(253, 57, 122, 0.2);}
.kt-portlet.kt-portlet--sortable .kt-portlet__head{cursor: move;}
.kt-portlet.kt-portlet--sortable-empty{visibility: hidden;height: 45px;min-height: 125px;}
.kt-portlet.ui-sortable-helper{border: 1px dashed #ebedf2;}
.kt-portlet .kt-portlet__head{-webkit-transition: left 0.3s, right 0.3s, height 0.3s;transition: left 0.3s, right 0.3s, height 0.3s;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head{-webkit-transition: left 0.3s, right 0.3s, height 0.3s;transition: left 0.3s, right 0.3s, height 0.3s;position: fixed;-webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);z-index: 101;background: #fff;}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title{color: #fff;}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title small{color: rgba(255, 255, 255, 0.8);}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-icon{color: rgba(255, 255, 255, 0.8);}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head:not(.kt-portlet__head--noborder){
border-bottom: 1px solid rgba(255, 255, 255, 0.2);}
.kt-portlet.kt-portlet--skin-solid .kt-portlet__body{color: #fff;}
.kt-portlet .kt-portlet__space-x{padding-left: 25px !important;padding-right: 25px !important;}
.kt-portlet .kt-portlet__space-y{padding-top: 25px !important;padding-bottom: 25px !important;}
.kt-portlet.kt-portlet--collapsed > .kt-form, .kt-portlet.kt-portlet--collapsed > .kt-portlet__body{display: none;}
.kt-portlet.kt-portlet--collapsed .kt-portlet__head .kt-portlet__head-toolbar .la-angle-down:before, .kt-portlet.kt-portlet--collapse .kt-portlet__head .kt-portlet__head-toolbar .la-angle-down:before{content: "\f113";}
.kt-portlet.kt-portlet--collapsed .kt-portlet__head .kt-portlet__head-toolbar .la-plus:before, .kt-portlet.kt-portlet--collapse .kt-portlet__head .kt-portlet__head-toolbar .la-plus:before{content: "\f28e";}
.tooltip-portlet{opacity: 1;}
@media (min-width: 1025px){
.kt-portlet.kt-portlet--height-fluid{height: calc(100% - 20px);}
.kt-portlet.kt-portlet--height-fluid .kt-portlet__body{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-portlet.kt-portlet--height-fluid-half{height: calc(50% - 20px);}
.kt-portlet.kt-portlet--height-fluid-half .kt-portlet__body{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
}
@media (max-width: 1024px){
.kt-portlet{margin-bottom: 20px;}
.kt-portlet .kt-portlet__head{padding: 0 15px;min-height: 50px;}
.kt-portlet .kt-portlet__head.kt-portlet__head--sm{min-height: 40px;}
.kt-portlet .kt-portlet__head.kt-portlet__head--lg{min-height: 60px;}
.kt-portlet .kt-portlet__head.kt-portlet__head--xl{min-height: 80px;}
.kt-portlet.kt-portlet--head-sm .kt-portlet__head{min-height: 40px;}
.kt-portlet.kt-portlet--head-lg .kt-portlet__head{min-height: 60px;}
.kt-portlet.kt-portlet--head-xl .kt-portlet__head{min-height: 80px;}
.kt-portlet .kt-portlet__body{padding: 15px;}
.kt-portlet .kt-portlet__body .kt-portlet__body--hor-fit{margin-left: -15px;margin-right: -15px;}
.kt-portlet .kt-portlet__foot{padding: 15px;}
.kt-portlet.kt-portlet--marginless{margin-bottom: 0;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head{height: 40px;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--sm{height: 30px;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--lg{height: 50px;}
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--xl{height: 70px;}
.kt-portlet.kt-portlet--head-overlay .kt-portlet__head{height: 50px;}
.kt-portlet.kt-portlet--head-overlay .kt-portlet__body{margin-top: -50px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-sm .kt-portlet__head{height: 40px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-sm .kt-portlet__body{margin-top: -40px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__head{height: 60px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__body{margin-top: -60px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__head{height: 80px;}
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__body{margin-top: -80px;}
.kt-portlet.kt-portlet--head--noborder .kt-portlet__body{padding-top: 7.5px;}
.kt-portlet .kt-portlet__space-x{padding-left: 15px !important;padding-right: 15px !important;}
.kt-portlet .kt-portlet__space-y{padding-top: 15px !important;padding-bottom: 15px !important;}
}
@media (max-width: 768px){
.kt-portlet.kt-portlet--head-break-sm .kt-portlet__head{padding-top: 15px;padding-bottom: 15px;min-height: initial !important;height: auto;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.kt-portlet.kt-portlet--head-break-sm .kt-portlet__head .kt-portlet__head-label{margin-bottom: 1rem;}
}

.kt-scrolltop{display: none;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 40px;height: 40px;position: fixed;bottom: 40px;right: 20px;cursor: pointer;z-index: 90;background: #5d78ff;-webkit-box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);opacity: 0;-webkit-transition: all 0.3s;transition: all 0.3s;border-radius: 4px;}
.kt-scrolltop svg{height: 20px;width: 20px;}
.kt-scrolltop > i{font-size: 1.3rem;color: #ffffff;}
.kt-scrolltop g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #ffffff;}
.kt-scrolltop:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-scrolltop:hover > i{color: #ffffff;}
.kt-scrolltop--on .kt-scrolltop{opacity: 0.3;-webkit-animation: kt-scrolltop-show-animation .4s ease-out 1;animation: kt-scrolltop-show-animation .4s ease-out 1;display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-scrolltop--on .kt-scrolltop:hover{-webkit-transition: all 0.3s;transition: all 0.3s;opacity: 1;}
@media (max-width: 1024px){
.kt-scrolltop{bottom: 30px;right: 15px;}
}
@-webkit-keyframes kt-scrolltop-show-animation{
from{margin-bottom: -15px;}
to{margin-bottom: 0;}
}
@keyframes kt-scrolltop-show-animation{
from{margin-bottom: -15px;}
to{margin-bottom: 0;}
}
.kt-form .kt-form__group.kt-form__group--inline{display: table;padding: 0;}
.kt-form .kt-form__group.kt-form__group--inline > .kt-form__label{display: table-cell;vertical-align: middle;margin: 0;padding-right: 1rem;}
.kt-form .kt-form__group.kt-form__group--inline > .kt-form__label.kt-form__label-no-wrap{white-space: nowrap;}
.kt-form .kt-form__group.kt-form__group--inline > .kt-form__label > label{margin: 0;}
.kt-form .kt-form__group.kt-form__group--inline > .kt-form__control{display: table-cell;vertical-align: middle;margin: 0;width: 100%;}
.kt-form .kt-form__actions.kt-form__actions--right{text-align: right;}
.kt-form .kt-form__actions.kt-form__actions--center{text-align: center;}
@media (min-width: 1025px){
.kt-form.kt-form--label-right .form-group label:not(.kt-checkbox):not(.kt-radio):not(.kt-option){
text-align: right;}
}
.kt-input-icon{position: relative;padding: 0;width: 100%;}
.kt-input-icon > .kt-input-icon__icon{position: absolute;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;top: 0;width: 3.2rem;}
.kt-input-icon > .kt-input-icon__icon.kt-input-icon__icon--left{left: 0;}
.kt-input-icon > .kt-input-icon__icon.kt-input-icon__icon--right{right: 0;}
.kt-input-icon > .kt-input-icon__icon > span{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;height: 100%;}
.kt-input-icon > .kt-input-icon__icon > span i{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;line-height: 0;color: #a7abc3;}
.kt-input-icon > .kt-input-icon__icon [class^="la-"], .kt-input-icon > .kt-input-icon__icon [class*=" la-"]{font-size: 1.4em;}
.kt-input-icon > .kt-input-icon__icon [class^="fa-"], .kt-input-icon > .kt-input-icon__icon [class*=" fa-"]{font-size: 1.2em;}
.kt-input-icon > .kt-input-icon__icon [class^="flaticon-"], .kt-input-icon > .kt-input-icon__icon [class*=" flaticon-"], .kt-input-icon > .kt-input-icon__icon [class^="flaticon2-"], .kt-input-icon > .kt-input-icon__icon [class*=" flaticon2-"]{font-size: 1.3em;}
.kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon{width: 4.2rem;}
.kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class^="la-"], .kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class*=" la-"]{font-size: 1.6em;}
.kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class^="fa-"], .kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class*=" fa-"]{font-size: 1.3em;}
.kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class^="flaticon-"], .kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class*=" flaticon-"], .kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class^="flaticon2-"], .kt-input-icon .form-control.form-control-lg + .kt-input-icon__icon [class*=" flaticon2-"]{font-size: 1.4em;}
.kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon{width: 2.7rem;}
.kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class^="la-"], .kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class*=" la-"]{font-size: 1.1em;}
.kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class^="fa-"], .kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class*=" fa-"]{font-size: 1em;}
.kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class^="flaticon-"], .kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class*=" flaticon-"], .kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class^="flaticon2-"], .kt-input-icon .form-control.form-control-sm ~ .kt-input-icon__icon [class*=" flaticon2-"]{font-size: 1em;}
.kt-input-icon.kt-input-icon--left .form-control{padding-left: 2.8rem;}
.kt-input-icon.kt-input-icon--left .form-control.form-control-lg{padding-left: 3.6rem;}
.kt-input-icon.kt-input-icon--left .form-control.form-control-sm{padding-left: 2.5rem;}
.kt-input-icon.kt-input-icon--right .form-control{padding-right: 2.8rem;}
.kt-input-icon.kt-input-icon--right .form-control.form-control-lg{padding-right: 3.6rem;}
.kt-input-icon.kt-input-icon--right .form-control.form-control-sm{padding-right: 2.5rem;}
.kt-input-icon.kt-input-icon--fixed{display: inline-block;}
.kt-input-icon.kt-input-icon--fixed-large{display: inline-block;}
.kt-input-icon.kt-input-icon--fixed-small{display: inline-block;}
.kt-checkbox{display: inline-block;position: relative;padding-left: 30px;margin-bottom: 10px;text-align: left;cursor: pointer;font-size: 1rem;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-checkbox.kt-checkbox--disabled{opacity: 0.8;cursor: not-allowed;}
.kt-checkbox > input{position: absolute;z-index: -1;opacity: 0;}
.kt-checkbox > span{border-radius: 3px;background: none;position: absolute;top: 1px;left: 0;height: 18px;width: 18px;}
.kt-checkbox > span:after{content: '';position: absolute;display: none;top: 50%;left: 50%;margin-left: -2px;margin-top: -6px;width: 5px;height: 10px;border-width: 0 2px 2px 0/*rtl:ignore*/ !important;-webkit-transform: rotate(45deg)/*rtl:ignore*/;transform: rotate(45deg)/*rtl:ignore*/;}
.kt-checkbox > input:checked ~ span{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;background: none;}
.kt-checkbox > input:checked ~ span:after{display: block;}
.kt-checkbox:hover > input:not([disabled]):checked ~ span, .kt-checkbox > input:checked ~ span{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-checkbox > input:disabled ~ span{opacity: 0.6;pointer-events: none;}
.kt-checkbox.kt-checkbox--solid > span{border: 1px solid transparent;}
.kt-checkbox.kt-checkbox--solid:hover > input:not([disabled]) ~ span, .kt-checkbox.kt-checkbox--solid > input:focus ~ span{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-checkbox.kt-checkbox--square > span{border-radius: 0;}
.kt-checkbox.kt-checkbox--bold > span{border-width: 2px !important;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.form-inline .kt-checkbox{margin-left: 15px;margin-right: 15px;}
.kt-checkbox.kt-checkbox--single{width: 18px;height: 18px;}
.kt-checkbox.kt-checkbox--single > span{top: 0px;}
th > .kt-checkbox.kt-checkbox--single, td > .kt-checkbox.kt-checkbox--single{right: -5px;}
.input-group .kt-checkbox{margin-bottom: 0 !important;padding-left: 0;}
.kt-checkbox-list{padding: 0 0;}
.form-horizontal .form-group .kt-checkbox-list{padding-top: 0;}
.kt-checkbox-list .kt-checkbox{text-align: left;display: block;}
.kt-checkbox-list .kt-checkbox:last-child{margin-bottom: 5px;}
.kt-checkbox-inline{padding: 0 0;}
.kt-checkbox-inline .kt-checkbox{display: inline-block;margin-right: 15px;margin-bottom: 5px;}
.kt-checkbox-inline .kt-checkbox:last-child{margin-right: 0;}
.form-group.row .kt-checkbox-inline{margin-top: 0.75rem;}
.form-group.row .kt-checkbox-list{margin-top: 2px;}
.kt-checkbox.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox > span{border: 1px solid #d1d7e2;}
.kt-checkbox > span:after{border: solid #bfc7d7;}
.kt-checkbox > input:disabled ~ span:after{border-color: #c8cfdd;}
.kt-checkbox > input:checked ~ span{border: 1px solid #c8cfdd;}
.kt-checkbox.kt-checkbox--bold > input:checked ~ span{border: 2px solid #c8cfdd;}
.kt-checkbox > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--solid > span{background: #e4e8ee;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--solid > span:after{border: solid #99a6bf;}
.kt-checkbox.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--solid > input:checked ~ span{background: #dee2ea;}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox.kt-checkbox--brand > span{border: 1px solid #5d78ff;}
.kt-checkbox.kt-checkbox--brand > span:after{border: solid #5d78ff;}
.kt-checkbox.kt-checkbox--brand > input:disabled ~ span:after{border-color: #5d78ff;}
.kt-checkbox.kt-checkbox--brand > input:checked ~ span{border: 1px solid #5d78ff;}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--bold > input:checked ~ span{border: 2px solid #5d78ff;}
.kt-checkbox.kt-checkbox--brand > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--solid > span{background: #5d78ff;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--solid > span:after{border: solid #ffffff;}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--brand.kt-checkbox--solid > input:checked ~ span{background: #5d78ff;}
.kt-checkbox.kt-checkbox--light.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox.kt-checkbox--light > span{border: 1px solid #ffffff;}
.kt-checkbox.kt-checkbox--light > span:after{border: solid #ffffff;}
.kt-checkbox.kt-checkbox--light > input:disabled ~ span:after{border-color: #ffffff;}
.kt-checkbox.kt-checkbox--light > input:checked ~ span{border: 1px solid #ffffff;}
.kt-checkbox.kt-checkbox--light.kt-checkbox--bold > input:checked ~ span{border: 2px solid #ffffff;}
.kt-checkbox.kt-checkbox--light > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--light.kt-checkbox--solid > span{background: #ffffff;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--light.kt-checkbox--solid > span:after{border: solid #282a3c;}
.kt-checkbox.kt-checkbox--light.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--light.kt-checkbox--solid > input:checked ~ span{background: #ffffff;}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox.kt-checkbox--dark > span{border: 1px solid #282a3c;}
.kt-checkbox.kt-checkbox--dark > span:after{border: solid #282a3c;}
.kt-checkbox.kt-checkbox--dark > input:disabled ~ span:after{border-color: #282a3c;}
.kt-checkbox.kt-checkbox--dark > input:checked ~ span{border: 1px solid #282a3c;}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--bold > input:checked ~ span{border: 2px solid #282a3c;}
.kt-checkbox.kt-checkbox--dark > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--solid > span{background: #282a3c;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--solid > span:after{border: solid #ffffff;}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--dark.kt-checkbox--solid > input:checked ~ span{background: #282a3c;}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox.kt-checkbox--primary > span{border: 1px solid #5867dd;}
.kt-checkbox.kt-checkbox--primary > span:after{border: solid #5867dd;}
.kt-checkbox.kt-checkbox--primary > input:disabled ~ span:after{border-color: #5867dd;}
.kt-checkbox.kt-checkbox--primary > input:checked ~ span{border: 1px solid #5867dd;}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--bold > input:checked ~ span{border: 2px solid #5867dd;}
.kt-checkbox.kt-checkbox--primary > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--solid > span{background: #5867dd;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--solid > span:after{border: solid #ffffff;}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--primary.kt-checkbox--solid > input:checked ~ span{background: #5867dd;}
.kt-checkbox.kt-checkbox--success.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox.kt-checkbox--success > span{border: 1px solid #0abb87;}
.kt-checkbox.kt-checkbox--success > span:after{border: solid #0abb87;}
.kt-checkbox.kt-checkbox--success > input:disabled ~ span:after{border-color: #0abb87;}
.kt-checkbox.kt-checkbox--success > input:checked ~ span{border: 1px solid #0abb87;}
.kt-checkbox.kt-checkbox--success.kt-checkbox--bold > input:checked ~ span{border: 2px solid #0abb87;}
.kt-checkbox.kt-checkbox--success > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--success.kt-checkbox--solid > span{background: #0abb87;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--success.kt-checkbox--solid > span:after{border: solid #ffffff;}
.kt-checkbox.kt-checkbox--success.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--success.kt-checkbox--solid > input:checked ~ span{background: #0abb87;}
.kt-checkbox.kt-checkbox--info.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox.kt-checkbox--info > span{border: 1px solid #5578eb;}
.kt-checkbox.kt-checkbox--info > span:after{border: solid #5578eb;}
.kt-checkbox.kt-checkbox--info > input:disabled ~ span:after{border-color: #5578eb;}
.kt-checkbox.kt-checkbox--info > input:checked ~ span{border: 1px solid #5578eb;}
.kt-checkbox.kt-checkbox--info.kt-checkbox--bold > input:checked ~ span{border: 2px solid #5578eb;}
.kt-checkbox.kt-checkbox--info > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--info.kt-checkbox--solid > span{background: #5578eb;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--info.kt-checkbox--solid > span:after{border: solid #ffffff;}
.kt-checkbox.kt-checkbox--info.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--info.kt-checkbox--solid > input:checked ~ span{background: #5578eb;}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox.kt-checkbox--warning > span{border: 1px solid #ffb822;}
.kt-checkbox.kt-checkbox--warning > span:after{border: solid #ffb822;}
.kt-checkbox.kt-checkbox--warning > input:disabled ~ span:after{border-color: #ffb822;}
.kt-checkbox.kt-checkbox--warning > input:checked ~ span{border: 1px solid #ffb822;}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--bold > input:checked ~ span{border: 2px solid #ffb822;}
.kt-checkbox.kt-checkbox--warning > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--solid > span{background: #ffb822;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--solid > span:after{border: solid #111111;}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--warning.kt-checkbox--solid > input:checked ~ span{background: #ffb822;}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--disabled{opacity: 0.7;}
.kt-checkbox.kt-checkbox--danger > span{border: 1px solid #fd397a;}
.kt-checkbox.kt-checkbox--danger > span:after{border: solid #fd397a;}
.kt-checkbox.kt-checkbox--danger > input:disabled ~ span:after{border-color: #fd397a;}
.kt-checkbox.kt-checkbox--danger > input:checked ~ span{border: 1px solid #fd397a;}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--bold > input:checked ~ span{border: 2px solid #fd397a;}
.kt-checkbox.kt-checkbox--danger > input:disabled ~ span{opacity: 0.6;}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--solid > span{background: #fd397a;border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--solid > span:after{border: solid #ffffff;}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-checkbox.kt-checkbox--danger.kt-checkbox--solid > input:checked ~ span{background: #fd397a;}
.kt-radio{display: inline-block;position: relative;padding-left: 30px;text-align: left;margin-bottom: 10px;cursor: pointer;font-size: 1rem;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-radio.kt-radio--disabled{opacity: 0.8;cursor: not-allowed;}
.kt-radio > input{position: absolute;z-index: -1;opacity: 0;}
.kt-radio > span{background: none;position: absolute;top: 1px;left: 0;height: 18px;width: 18px;border-radius: 50% !important;}
.kt-radio > span:after{content: '';position: absolute;display: none;top: 50%;left: 50%;margin-left: -3px;margin-top: -3px;width: 6px;height: 6px;border-radius: 100% !important;}
.kt-radio > input:checked ~ span{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;background: none;}
.kt-radio > input:checked ~ span:after{display: block;}
.kt-radio:hover > input:not([disabled]):checked ~ span, .kt-radio > input:checked ~ span{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-radio > input:disabled ~ span{opacity: 0.6;pointer-events: none;}
.kt-radio.kt-radio--solid > span{border: 1px solid transparent;}
.kt-radio.kt-radio--solid:hover > input:not([disabled]) ~ span, .kt-radio.kt-radio--solid > input:focus ~ span{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-radio.kt-radio--square > span{border-radius: 0;}
.kt-radio.kt-radio--bold > span{border-width: 2px !important;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.form-inline .kt-radio{margin-left: 15px;margin-right: 15px;}
.kt-radio.kt-radio--single{width: 18px;height: 18px;}
.kt-radio.kt-radio--single > span{top: 0px;}
th > .kt-radio.kt-radio--single, td > .kt-radio.kt-radio--single{right: -5px;}
.input-group .kt-radio{margin-bottom: 0 !important;padding-left: 0;}
.kt-radio-list{padding: 0 0;}
.form-horizontal .form-group .kt-radio-list{padding-top: 0;}
.kt-radio-list .kt-radio{display: block;text-align: left;}
.kt-radio-list .kt-radio:last-child{margin-bottom: 5px;}
.kt-radio-inline{padding: 0 0;}
.kt-radio-inline .kt-radio{display: inline-block;margin-right: 15px;margin-bottom: 5px;}
.kt-radio-inline .kt-radio:last-child{margin-right: 0;}
.form-group.row .kt-radio-inline{margin-top: 0.75rem;}
.form-group.row .kt-radio-list{margin-top: 2px;}
.kt-radio.kt-radio--disabled{opacity: 0.8;}
.kt-radio > span{border: 1px solid #d1d7e2;}
.kt-radio > span:after{border: solid #bfc7d7;background: #bfc7d7;}
.kt-radio > input:disabled ~ span:after{border-color: #c8cfdd;}
.kt-radio > input:checked ~ span{border: 1px solid #c8cfdd;}
.kt-radio.kt-radio--bold > input:checked ~ span{border: 2px solid #c8cfdd;}
.kt-radio > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--solid > span{background: #e4e8ee;border: 1px solid transparent !important;}
.kt-radio.kt-radio--solid > span:after{border: solid #99a6bf;background: #99a6bf;}
.kt-radio.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--solid > input:checked ~ span{background: #dee2ea;}
.kt-radio.kt-radio--brand.kt-radio--disabled{opacity: 0.8;}
.kt-radio.kt-radio--brand > span{border: 1px solid #5d78ff;}
.kt-radio.kt-radio--brand > span:after{border: solid #5d78ff;background: #5d78ff;}
.kt-radio.kt-radio--brand > input:disabled ~ span:after{border-color: #5d78ff;}
.kt-radio.kt-radio--brand > input:checked ~ span{border: 1px solid #5d78ff;}
.kt-radio.kt-radio--brand.kt-radio--bold > input:checked ~ span{border: 2px solid #5d78ff;}
.kt-radio.kt-radio--brand > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--brand.kt-radio--solid > span{background: #5d78ff;border: 1px solid transparent !important;}
.kt-radio.kt-radio--brand.kt-radio--solid > span:after{border: solid #ffffff;background: #ffffff;}
.kt-radio.kt-radio--brand.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--brand.kt-radio--solid > input:checked ~ span{background: #5d78ff;}
.kt-radio.kt-radio--light.kt-radio--disabled{opacity: 0.8;}
.kt-radio.kt-radio--light > span{border: 1px solid #ffffff;}
.kt-radio.kt-radio--light > span:after{border: solid #ffffff;background: #ffffff;}
.kt-radio.kt-radio--light > input:disabled ~ span:after{border-color: #ffffff;}
.kt-radio.kt-radio--light > input:checked ~ span{border: 1px solid #ffffff;}
.kt-radio.kt-radio--light.kt-radio--bold > input:checked ~ span{border: 2px solid #ffffff;}
.kt-radio.kt-radio--light > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--light.kt-radio--solid > span{background: #ffffff;border: 1px solid transparent !important;}
.kt-radio.kt-radio--light.kt-radio--solid > span:after{border: solid #282a3c;background: #282a3c;}
.kt-radio.kt-radio--light.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--light.kt-radio--solid > input:checked ~ span{background: #ffffff;}
.kt-radio.kt-radio--dark.kt-radio--disabled{opacity: 0.8;}
.kt-radio.kt-radio--dark > span{border: 1px solid #282a3c;}
.kt-radio.kt-radio--dark > span:after{border: solid #282a3c;background: #282a3c;}
.kt-radio.kt-radio--dark > input:disabled ~ span:after{border-color: #282a3c;}
.kt-radio.kt-radio--dark > input:checked ~ span{border: 1px solid #282a3c;}
.kt-radio.kt-radio--dark.kt-radio--bold > input:checked ~ span{border: 2px solid #282a3c;}
.kt-radio.kt-radio--dark > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--dark.kt-radio--solid > span{background: #282a3c;border: 1px solid transparent !important;}
.kt-radio.kt-radio--dark.kt-radio--solid > span:after{border: solid #ffffff;background: #ffffff;}
.kt-radio.kt-radio--dark.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--dark.kt-radio--solid > input:checked ~ span{background: #282a3c;}
.kt-radio.kt-radio--primary.kt-radio--disabled{opacity: 0.8;}
.kt-radio.kt-radio--primary > span{border: 1px solid #5867dd;}
.kt-radio.kt-radio--primary > span:after{border: solid #5867dd;background: #5867dd;}
.kt-radio.kt-radio--primary > input:disabled ~ span:after{border-color: #5867dd;}
.kt-radio.kt-radio--primary > input:checked ~ span{border: 1px solid #5867dd;}
.kt-radio.kt-radio--primary.kt-radio--bold > input:checked ~ span{border: 2px solid #5867dd;}
.kt-radio.kt-radio--primary > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--primary.kt-radio--solid > span{background: #5867dd;border: 1px solid transparent !important;}
.kt-radio.kt-radio--primary.kt-radio--solid > span:after{border: solid #ffffff;background: #ffffff;}
.kt-radio.kt-radio--primary.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--primary.kt-radio--solid > input:checked ~ span{background: #5867dd;}
.kt-radio.kt-radio--success.kt-radio--disabled{opacity: 0.8;}
.kt-radio.kt-radio--success > span{border: 1px solid #0abb87;}
.kt-radio.kt-radio--success > span:after{border: solid #0abb87;background: #0abb87;}
.kt-radio.kt-radio--success > input:disabled ~ span:after{border-color: #0abb87;}
.kt-radio.kt-radio--success > input:checked ~ span{border: 1px solid #0abb87;}
.kt-radio.kt-radio--success.kt-radio--bold > input:checked ~ span{border: 2px solid #0abb87;}
.kt-radio.kt-radio--success > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--success.kt-radio--solid > span{background: #0abb87;border: 1px solid transparent !important;}
.kt-radio.kt-radio--success.kt-radio--solid > span:after{border: solid #ffffff;background: #ffffff;}
.kt-radio.kt-radio--success.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--success.kt-radio--solid > input:checked ~ span{background: #0abb87;}
.kt-radio.kt-radio--info.kt-radio--disabled{opacity: 0.8;}
.kt-radio.kt-radio--info > span{border: 1px solid #5578eb;}
.kt-radio.kt-radio--info > span:after{border: solid #5578eb;background: #5578eb;}
.kt-radio.kt-radio--info > input:disabled ~ span:after{border-color: #5578eb;}
.kt-radio.kt-radio--info > input:checked ~ span{border: 1px solid #5578eb;}
.kt-radio.kt-radio--info.kt-radio--bold > input:checked ~ span{border: 2px solid #5578eb;}
.kt-radio.kt-radio--info > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--info.kt-radio--solid > span{background: #5578eb;border: 1px solid transparent !important;}
.kt-radio.kt-radio--info.kt-radio--solid > span:after{border: solid #ffffff;background: #ffffff;}
.kt-radio.kt-radio--info.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--info.kt-radio--solid > input:checked ~ span{background: #5578eb;}
.kt-radio.kt-radio--warning.kt-radio--disabled{opacity: 0.8;}
.kt-radio.kt-radio--warning > span{border: 1px solid #ffb822;}
.kt-radio.kt-radio--warning > span:after{border: solid #ffb822;background: #ffb822;}
.kt-radio.kt-radio--warning > input:disabled ~ span:after{border-color: #ffb822;}
.kt-radio.kt-radio--warning > input:checked ~ span{border: 1px solid #ffb822;}
.kt-radio.kt-radio--warning.kt-radio--bold > input:checked ~ span{border: 2px solid #ffb822;}
.kt-radio.kt-radio--warning > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--warning.kt-radio--solid > span{background: #ffb822;border: 1px solid transparent !important;}
.kt-radio.kt-radio--warning.kt-radio--solid > span:after{border: solid #111111;background: #111111;}
.kt-radio.kt-radio--warning.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--warning.kt-radio--solid > input:checked ~ span{background: #ffb822;}
.kt-radio.kt-radio--danger.kt-radio--disabled{opacity: 0.8;}
.kt-radio.kt-radio--danger > span{border: 1px solid #fd397a;}
.kt-radio.kt-radio--danger > span:after{border: solid #fd397a;background: #fd397a;}
.kt-radio.kt-radio--danger > input:disabled ~ span:after{border-color: #fd397a;}
.kt-radio.kt-radio--danger > input:checked ~ span{border: 1px solid #fd397a;}
.kt-radio.kt-radio--danger.kt-radio--bold > input:checked ~ span{border: 2px solid #fd397a;}
.kt-radio.kt-radio--danger > input:disabled ~ span{opacity: 0.6;}
.kt-radio.kt-radio--danger.kt-radio--solid > span{background: #fd397a;border: 1px solid transparent !important;}
.kt-radio.kt-radio--danger.kt-radio--solid > span:after{border: solid #ffffff;background: #ffffff;}
.kt-radio.kt-radio--danger.kt-radio--solid > input:focus ~ span{border: 1px solid transparent !important;}
.kt-radio.kt-radio--danger.kt-radio--solid > input:checked ~ span{background: #fd397a;}
.kt-switch{display: inline-block;font-size: 1rem;}
.kt-switch input:empty{margin-left: -999px;height: 0;width: 0;overflow: hidden;position: absolute;opacity: 0;}
.kt-switch input:empty ~ span{display: inline-block;position: relative;float: left;width: 1px;text-indent: 0;cursor: pointer;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.kt-switch input:empty ~ span:before, .kt-switch input:empty ~ span:after{position: absolute;display: block;top: 0;bottom: 0;left: 0;content: ' ';-webkit-transition: all 100ms ease-in;transition: all 100ms ease-in;}
.kt-switch.kt-switch--icon input:empty ~ span:after{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";}
.kt-switch.kt-switch--icon input:checked ~ span:after{content: '\f17b';}
.kt-switch.kt-switch--icon-check input:checked ~ span:after{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";}
.kt-switch input:empty ~ span{line-height: 30px;margin: 2px 0;height: 30px;width: 57px;border-radius: 15px;}
.kt-switch input:empty ~ span:before, .kt-switch input:empty ~ span:after{width: 54px;border-radius: 15px;}
.kt-switch input:empty ~ span:after{height: 24px;width: 24px;line-height: 26px;top: 3px;bottom: 3px;margin-left: 3px;font-size: 0.9em;text-align: center;vertical-align: middle;}
.kt-switch input:checked ~ span:after{margin-left: 26px;}
.kt-switch.kt-switch--lg input:empty ~ span{line-height: 40px;margin: 2px 0;height: 40px;width: 75px;border-radius: 20px;}
.kt-switch.kt-switch--lg input:empty ~ span:before, .kt-switch.kt-switch--lg input:empty ~ span:after{width: 72px;border-radius: 20px;}
.kt-switch.kt-switch--lg input:empty ~ span:after{height: 34px;width: 34px;line-height: 34px;top: 3px;bottom: 3px;margin-left: 3px;font-size: 1em;text-align: center;vertical-align: middle;}
.kt-switch.kt-switch--lg input:checked ~ span:after{margin-left: 34px;}
.kt-switch.kt-switch--sm input:empty ~ span{line-height: 24px;margin: 2px 0;height: 24px;width: 40px;border-radius: 12px;}
.kt-switch.kt-switch--sm input:empty ~ span:before, .kt-switch.kt-switch--sm input:empty ~ span:after{width: 38px;border-radius: 12px;}
.kt-switch.kt-switch--sm input:empty ~ span:after{height: 20px;width: 20px;line-height: 20px;top: 2px;bottom: 2px;margin-left: 2px;font-size: 0.8em;text-align: center;vertical-align: middle;}
.kt-switch.kt-switch--sm input:checked ~ span:after{margin-left: 16px;}
.form-group.row .kt-switch{margin-top: 0.15rem;}
.form-group.row .kt-switch.kt-switch--lg{margin-top: 0rem;position: relative;top: -0.3rem;}
.form-group.row .kt-switch.kt-switch--sm{margin-top: 0.3rem;}
.kt-switch input:empty ~ span:before{background-color: #e8ebf1;}
.kt-switch input:empty ~ span:after{color: #f8f9fb;background-color: #ffffff;}
.kt-switch input:checked ~ span:before{background-color: #e8ebf1;}
.kt-switch input:checked ~ span:after{background-color: #5d78ff;color: #ffffff;}
.kt-switch input[disabled]{cursor: not-allowed;}
.kt-switch input[disabled] ~ span:after, .kt-switch input[disabled] ~ span:before{cursor: not-allowed;opacity: 0.7;}
.kt-switch.kt-switch--brand:not(.kt-switch--outline) input:empty ~ span:before{background-color: #5d78ff;}
.kt-switch.kt-switch--brand:not(.kt-switch--outline) input:empty ~ span:after{color: #5d78ff;background-color: #ffffff;opacity: 0.4;}
.kt-switch.kt-switch--brand:not(.kt-switch--outline) input:checked ~ span:before{background-color: #5d78ff;}
.kt-switch.kt-switch--brand:not(.kt-switch--outline) input:checked ~ span:after{opacity: 1;}
.kt-switch.kt-switch--outline.kt-switch--brand input:empty ~ span:before{border: 2px solid #dee3eb;background-color: #e8ebf1;}
.kt-switch.kt-switch--outline.kt-switch--brand input:empty ~ span:after{color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--brand input:checked ~ span:before{background-color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--brand input:checked ~ span:after{background-color: #5d78ff;opacity: 1;}
.kt-switch.kt-switch--light:not(.kt-switch--outline) input:empty ~ span:before{background-color: #ffffff;}
.kt-switch.kt-switch--light:not(.kt-switch--outline) input:empty ~ span:after{color: #ffffff;background-color: #282a3c;opacity: 0.4;}
.kt-switch.kt-switch--light:not(.kt-switch--outline) input:checked ~ span:before{background-color: #ffffff;}
.kt-switch.kt-switch--light:not(.kt-switch--outline) input:checked ~ span:after{opacity: 1;}
.kt-switch.kt-switch--outline.kt-switch--light input:empty ~ span:before{border: 2px solid #dee3eb;background-color: #e8ebf1;}
.kt-switch.kt-switch--outline.kt-switch--light input:empty ~ span:after{color: #282a3c;}
.kt-switch.kt-switch--outline.kt-switch--light input:checked ~ span:before{background-color: #282a3c;}
.kt-switch.kt-switch--outline.kt-switch--light input:checked ~ span:after{background-color: #ffffff;opacity: 1;}
.kt-switch.kt-switch--dark:not(.kt-switch--outline) input:empty ~ span:before{background-color: #282a3c;}
.kt-switch.kt-switch--dark:not(.kt-switch--outline) input:empty ~ span:after{color: #282a3c;background-color: #ffffff;opacity: 0.4;}
.kt-switch.kt-switch--dark:not(.kt-switch--outline) input:checked ~ span:before{background-color: #282a3c;}
.kt-switch.kt-switch--dark:not(.kt-switch--outline) input:checked ~ span:after{opacity: 1;}
.kt-switch.kt-switch--outline.kt-switch--dark input:empty ~ span:before{border: 2px solid #dee3eb;background-color: #e8ebf1;}
.kt-switch.kt-switch--outline.kt-switch--dark input:empty ~ span:after{color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--dark input:checked ~ span:before{background-color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--dark input:checked ~ span:after{background-color: #282a3c;opacity: 1;}
.kt-switch.kt-switch--primary:not(.kt-switch--outline) input:empty ~ span:before{background-color: #5867dd;}
.kt-switch.kt-switch--primary:not(.kt-switch--outline) input:empty ~ span:after{color: #5867dd;background-color: #ffffff;opacity: 0.4;}
.kt-switch.kt-switch--primary:not(.kt-switch--outline) input:checked ~ span:before{background-color: #5867dd;}
.kt-switch.kt-switch--primary:not(.kt-switch--outline) input:checked ~ span:after{opacity: 1;}
.kt-switch.kt-switch--outline.kt-switch--primary input:empty ~ span:before{border: 2px solid #dee3eb;background-color: #e8ebf1;}
.kt-switch.kt-switch--outline.kt-switch--primary input:empty ~ span:after{color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--primary input:checked ~ span:before{background-color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--primary input:checked ~ span:after{background-color: #5867dd;opacity: 1;}
.kt-switch.kt-switch--success:not(.kt-switch--outline) input:empty ~ span:before{background-color: #0abb87;}
.kt-switch.kt-switch--success:not(.kt-switch--outline) input:empty ~ span:after{color: #0abb87;background-color: #ffffff;opacity: 0.4;}
.kt-switch.kt-switch--success:not(.kt-switch--outline) input:checked ~ span:before{background-color: #0abb87;}
.kt-switch.kt-switch--success:not(.kt-switch--outline) input:checked ~ span:after{opacity: 1;}
.kt-switch.kt-switch--outline.kt-switch--success input:empty ~ span:before{border: 2px solid #dee3eb;background-color: #e8ebf1;}
.kt-switch.kt-switch--outline.kt-switch--success input:empty ~ span:after{color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--success input:checked ~ span:before{background-color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--success input:checked ~ span:after{background-color: #0abb87;opacity: 1;}
.kt-switch.kt-switch--info:not(.kt-switch--outline) input:empty ~ span:before{background-color: #5578eb;}
.kt-switch.kt-switch--info:not(.kt-switch--outline) input:empty ~ span:after{color: #5578eb;background-color: #ffffff;opacity: 0.4;}
.kt-switch.kt-switch--info:not(.kt-switch--outline) input:checked ~ span:before{background-color: #5578eb;}
.kt-switch.kt-switch--info:not(.kt-switch--outline) input:checked ~ span:after{opacity: 1;}
.kt-switch.kt-switch--outline.kt-switch--info input:empty ~ span:before{border: 2px solid #dee3eb;background-color: #e8ebf1;}
.kt-switch.kt-switch--outline.kt-switch--info input:empty ~ span:after{color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--info input:checked ~ span:before{background-color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--info input:checked ~ span:after{background-color: #5578eb;opacity: 1;}
.kt-switch.kt-switch--warning:not(.kt-switch--outline) input:empty ~ span:before{background-color: #ffb822;}
.kt-switch.kt-switch--warning:not(.kt-switch--outline) input:empty ~ span:after{color: #ffb822;background-color: #111111;opacity: 0.4;}
.kt-switch.kt-switch--warning:not(.kt-switch--outline) input:checked ~ span:before{background-color: #ffb822;}
.kt-switch.kt-switch--warning:not(.kt-switch--outline) input:checked ~ span:after{opacity: 1;}
.kt-switch.kt-switch--outline.kt-switch--warning input:empty ~ span:before{border: 2px solid #dee3eb;background-color: #e8ebf1;}
.kt-switch.kt-switch--outline.kt-switch--warning input:empty ~ span:after{color: #111111;}
.kt-switch.kt-switch--outline.kt-switch--warning input:checked ~ span:before{background-color: #111111;}
.kt-switch.kt-switch--outline.kt-switch--warning input:checked ~ span:after{background-color: #ffb822;opacity: 1;}
.kt-switch.kt-switch--danger:not(.kt-switch--outline) input:empty ~ span:before{background-color: #fd397a;}
.kt-switch.kt-switch--danger:not(.kt-switch--outline) input:empty ~ span:after{color: #fd397a;background-color: #ffffff;opacity: 0.4;}
.kt-switch.kt-switch--danger:not(.kt-switch--outline) input:checked ~ span:before{background-color: #fd397a;}
.kt-switch.kt-switch--danger:not(.kt-switch--outline) input:checked ~ span:after{opacity: 1;}
.kt-switch.kt-switch--outline.kt-switch--danger input:empty ~ span:before{border: 2px solid #dee3eb;background-color: #e8ebf1;}
.kt-switch.kt-switch--outline.kt-switch--danger input:empty ~ span:after{color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--danger input:checked ~ span:before{background-color: #ffffff;}
.kt-switch.kt-switch--outline.kt-switch--danger input:checked ~ span:after{background-color: #fd397a;opacity: 1;}
.kt-option{display: -webkit-box;display: -ms-flexbox;display: flex;padding: 1.4em;border: 1px solid #ebedf2;border-radius: 4px;}
.kt-option .kt-option__control{width: 2.7rem;padding-top: 0.1rem;}
.kt-option .kt-option__control .kt-radio{display: block;}
.kt-option .kt-option__label{width: 100%;}
.kt-option .kt-option__label .kt-option__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.kt-option .kt-option__label .kt-option__head .kt-option__title{font-size: 1.1rem;font-weight: 400;color: #6c7293;}
.kt-option .kt-option__label .kt-option__head .kt-option__focus{font-size: 1.1rem;font-weight: 500;color: #464457;}
.kt-option .kt-option__label .kt-option__body{display: block;padding-top: 0.7rem;font-size: 0.9rem;color: #a7abc3;}
.kt-option.kt-option--plain{border: 0;padding: 0;margin-top: -0.2rem;margin-bottom: 2rem;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-option.kt-option--plain .kt-option__control{vertical-align: middle;width: 2.7rem;}
.kt-option.kt-option--plain .kt-option__control .kt-radio{margin-bottom: 1.6rem;}
.kt-option.kt-option--plain .kt-option__label .kt-option__body{padding-top: 0.2rem;}
.is-valid .kt-option{border-color: #0abb87;}
.is-invalid .kt-option{border-color: #fd397a;}
.has-success .kt-option .kt-option__label .kt-option__head .kt-option__title{color: #0abb87;}
.has-error .kt-option .kt-option__label .kt-option__head .kt-option__title{color: #ffb822;}
.kt-avatar{position: relative;display: inline-block;}
.kt-avatar .kt-avatar__holder{width: 120px;height: 120px;border-radius: 3px;background-repeat: no-repeat;background-size: contain;}
.kt-avatar .kt-avatar__upload{cursor: pointer;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;position: absolute;right: -10px;top: -10px;width: 30px;height: 30px;border-radius: 50%;background-color: #ffffff;-webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-avatar .kt-avatar__upload input{width: 0 !important;height: 0 !important;overflow: hidden;opacity: 0;}
.kt-avatar .kt-avatar__upload i{color: #5d78ff;font-size: 0.9rem;}
.kt-avatar .kt-avatar__upload:hover{-webkit-transition: all 0.3s;transition: all 0.3s;background-color: #5d78ff;}
.kt-avatar .kt-avatar__upload:hover i{color: #ffffff;}
.kt-avatar .kt-avatar__cancel{cursor: pointer;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;position: absolute;top: auto;right: -10px;bottom: -5px;width: 22px;height: 22px;border-radius: 50%;background-color: #ffffff;-webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);display: none;}
.kt-avatar .kt-avatar__cancel i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #a7abc3;font-size: 0.9rem;}
.kt-avatar .kt-avatar__cancel:hover i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #5d78ff;}
.kt-avatar.kt-avatar--changed .kt-avatar__cancel{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-avatar.kt-avatar--brand .kt-avatar__upload i{color: #5d78ff;}
.kt-avatar.kt-avatar--brand .kt-avatar__upload:hover{background-color: #5d78ff;}
.kt-avatar.kt-avatar--brand .kt-avatar__upload:hover i{color: #ffffff;}
.kt-avatar.kt-avatar--light .kt-avatar__upload i{color: #ffffff;}
.kt-avatar.kt-avatar--light .kt-avatar__upload:hover{background-color: #ffffff;}
.kt-avatar.kt-avatar--light .kt-avatar__upload:hover i{color: #282a3c;}
.kt-avatar.kt-avatar--dark .kt-avatar__upload i{color: #282a3c;}
.kt-avatar.kt-avatar--dark .kt-avatar__upload:hover{background-color: #282a3c;}
.kt-avatar.kt-avatar--dark .kt-avatar__upload:hover i{color: #ffffff;}
.kt-avatar.kt-avatar--primary .kt-avatar__upload i{color: #5867dd;}
.kt-avatar.kt-avatar--primary .kt-avatar__upload:hover{background-color: #5867dd;}
.kt-avatar.kt-avatar--primary .kt-avatar__upload:hover i{color: #ffffff;}
.kt-avatar.kt-avatar--success .kt-avatar__upload i{color: #0abb87;}
.kt-avatar.kt-avatar--success .kt-avatar__upload:hover{background-color: #0abb87;}
.kt-avatar.kt-avatar--success .kt-avatar__upload:hover i{color: #ffffff;}
.kt-avatar.kt-avatar--info .kt-avatar__upload i{color: #5578eb;}
.kt-avatar.kt-avatar--info .kt-avatar__upload:hover{background-color: #5578eb;}
.kt-avatar.kt-avatar--info .kt-avatar__upload:hover i{color: #ffffff;}
.kt-avatar.kt-avatar--warning .kt-avatar__upload i{color: #ffb822;}
.kt-avatar.kt-avatar--warning .kt-avatar__upload:hover{background-color: #ffb822;}
.kt-avatar.kt-avatar--warning .kt-avatar__upload:hover i{color: #111111;}
.kt-avatar.kt-avatar--danger .kt-avatar__upload i{color: #fd397a;}
.kt-avatar.kt-avatar--danger .kt-avatar__upload:hover{background-color: #fd397a;}
.kt-avatar.kt-avatar--danger .kt-avatar__upload:hover i{color: #ffffff;}
.kt-avatar.kt-avatar--circle .kt-avatar__holder{border-radius: 50%;}
.kt-avatar.kt-avatar--circle .kt-avatar__upload{right: 0px;top: 5px;}
.kt-avatar.kt-avatar--circle .kt-avatar__cancel{right: 3px;bottom: 10px;}
.kt-avatar.kt-avatar--outline .kt-avatar__holder{border: 3px solid #ffffff;-webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);}
.kt-spinner{position: relative;}
.kt-spinner:before{content: '';-webkit-box-sizing: border-box;box-sizing: border-box;position: absolute;top: 50%;left: 0;border-radius: 50%;}
.kt-spinner.kt-spinner--sm:before{width: 16px;height: 16px;margin-top: -8px;}
.kt-spinner.kt-spinner--sm.kt-spinner--center:before{left: 50%;margin-left: -8px;}
.kt-spinner.kt-spinner--sm.kt-spinner--left:before{left: 0;right: auto;}
.kt-spinner.kt-spinner--sm.kt-spinner--right:before{left: auto;right: 0;}
.kt-spinner:before{width: 20px;height: 20px;margin-top: -10px;}
.kt-spinner.kt-spinner--center:before{left: 50%;margin-left: -10px;}
.kt-spinner.kt-spinner--left:before{left: 0;right: auto;}
.kt-spinner.kt-spinner--right:before{left: auto;right: 0;}
.kt-spinner.kt-spinner--lg:before{width: 24px;height: 24px;margin-top: -12px;}
.kt-spinner.kt-spinner--lg.kt-spinner--center:before{left: 50%;margin-left: -12px;}
.kt-spinner.kt-spinner--lg.kt-spinner--left:before{left: 0;right: auto;}
.kt-spinner.kt-spinner--lg.kt-spinner--right:before{left: auto;right: 0;}
.kt-spinner:before{-webkit-animation: kt-spinner .5s linear infinite;animation: kt-spinner .5s linear infinite;}
.kt-spinner.kt-spinner--brand:before{border: 2px solid #5d78ff;border-right: 2px solid transparent;}
.kt-spinner.kt-spinner--light:before{border: 2px solid #ffffff;border-right: 2px solid transparent;}
.kt-spinner.kt-spinner--dark:before{border: 2px solid #282a3c;border-right: 2px solid transparent;}
.kt-spinner.kt-spinner--primary:before{border: 2px solid #5867dd;border-right: 2px solid transparent;}
.kt-spinner.kt-spinner--success:before{border: 2px solid #0abb87;border-right: 2px solid transparent;}
.kt-spinner.kt-spinner--info:before{border: 2px solid #5578eb;border-right: 2px solid transparent;}
.kt-spinner.kt-spinner--warning:before{border: 2px solid #ffb822;border-right: 2px solid transparent;}
.kt-spinner.kt-spinner--danger:before{border: 2px solid #fd397a;border-right: 2px solid transparent;}
.kt-spinner.kt-spinner--input.kt-spinner--right:before{left: auto;right: 1rem;}
.kt-spinner.kt-spinner--input.kt-spinner--left:before{right: auto;left: 1rem;}
.kt-spinner.kt-spinner--v2:before{-webkit-animation: kt-spinner .5s linear infinite;animation: kt-spinner .5s linear infinite;}
.kt-spinner.kt-spinner--v2.kt-spinner--brand:before{border: 2px solid #f6f8ff;border-top-color: #4e6bff;}
.kt-spinner.kt-spinner--v2.kt-spinner--light:before{border: 2px solid white;border-top-color: #f7f7f7;}
.kt-spinner.kt-spinner--v2.kt-spinner--dark:before{border: 2px solid #656a98;border-top-color: #222433;}
.kt-spinner.kt-spinner--v2.kt-spinner--primary:before{border: 2px solid #d7dbf7;border-top-color: #4b5bda;}
.kt-spinner.kt-spinner--v2.kt-spinner--success:before{border: 2px solid #67f7cd;border-top-color: #09ac7d;}
.kt-spinner.kt-spinner--v2.kt-spinner--info:before{border: 2px solid #dee5fb;border-top-color: #476de9;}
.kt-spinner.kt-spinner--v2.kt-spinner--warning:before{border: 2px solid #ffe9bb;border-top-color: #ffb313;}
.kt-spinner.kt-spinner--v2.kt-spinner--danger:before{border: 2px solid #ffd0e0;border-top-color: #fd2a70;}
@-webkit-keyframes kt-spinner{to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes kt-spinner{to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
.kt-loader{display: inline-block;width: 1.4rem;height: 1.4rem;margin: 0 auto;border-radius: 100%;background-color: #f7f8fa;opacity: 0;-webkit-animation: kt-loader-scaleout 0.9s infinite ease-in-out;animation: kt-loader-scaleout 0.9s infinite ease-in-out;}
.kt-loader.kt-loader--lg{width: 2rem;height: 2rem;}
.kt-loader.kt-loader--sm{width: 1rem;height: 1rem;}
.btn .kt-loader{position: relative;line-height: 0;}
.kt-loader.kt-loader--brand{background-color: #5d78ff;}
.kt-loader.kt-loader--light{background-color: #ffffff;}
.kt-loader.kt-loader--dark{background-color: #282a3c;}
.kt-loader.kt-loader--primary{background-color: #5867dd;}
.kt-loader.kt-loader--success{background-color: #0abb87;}
.kt-loader.kt-loader--info{background-color: #5578eb;}
.kt-loader.kt-loader--warning{background-color: #ffb822;}
.kt-loader.kt-loader--danger{background-color: #fd397a;}
@-webkit-keyframes kt-loader-scaleout{0%{opacity: 0.3;-webkit-transform: scale(0);transform: scale(0);}
100%{opacity: 1;-webkit-transform: scale(1);transform: scale(1);}
}
@keyframes kt-loader-scaleout{0%{opacity: 0.3;-webkit-transform: scale(0);transform: scale(0);}
100%{opacity: 1;-webkit-transform: scale(1);transform: scale(1);}
}
.kt-pulse{position: relative;}
.kt-pulse .kt-pulse__ring{display: block;border-radius: 40px;height: 40px;width: 40px;position: absolute;-webkit-animation: kt-pulse 3.5s ease-out;animation: kt-pulse 3.5s ease-out;-webkit-animation-iteration-count: infinite;animation-iteration-count: infinite;opacity: 0;border-width: 3px;border-style: solid;border-color: #d8dce6;}
@media screen\0{.kt-pulse .kt-pulse__ring{top: 2px;left: 2px;}
}
.kt-pulse.kt-pulse--brand .kt-pulse__ring{border-color: rgba(93, 120, 255, 0.8);}
.kt-pulse.kt-pulse--light .kt-pulse__ring{border-color: rgba(255, 255, 255, 0.8);}
.kt-pulse.kt-pulse--dark .kt-pulse__ring{border-color: rgba(40, 42, 60, 0.8);}
.kt-pulse.kt-pulse--primary .kt-pulse__ring{border-color: rgba(88, 103, 221, 0.8);}
.kt-pulse.kt-pulse--success .kt-pulse__ring{border-color: rgba(10, 187, 135, 0.8);}
.kt-pulse.kt-pulse--info .kt-pulse__ring{border-color: rgba(85, 120, 235, 0.8);}
.kt-pulse.kt-pulse--warning .kt-pulse__ring{border-color: rgba(255, 184, 34, 0.8);}
.kt-pulse.kt-pulse--danger .kt-pulse__ring{border-color: rgba(253, 57, 122, 0.8);}
@-webkit-keyframes kt-pulse{0%{-webkit-transform: scale(0.1, 0.1);opacity: 0.0;}
60%{-webkit-transform: scale(0.1, 0.1);opacity: 0.0;}
65%{opacity: 1;}
100%{-webkit-transform: scale(1.2, 1.2);opacity: 0.0;}
}
@keyframes kt-pulse{0%{-webkit-transform: scale(0.1, 0.1);opacity: 0.0;}
60%{-webkit-transform: scale(0.1, 0.1);opacity: 0.0;}
65%{opacity: 1;}
100%{-webkit-transform: scale(1.2, 1.2);opacity: 0.0;}
}
.kt-dialog{width: 130px;margin: 0 auto;position: fixed;z-index: 1100;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;padding: 1rem;-webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);background-color: #fff;border-radius: 4px;}
.kt-dialog.kt-dialog--top-center{display: -webkit-box;display: -ms-flexbox;display: flex;top: -100px;left: 50%;margin-left: -65px;-webkit-transition: top 0.6s ease;transition: top 0.6s ease;border-top-left-radius: 0;border-top-right-radius: 0;}
.kt-dialog.kt-dialog--top-center.kt-dialog--shown{top: 0px;-webkit-transition: top 0.6s ease;transition: top 0.6s ease;}
.kt-dialog.kt-dialog--loader{font-size: 1rem;padding: 0.75rem;font-weight: 400;color: #6c7293;}
.kt-dialog.kt-dialog--loader.kt-dialog--brand{background-color: rgba(93, 120, 255, 0.1);color: #5d78ff;-webkit-box-shadow: 0px 0px 20px 0px rgba(93, 120, 255, 0.3);box-shadow: 0px 0px 20px 0px rgba(93, 120, 255, 0.3);}
.kt-dialog.kt-dialog--loader.kt-dialog--light{background-color: rgba(255, 255, 255, 0.1);color: #ffffff;-webkit-box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.3);box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.3);}
.kt-dialog.kt-dialog--loader.kt-dialog--dark{background-color: rgba(40, 42, 60, 0.1);color: #282a3c;-webkit-box-shadow: 0px 0px 20px 0px rgba(40, 42, 60, 0.3);box-shadow: 0px 0px 20px 0px rgba(40, 42, 60, 0.3);}
.kt-dialog.kt-dialog--loader.kt-dialog--primary{background-color: rgba(88, 103, 221, 0.1);color: #5867dd;-webkit-box-shadow: 0px 0px 20px 0px rgba(88, 103, 221, 0.3);box-shadow: 0px 0px 20px 0px rgba(88, 103, 221, 0.3);}
.kt-dialog.kt-dialog--loader.kt-dialog--success{background-color: rgba(10, 187, 135, 0.1);color: #0abb87;-webkit-box-shadow: 0px 0px 20px 0px rgba(10, 187, 135, 0.3);box-shadow: 0px 0px 20px 0px rgba(10, 187, 135, 0.3);}
.kt-dialog.kt-dialog--loader.kt-dialog--info{background-color: rgba(85, 120, 235, 0.1);color: #5578eb;-webkit-box-shadow: 0px 0px 20px 0px rgba(85, 120, 235, 0.3);box-shadow: 0px 0px 20px 0px rgba(85, 120, 235, 0.3);}
.kt-dialog.kt-dialog--loader.kt-dialog--warning{background-color: rgba(255, 184, 34, 0.1);color: #ffb822;-webkit-box-shadow: 0px 0px 20px 0px rgba(255, 184, 34, 0.3);box-shadow: 0px 0px 20px 0px rgba(255, 184, 34, 0.3);}
.kt-dialog.kt-dialog--loader.kt-dialog--danger{background-color: rgba(253, 57, 122, 0.1);color: #fd397a;-webkit-box-shadow: 0px 0px 20px 0px rgba(253, 57, 122, 0.3);box-shadow: 0px 0px 20px 0px rgba(253, 57, 122, 0.3);}
.kt-scroll.kt-scroll--pull{padding-right: 12px;margin-right: -12px;}
.kt-scroll.kt-scroll--pull .ps__rail-y{right: -2px;}
.ps > .ps__rail-y{width: 4px;}
.ps > .ps__rail-y:hover, .ps > .ps__rail-y:focus{width: 4px;}
.ps > .ps__rail-y > .ps__thumb-y{width: 4px;border-radius: 4px;}
.ps > .ps__rail-y > .ps__thumb-y:hover, .ps > .ps__rail-y > .ps__thumb-y:focus{width: 4px;}
.ps > .ps__rail-x{height: 4px;}
.ps > .ps__rail-x:hover, .ps > .ps__rail-x:focus{height: 4px;}
.ps > .ps__rail-x > .ps__thumb-x{top: 0;height: 4px;border-radius: 4px;}
.ps > .ps__rail-x > .ps__thumb-x:hover, .ps > .ps__rail-x > .ps__thumb-x:focus{top: 0;height: 4px;}
.ps > .ps__rail-x{background: transparent;}
.ps > .ps__rail-x:hover, .ps > .ps__rail-x:focus{opacity: 1;background: transparent;}
.ps > .ps__rail-x:hover > .ps__thumb-x, .ps > .ps__rail-x:focus > .ps__thumb-x{opacity: 1;}
.ps > .ps__rail-x > .ps__thumb-x{background: #d8dce6;opacity: 1;}
.ps > .ps__rail-x > .ps__thumb-x:hover, .ps > .ps__rail-x > .ps__thumb-x:focus{opacity: 1;background: #d8dce6;}
.ps > .ps__rail-y{background: transparent;}
.ps > .ps__rail-y:hover, .ps > .ps__rail-y:focus{background: transparent;opacity: 1;}
.ps > .ps__rail-y:hover > .ps__thumb-y, .ps > .ps__rail-y:focus > .ps__thumb-y{opacity: 1;}
.ps > .ps__rail-y > .ps__thumb-y{background: #d8dce6;opacity: 1;}
.ps > .ps__rail-y > .ps__thumb-y:hover, .ps > .ps__rail-y > .ps__thumb-y:focus{opacity: 1;background: #d8dce6;}
.blockui{background: #fff;-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);display: table;table-layout: fixed;border-radius: 4px;}
.blockui.blockui-noshadow{-webkit-box-shadow: none;box-shadow: none;}
.blockui > span{color: #a7abc3;display: table-cell;vertical-align: middle;padding: 0.75rem 1.2rem;font-size: 1rem;font-weight: 400;}
.blockui > span > .kt-loader, .blockui > span > .kt-spinner{margin-right: 1.25rem;}
.fc-unthemed .fc-day-grid td:not(.fc-axis){
padding: 0.5rem 0.5rem;}
.fc-unthemed .fc-day-grid td:not(.fc-axis).fc-event-container{padding: 0.2rem 0.5rem;}
.fc-unthemed .fc-axis{padding-top: 0.5rem;padding-bottom: 0.5rem;}
.fc-unthemed .fc-scroller .fc-content-col{padding: 0.5rem 0.5rem;}
.fc-unthemed th.fc-day-header{padding: 0.75rem 0.5rem;font-size: 1rem;font-weight: 500;}
.fc-unthemed .fc-list-heading .fc-widget-header{padding: 0.75rem 1.25rem;}
.fc-unthemed .fc-list-heading .fc-list-heading-main, .fc-unthemed .fc-list-heading .fc-list-heading-alt{font-size: 1rem;font-weight: 400;}
.fc-unthemed .fc-list-heading .fc-list-heading-main{font-weight: 500;text-transform: uppercase;}
.fc-unthemed .fc-list-item td{padding: 0.75rem 1.25rem;}
.fc-unthemed .fc-list-item .fc-event-dot{border-radius: 50%;}
.fc-unthemed .fc-event, .fc-unthemed .fc-event-dot{padding: 0;border-radius: 4px;}
.fc-unthemed .fc-event .fc-content, .fc-unthemed .fc-event-dot .fc-content{padding: 0.55rem 0.55rem 0.55rem 2rem;}
.fc-unthemed .fc-event .fc-content:before, .fc-unthemed .fc-event-dot .fc-content:before{display: block;content: " ";position: absolute;height: 10px;width: 10px;border-radius: 50%;top: 0.7rem;left: 0.75rem;}
.fc-unthemed .fc-event.fc-not-start.fc-end .fc-content, .fc-unthemed .fc-event-dot.fc-not-start.fc-end .fc-content{padding-left: 0.5rem;}
.fc-unthemed .fc-event .fc-time, .fc-unthemed .fc-event-dot .fc-time{font-size: 0.9rem;text-transform: uppercase;font-weight: 500;}
.fc-unthemed .fc-event .fc-title, .fc-unthemed .fc-event-dot .fc-title{font-size: 0.9rem;font-weight: 400;}
.fc-unthemed .fc-description{font-size: 0.9rem;margin-top: 0.25rem;font-weight: normal;}
.fc-unthemed .fc-list-item-title > a{font-size: 1rem;font-weight: 500;}
.fc-unthemed a.fc-more{font-size: 0.9rem;font-weight: 500;}
.fc-unthemed .fc-popover{border-radius: 4px;}
.fc-unthemed .fc-popover .fc-header{padding: 0.75rem 1.25rem;border-top-left-radius: 4px;border-top-right-radius: 4px;}
.fc-unthemed .fc-popover .fc-header .fc-close{margin-top: 0.35rem;}
.fc-unthemed .fc-popover .fc-header .fc-title{font-weight: 400;}
.fc-unthemed .fc-popover .fc-body .fc-event-container{padding: 1.25rem 1.25rem 0.75rem 1.25rem;}
.fc-unthemed .fc-popover .fc-body .fc-event{margin-bottom: 0.5rem;border-radius: 4px;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end{border-top-right-radius: 0;border-bottom-right-radius: 0;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-end{border-top-left-radius: 0;border-bottom-left-radius: 0;}
.fc-unthemed .fc-toolbar{margin-bottom: 1.5rem;}
.fc-unthemed .fc-toolbar h2{font-size: 1.2rem;font-weight: 500;text-transform: uppercase;margin-top: 0.75rem;}
.fc-unthemed .fc-toolbar .fc-button{outline: none !important;height: 2.75rem;padding: 0 1.25rem;font-size: 1rem;}
.fc-unthemed .fc-toolbar .fc-button.fc-corner-left{border-top-left-radius: 4px;border-bottom-left-radius: 4px;}
.fc-unthemed .fc-toolbar .fc-button.fc-corner-right{border-top-right-radius: 4px;border-bottom-right-radius: 4px;}
.fc-unthemed .fc-toolbar .fc-button .fc-icon{font-size: 1.1rem;}
.fc-unthemed .fc-toolbar .fc-button .fc-icon:after{display: none;}
.fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-left-single-arrow{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;}
.fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-left-single-arrow:before{content: "";}
.fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-right-single-arrow{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;}
.fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-right-single-arrow:before{content: "";}
.fc-unthemed .fc-axis > span{font-size: 0.9rem;}
.ui-draggable-handle{cursor: move;cursor: grab;cursor: -webkit-grab;}
@media (max-width: 1024px){
.fc-unthemed .fc-toolbar{margin-bottom: 1.5rem;}
.fc-unthemed .fc-toolbar .fc-left, .fc-unthemed .fc-toolbar .fc-right, .fc-unthemed .fc-toolbar .fc-center{display: block;float: none;margin-bottom: 1rem;text-align: center;}
.fc-unthemed .fc-toolbar .fc-left h2, .fc-unthemed .fc-toolbar .fc-right h2, .fc-unthemed .fc-toolbar .fc-center h2{text-align: center;float: none;}
.fc-unthemed .fc-toolbar .fc-left > .fc-button-group, .fc-unthemed .fc-toolbar .fc-right > .fc-button-group, .fc-unthemed .fc-toolbar .fc-center > .fc-button-group{display: inline-block;float: none;}
.fc-unthemed .fc-toolbar .fc-left > .fc-button-group > .fc-button, .fc-unthemed .fc-toolbar .fc-right > .fc-button-group > .fc-button, .fc-unthemed .fc-toolbar .fc-center > .fc-button-group > .fc-button{float: none;}
.fc-unthemed .fc-toolbar .fc-left > .fc-button, .fc-unthemed .fc-toolbar .fc-right > .fc-button, .fc-unthemed .fc-toolbar .fc-center > .fc-button{float: none;}
}
.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td{border-color: #ebedf2;}
.fc-unthemed td.fc-today{background: rgba(93, 120, 255, 0.025);}
.fc-unthemed .fc-day-grid-event{margin: 0;}
.fc-unthemed .fc-event, .fc-unthemed .fc-event-dot{background: #fff;border: 1px solid #ebedf2;-webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.055);box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.055);}
.fc-unthemed .fc-event.fc-not-start.fc-not-end, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end{border-left: 1px solid #f7f8fa;}
.fc-unthemed .fc-event.fc-start .fc-content:before, .fc-unthemed .fc-event-dot.fc-start .fc-content:before{background: #e2e5ec;}
.fc-unthemed .fc-event .fc-time, .fc-unthemed .fc-event-dot .fc-time{color: #6c7293;}
.fc-unthemed .fc-event .fc-title, .fc-unthemed .fc-event-dot .fc-title{color: #6c7293;}
.fc-unthemed .fc-description{color: #a7abc3;}
.fc-unthemed .fc-list-item-title > a[href]:hover{color: #5d78ff;text-decoration: none;}
.fc-unthemed .fc-more{color: #a7abc3;}
.fc-unthemed .fc-event.fc-start.fc-event-brand .fc-content:before, .fc-unthemed .fc-event-dot.fc-start.fc-event-brand .fc-content:before{background: #5d78ff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-brand, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-brand, .fc-unthemed .fc-event.fc-start.fc-event-solid-brand, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-brand, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-brand, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-brand{background: #5d78ff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-brand .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-brand .fc-title, .fc-unthemed .fc-event.fc-start.fc-event-solid-brand .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-brand .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-brand .fc-title, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-brand .fc-title{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-brand .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-brand .fc-description, .fc-unthemed .fc-event.fc-start.fc-event-solid-brand .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-brand .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-brand .fc-description, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-brand .fc-description{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-brand .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-brand .fc-time, .fc-unthemed .fc-event.fc-start.fc-event-solid-brand .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-brand .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-brand .fc-time, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-brand .fc-time{color: #ffffff;}
.fc-unthemed .fc-event.fc-start.fc-event-light .fc-content:before, .fc-unthemed .fc-event-dot.fc-start.fc-event-light .fc-content:before{background: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-light, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-light, .fc-unthemed .fc-event.fc-start.fc-event-solid-light, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-light, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-light, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-light{background: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-light .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-light .fc-title, .fc-unthemed .fc-event.fc-start.fc-event-solid-light .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-light .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-light .fc-title, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-light .fc-title{color: #282a3c;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-light .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-light .fc-description, .fc-unthemed .fc-event.fc-start.fc-event-solid-light .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-light .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-light .fc-description, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-light .fc-description{color: #282a3c;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-light .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-light .fc-time, .fc-unthemed .fc-event.fc-start.fc-event-solid-light .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-light .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-light .fc-time, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-light .fc-time{color: #282a3c;}
.fc-unthemed .fc-event.fc-start.fc-event-dark .fc-content:before, .fc-unthemed .fc-event-dot.fc-start.fc-event-dark .fc-content:before{background: #282a3c;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-dark, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-dark, .fc-unthemed .fc-event.fc-start.fc-event-solid-dark, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-dark, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-dark, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-dark{background: #282a3c;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-dark .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-dark .fc-title, .fc-unthemed .fc-event.fc-start.fc-event-solid-dark .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-dark .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-dark .fc-title, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-dark .fc-title{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-dark .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-dark .fc-description, .fc-unthemed .fc-event.fc-start.fc-event-solid-dark .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-dark .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-dark .fc-description, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-dark .fc-description{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-dark .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-dark .fc-time, .fc-unthemed .fc-event.fc-start.fc-event-solid-dark .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-dark .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-dark .fc-time, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-dark .fc-time{color: #ffffff;}
.fc-unthemed .fc-event.fc-start.fc-event-primary .fc-content:before, .fc-unthemed .fc-event-dot.fc-start.fc-event-primary .fc-content:before{background: #5867dd;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-primary, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-primary, .fc-unthemed .fc-event.fc-start.fc-event-solid-primary, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-primary, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-primary, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-primary{background: #5867dd;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-primary .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-primary .fc-title, .fc-unthemed .fc-event.fc-start.fc-event-solid-primary .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-primary .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-primary .fc-title, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-primary .fc-title{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-primary .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-primary .fc-description, .fc-unthemed .fc-event.fc-start.fc-event-solid-primary .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-primary .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-primary .fc-description, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-primary .fc-description{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-primary .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-primary .fc-time, .fc-unthemed .fc-event.fc-start.fc-event-solid-primary .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-primary .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-primary .fc-time, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-primary .fc-time{color: #ffffff;}
.fc-unthemed .fc-event.fc-start.fc-event-success .fc-content:before, .fc-unthemed .fc-event-dot.fc-start.fc-event-success .fc-content:before{background: #0abb87;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-success, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-success, .fc-unthemed .fc-event.fc-start.fc-event-solid-success, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-success, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-success, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-success{background: #0abb87;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-success .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-success .fc-title, .fc-unthemed .fc-event.fc-start.fc-event-solid-success .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-success .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-success .fc-title, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-success .fc-title{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-success .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-success .fc-description, .fc-unthemed .fc-event.fc-start.fc-event-solid-success .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-success .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-success .fc-description, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-success .fc-description{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-success .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-success .fc-time, .fc-unthemed .fc-event.fc-start.fc-event-solid-success .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-success .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-success .fc-time, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-success .fc-time{color: #ffffff;}
.fc-unthemed .fc-event.fc-start.fc-event-info .fc-content:before, .fc-unthemed .fc-event-dot.fc-start.fc-event-info .fc-content:before{background: #5578eb;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-info, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-info, .fc-unthemed .fc-event.fc-start.fc-event-solid-info, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-info, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-info, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-info{background: #5578eb;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-info .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-info .fc-title, .fc-unthemed .fc-event.fc-start.fc-event-solid-info .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-info .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-info .fc-title, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-info .fc-title{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-info .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-info .fc-description, .fc-unthemed .fc-event.fc-start.fc-event-solid-info .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-info .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-info .fc-description, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-info .fc-description{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-info .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-info .fc-time, .fc-unthemed .fc-event.fc-start.fc-event-solid-info .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-info .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-info .fc-time, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-info .fc-time{color: #ffffff;}
.fc-unthemed .fc-event.fc-start.fc-event-warning .fc-content:before, .fc-unthemed .fc-event-dot.fc-start.fc-event-warning .fc-content:before{background: #ffb822;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-warning, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-warning, .fc-unthemed .fc-event.fc-start.fc-event-solid-warning, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-warning, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-warning, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-warning{background: #ffb822;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-warning .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-warning .fc-title, .fc-unthemed .fc-event.fc-start.fc-event-solid-warning .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-warning .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-warning .fc-title, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-warning .fc-title{color: #111111;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-warning .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-warning .fc-description, .fc-unthemed .fc-event.fc-start.fc-event-solid-warning .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-warning .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-warning .fc-description, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-warning .fc-description{color: #111111;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-warning .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-warning .fc-time, .fc-unthemed .fc-event.fc-start.fc-event-solid-warning .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-warning .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-warning .fc-time, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-warning .fc-time{color: #111111;}
.fc-unthemed .fc-event.fc-start.fc-event-danger .fc-content:before, .fc-unthemed .fc-event-dot.fc-start.fc-event-danger .fc-content:before{background: #fd397a;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-danger, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-danger, .fc-unthemed .fc-event.fc-start.fc-event-solid-danger, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-danger, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-danger, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-danger{background: #fd397a;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-danger .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-danger .fc-title, .fc-unthemed .fc-event.fc-start.fc-event-solid-danger .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-danger .fc-title, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-danger .fc-title, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-danger .fc-title{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-danger .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-danger .fc-description, .fc-unthemed .fc-event.fc-start.fc-event-solid-danger .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-danger .fc-description, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-danger .fc-description, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-danger .fc-description{color: #ffffff;}
.fc-unthemed .fc-event.fc-not-start.fc-end.fc-event-solid-danger .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.fc-event-solid-danger .fc-time, .fc-unthemed .fc-event.fc-start.fc-event-solid-danger .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-end.fc-event-solid-danger .fc-time, .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-danger .fc-time, .fc-unthemed .fc-event-dot.fc-start.fc-event-solid-danger .fc-time{color: #ffffff;}
.fc-unthemed .fc-divider, .fc-unthemed .fc-popover .fc-header, .fc-unthemed .fc-list-heading td{background: #f7f8fa;}
.fc-unthemed .fc-popover{-webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.075);box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.075);background: #fff;}
.fc-unthemed .fc-popover .fc-header{background: #fff;border-bottom: 1px solid #ebedf2;}
.fc-unthemed .fc-popover .fc-header .fc-close{color: #a7abc3;}
.fc-unthemed .fc-popover .fc-header .fc-close:hover{color: #6c7293;}
.fc-unthemed .fc-popover .fc-header .fc-title{color: #6c7293;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end .fc-content:before{background: #ebedf2;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-brand .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-brand .fc-content:before{background: #5d78ff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-brand, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-brand{background: #5d78ff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-brand .fc-title, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-brand .fc-title{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-brand .fc-description, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-brand .fc-description{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-brand .fc-time, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-brand .fc-time{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-light .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-light .fc-content:before{background: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-light, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-light{background: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-light .fc-title, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-light .fc-title{color: #282a3c;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-light .fc-description, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-light .fc-description{color: #282a3c;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-light .fc-time, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-light .fc-time{color: #282a3c;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-dark .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-dark .fc-content:before{background: #282a3c;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-dark, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-dark{background: #282a3c;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-dark .fc-title, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-dark .fc-title{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-dark .fc-description, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-dark .fc-description{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-dark .fc-time, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-dark .fc-time{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-primary .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-primary .fc-content:before{background: #5867dd;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-primary, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-primary{background: #5867dd;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-primary .fc-title, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-primary .fc-title{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-primary .fc-description, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-primary .fc-description{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-primary .fc-time, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-primary .fc-time{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-success .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-success .fc-content:before{background: #0abb87;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-success, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-success{background: #0abb87;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-success .fc-title, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-success .fc-title{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-success .fc-description, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-success .fc-description{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-success .fc-time, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-success .fc-time{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-info .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-info .fc-content:before{background: #5578eb;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-info, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-info{background: #5578eb;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-info .fc-title, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-info .fc-title{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-info .fc-description, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-info .fc-description{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-info .fc-time, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-info .fc-time{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-warning .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-warning .fc-content:before{background: #ffb822;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-warning, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-warning{background: #ffb822;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-warning .fc-title, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-warning .fc-title{color: #111111;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-warning .fc-description, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-warning .fc-description{color: #111111;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-warning .fc-time, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-warning .fc-time{color: #111111;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-danger .fc-content:before, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-danger .fc-content:before{background: #fd397a;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-danger, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-danger{background: #fd397a;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-danger .fc-title, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-danger .fc-title{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-danger .fc-description, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-danger .fc-description{color: #ffffff;}
.fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.fc-event-solid-danger .fc-time, .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.fc-event-solid-danger .fc-time{color: #ffffff;}
.fc-unthemed th.fc-day-header > a, .fc-unthemed th.fc-day-header > span{color: #a7abc3;}
.fc-unthemed .fc-list-heading td{background: #ebedf2;border-color: #ebedf2;}
.fc-unthemed .fc-list-heading .fc-list-heading-main{color: #6c7293;}
.fc-unthemed .fc-list-heading .fc-list-heading-alt{color: #a7abc3;}
.fc-unthemed .fc-divider, .fc-unthemed .fc-popover .fc-header, .fc-unthemed .fc-list-heading td{background: #f7f8fa;}
.fc-unthemed .fc-list-item:hover td{background: transparent !important;}
.fc-unthemed .fc-list-item .fc-event-dot{background: #e2e5ec;border-color: #e2e5ec;}
.fc-unthemed .fc-list-item.fc-event-solid-brand .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-brand .fc-event-dot{background: #5d78ff;border-color: #5d78ff;}
.fc-unthemed .fc-list-item.fc-event-solid-light .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-light .fc-event-dot{background: #ffffff;border-color: #ffffff;}
.fc-unthemed .fc-list-item.fc-event-solid-dark .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-dark .fc-event-dot{background: #282a3c;border-color: #282a3c;}
.fc-unthemed .fc-list-item.fc-event-solid-primary .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-primary .fc-event-dot{background: #5867dd;border-color: #5867dd;}
.fc-unthemed .fc-list-item.fc-event-solid-success .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-success .fc-event-dot{background: #0abb87;border-color: #0abb87;}
.fc-unthemed .fc-list-item.fc-event-solid-info .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-info .fc-event-dot{background: #5578eb;border-color: #5578eb;}
.fc-unthemed .fc-list-item.fc-event-solid-warning .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-warning .fc-event-dot{background: #ffb822;border-color: #ffb822;}
.fc-unthemed .fc-list-item.fc-event-solid-danger .fc-event-dot, .fc-unthemed .fc-list-item.fc-event-danger .fc-event-dot{background: #fd397a;border-color: #fd397a;}
.fc-unthemed .fc-toolbar .fc-button{color: #a7abc3;background: transparent;border: 1px solid #ebedf2;text-shadow: none !important;-webkit-box-shadow: none !important;box-shadow: none !important;}
.fc-unthemed .fc-toolbar .fc-button .fc-icon{color: #a7abc3;}
.fc-unthemed .fc-toolbar .fc-button:hover{border: 0;background: #f7f8fa;border: 1px solid #ebedf2;color: #a7abc3;}
.fc-unthemed .fc-toolbar .fc-button:focus, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button.fc-state-active{background: #5d78ff;color: #ffffff;border: 1px solid #5d78ff;-webkit-box-shadow: none;box-shadow: none;text-shadow: none;}
.fc-unthemed .fc-toolbar .fc-button:focus .fc-icon, .fc-unthemed .fc-toolbar .fc-button:active .fc-icon, .fc-unthemed .fc-toolbar .fc-button.fc-state-active .fc-icon{color: #ffffff;}
.fc-unthemed .fc-toolbar .fc-button.fc-state-disabled{opacity: 0.7;}
.datepicker{width: 265px;padding: 10px;}
.datepicker.datepicker-orient-top{margin-top: 8px;}
.datepicker table{width: 100%;}
.datepicker td, .datepicker th{font-weight: regular;width: 35px;height: 35px;border-radius: 3px;}
.datepicker thead th{color: #a7abc3;}
.datepicker thead th.prev, .datepicker thead th.datepicker-switch, .datepicker thead th.next{font-weight: 500;color: #a7abc3;}
.datepicker thead th.prev i, .datepicker thead th.datepicker-switch i, .datepicker thead th.next i{font-size: 1.2rem;color: #a7abc3;}
.datepicker thead th.prev i:before, .datepicker thead th.datepicker-switch i:before, .datepicker thead th.next i:before{line-height: 0;vertical-align: middle;}
.datepicker thead th.prev:hover, .datepicker thead th.datepicker-switch:hover, .datepicker thead th.next:hover{background: #f7f8fa !important;}
.datepicker thead th.dow{color: #6c7293;font-weight: 500;}
.datepicker tbody tr > td.day{color: #6c7293;}
.datepicker tbody tr > td.day:hover{background: #ebedf2;color: #6c7293;}
.datepicker tbody tr > td.day.old{color: #a7abc3;}
.datepicker tbody tr > td.day.new{color: #6c7293;}
.datepicker tbody tr > td.day.selected, .datepicker tbody tr > td.day.selected:hover, .datepicker tbody tr > td.day.active, .datepicker tbody tr > td.day.active:hover{background: #5867dd;color: #ffffff;}
.datepicker tbody tr > td.day.today{position: relative;background: rgba(93, 120, 255, 0.7) !important;color: #ffffff !important;}
.datepicker tbody tr > td.day.today:before{content: '';display: inline-block;border: solid transparent;border-width: 0 0 7px 7px;border-bottom-color: #ffffff;border-top-color: #ebedf2;position: absolute;bottom: 4px;right: 4px;}
.datepicker tbody tr > td.day.range{background: #f7f8fa;}
.datepicker tbody tr > td span.year, .datepicker tbody tr > td span.hour, .datepicker tbody tr > td span.minute, .datepicker tbody tr > td span.month{color: #6c7293;}
.datepicker tbody tr > td span.year:hover, .datepicker tbody tr > td span.hour:hover, .datepicker tbody tr > td span.minute:hover, .datepicker tbody tr > td span.month:hover{background: #f7f8fa;}
.datepicker tbody tr > td span.year.focused, .datepicker tbody tr > td span.year.focused:hover, .datepicker tbody tr > td span.year.active:hover, .datepicker tbody tr > td span.year.active.focused:hover, .datepicker tbody tr > td span.year.active, .datepicker tbody tr > td span.hour.focused, .datepicker tbody tr > td span.hour.focused:hover, .datepicker tbody tr > td span.hour.active:hover, .datepicker tbody tr > td span.hour.active.focused:hover, .datepicker tbody tr > td span.hour.active, .datepicker tbody tr > td span.minute.focused, .datepicker tbody tr > td span.minute.focused:hover, .datepicker tbody tr > td span.minute.active:hover, .datepicker tbody tr > td span.minute.active.focused:hover, .datepicker tbody tr > td span.minute.active, .datepicker tbody tr > td span.month.focused, .datepicker tbody tr > td span.month.focused:hover, .datepicker tbody tr > td span.month.active:hover, .datepicker tbody tr > td span.month.active.focused:hover, .datepicker tbody tr > td span.month.active{background: #5867dd;color: #ffffff;}
.datepicker tfoot tr > th.today, .datepicker tfoot tr > th.clear{border-radius: 3px;font-weight: 500;}
.datepicker tfoot tr > th.today:hover, .datepicker tfoot tr > th.clear:hover{background: #ebedf2;}
.datepicker.datepicker-inline{border: 1px solid #ebedf2;}
.input-daterange .input-group-addon{min-width: 44px;}
.input-daterange input{text-align: left;}
.alert[data-notify]{min-width: 300px;}
.alert[data-notify] .close{right: 10px !important;padding: 0.25rem 0 0 2rem;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;font-weight: 300;}
.alert[data-notify] .close:before{content: "";}
.alert[data-notify] .close:before{font-size: 1.1rem;color: #fff;opacity: 0.7;-webkit-transition: all 0.3s;transition: all 0.3s;}
.alert[data-notify] .close:hover:before{opacity: 1;-webkit-transition: all 0.3s;transition: all 0.3s;}
@media (min-width: 769px) and (max-width: 1024px){
.alert[data-notify]{max-width: 70%;}
}
@media (max-width: 768px){
.alert[data-notify]{max-width: 90%;}
}
.alert[data-notify][data-notify-position=top-center], .alert[data-notify][data-notify-position=bottom-center]{width: 30%;}
@media (min-width: 769px) and (max-width: 1024px){
.alert[data-notify][data-notify-position=top-center], .alert[data-notify][data-notify-position=bottom-center]{width: 70%;}
}
@media (max-width: 768px){
.alert[data-notify][data-notify-position=top-center], .alert[data-notify][data-notify-position=bottom-center]{width: 90%;}
}
.alert[data-notify] .icon{position: absolute;}
.alert[data-notify] [class^="la-"], .alert[data-notify] [class*=" la-"]{font-size: 1.8rem;}
.alert[data-notify] [class^="fa-"], .alert[data-notify] [class*=" fa-"]{font-size: 1.6rem;}
.alert[data-notify] [class^="flaticon-"], .alert[data-notify] [class*=" flaticon-"], .alert[data-notify] [class^="flaticon2-"], .alert[data-notify] [class*=" flaticon2-"]{font-size: 1.8rem;}
.alert[data-notify] [class^="la-"], .alert[data-notify] [class*=" la-"]{margin-top: -0.1rem;}
.alert[data-notify] [class^="fa-"], .alert[data-notify] [class*=" fa-"]{margin-top: -0.1rem;}
.alert[data-notify] [class^="flaticon-"], .alert[data-notify] [class*=" flaticon-"], .alert[data-notify] [class^="flaticon2-"], .alert[data-notify] [class*=" flaticon2-"]{margin-top: -0.4rem;}
.alert[data-notify] [data-notify=title]{display: block;font-weight: 500;}
.alert[data-notify] .icon ~ [data-notify=title]{padding-left: 2.85rem;}
.alert[data-notify] .icon ~ [data-notify=message]{display: inline-block;padding-left: 2.85rem;}
.alert[data-notify] [data-notify=title]:not(:empty) ~ [data-notify=message]{margin-top: 0.2rem;}
.alert[data-notify] .progress{margin-top: 0.5rem;line-height: 0.5rem;height: 0.5rem;}
[data-switch=true]{opacity: 0;}
.bootstrap-switch .bootstrap-switch-label{background: #fff;}
.bootstrap-switch .bootstrap-switch-handle-on, .bootstrap-switch .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-label{padding-top: 0.65rem;padding-bottom: 0.65rem;padding-left: 1rem;padding-right: 1rem;line-height: 1.5;letter-spacing: 0.1rem;font-size: 0.9rem;font-weight: 400;}
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on, .bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off, .bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label{font-size: 0.85rem;line-height: 1.5;}
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on, .bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off, .bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label{padding-top: 0.5rem;padding-bottom: 0.5rem;padding-left: 1rem;padding-right: 1rem;font-size: 0.9rem;line-height: 1.5;}
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on, .bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off, .bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label{padding: 1.15rem 1.65rem;font-size: 1.1rem;line-height: 1.5;}
.bootstrap-switch{display: inline-block;}
.bootstrap-switch.bootstrap-switch--pill .bootstrap-switch{border-radius: 4px;}
.bootstrap-switch.bootstrap-switch--square .bootstrap-switch{border-radius: 0 !important;}
.bootstrap-switch.bootstrap-switch--square .bootstrap-switch .bootstrap-switch-handle-off, .bootstrap-switch.bootstrap-switch--square .bootstrap-switch .bootstrap-switch-handle-on{border-radius: 0 !important;}
.bootstrap-switch{border-color: #e2e5ec;}
.bootstrap-switch .bootstrap-switch-label{color: #a7abc3;background: #fff;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-brand, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-brand{background-color: #5d78ff;border-color: #5d78ff;color: #ffffff;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-light, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-light{background-color: #ffffff;border-color: #ffffff;color: #282a3c;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-dark, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-dark{background-color: #282a3c;border-color: #282a3c;color: #ffffff;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary{background-color: #5867dd;border-color: #5867dd;color: #ffffff;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success{background-color: #0abb87;border-color: #0abb87;color: #ffffff;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info{background-color: #5578eb;border-color: #5578eb;color: #ffffff;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning{background-color: #ffb822;border-color: #ffb822;color: #111111;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger{background-color: #fd397a;border-color: #fd397a;color: #ffffff;}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default{background-color: #e2e5ec;border-color: #e2e5ec;color: #a7abc3;}
.bootstrap-switch.bootstrap-switch--air .bootstrap-switch{-webkit-box-shadow: 0px 0px 11px 0px rgba(56, 36, 99, 0.08);box-shadow: 0px 0px 11px 0px rgba(56, 36, 99, 0.08);}
.datetimepicker{width: 265px;padding: 10px;left: auto;}
.datetimepicker.datetimepicker-orient-top{margin-top: 8px;}
.datetimepicker table{width: 100%;}
.datetimepicker td, .datetimepicker th{width: 35px;height: 35px;border-radius: 3px;font-weight: normal;}
.datetimepicker thead th i{font-size: 1.2rem;}
.datetimepicker thead th.prev, .datetimepicker thead th.switch, .datetimepicker thead th.next{color: #a7abc3;font-weight: 500;}
.datetimepicker thead th.prev .glyphicon, .datetimepicker thead th.switch .glyphicon, .datetimepicker thead th.next .glyphicon{color: #a7abc3;}
.datetimepicker thead th.prev:hover, .datetimepicker thead th.switch:hover, .datetimepicker thead th.next:hover{background: #f7f8fa !important;}
.datetimepicker thead th.dow{font-weight: 500;color: #6c7293;}
.datetimepicker thead th.next > span, .datetimepicker thead th.prev > span{display: inline-block;font: normal normal normal 16px/1 "LineAwesome";font-size: inherit;text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;font-size: 1.2rem;}
.datetimepicker thead th.next > span:before{content: "\f112";}
.datetimepicker thead th.prev > span:before{content: "\f111";}
.datetimepicker tbody tr > td span{color: #6c7293;}
.datetimepicker tbody tr > td.day{color: #6c7293;}
.datetimepicker tbody tr > td.day:hover{background: #f7f8fa;}
.datetimepicker tbody tr > td.day.old{color: #a7abc3;}
.datetimepicker tbody tr > td.day.new{color: #6c7293;}
.datetimepicker tbody tr > td.day.selected, .datetimepicker tbody tr > td.day.active{background: #5867dd;color: #ffffff;}
.datetimepicker tbody tr > td.day.today{position: relative;background: rgba(93, 120, 255, 0.7) !important;color: #ffffff !important;}
.datetimepicker tbody tr > td.day.today:before{content: '';display: inline-block;border: solid transparent;border-width: 0 0 7px 7px;border-bottom-color: #ffffff;border-top-color: #ebedf2;position: absolute;bottom: 4px;right: 4px;}
.datetimepicker tbody tr > td.day.range{background: #f7f8fa;}
.datetimepicker tbody tr > td span.year, .datetimepicker tbody tr > td span.hour, .datetimepicker tbody tr > td span.minute, .datetimepicker tbody tr > td span.month{color: #6c7293;}
.datetimepicker tbody tr > td span.year.focused, .datetimepicker tbody tr > td span.year:hover, .datetimepicker tbody tr > td span.hour.focused, .datetimepicker tbody tr > td span.hour:hover, .datetimepicker tbody tr > td span.minute.focused, .datetimepicker tbody tr > td span.minute:hover, .datetimepicker tbody tr > td span.month.focused, .datetimepicker tbody tr > td span.month:hover{background: #f7f8fa;}
.datetimepicker tbody tr > td span.year.active:hover, .datetimepicker tbody tr > td span.year.active, .datetimepicker tbody tr > td span.hour.active:hover, .datetimepicker tbody tr > td span.hour.active, .datetimepicker tbody tr > td span.minute.active:hover, .datetimepicker tbody tr > td span.minute.active, .datetimepicker tbody tr > td span.month.active:hover, .datetimepicker tbody tr > td span.month.active{background: #5867dd;color: #ffffff;}
.datetimepicker tfoot tr > th.today, .datetimepicker tfoot tr > th.clear{border-radius: 3px;font-weight: 500;}
.datetimepicker tfoot tr > th.today:hover, .datetimepicker tfoot tr > th.clear:hover{background-color: #f7f8fa;}
.datetimepicker.datetimepicker-inline{border: 1px solid #f7f8fa;}
.input-daterange .input-group-addon{min-width: 44px;}
.bootstrap-timepicker-widget{left: auto;width: 175px;}
.bootstrap-timepicker-widget .bootstrap-timepicker-hour, .bootstrap-timepicker-widget .bootstrap-timepicker-minute, .bootstrap-timepicker-widget .bootstrap-timepicker-meridian, .bootstrap-timepicker-widget .bootstrap-timepicker-second{border: 0;background: none;outline: none !important;-webkit-box-shadow: none;box-shadow: none;}
.bootstrap-timepicker-widget table td > a{border: 0;}
.bootstrap-timepicker-widget table td > a i{font-size: 1.1rem;}
.bootstrap-timepicker-widget .bootstrap-timepicker-hour, .bootstrap-timepicker-widget .bootstrap-timepicker-minute, .bootstrap-timepicker-widget .bootstrap-timepicker-meridian, .bootstrap-timepicker-widget .bootstrap-timepicker-second{color: #6c7293;}
.bootstrap-timepicker-widget table td > a .la{font-size: 1.2rem;color: #a7abc3;}
.bootstrap-timepicker-widget table td > a:hover{background: #f7f8fa;}
.daterangepicker{padding: 0;margin: 0;width: auto;-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);border-radius: 4px;border: 0;font-family: Poppins;z-index: 95;}
.modal-open .daterangepicker{z-index: 1051;}
.daterangepicker:after, .daterangepicker:before{display: none;}
.daterangepicker .ranges ul{padding: 1rem 0;width: 170px;}
.daterangepicker .ranges li{padding: 0.7rem 1.75rem;font-weight: 400;font-size: 1rem;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.daterangepicker .ranges li:hover, .daterangepicker .ranges li.active{background-color: #f7f8fa;color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.daterangepicker.show-calendar .ranges{border-right: 1px solid #ebedf2;}
.daterangepicker.show-ranges .drp-calendar.left{border-left: 0;}
.daterangepicker.show-calendar .ranges{margin-top: 0;}
.daterangepicker .drp-buttons{padding: 1rem 1.75rem;border-top: 1px solid #ebedf2;}
.daterangepicker .drp-buttons .btn{font-size: 0.9rem;font-weight: 400;padding: 0.5rem 1rem;border-radius: 0;border-radius: 4px;}
.daterangepicker .drp-calendar.left, .daterangepicker .drp-calendar.right{padding: 1rem 1.5rem 1rem 1.5rem;}
.daterangepicker .drp-calendar th{font-weight: 500;font-size: 1rem;color: #6c7293;}
.daterangepicker .drp-calendar th.month{font-weight: 400;color: #a7abc3;}
.daterangepicker .drp-calendar th.next span, .daterangepicker .drp-calendar th.prev span{border-width: 0 1px 1px 0;border-color: #a7abc3;}
.daterangepicker .drp-calendar th.next span{margin-right: 1px;}
.daterangepicker .drp-calendar th.prev span{margin-left: 1px;}
.daterangepicker .drp-calendar td{font-size: 1rem;color: #6c7293;}
.daterangepicker .drp-calendar td.available.off{color: #adb1c7;}
.daterangepicker .drp-calendar td.active{background-color: #5d78ff;color: #ffffff;border-radius: 0;border-radius: 4px;}
.daterangepicker .drp-calendar td.active.start-date{border-top-right-radius: 0;border-bottom-right-radius: 0;}
.daterangepicker .drp-calendar td.active.end-date{border-top-left-radius: 0;border-bottom-left-radius: 0;}
.daterangepicker .drp-calendar td.active.start-date.end-date{border-radius: 4px;}
.daterangepicker .drp-calendar td.today, .daterangepicker .drp-calendar td.today.active{border-radius: 0;border-radius: 4px;background: rgba(93, 120, 255, 0.7) !important;color: #ffffff !important;}
.daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today){
background-color: #f7f8fa;color: #6c7293;}
.daterangepicker select{border-radius: 4px;background: transparent !important;border-color: #ebedf2;color: #6c7293;}
.bootstrap-touchspin .input-group-btn-vertical .btn{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;text-align: center;}
.bootstrap-touchspin .input-group-btn-vertical .btn:first-child{border-bottom: 0;}
.bootstrap-touchspin .input-group-btn-vertical .btn i{padding: 0;font-size: 1rem;}
.bootstrap-maxlength{z-index: 1040 !important;}
.bootstrap-maxlength.kt-badge{display: -webkit-inline-box !important;display: -ms-inline-flexbox !important;display: inline-flex !important;}
.modal .bootstrap-maxlength{z-index: 1060 !important;}
.bootstrap-select > .dropdown-toggle{position: relative;outline: none !important;padding: 0.65rem 1rem;border-radius: 4px !important;}
.bootstrap-select > .dropdown-toggle:focus{outline: none !important;}
.bootstrap-select > .dropdown-toggle:after{border: 0;margin-right: -2px;font-size: 0.6rem;display: inline-block;font-family: Flaticon2;font-style: normal;font-weight: normal;font-variant: normal;line-height: 1;text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";}
.bootstrap-select > .dropdown-toggle:before{width: auto;}
.bootstrap-select .dropdown-menu.inner{display: block;}
.bootstrap-select .dropdown-menu.inner > li > a{display: block;position: relative;outline: none !important;padding: 10px 15px;}
.bootstrap-select .dropdown-menu.inner > li > a:hover{text-decoration: none;}
.bootstrap-select .dropdown-menu.inner > li > a [class^="la-"], .bootstrap-select .dropdown-menu.inner > li > a [class*=" la-"]{font-size: 1.2rem;}
.bootstrap-select .dropdown-menu.inner > li > a [class^="fa-"], .bootstrap-select .dropdown-menu.inner > li > a [class*=" fa-"]{font-size: 1.2rem;}
.bootstrap-select .dropdown-menu.inner > li > a [class^="flaticon-"], .bootstrap-select .dropdown-menu.inner > li > a [class*=" flaticon-"], .bootstrap-select .dropdown-menu.inner > li > a [class^="flaticon2-"], .bootstrap-select .dropdown-menu.inner > li > a [class*=" flaticon2-"]{font-size: 1.1rem;}
.bootstrap-select .dropdown-menu.inner > li > a i{vertical-align: middle;}
.bootstrap-select .dropdown-menu.inner > li.selected > a span.check-mark{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;top: 50%;position: absolute;margin-top: -0.425rem;font-size: 0.85rem;right: 1rem;display: inline-block;}
.bootstrap-select .dropdown-menu.inner > li.selected > a span.check-mark:before{content: "";}
.bootstrap-select .dropdown-menu.inner > li.selected > a span.check-mark:after{display: none;}
.bootstrap-select .dropdown-menu.inner > li.divider{margin: 10px 0;}
.bootstrap-select .dropdown-menu.inner > li.hidden{display: none;}
.bootstrap-select .dropdown-menu.inner > li.no-results{padding: 10px 15px;}
.bootstrap-select .popover-title{padding: 10px 15px;margin-bottom: 5px;}
.bootstrap-select .popover-title .close{display: none;}
.bootstrap-select .bs-searchbox, .bootstrap-select .bs-actionsbox, .bootstrap-select .bs-donebutton{padding: 10px 15px;}
.bootstrap-select > .dropdown-toggle.btn-light, .bootstrap-select > .dropdown-toggle.btn-secondary{background: #fff;color: #6c7293;border-color: #e2e5ec;-webkit-box-shadow: none;box-shadow: none;}
.bootstrap-select > .dropdown-toggle.btn-light:focus, .bootstrap-select > .dropdown-toggle.btn-light.active, .bootstrap-select > .dropdown-toggle.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.btn-secondary.active{background-color: transparent;border-color: #9aabff;}
.bootstrap-select > .dropdown-toggle.btn-light.disabled, .bootstrap-select > .dropdown-toggle.btn-light:disabled, .bootstrap-select > .dropdown-toggle.btn-secondary.disabled, .bootstrap-select > .dropdown-toggle.btn-secondary:disabled{background: #f7f8fa;border-color: #f7f8fa;}
.bootstrap-select > .dropdown-toggle.btn-light .filter-option [class^="la-"], .bootstrap-select > .dropdown-toggle.btn-light .filter-option [class*=" la-"], .bootstrap-select > .dropdown-toggle.btn-light .filter-option [class^="fa-"], .bootstrap-select > .dropdown-toggle.btn-light .filter-option [class*=" fa-"], .bootstrap-select > .dropdown-toggle.btn-light .filter-option [class^="flaticon-"], .bootstrap-select > .dropdown-toggle.btn-light .filter-option [class*=" flaticon-"], .bootstrap-select > .dropdown-toggle.btn-light .filter-option [class^="flaticon2-"], .bootstrap-select > .dropdown-toggle.btn-light .filter-option [class*=" flaticon2-"], .bootstrap-select > .dropdown-toggle.btn-secondary .filter-option [class^="la-"], .bootstrap-select > .dropdown-toggle.btn-secondary .filter-option [class*=" la-"], .bootstrap-select > .dropdown-toggle.btn-secondary .filter-option [class^="fa-"], .bootstrap-select > .dropdown-toggle.btn-secondary .filter-option [class*=" fa-"], .bootstrap-select > .dropdown-toggle.btn-secondary .filter-option [class^="flaticon-"], .bootstrap-select > .dropdown-toggle.btn-secondary .filter-option [class*=" flaticon-"], .bootstrap-select > .dropdown-toggle.btn-secondary .filter-option [class^="flaticon2-"], .bootstrap-select > .dropdown-toggle.btn-secondary .filter-option [class*=" flaticon2-"]{color: #a7abc3;}
.bootstrap-select > .dropdown-toggle.bs-placeholder{color: #a7abc3;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-brand{color: #ffffff;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-brand > .filter-option{opacity: 0.8;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-light{color: #282a3c;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-light > .filter-option{opacity: 0.8;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark{color: #ffffff;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark > .filter-option{opacity: 0.8;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary{color: #ffffff;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary > .filter-option{opacity: 0.8;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success{color: #ffffff;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success > .filter-option{opacity: 0.8;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info{color: #ffffff;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info > .filter-option{opacity: 0.8;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-warning{color: #111111;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-warning > .filter-option{opacity: 0.8;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger{color: #ffffff;}
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger > .filter-option{opacity: 0.8;}
.bootstrap-select.show > .dropdown-toggle.btn-light, .bootstrap-select.show > .dropdown-toggle.btn-secondary{border-color: #9aabff;-webkit-box-shadow: none;box-shadow: none;}
.bootstrap-select.is-invalid .btn.dropdown-toggle{border-color: #fd397a;}
.bootstrap-select.is-valid .btn.dropdown-toggle{border-color: #0abb87;}
.bootstrap-select .dropdown-menu.inner > li > a .text{color: #6c7293;}
.bootstrap-select .dropdown-menu.inner > li > a .text small{color: #adb1c7;}
.bootstrap-select .dropdown-menu.inner > li > a span.check-mark{color: #a7abc3;}
.bootstrap-select .dropdown-menu.inner > li > a [class^="la-"], .bootstrap-select .dropdown-menu.inner > li > a [class*=" la-"], .bootstrap-select .dropdown-menu.inner > li > a [class^="fa-"], .bootstrap-select .dropdown-menu.inner > li > a [class*=" fa-"], .bootstrap-select .dropdown-menu.inner > li > a [class^="flaticon-"], .bootstrap-select .dropdown-menu.inner > li > a [class*=" flaticon-"], .bootstrap-select .dropdown-menu.inner > li > a [class^="flaticon2-"], .bootstrap-select .dropdown-menu.inner > li > a [class*=" flaticon2-"]{color: #adb1c7;}
.bootstrap-select .dropdown-menu.inner > li.selected > a, .bootstrap-select .dropdown-menu.inner > li:hover > a{background: #f7f8fa;}
.bootstrap-select .dropdown-menu.inner > li.selected > a .text, .bootstrap-select .dropdown-menu.inner > li:hover > a .text{color: #6c7293;}
.bootstrap-select .dropdown-menu.inner > li.selected > a [class^="la-"], .bootstrap-select .dropdown-menu.inner > li.selected > a [class*=" la-"], .bootstrap-select .dropdown-menu.inner > li.selected > a [class^="fa-"], .bootstrap-select .dropdown-menu.inner > li.selected > a [class*=" fa-"], .bootstrap-select .dropdown-menu.inner > li.selected > a [class^="flaticon-"], .bootstrap-select .dropdown-menu.inner > li.selected > a [class*=" flaticon-"], .bootstrap-select .dropdown-menu.inner > li.selected > a [class^="flaticon2-"], .bootstrap-select .dropdown-menu.inner > li.selected > a [class*=" flaticon2-"], .bootstrap-select .dropdown-menu.inner > li:hover > a [class^="la-"], .bootstrap-select .dropdown-menu.inner > li:hover > a [class*=" la-"], .bootstrap-select .dropdown-menu.inner > li:hover > a [class^="fa-"], .bootstrap-select .dropdown-menu.inner > li:hover > a [class*=" fa-"], .bootstrap-select .dropdown-menu.inner > li:hover > a [class^="flaticon-"], .bootstrap-select .dropdown-menu.inner > li:hover > a [class*=" flaticon-"], .bootstrap-select .dropdown-menu.inner > li:hover > a [class^="flaticon2-"], .bootstrap-select .dropdown-menu.inner > li:hover > a [class*=" flaticon2-"]{color: #adb1c7;}
.bootstrap-select .dropdown-menu.inner > li.selected > a span.check-mark, .bootstrap-select .dropdown-menu.inner > li:hover > a span.check-mark{color: #a7abc3;}
.bootstrap-select .dropdown-menu.inner > li.dropdown-header{color: #a7abc3;}
.bootstrap-select .dropdown-menu.inner > li.selected > a{background: #f7f8fa;}
.bootstrap-select .dropdown-menu.inner > li.disabled > a{opacity: 0.6;}
.bootstrap-select .dropdown-menu.inner > li.divider{border-bottom: 1px solid #f7f8fa;}
.bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a{background: #5867dd;}
.bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a .text{color: #ffffff;}
.bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a [class^="la-"], .bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a [class*=" la-"], .bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a [class^="fa-"], .bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a [class*=" fa-"], .bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a [class^="flaticon-"], .bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a [class*=" flaticon-"], .bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a [class^="flaticon2-"], .bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a [class*=" flaticon2-"]{color: #ffffff;}
.bootstrap-select .dropdown-menu.inner > li.active:not(.selected) > a span.check-mark{color: #ffffff;}
.bootstrap-select .dropdown-menu.inner > li.no-results{color: #a7abc3;}
.bootstrap-select .popover-title{background: #f7f8fa;border: 0;}
.bootstrap-select .dropdown-menu{margin-top: 1px;}
#session-timeout-dialog .modal-header .close{position: absolute;right: 25px;font-size: 0;top: 1.75rem;}
#session-timeout-dialog .modal-header .modal-title{-webkit-box-flex: 1;-ms-flex: 1 auto;flex: 1 auto;}
.m-jqvmap{position: relative;overflow: hidden;}
.m-jqvmap .jqvmap-zoomout, .m-jqvmap .jqvmap-zoomin{height: 16px;width: 16px;line-height: 12px;vertical-align: middle;background-color: m-base-color(label, 3);}
.m-ion-range-slider .irs .irs-min, .m-ion-range-slider .irs .irs-max, .m-ion-range-slider .irs .irs-from, .m-ion-range-slider .irs .irs-to, .m-ion-range-slider .irs .irs-single{padding: 2px 5px 1px 5px;}
.typeahead .twitter-typeahead{display: block !important;}
.typeahead .tt-menu{border: 0;margin: 0;min-width: 275px;padding: 5px 0;left: auto /*rtl:ignore*/ !important;border-radius: 0;border-radius: 4px;}
.typeahead .tt-menu .tt-dataset .tt-suggestion{padding: 5px 15px;font-size: 1rem;}
.typeahead .form-control.tt-hint{color: #a7abc3;}
.typeahead .form-control.tt-input{color: #6c7293;}
.typeahead .tt-menu{background: white;-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);}
.typeahead .tt-menu .tt-dataset .tt-suggestion{color: #6c7293;cursor: pointer;}
.typeahead .tt-menu .tt-dataset .tt-suggestion .tt-highlight{color: #464457;font-weight: 500;}
.typeahead .tt-menu .tt-dataset .tt-suggestion:hover{background: #f7f8fa;}
.noUi-target.noUi-horizontal{height: 15px;}
.noUi-target.noUi-horizontal .noUi-handle{width: 24px;height: 24px;left: -17px;top: -6px;border-radius: 50%;outline: none;}
.noUi-target.noUi-horizontal .noUi-handle::before{display: none;}
.noUi-target.noUi-horizontal .noUi-handle::after{display: none;}
.noUi-target.noUi-vertical{height: 150px;width: 15px;}
.noUi-target.noUi-vertical .noUi-handle{width: 24px;height: 24px;left: -5.5px;top: -6px;border-radius: 50%;outline: none;}
.noUi-target.noUi-vertical .noUi-handle::before{display: none;}
.noUi-target.noUi-vertical .noUi-handle::after{display: none;}
.noUi-target.nouislider.nouislider-connect-brand .noUi-connect{background: #5d78ff;}
.noUi-target.nouislider.nouislider-handle-brand .noUi-handle{border: 0;background: #5d78ff;-webkit-box-shadow: 0 3px 6px -3px #5d78ff;box-shadow: 0 3px 6px -3px #5d78ff;}
.noUi-target.nouislider.nouislider-connect-light .noUi-connect{background: #ffffff;}
.noUi-target.nouislider.nouislider-handle-light .noUi-handle{border: 0;background: #ffffff;-webkit-box-shadow: 0 3px 6px -3px #ffffff;box-shadow: 0 3px 6px -3px #ffffff;}
.noUi-target.nouislider.nouislider-connect-dark .noUi-connect{background: #282a3c;}
.noUi-target.nouislider.nouislider-handle-dark .noUi-handle{border: 0;background: #282a3c;-webkit-box-shadow: 0 3px 6px -3px #282a3c;box-shadow: 0 3px 6px -3px #282a3c;}
.noUi-target.nouislider.nouislider-connect-primary .noUi-connect{background: #5867dd;}
.noUi-target.nouislider.nouislider-handle-primary .noUi-handle{border: 0;background: #5867dd;-webkit-box-shadow: 0 3px 6px -3px #5867dd;box-shadow: 0 3px 6px -3px #5867dd;}
.noUi-target.nouislider.nouislider-connect-success .noUi-connect{background: #0abb87;}
.noUi-target.nouislider.nouislider-handle-success .noUi-handle{border: 0;background: #0abb87;-webkit-box-shadow: 0 3px 6px -3px #0abb87;box-shadow: 0 3px 6px -3px #0abb87;}
.noUi-target.nouislider.nouislider-connect-info .noUi-connect{background: #5578eb;}
.noUi-target.nouislider.nouislider-handle-info .noUi-handle{border: 0;background: #5578eb;-webkit-box-shadow: 0 3px 6px -3px #5578eb;box-shadow: 0 3px 6px -3px #5578eb;}
.noUi-target.nouislider.nouislider-connect-warning .noUi-connect{background: #ffb822;}
.noUi-target.nouislider.nouislider-handle-warning .noUi-handle{border: 0;background: #ffb822;-webkit-box-shadow: 0 3px 6px -3px #ffb822;box-shadow: 0 3px 6px -3px #ffb822;}
.noUi-target.nouislider.nouislider-connect-danger .noUi-connect{background: #fd397a;}
.noUi-target.nouislider.nouislider-handle-danger .noUi-handle{border: 0;background: #fd397a;-webkit-box-shadow: 0 3px 6px -3px #fd397a;box-shadow: 0 3px 6px -3px #fd397a;}
.noUi-target{border: 1px solid #e2e5ec;background: #f7f8fa;-webkit-box-shadow: none;box-shadow: none;}
.noUi-target .noUi-connect{background: #5d78ff;}
.noUi-target .noUi-handle{border: 1px solid #f7f8fa;-webkit-box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.7);box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.7);}
.note-popover{display: none;}
.note-editor{z-index: 1;border-radius: 4px;}
.note-editor .note-toolbar-wrapper{position: relative;z-index: 1;}
.note-editor .modal .modal-header .close{position: absolute;right: 25px;top: 20px;font-size: 0;}
.note-editor .panel-heading.note-toolbar{padding: 5px 10px 10px 10px;z-index: 0;border-top-left-radius: 4px;border-top-right-radius: 4px;}
.note-editor .panel-heading.note-toolbar .btn{outline: none !important;}
.note-editor .panel-heading.note-toolbar .dropdown-menu{min-width: 185px;}
.note-editor .panel-heading.note-toolbar .note-color .dropdown-menu{min-width: 350px;padding: 10px;}
.note-editor .panel-heading.note-toolbar .note-color .dropdown-menu > li{display: table;table-layout: fixed;}
.note-editor .panel-heading.note-toolbar .note-color .dropdown-menu > li > .btn-group{display: table-cell !important;}
.note-editor .panel-heading.note-toolbar .note-color .dropdown-menu > li > .btn-group:first-child{padding-right: 10px;}
.note-editor .panel-heading.note-toolbar .note-color .dropdown-menu > li > .btn-group .note-color-reset{margin: 5px 0 10px 0;}
.note-editor .panel-heading.note-toolbar .note-color .dropdown-menu > li > .btn-group .note-palette-title{margin: 5px 0;text-align: left;border: 0;}
.note-editor .dropdown-toggle::after{display: none;}
.note-editor.note-frame{border: 1px solid #e2e5ec;}
.note-editor.note-frame .panel-heading.note-toolbar{background: #f7f8fa;}
.note-editor.note-frame .note-statusbar{background: #f7f8fa;}
.note-editor .note-toolbar .note-btn-group .btn{background: white;border: 1px solid #ebedf2;}
.note-editor .note-toolbar .note-btn-group .btn i{vertical-align: initial;line-height: initial;padding-right: 0;color: #a7abc3;}
.note-editor .note-toolbar .note-btn-group .btn:hover, .note-editor .note-toolbar .note-btn-group .btn:focus, .note-editor .note-toolbar .note-btn-group .btn:active, .note-editor .note-toolbar .note-btn-group .btn.active{background: transparent;color: #6c7293;}
.note-editable .table.table-bordered th, .note-editable .table.table-bordered td{border: 1px solid #e2e5ec;}
.is-valid .note-editor{border-color: #0abb87;}
.is-invalid .note-editor{border-color: #fd397a;}
.md-editor{border-radius: 4px;border-bottom-left-radius: 0;border-bottom-right-radius: 0;outline: none !important;}
.md-editor .md-footer, .md-editor .md-header{padding: 10px 10px;}
.md-editor .md-header{border-top-left-radius: 4px;border-top-right-radius: 4px;}
.md-editor .md-header .btn-group{margin-right: 10px;}
.md-editor .md-header .btn-group .btn{background: white;border: 1px solid #ebedf2;color: #a7abc3;-webkit-transition: all 0.3s;transition: all 0.3s;}
.md-editor .md-header .btn-group .btn:hover, .md-editor .md-header .btn-group .btn:focus, .md-editor .md-header .btn-group .btn:active, .md-editor .md-header .btn-group .btn.active{-webkit-transition: all 0.3s;transition: all 0.3s;color: #6c7293;background: transparent;}
.md-editor .md-footer{border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
.md-editor > textarea{padding: 10px 10px;border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
.md-editor{border: 1px solid #e2e5ec;}
.md-editor.active{border: 1px solid #e2e5ec;-webkit-box-shadow: none;box-shadow: none;}
.md-editor .md-footer, .md-editor .md-header{background: #f7f8fa;}
.md-editor > textarea{background: #f7f8fa;}
.md-editor.active > textarea{background: #f7f8fa;}
.is-valid .md-editor{border-color: #0abb87;}
.is-invalid .md-editor{border-color: #fd397a;}
.dropzone{border-radius: 4px;padding: 20px;text-align: center;cursor: pointer;}
.dropzone .dropzone-msg-title{margin: 0 0 5px 0;padding: 0;font-weight: 600;font-size: 1.1rem;}
.dropzone .dropzone-msg-desc{font-size: 0.9rem;}
.dropzone .dz-preview .dz-image{border-radius: 4px;}
.dropzone{border: 2px dashed #ebedf2;}
.dropzone .dropzone-msg-title{color: #6c7293;}
.dropzone .dropzone-msg-desc{color: #a7abc3;}
.dropzone.dropzone-brand{border-color: #5d78ff;}
.dropzone.dropzone-light{border-color: #ffffff;}
.dropzone.dropzone-dark{border-color: #282a3c;}
.dropzone.dropzone-primary{border-color: #5867dd;}
.dropzone.dropzone-success{border-color: #0abb87;}
.dropzone.dropzone-info{border-color: #5578eb;}
.dropzone.dropzone-warning{border-color: #ffb822;}
.dropzone.dropzone-danger{border-color: #fd397a;}
.recaptcha{padding: 15px;border-radius: 4px;}
.recaptcha .recaptcha-img{margin-bottom: 10px;}
.recaptcha .input-group .btn i{padding-right: 0;}
.recaptcha .input-group .form-control{border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important;}
.recaptcha{border: 1px solid #ebedf2;}
.recaptcha .recaptcha_only_if_incorrect_sol{color: #fd397a;}
.toast{background-position: 15px center /*rtl:calc(100% - 15px) center*/ !important;-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);border: 0;}
.toast .toast-close-button{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;outline: none !important;font-size: 0;content: " ";}
.toast .toast-close-button:before{content: "";}
.toast .toast-close-button:before{font-size: 0.9rem;}
.toast .toast-title{font-size: 1rem;font-weight: 500;margin-bottom: 0.25rem;}
.toast .toast-message{font-size: 0.9rem;}
.toast-success{background-color: #0abb87;color: #ffffff;-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);}
#toast-container > div:hover{-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.125);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.125);}
.toast-warning{background-color: #ffb822;color: #111111;-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);}
#toast-container > div:hover{-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.125);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.125);}
.toast-info{background-color: #5578eb;color: #ffffff;-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);}
#toast-container > div:hover{-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.125);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.125);}
.toast-error{background-color: #fd397a;color: #ffffff;-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);}
#toast-container > div:hover{-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.125);box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.125);}
.kt-gmaps{}
.kt-gmaps img{max-width: none;}
.kt-gmaps.kt-gmaps--static > div{background-repeat: no-repeat;background-position: 50% 50%;display: block;}
.fa-lg{font-size: 1.33333em !important;}
.fa-xs{font-size: .75em !important;}
.fa-sm{font-size: .875em !important;}
.fa-1x{font-size: 1em !important;}
.fa-2x{font-size: 2em !important;}
.fa-3x{font-size: 3em !important;}
.fa-4x{font-size: 4em !important;}
.fa-5x{font-size: 5em !important;}
.fa-6x{font-size: 6em !important;}
.fa-7x{font-size: 7em !important;}
.fa-8x{font-size: 8em !important;}
.fa-9x{font-size: 9em !important;}
.fa-10x{font-size: 10em !important;}
.jstree-default .jstree-disabled{cursor: not-allowed;line-height: auto;height: auto;}
.jstree-default .jstree-icon.la{font-size: 1.5rem;}
.jstree-default .jstree-icon.fa{font-size: 1.2rem;}
.jstree-open > .jstree-anchor > .fa-folder:before{margin-left: 2px;content: "\f07c";}
.jstree-open > .jstree-anchor > .la-folder:before{margin-left: 2px;content: "\f200";}
.jstree-default.jstree-rtl .jstree-node{background-position: 100% 1px/*rtl:ignore*/ !important;}
.jstree-default.jstree-rtl .jstree-last{background: transparent /*rtl:ignore*/;background-repeat: no-repeat;}
.jstree-rtl .jstree-anchor{padding: 0 4px 0 8px/*rtl:ignore*/;}
.vakata-context, .vakata-context ul{padding: 0;min-width: 125px;font-size: 1rem;font-family: Poppins;border-radius: 4px;}
.vakata-context li, .vakata-context ul li{border: 0;}
.vakata-context li a, .vakata-context ul li a{padding: 0 10px;border: 0;line-height: 2.2em;}
.vakata-context li a i, .vakata-context ul li a i{display: none;}
.vakata-context li a .vakata-contextmenu-sep, .vakata-context ul li a .vakata-contextmenu-sep{display: none;}
.vakata-context li a span, .vakata-context li a ins, .vakata-context ul li a span, .vakata-context ul li a ins{display: none;border: 0 !important;}
.vakata-context .vakata-context-hover > a, .vakata-context li a:hover, .vakata-context ul .vakata-context-hover > a, .vakata-context ul li a:hover{margin: 0;}
.vakata-context .vakata-context-hover > a .span, .vakata-context .vakata-context-hover > a .ins, .vakata-context li a:hover .span, .vakata-context li a:hover .ins, .vakata-context ul .vakata-context-hover > a .span, .vakata-context ul .vakata-context-hover > a .ins, .vakata-context ul li a:hover .span, .vakata-context ul li a:hover .ins{border: 0 !important;}
.vakata-context .vakata-context-separator a, .vakata-context-rtl .vakata-context-separator a{margin: 0;border: 0;}
.jstree-rename-input{outline: none !important;padding: 2px 6px !important;margin-right: -4px !important;border-radius: 4px;}
.jstree-default .jstree-anchor{color: #6c7293;}
.jstree-default .jstree-icon{color: #6c7293;}
.jstree-default .jstree-disabled{opacity: 0.7;}
.jstree-default .jstree-disabled .jstree-icon{color: #6c7293;}
.jstree-default .jstree-clicked{border: 0;background: #f7f8fa;-webkit-box-shadow: none;box-shadow: none;}
.jstree-default .jstree-hovered{border: 0;background-color: #ebedf2;-webkit-box-shadow: none;box-shadow: none;}
.jstree-default .jstree-wholerow-clicked, .jstree-default .jstree-wholerow-clicked{background: #ebedf2;-webkit-box-shadow: none;box-shadow: none;}
.jstree-default .jstree-wholerow-hovered, .jstree-default.jstree-wholerow .jstree-wholerow-hovered{border: 0;background-color: #ebedf2;-webkit-box-shadow: none;box-shadow: none;}
.vakata-context, .vakata-context ul{background: #ebedf2;-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);border: 0;}
.vakata-context .vakata-context-hover > a, .vakata-context li a:hover, .vakata-context ul .vakata-context-hover > a, .vakata-context ul li a:hover{background: #ebedf2;color: #a7abc3;-webkit-box-shadow: none;box-shadow: none;}
.vakata-context .vakata-context-hover > a .span, .vakata-context .vakata-context-hover > a .ins, .vakata-context li a:hover .span, .vakata-context li a:hover .ins, .vakata-context ul .vakata-context-hover > a .span, .vakata-context ul .vakata-context-hover > a .ins, .vakata-context ul li a:hover .span, .vakata-context ul li a:hover .ins{border: 0 !important;}
.vakata-context .vakata-context-separator a, .vakata-context-rtl .vakata-context-separator a{margin: 0;border: 0;}
.jstree-rename-input{background-color: #ebedf2 !important;border: 1px solid #f7f8fa !important;}
.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple{outline: none !important;border-radius: 4px;height: auto;line-height: 0;}
.select2-container--default .select2-selection--single .select2-selection__arrow, .select2-container--default .select2-selection--multiple .select2-selection__arrow{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;border: 0;top: 50%;position: absolute;margin-top: 0;margin-left: 0;font-size: 0.85rem;left: auto;right: 0;display: inline-block;width: 1.9rem;}
.select2-container--default .select2-selection--single .select2-selection__arrow:before, .select2-container--default .select2-selection--multiple .select2-selection__arrow:before{content: "";}
.select2-container--default .select2-selection--single .select2-selection__arrow b, .select2-container--default .select2-selection--multiple .select2-selection__arrow b{display: none;}
.select2-container--default .select2-selection--single .select2-selection__rendered{position: relative;padding: 0.65rem 3rem 0.65rem 1rem;line-height: 1.5;}
.select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__clear{border: 0;position: absolute;top: 50%;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";font-size: 1.4rem;display: inline-block;left: auto;right: 1.85rem;margin-right: 0.4rem;margin-top: -1rem;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered{padding: 0.37rem 1rem;line-height: 1.5;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice{padding: 0.05rem 0.4rem 0.05rem 0.4rem;font-size: 1rem;margin: 0.1rem 0.4rem 0.1rem 0;position: relative;float: left/*rtl:right*/;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";font-size: 1.4rem;display: inline-block;line-height: 0;margin-right: 0.3rem;position: relative;top: 0.1rem;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search__field{font-weight: 300;margin: 0.25rem 0.25rem 0.25rem 0;}
.select2-container--default .select2-search--dropdown .select2-search__field{outline: none !important;border-radius: 4px;}
.select2-container--default .select2-search--dropdown{padding: 15px 15px;}
.select2-container--default .select2-results__option{padding: 5px 15px;}
.select2-container--default .select2-results__option[aria-disabled=true]{cursor: not-allowed;}
.select2-container--default .select2-results__option .select2-results__group{padding: 5px 15px;font-weight: 600;}
.select2-container--default .select2-results__option .select2-results__option{padding: 5px 30px;}
.select2-container .select2-search--inline .select2-search__field{margin: 0;}
select.select2{opacity: 0;}
.select2 > select.form-control{opacity: 0;}
.input-group > .select2-hidden-accessible:first-child + .select2-container > .selection .select2-selection--single{border-top-right-radius: 0;border-bottom-right-radius: 0;}
.input-group > .select2-hidden-accessible:first-child + .select2-container > .selection, .input-group > .select2-hidden-accessible:first-child + .select2-container > .selection.form-control{border-top-left-radius: 0;border-bottom-right-radius: 0;border-top-right-radius: 0;}
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container:last-child > .selection .select2-selection--single{border-top-left-radius: 0;border-bottom-left-radius: 0;}
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container:last-child > .selection .select2-selection, .input-group > .select2-hidden-accessible:not(:first-child) + .select2-container:last-child > .selection .select2-selection.form-control{border-top-left-radius: 0;border-bottom-left-radius: 0;}
.input-group > .select2-hidden-accessible:first-child + .select2-container + .input-group-text, .input-group > .select2-hidden-accessible:not(:first-child) + .select2-container + .input-group-text{border-left: 0;}
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container:not(:last-child) > .selection .select2-selection--single{border-top-left-radius: 0;border-bottom-left-radius: 0;}
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container:not(:last-child) > .selection .select2-selection, .input-group > .select2-hidden-accessible:not(:first-child) + .select2-container:not(:last-child) > .selection .select2-selection.form-control{border-top-left-radius: 0;border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
.select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single{border: 1px solid #e2e5ec;}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder, .select2-container--default .select2-selection--single .select2-selection__placeholder{color: #a7abc3;}
.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--multiple, .select2-container--default.select2-container--open .select2-selection--single{border-color: #9aabff;}
.select2-container--default .select2-selection--single .select2-selection__rendered{color: #6c7293;}
.select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__clear{color: #a7abc3;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered{color: #6c7293;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice{color: #a7abc3;background: #f7f8fa;border: 1px solid #ebedf2;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove{color: #a7abc3;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search__field::-moz-placeholder{color: #a7abc3;opacity: 1;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search__field:-ms-input-placeholder{color: #a7abc3;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search__field::-webkit-input-placeholder{color: #a7abc3;}
.select2-container--default .select2-search--dropdown .select2-search__field{border: 1px solid #e2e5ec;}
.select2-container--default .select2-dropdown{border: 1px solid #e2e5ec;-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);}
.select2-container--default .select2-results__option[aria-selected=true]{background: #f7f8fa;color: #a7abc3;}
.select2-container--default .select2-results__option.select2-results__option--highlighted{background: #f7f8fa;color: #a7abc3;}
.select2-container--default.select2-container--disabled{cursor: not-allowed;}
.select2-container--default.select2-container--disabled .select2-selection--multiple, .select2-container--default.select2-container--disabled .select2-selection--single{cursor: not-allowed;background: #f7f8fa;border-color: #a7abc3;}
.select2.select2--air .select2-container--default .select2-selection--single, .select2.select2--air .select2-container--default .select2-selection--multiple{-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);}
.select2.select2--solid .select2-container--default .select2-selection--multiple, .select2.select2--solid .select2-container--default .select2-selection--single{background-color: #f7f8fa;border-color: #ebedf2;}
.select2.select2--solid .select2-container--default .select2-selection--multiple .select2-selection__placeholder, .select2.select2--solid .select2-container--default .select2-selection--single .select2-selection__placeholder{color: #a7abc3;}
.m-form.m-form--state .is-valid .select2-container--default .select2-selection--multiple, .m-form.m-form--state .is-valid .select2-container--default .select2-selection--single{border-color: success;}
.m-form.m-form--state .is-valid .select2-container--default.select2-container--focus .select2-selection--multiple, .m-form.m-form--state .is-valid .select2-container--default.select2-container--focus .select2-selection--single, .m-form.m-form--state .is-valid .select2-container--default.select2-container--open .select2-selection--multiple, .m-form.m-form--state .is-valid .select2-container--default.select2-container--open .select2-selection--single{border-color: success;}
.m-form.m-form--state .is-invalid .select2-container--default .select2-selection--multiple, .m-form.m-form--state .is-invalid .select2-container--default .select2-selection--single{border-color: danger;}
.m-form.m-form--state .is-invalid .select2-container--default.select2-container--focus .select2-selection--multiple, .m-form.m-form--state .is-invalid .select2-container--default.select2-container--focus .select2-selection--single, .m-form.m-form--state .is-invalid .select2-container--default.select2-container--open .select2-selection--multiple, .m-form.m-form--state .is-invalid .select2-container--default.select2-container--open .select2-selection--single{border-color: danger;}
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown), body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown){
overflow-y: initial !important;}
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.kt-sweetalert2--nopadding), body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.kt-sweetalert2--nopadding){
padding-right: 0 !important;}
@media screen\0{html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown), body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown){
overflow: visible;}
}
.swal2-popup{border-radius: 4px;padding: 3rem;}
.swal2-popup .swal2-title{font-weight: 500;font-size: 1.2rem;margin: 0 0 1rem;}
.swal2-popup .swal2-content{font-weight: normal;font-size: 1rem;}
.swal2-popup .btn{margin: 15px 5px 0;}
.swal2-popup .swal2-styled:focus{-webkit-box-shadow: none;box-shadow: none;}
.swal2-container{overflow-y: hidden !important;}
body.swal2-height-auto{height: 100% !important;}
.swal2-container.swal2-shown{background-color: rgba(0, 0, 0, 0.2);}
.swal2-popup .swal2-title{color: #464457;}
.swal2-popup .swal2-content{color: #a7abc3;}
.dataTables_wrapper{padding: 0 !important;}
.dataTables_wrapper .dataTable{width: 100% !important;border-collapse: initial !important;border-spacing: 0 !important;margin: 1rem 0 !important;}
.dataTables_wrapper .dataTable th, .dataTables_wrapper .dataTable td{vertical-align: middle;}
.dataTables_wrapper .dataTable th .kt-checkbox, .dataTables_wrapper .dataTable td .kt-checkbox{margin-top: 0.3rem;margin-bottom: 0;}
.dataTables_wrapper .dataTable th.dt-center, .dataTables_wrapper .dataTable td.dt-center{text-align: center;}
.dataTables_wrapper .dataTable th.dt-left, .dataTables_wrapper .dataTable td.dt-left{text-align: left;}
.dataTables_wrapper .dataTable th.dt-right, .dataTables_wrapper .dataTable td.dt-right{text-align: right;}
.dataTables_wrapper .dataTable .filter th, .dataTables_wrapper .dataTable .filter td{vertical-align: top;}
.dataTables_wrapper .dataTable .filter th .input-group + .input-group, .dataTables_wrapper .dataTable .filter td .input-group + .input-group{margin-top: 0.5rem;}
.dataTables_wrapper .dataTable .filter th .btn + .btn, .dataTables_wrapper .dataTable .filter td .btn + .btn{margin-top: 0.5rem;}
.dataTables_wrapper .dataTable .group th, .dataTables_wrapper .dataTable .group td{font-size: 1.1rem;font-weight: 500;}
.dataTables_wrapper .dataTables_scroll{margin: 1rem 0 !important;}
.dataTables_wrapper .dataTables_scroll .dataTable{margin: 0 !important;}
.dataTables_wrapper .dataTables_paginate .pagination .page-item{margin-left: 0.4rem;}
.dataTables_wrapper .dataTables_paginate .pagination .page-item > .page-link{border-radius: 3px;cursor: pointer;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;height: 2.25rem;min-width: 2.25rem;vertical-align: middle;padding: 0.5rem;text-align: center;position: relative;font-size: 1rem;line-height: 1rem;font-weight: 400;}
.dataTables_wrapper .dataTables_paginate .pagination .page-item > .page-link > i{font-size: 1rem;text-align: center;display: inline-block;}
.dataTables_wrapper .text-right .dataTables_filter, .dataTables_wrapper .text-left .dataTables_filter{display: inline-block;}
.dataTables_wrapper .dataTables_info{padding-top: 0.45rem;}
.dataTables_wrapper .dataTables_pager{text-align: right;}
.dataTables_wrapper .dataTables_pager .dataTables_length{margin-right: 0.5rem;display: inline-block;}
.dataTables_wrapper .dataTables_pager .dataTables_length + .dataTables_paginate{margin-left: 1rem;}
.dataTables_wrapper .dataTables_pager .dataTables_info{display: inline-block;margin-right: 0.5rem;}
.dataTables_wrapper .dataTables_pager .dataTables_paginate{display: inline-block;}
@media (max-width: 1024px){
.dataTables_wrapper .dataTables_pager{margin-top: 1rem;text-align: center;}
}
@media (max-width: 768px){
.dataTables_wrapper .dataTables_paginate{margin-left: 0 !important;}
.dataTables_wrapper .dataTables_paginate .pagination .page-item:first-child{margin-left: 0 !important;}
.dataTables_wrapper .dataTables_paginate .pagination{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
}
.dataTables_wrapper table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before{-webkit-box-shadow: none;box-shadow: none;border-radius: 0;top: 50%;left: 8px;height: 18px;width: 18px;margin-top: -9px;font-size: 18px;color: #5d78ff;border: 0;background-color: transparent;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";}
.dataTables_wrapper table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";color: #5d78ff;background-color: transparent;}
.dataTables_wrapper .child .dtr-details{display: table !important;}
.dataTables_wrapper .child .dtr-details > li{display: table-row !important;border: 0 !important;}
.dataTables_wrapper .child .dtr-details > li .dtr-title{display: table-cell;vertical-align: top;border-bottom: 1px dashed #efefef;font-weight: 500;padding: 0.5rem 2rem 0.5rem 0;}
.dataTables_wrapper .child .dtr-details > li .dtr-title:after{content: ':';}
.dataTables_wrapper .child .dtr-details > li .dtr-data{display: table-cell;vertical-align: top;border-bottom: 1px dashed #efefef;padding: 0.5rem 0;}
.dataTables_wrapper .child .dtr-details > li:last-child .dtr-title{border-bottom: 0;}
.dataTables_wrapper .child .dtr-details > li:last-child .dtr-data{border-bottom: 0;}
.dt-bootstrap4 .dropdown .dropdown-menu.dropdown-menu-right{margin-left: 65px;}
.dataTables_wrapper .dataTable th, .dataTables_wrapper .dataTable td{color: #6c7293;}
.dataTables_wrapper .dataTable .selected th, .dataTables_wrapper .dataTable .selected td{background-color: #f7f8fa;color: #6c7293;}
.dataTables_wrapper .dataTable .group th, .dataTables_wrapper .dataTable .group td{background-color: #f7f8fa;}
.dataTables_wrapper .pagination .page-item > .page-link{color: #6c7293;border: 0;outline: none !important;}
.dataTables_wrapper .pagination .page-item.previous > .page-link, .dataTables_wrapper .pagination .page-item.next > .page-link, .dataTables_wrapper .pagination .page-item.last > .page-link, .dataTables_wrapper .pagination .page-item.first > .page-link{background: #ebe9f2;}
.dataTables_wrapper .pagination .page-item.previous > .page-link:hover, .dataTables_wrapper .pagination .page-item.next > .page-link:hover, .dataTables_wrapper .pagination .page-item.last > .page-link:hover, .dataTables_wrapper .pagination .page-item.first > .page-link:hover{background: #5d78ff;color: #ffffff;}
.dataTables_wrapper .pagination .page-item.active > .page-link{background: #5d78ff;color: #ffffff;}
.dataTables_wrapper .pagination .page-item:hover > .page-link{background: #5d78ff;color: #ffffff;}
.dataTables_wrapper .pagination .page-item.disabled > .page-link, .dataTables_wrapper .pagination .page-item.disabled:hover > .page-link{opacity: 0.6;}
.kt-repeater .kt-repeater__row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.kt-repeater .kt-repeater__row > input{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-repeater .kt-repeater__row .kt-repeater__close{background-color: transparent;border: none;}
.kt-repeater .kt-repeater__row .form-text{-ms-flex-preferred-size: 100%;flex-basis: 100%;}
.kt-repeater .kt-repeater__item{position: relative;}
.kt-repeater .kt-repeater__item .btn-icon{height: auto;}
.kt-repeater .kt-repeater__item:first-child .form-control{border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;}
.kt-repeater .kt-repeater__item:first-child .input-group-append{display: none;}
.kt-repeater .kt-repeater__close--align-right{position: absolute;top: 0;right: 0;}
.kt-grid-nav-v2{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.kt-grid-nav-v2 .kt-grid-nav-v2__item{display: -webkit-box;display: -ms-flexbox;display: flex;width: calc(50% - 3px);min-height: 180px;border-radius: 6px;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 1rem;background: #f7f8fa;-webkit-transition: all 0.3s;transition: all 0.3s;}
@media screen\0{.kt-grid-nav-v2 .kt-grid-nav-v2__item{height: 180px;}
}
.kt-grid-nav-v2 .kt-grid-nav-v2__item .kt-grid-nav-v2__item-icon{display: inline-block;text-align: center;margin-bottom: 0.5rem;}
.kt-grid-nav-v2 .kt-grid-nav-v2__item .kt-grid-nav-v2__item-icon i{font-size: 2.25rem;color: #5d78ff;}
.kt-grid-nav-v2 .kt-grid-nav-v2__item .kt-grid-nav-v2__item-title{display: inline-block;text-align: center;font-size: 1.2rem;font-weight: 500;color: #5d5b6f;}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:hover{-webkit-transition: all 0.3s;transition: all 0.3s;text-transform: none;background: #5d78ff;}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:hover .kt-grid-nav-v2__item-icon i{color: #ffffff;}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:hover .kt-grid-nav-v2__item-title{color: #ffffff;}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:nth-of-type(odd){
margin: 3px 3px 3px 0;}
.kt-grid-nav-v2 .kt-grid-nav-v2__item:nth-of-type(even){
margin: 3px 0 3px 3px;}
.kt-grid-nav{padding: 0;margin: 0;display: table;table-layout: fixed;width: 100%;background-size: cover;background-repeat: no-repeat;}
.kt-grid-nav > .kt-grid-nav__row{display: table-row;}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item{display: table-cell;vertical-align: middle;text-align: center;padding: 3.3rem 0.75rem;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item:hover{text-decoration: none;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item .kt-grid-nav__icon{text-align: center;font-size: 2.5rem;}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item .kt-grid-nav__title{display: block;line-height: 1;text-align: center;margin: 10px 0 0 0;font-size: 1.2rem;font-weight: 500;}
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item .kt-grid-nav__desc{display: block;line-height: 1;text-align: center;margin: 10px 0 0 0;font-size: 1rem;font-weight: 400;}
.dropdown-menu .kt-grid-nav{border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
@media (max-width: 1024px){
.kt-grid-nav > .kt-grid-nav__row > .kt-grid-nav__item{padding: 1rem 0.5rem;}
}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item{border-right: 1px solid #ebedf2;border-bottom: 1px solid #ebedf2;}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item:last-child{border-right: 0;}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item .kt-grid-nav__icon{color: #5d78ff;}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item .kt-grid-nav__title{color: #5d78ff;}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item .kt-grid-nav__desc{color: #a7abc3;}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item:hover{background: #f7f8fa;}
.kt-grid-nav .kt-grid-nav__row .kt-grid-nav__item:hover .kt-grid-nav__icon{color: #6c7293;}
.kt-grid-nav .kt-grid-nav__row:last-child .kt-grid-nav__item{border-bottom: 0 !important;}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item{border-right: 1px solid rgba(255, 255, 255, 0.1);border-bottom: 1px solid rgba(255, 255, 255, 0.1);}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item:last-child{border-right: 0;}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item .kt-grid-nav__icon{color: #5d78ff;}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item .kt-grid-nav__title{color: #fff;}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item .kt-grid-nav__desc{color: rgba(255, 255, 255, 0.5);}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item:hover{background: transparent;}
.kt-grid-nav.kt-grid-nav--skin-dark .kt-grid-nav__item:hover .kt-grid-nav__icon{color: #5d78ff;}
.kt-head{padding: 2.5rem 1rem;margin: 0;background-size: cover;background-repeat: no-repeat;}
.kt-head.kt-head--space-sm{padding: 1.5rem 1rem;}
.kt-head.kt-head--space-lg{padding: 3rem 1rem;}
.kt-head.kt-head--fit-x{padding-left: 0;padding-right: 0;}
.kt-head.kt-head--fit-b{padding-bottom: 0 !important;}
.kt-head.kt-head--fit-t{padding-top: 0 !important;}
.kt-head.kt-head--space-bottom-sm{padding-bottom: 1rem;}
.kt-head .kt-head__title{font-size: 1.3rem;font-weight: 500;text-align: center;margin: 0;}
.kt-head .kt-head__sub{margin-top: 0.5rem;font-size: 1rem;font-weight: 400;text-align: center;}
.kt-head.kt-head--skin-dark .kt-head__title{color: #fff;}
.kt-head.kt-head--skin-dark .kt-head__sub{color: #f0f0f0;}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line{border-bottom-color: rgba(255, 255, 255, 0.3) !important;}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link{color: rgba(255, 255, 255, 0.5);}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link > i{color: rgba(255, 255, 255, 0.4);}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link:hover, .kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link.active{color: #fff;}
.kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link:hover > i, .kt-head.kt-head--skin-dark .nav.nav-tabs-line .nav-item .nav-link.active > i{color: #fff;}
.kt-head.kt-head--skin-light{background-color: #f9fafe;}
.kt-head.kt-head--skin-light .kt-head__title{color: #6c7293;}
.kt-head.kt-head--skin-light .kt-head__sub{color: #a7abc3;}
.kt-head .nav{margin: 1rem 0 0 0;}
.dropdown-menu:not(.dropdown-menu-top-unround) .kt-head{border-top-left-radius: 4px;border-top-right-radius: 4px;}
.kt-head-skin-light-bg-color{background-color: #f9fafe;}
.kt-list-pics > a{margin-right: 0.25rem;}
.kt-list-pics > a > img{width: 3.36rem;border-radius: 50%;border-radius: 3px;}
.kt-list-pics.kt-list-pics--circle > a > img{width: 3.36rem;border-radius: 50%;}
.kt-list-pics.kt-list-pics--sm > a > img{width: 2.14rem;}
.kt-list-pics.kt-list-pics-rounded--sm > a > img{width: 2.14rem;}
.kt-list-timeline .kt-list-timeline__group{margin: 0 0 2rem 0;}
.kt-list-timeline .kt-list-timeline__heading{margin: 0 0 1.25rem 0;font-weight: 700;font-size: 1rem;text-transform: uppercase;}
.kt-list-timeline .kt-list-timeline__items{position: relative;padding: 0;margin: 0;}
.kt-list-timeline .kt-list-timeline__items:before{background-color: #ebedf2;position: absolute;display: block;content: '';width: 1px;height: 100%;top: 0;bottom: 0;left: 3px;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item{position: relative;display: table;table-layout: fixed;width: 100%;padding: 0.3rem 0;margin: 0.5rem 0;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:first-child:before, .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:last-child:before{background-color: white;position: absolute;display: block;content: '';width: 1px;height: 50%;top: 0;bottom: 0;left: 3px;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:last-child:before{top: auto;bottom: 0;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge{text-align: left;vertical-align: middle;display: table-cell;position: relative;width: 20px;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge:before{background-color: #ebedf2;position: absolute;display: block;content: '';width: 7px;height: 7px;left: 0;top: 50%;margin-top: -3.5px;border-radius: 100%;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge.kt-list-timeline__badge--brand:before{background-color: #5d78ff;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge.kt-list-timeline__badge--light:before{background-color: #ffffff;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge.kt-list-timeline__badge--dark:before{background-color: #282a3c;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge.kt-list-timeline__badge--primary:before{background-color: #5867dd;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge.kt-list-timeline__badge--success:before{background-color: #0abb87;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge.kt-list-timeline__badge--info:before{background-color: #5578eb;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge.kt-list-timeline__badge--warning:before{background-color: #ffb822;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__badge.kt-list-timeline__badge--danger:before{background-color: #fd397a;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__icon{text-align: left;vertical-align: middle;display: table-cell;width: 30px;padding: 0 5px 0 0;line-height: 0;color: #a7abc3;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class^="la-"], .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class*=" la-"]{font-size: 1.6rem;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class^="fa-"], .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class*=" fa-"]{font-size: 1.2rem;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class^="flaticon-"], .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class*=" flaticon-"], .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class^="flaticon2-"], .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item [class*=" flaticon2-"]{font-size: 1.6rem;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__text{display: table-cell;text-align: left;vertical-align: middle;width: 100%;padding: 0 0 0 5px;font-size: 1rem;color: #6c7293;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__text:hover{text-decoration: none;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__time{display: table-cell;text-align: right;vertical-align: middle;width: 80px;padding: 0 0 0 5px;font-size: 0.9rem;color: #a7abc3;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:first-child{padding-top: 0;margin-top: 0;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:last-child{padding-bottom: 0;margin-bottom: 0;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:first-child:before, .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item:last-child:before{background-color: #fff;}
.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item.kt-list-timeline__item--read .kt-list-timeline__badge, .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item.kt-list-timeline__item--read .kt-list-timeline__icon, .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item.kt-list-timeline__item--read .kt-list-timeline__text, .kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item.kt-list-timeline__item--read .kt-list-timeline__time{opacity: 0.5;}
.kt-mycart .kt-mycart__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 2.8rem 2rem;background-position: center;background-size: cover;}
.kt-mycart .kt-mycart__head .kt-mycart__info{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-mycart .kt-mycart__head .kt-mycart__info .kt-mycart__icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 3rem;height: 3rem;background: rgba(56, 80, 168, 0.65);border-radius: 4px;}
.kt-mycart .kt-mycart__head .kt-mycart__info .kt-mycart__icon i{font-size: 1.4rem;font-weight: 500;position: absolute;}
.kt-mycart .kt-mycart__head .kt-mycart__info .kt-mycart__title{padding-left: 1rem;color: #fff;font-size: 1.3rem;font-weight: 500;margin: 0;}
.kt-mycart .kt-mycart__head .kt-mycart__button{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-mycart .kt-mycart__head .kt-mycart__button .btn{padding: 0.3rem 0.5rem;}
.kt-mycart .kt-mycart__body{margin: 0.5rem 0;}
.kt-mycart .kt-mycart__body .kt-mycart__item{border-bottom: 1px solid #ebedf2;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 1.5rem 2rem;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__title{color: #5e5b70;font-size: 1.1rem;font-weight: 500;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__desc{padding: 0.5rem 0;color: #a7abc3;font-weight: 400;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action .kt-mycart__price{margin-right: 0.5rem;color: #6c7293;font-size: 1.2rem;font-weight: 500;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action .kt-mycart__text{color: #a7abc3;font-weight: 400;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action .kt-mycart__quantity{margin-left: 0.5rem;color: #6c7293;font-size: 1.2rem;font-weight: 500;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action .btn{font-size: 1.4rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-left: 0.5rem;width: 19px !important;height: 19px !important;padding-top: 0.1rem;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info .kt-mycart__action .btn:hover{color: #fff;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__info span{display: block;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__pic{width: 5rem;}
.kt-mycart .kt-mycart__body .kt-mycart__item .kt-mycart__container .kt-mycart__pic img{width: 5rem;height: 5rem;background-position: center;background-size: cover;border-radius: 4px;}
.kt-mycart .kt-mycart__body .ps__rail-y .ps__thumb-y{right: 5px !important;}
.kt-mycart .kt-mycart__footer{padding: 0.4rem 2rem 1.5rem 2rem;}
.kt-mycart .kt-mycart__footer .kt-mycart__section{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__subtitel span{display: block;color: #5e5b70;font-size: 1rem;font-weight: 500;}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__subtitel span:not(:first-child):not(:last-child){
padding: 0.4rem 0;}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__subtitel span:last-child{font-size: 1.1rem;font-weight: 600;}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__prices span{display: block;text-align: right;color: #a7abc3;font-size: 1.2rem;font-weight: 500;}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__prices span:not(:first-child):not(:last-child){
padding: 0.3rem 0;}
.kt-mycart .kt-mycart__footer .kt-mycart__section .kt-mycart__prices span:last-child{font-weight: 700;font-size: 1.3rem;}
.kt-mycart .kt-mycart__footer .kt-mycart__button{margin: 1.5rem 0 0.8rem 0;}
.kt-nav{display: block;padding: 0.5rem 0;margin: 0;list-style: none;border: 0;}
.kt-nav .kt-nav__item{display: block;}
.kt-nav .kt-nav__item > .kt-nav__link{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0.55rem 1.75rem;cursor: pointer;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-text{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;font-size: 1rem;color: #6c7293;font-weight: 400;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon{-webkit-box-flex: 0;-ms-flex: 0 0 30px;flex: 0 0 30px;font-size: 1.25rem;line-height: 0;color: #a7abc3;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon:before{line-height: 0;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #a7abc3;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet{text-align: center;-webkit-box-flex: 0;-ms-flex: 0 0 10px;flex: 0 0 10px;margin-right: 10px;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span{width: 8px;height: 2px;border-radius: 2px;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span, .kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span{background-color: #adb1c7;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-badge{padding: 0px 0px 0px 5px;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow{text-align: right;-webkit-box-flex: 0;-ms-flex: 0 0 20px;flex: 0 0 20px;font-size: 0.8rem;color: #a7abc3;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow:before{content: "";}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow:before{display: inline-block;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-nav .kt-nav__item > .kt-nav__link.collapsed .kt-nav__link-arrow{font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;}
.kt-nav .kt-nav__item > .kt-nav__link.collapsed .kt-nav__link-arrow:before{content: "";}
.kt-nav .kt-nav__item > .kt-nav__link.collapsed .kt-nav__link-arrow:before{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-nav .kt-nav__item > .kt-nav__sub{padding: 0;margin: 0;}
.kt-nav .kt-nav__item > .kt-nav__sub .kt-nav__item > .kt-nav__link{padding: 0.5rem 1.5rem 0.5rem 2.5rem;}
.kt-nav .kt-nav__item.kt-nav__item--last{padding-bottom: 0;}
.kt-nav .kt-nav__item.active > .kt-nav__link, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link{background-color: #f7f8fa;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-icon, .kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-text, .kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-arrow, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-icon, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-text, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-arrow{color: #5d78ff;}
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-icon g [fill], .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-icon g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-icon:hover g [fill], .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-icon:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span, .kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub) > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span{background-color: #5d78ff;}
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub > .kt-nav__link.collapsed{background-color: #f7f8fa;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub > .kt-nav__link.collapsed .kt-nav__link-icon, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub > .kt-nav__link.collapsed .kt-nav__link-text, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub > .kt-nav__link.collapsed .kt-nav__link-arrow{color: #5d78ff;}
.kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub > .kt-nav__link.collapsed .kt-nav__link-bullet.kt-nav__link-bullet--line > span, .kt-nav .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub > .kt-nav__link.collapsed .kt-nav__link-bullet.kt-nav__link-bullet--dot > span{background-color: #5d78ff;}
.kt-nav .kt-nav__item.kt-nav__item--disabled{opacity: 0.6;}
.kt-nav .kt-nav__custom{padding: 0.55rem 1.75rem;}
.kt-nav .kt-nav__section{margin-top: 1rem;padding: 0 1.75rem;}
.kt-nav .kt-nav__section .kt-nav__section-icon{color: #adb1c7;}
.kt-nav .kt-nav__section.kt-nav__section--first{margin-top: 0;}
.kt-nav .kt-nav__section .kt-nav__section-text{color: #5d78ff;font-weight: 600;font-size: 0.8rem;text-transform: uppercase;}
.kt-nav .kt-nav__separator{margin: 0.7rem 0;height: 0;display: block;border-bottom: 1px solid #ebedf2;}
.kt-nav .kt-nav__separator.kt-nav__separator--dashed{border-bottom: 1px dashed #ebedf2;}
.kt-nav .kt-nav__head, .kt-nav .kt-nav__foot{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;font-size: 1rem;color: #6c7293;font-weight: 500;padding: 0.55rem 1.75rem;}
.kt-nav .kt-nav__head > i, .kt-nav .kt-nav__foot > i{font-size: 1.2rem;color: #a7abc3;}
.kt-nav .kt-nav__head{font-size: 1.1rem;}
.kt-nav.kt-nav--fit{padding: 0;}
.kt-nav.kt-nav--fit .kt-nav__item > .kt-nav__link{padding-left: 0;padding-right: 0;}
.kt-nav.kt-nav--lg-space .kt-nav__item{margin: 0.35rem 0;}
.kt-nav.kt-nav--lg-space .kt-nav__item .kt-nav__link{padding-top: 1.5rem;padding-bottom: 1.5rem;}
.kt-nav.kt-nav--lg-space .kt-nav__item:first-child{margin-top: 0;}
.kt-nav.kt-nav--lg-space .kt-nav__item:last-child{margin-bottom: 0;}
.kt-nav.kt-nav--md-space .kt-nav__item{margin: 0.25rem 0;}
.kt-nav.kt-nav--md-space .kt-nav__item .kt-nav__link{padding-top: 0.75rem;padding-bottom: 0.75rem;}
.kt-nav.kt-nav--md-space .kt-nav__item:first-child{margin-top: 0;}
.kt-nav.kt-nav--md-space .kt-nav__item:last-child{margin-bottom: 0;}
.kt-nav.kt-nav--bold .kt-nav__item .kt-nav__link .kt-nav__link-text{font-weight: 500;}
.kt-nav.kt-nav--thin .kt-nav__item .kt-nav__link .kt-nav__link-text{font-weight: 300;}
.kt-nav.kt-nav--lg-font .kt-nav__item .kt-nav__link .kt-nav__link-text{font-size: 1.15rem;}
.kt-nav.kt-nav--xl-font .kt-nav__item .kt-nav__link .kt-nav__link-text{font-size: 1.25rem;}
.kt-nav.kt-nav--v2 .kt-nav__item{border-radius: 2rem;overflow: hidden;}
.kt-nav.kt-nav--v2 .kt-nav__item > .kt-nav__link{-webkit-transition: none;transition: none;}
.kt-nav.kt-nav--v2 .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active) > .kt-nav__link{background-color: #f4f4f9;-webkit-transition: none;transition: none;}
.kt-nav.kt-nav--v2 .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active) > .kt-nav__link .kt-nav__link-icon, .kt-nav.kt-nav--v2 .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active) > .kt-nav__link .kt-nav__link-text, .kt-nav.kt-nav--v2 .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active) > .kt-nav__link .kt-nav__link-arrow{color: #5d78ff;}
.kt-nav.kt-nav--v2 .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active) > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span, .kt-nav.kt-nav--v2 .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active) > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span{background-color: #f4f4f9;}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link{background-color: #5d78ff;-webkit-transition: none;transition: none;}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-icon, .kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-text, .kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-arrow{color: #ffffff;}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span, .kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span{background-color: #ffffff;}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link{background-color: #5d78ff;}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link .kt-nav__link-icon, .kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link .kt-nav__link-text, .kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link .kt-nav__link-arrow{color: #ffffff;}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--line > span, .kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link .kt-nav__link-bullet.kt-nav__link-bullet--dot > span{background-color: #ffffff;}
.kt-nav.kt-nav--v3 .kt-nav__item{margin: 0;}
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link{-webkit-transition: all 0.3s;transition: all 0.3s;padding-top: 1rem;padding-bottom: 1rem;border-left: 2px solid transparent;}
.kt-nav.kt-nav--v3 .kt-nav__item.active .kt-nav__link, .kt-nav.kt-nav--v3 .kt-nav__item.kt-nav__link--active .kt-nav__link{-webkit-transition: all 0.3s;transition: all 0.3s;background-color: #f7f8fa;border-left: 2px solid #5d78ff;}
.kt-nav.kt-nav--v3 .kt-nav__item.active .kt-nav__link .kt-nav__link-text, .kt-nav.kt-nav--v3 .kt-nav__item.kt-nav__link--active .kt-nav__link .kt-nav__link-text{color: #5d78ff;}
.kt-nav.kt-nav--v3 .kt-nav__item:hover{-webkit-transition: all 0.3s;transition: all 0.3s;background-color: #f7f8fa;}
.kt-nav.kt-nav--v3 .kt-nav__item:hover .kt-nav__link .kt-nav__link-text{color: #5d78ff;}
.kt-notification .kt-notification__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 1.1rem 1.5rem;border-bottom: 1px solid #f7f8fa;-webkit-transition: background-color 0.3s ease;transition: background-color 0.3s ease;}
.kt-notification .kt-notification__item:last-child{border-bottom: 1px solid transparent;}
.kt-notification .kt-notification__item .kt-notification__item-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 0;-ms-flex: 0 0 2.5rem;flex: 0 0 2.5rem;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-notification .kt-notification__item .kt-notification__item-icon i{font-size: 1.4rem;color: #5d78ff;}
.kt-notification .kt-notification__item .kt-notification__item-icon svg{height: 24px;width: 24px;margin-left: -4px;}
.kt-notification .kt-notification__item .kt-notification__item-details{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-notification .kt-notification__item .kt-notification__item-details .kt-notification__item-title{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;font-size: 1rem;font-weight: 400;color: #6c7293;}
.kt-notification .kt-notification__item .kt-notification__item-details .kt-notification__item-time{font-size: 1rem;font-weight: 300;color: #a7abc3;}
.kt-notification .kt-notification__item:hover{-webkit-transition: background-color 0.3s ease;transition: background-color 0.3s ease;text-decoration: none;background-color: #f7f8fa;}
.kt-notification .kt-notification__item:hover .kt-notification__item-details .kt-notification__item-title{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;color: #5d78ff;}
.kt-notification .kt-notification__item.kt-notification__item--read{opacity: 0.5;}
.kt-notification .kt-notification__item:after{border: 0;font-family: "LineAwesome";text-decoration: inherit;text-rendering: optimizeLegibility;text-transform: none;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;content: "";font-size: 0.8rem;line-height: 0;position: relative;color: #a7abc3;}
.kt-notification .kt-notification__custom{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;border-top: 1px solid #f7f8fa;padding: 1.6rem 1.5rem;}
.kt-notification.kt-notification--fit .kt-notification__item{padding: 0.55rem 0;}
.kt-notification.kt-notification--fit .kt-notification__item:hover{background-color: transparent;}
.kt-notification.kt-notification--fit .kt-notification__item:hover .kt-notification__item-title{color: #5d78ff;}
.kt-notification-item-padding-x{padding-left: 1.5rem !important;padding-right: 1.5rem !important;}
.kt-notification-item-margin-x{margin-left: 1.5rem !important;margin-right: 1.5rem !important;}
.kt-notification-v2{margin: 0;}
.kt-notification-v2 .kt-notification-v2__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 1rem;text-decoration: none;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__item-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;background-color: #f7f8fa;height: 50px;width: 50px;min-width: 50px;border-radius: 50%;}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__item-icon i{font-size: 1.5rem;}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper{padding-left: 1.5rem;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper .kt-notification-v2__item-title{font-size: 1.1rem;font-weight: 500;color: #5d5b6f;}
.kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper .kt-notification-v2__item-desc{font-size: 1rem;font-weight: 300;color: #b3b1c2;}
.kt-notification-v2 .kt-notification-v2__item:hover{background-color: #f7f8fa;border-radius: 5px;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-notification-v2 .kt-notification-v2__item:hover .kt-notification-v2__item-icon{background-color: #ebedf2;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-card-profile{padding: 5px 0;margin: 0;}
.kt-card-profile .kt-card-profile__pic{text-align: center;padding: 0 5px 0 0;text-align: center;}
.kt-card-profile .kt-card-profile__pic .kt-card-profile__pic-wrapper{padding: 8px;display: inline-block;margin: 2rem auto;border-radius: 100%;border: 2px solid #ebedf2;}
.kt-card-profile .kt-card-profile__pic img{text-align: center;max-width: 130px !important;margin: 0 auto !important;border-radius: 100%;}
.kt-card-profile .kt-card-profile__pic .kt-card-profile__no-photo{height: 130px;width: 130px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;text-align: center;position: relative;border-radius: 100%;}
.kt-card-profile .kt-card-profile__pic .kt-card-profile__no-photo > span{line-height: 0;font-weight: 700;font-size: 1.3rem;text-transform: uppercase;}
.kt-card-profile .kt-card-profile__title{text-align: center;font-size: 1.3rem;font-weight: 500;}
.kt-card-profile .kt-card-profile__details{text-align: center;padding: 0 0 0 0;}
.kt-card-profile .kt-card-profile__details .kt-card-profile__name{display: block;padding: 0 0 0 0;font-size: 1.3rem;font-weight: 500;color: #6c7293;}
.kt-card-profile .kt-card-profile__details .kt-card-profile__email{display: inline-block;padding: 6px 0 0 0;font-size: 500;color: #6c7293;}
.kt-notes .kt-notes__items{margin: 0;padding: 0;}
.kt-notes .kt-notes__items .kt-notes__item{left: 8px;border-left: 2px solid #ebedf2;padding: 0 0 30px 45px;position: relative;margin: 0 5px 0 17px;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media{position: absolute;top: 0;left: -25.5px;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media img{max-width: 50px;max-height: 50px;border-radius: 50%;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media .kt-notes__icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 50px;height: 50px;background-color: #fff;border: 2px solid #ebedf2;border-radius: 50%;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media .kt-notes__icon i{color: #a7abc3;font-size: 1.8rem;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media .kt-notes__user{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 50px;height: 50px;font-size: 1.2rem;background-color: #fff;border: 2px solid #ebedf2;color: #a7abc3;border-radius: 50%;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__media .kt-notes__circle{width: 25px;height: 25px;display: block;border: 2px solid #ebedf2;margin-left: 12px;background-color: #fff;border-radius: 50%;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content{background-color: #F7F8FC;padding: 0.5rem 1rem 1rem 1.5rem;border-radius: 4px;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__info{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-flow: wrap;flex-flow: wrap;-webkit-box-align: baseline;-ms-flex-align: baseline;align-items: baseline;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__info .kt-notes__title{font-size: 1.1rem;color: #6c7293;font-weight: 500;padding-right: 0.5rem;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__info .kt-notes__title:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__info .kt-notes__desc{color: #a7abc3;font-weight: 400;padding-right: 1rem;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__section .kt-notes__dropdown .btn-icon{width: 26px;height: 26px;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__body{color: #a7abc3;font-weight: 400;padding-right: 3rem;padding-top: 0.5rem;display: block;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content:after{position: absolute;content: '';width: 0;height: 0;top: 10px;right: 100%;margin-right: -3.5rem;border-right: solid 10px #F7F8FC;border-bottom: solid 17px transparent;border-left: solid 17px transparent;border-top: solid 17px transparent;}
.kt-notes .kt-notes__items .kt-notes__item:last-child{border-left: 0;}
.kt-notes .kt-notes__items .kt-notes__item.kt-notes__item--clean .kt-notes__content{background-color: #fff;padding: 0;}
.kt-notes .kt-notes__items .kt-notes__item.kt-notes__item--clean .kt-notes__content:after{display: none;}
.kt-notes .kt-notes__items .kt-notes__item:last-child .kt-notes__media .kt-notes__circle{margin-left: 14px;}
@media (max-width: 1024px){
.kt-notes .kt-notes__items .kt-notes__item{margin: 0 7px 0 17px;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content{padding: 0.5rem 1rem 0.7rem 1rem;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content .kt-notes__desc{padding-top: 0.5rem;display: block;}
.kt-notes .kt-notes__items .kt-notes__item .kt-notes__content:after{margin-right: -3.8rem;}
}
.kt-timeline-v1{position: relative;width: 100%;}
.kt-timeline-v1:after{content: '';position: absolute;width: 5px;top: 0;margin-top: 0.1rem;bottom: 3rem;left: 50%;margin-left: -2.5px;background-color: #E6EAF5;}
.kt-timeline-v1.kt-timeline-v1--center{margin: 0 auto;}
.kt-timeline-v1 .kt-timeline-v1__items{padding-bottom: 6rem;position: relative;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__marker{width: 20px;height: 5px;border-radius: 4px;position: absolute;left: 50%;margin-left: -10px;background-color: #E6EAF5;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item{position: relative;margin-left: 0;width: 50%;min-height: 3rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-arrow{color: #F7F8FC;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-circle{background: white;width: 20px;height: 20px;border-radius: 50%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;z-index: 1;position: absolute;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-circle > div{border-radius: 50%;width: 6px;height: 6px;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time{position: absolute;display: inline-block;margin-top: -2.14rem;font-weight: 700;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time > span{padding-left: 5px;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-content{position: relative;border-radius: 4px;padding: 2.14rem;background-color: #F7F8FC;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-content .kt-timeline-v1__item-title{font-size: 1.2rem;font-weight: 500;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-content .kt-timeline-v1__item-title + .kt-timeline-v1__item-body{margin-top: 1.25rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-content .kt-timeline-v1__item-actions{margin-top: 1.25rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-content .kt-timeline-v1__item-desc{color: #a7abc3;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--left{left: 0;padding-right: 2.85rem;position: relative;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--left .kt-timeline-v1__item-circle{right: -10px;top: 20px;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--left .kt-timeline-v1__item-time{right: 3.8rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--left:after{position: absolute;content: '';width: 0;height: 0;top: 15px;left: 100%;margin-left: -3rem;border-left: solid 10px #F7F8FC;border-bottom: solid 17px transparent;border-right: solid 17px transparent;border-top: solid 17px transparent;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--right{left: 50%;padding-left: 2.86rem;top: -3.5rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--right .kt-timeline-v1__item-circle{top: 20px;left: -10px;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--right .kt-timeline-v1__item-time{left: 3.57rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--right:after{position: absolute;content: '';width: 0;height: 0;top: 15px;right: 100%;margin-right: -3rem;border-right: solid 10px #F7F8FC;border-bottom: solid 17px transparent;border-left: solid 17px transparent;border-top: solid 17px transparent;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--first{top: 3.5rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--first + .kt-timeline-v1__item--right{top: -1rem;}
.kt-timeline-v1.kt-timeline-v1--justified:after{left: 0;bottom: 0;}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items{position: static;margin-bottom: 40px;padding: 20px 0;}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__marker{left: 0;top: 0;}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item{width: 100%;top: 0 !important;left: 0 !important;margin-top: 50px !important;padding-left: 2.25rem;padding-right: 0;position: relative;}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item:after{position: absolute;content: '';width: 0;height: 0;top: 15px;left: 0;margin-left: 3px;border-right: solid 10px #F7F8FC;border-bottom: solid 17px transparent;border-left: solid 17px transparent;border-top: solid 17px transparent;}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time{position: absolute;display: inline-block;margin-top: -2.14rem;}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-circle{left: -10px;top: 20px;}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time{right: 100%;left: 3.57rem;}
.kt-timeline-v1.kt-timeline-v1--justified .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--first{margin-top: 20px !important;}
@media (max-width: 1399px){
.kt-timeline-v1:after{left: 0;bottom: 0;}
.kt-timeline-v1 .kt-timeline-v1__items{position: static;margin-bottom: 40px;padding: 20px 0;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__marker{left: 0;top: 0;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item{width: 100%;left: 0 !important;top: 0 !important;margin-top: 50px !important;padding-left: 2.25rem !important;padding-right: 0 !important;position: relative;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item:after{position: absolute;content: '';width: 0;height: 0;top: 15px !important;left: 0 !important;right: auto !important;margin-left: 3px !important;border-right: solid 10px #F7F8FC !important;border-bottom: solid 17px transparent !important;border-left: solid 17px transparent !important;border-top: solid 17px transparent !important;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time{position: absolute;display: inline-block;margin-top: -2.14rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-circle{left: -10px;top: 20px;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item .kt-timeline-v1__item-time{right: 100%;left: 3.57rem;}
.kt-timeline-v1 .kt-timeline-v1__items .kt-timeline-v1__item.kt-timeline-v1__item--first{margin-top: 20px !important;}
}
.kt-timeline-v2{position: relative;}
.kt-timeline-v2:before{content: '';position: absolute;left: 4.85rem;width: 0.214rem;top: 5px;bottom: 5px;height: calc(100% - 5px);background-color: #E7E9F5;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item{display: table;position: relative;margin-bottom: 1.75rem;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-time{display: table-cell;font-size: 1.3rem;font-weight: 500;vertical-align: top;position: absolute;padding-top: 0.18rem;color: #a7abc3;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-cricle{border-radius: 50%;border: 0.89rem solid white;z-index: 1;top: 0.2rem;left: 4.12rem;position: relative;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-cricle > i{font-size: 1.78rem;top: -0.93rem;left: -0.57rem;position: absolute;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-text{display: table-cell;vertical-align: top;font-size: 1rem;padding: 0.35rem 0 0 5rem;color: #a7abc3;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-text.kt-timeline-v2__item-text--bold{font-size: 1.2rem;font-weight: 500;padding-top: 0.21rem;color: #a7abc3;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-timeline-v2__item-text > span{font-weight: 700;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item .kt-list-pics{display: table-cell;vertical-align: top;}
.kt-timeline-v2 .kt-timeline-v2__items .kt-timeline-v2__item:last-child{margin-bottom: 0;}
.kt-timeline-v3 .kt-timeline-v3__item{display: table;margin-bottom: 1rem;position: relative;}
.kt-timeline-v3 .kt-timeline-v3__item:before{position: absolute;display: block;width: 0.28rem;border-radius: 0.3rem;height: 70%;left: 5.1rem;top: 0.46rem;content: "";}
.kt-timeline-v3 .kt-timeline-v3__item .kt-timeline-v3__item-time{display: table-cell;vertical-align: top;padding-top: 0.6rem;font-weight: 500;font-size: 1.3rem;position: absolute;text-align: right;width: 3.57rem;color: #a7abc3;}
.kt-timeline-v3 .kt-timeline-v3__item .kt-timeline-v3__item-desc{display: table-cell;width: 100%;vertical-align: top;padding-left: 7rem;}
.kt-timeline-v3 .kt-timeline-v3__item .kt-timeline-v3__item-desc .kt-timeline-v3__item-text{font-size: 1rem;font-weight: 400;color: #a7abc3;}
.kt-timeline-v3 .kt-timeline-v3__item .kt-timeline-v3__item-desc .kt-timeline-v3__item-user-name .kt-timeline-v3__itek-link{font-size: 0.9rem;text-decoration: none;}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--brand:before{background: #5d78ff;}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--light:before{background: #ffffff;}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--dark:before{background: #282a3c;}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--primary:before{background: #5867dd;}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--success:before{background: #0abb87;}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--info:before{background: #5578eb;}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--warning:before{background: #ffb822;}
.kt-timeline-v3 .kt-timeline-v3__item.kt-timeline-v3__item--danger:before{background: #fd397a;}
@media (max-width: 768px){
.kt-timeline-v3 .kt-timeline-v3__item{margin-left: 0;}
}
.kt-user-card{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;background-size: cover;background-repeat: no-repeat;padding: 2rem 1rem;background-color: #f9fafe;}
.kt-user-card .kt-user-card__avatar .kt-badge, .kt-user-card .kt-user-card__avatar img{width: 60px;height: 60px;border-radius: 4px;}
.kt-user-card .kt-user-card__avatar .kt-badge{font-size: 2.2rem;}
.kt-user-card .kt-user-card__name{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;color: #6c7293;font-size: 1.3rem;font-weight: 500;padding-left: 1rem;}
.kt-user-card .kt-user-card__badge{padding-left: 0.5rem;}
.kt-user-card.kt-user-card--skin-light .kt-user-card__name{color: #6c7293;}
.kt-user-card.kt-user-card--skin-dark .kt-user-card__name{color: #fff;}
.kt-user-card.kt-user-card--skin-dark .kt-user-card__avatar .kt-badge{background-color: rgba(255, 255, 255, 0.1);}
@media (max-width: 1024px){
.kt-user-card{padding: 1rem 1rem;}
.kt-user-card .kt-user-card__avatar .kt-badge, .kt-user-card .kt-user-card__avatar img{width: 40px;height: 40px;}
.kt-user-card .kt-user-card__name{font-size: 1.1rem;padding-left: 0.5rem;}
.kt-user-card .kt-user-card__badge{padding-left: 0.5rem;}
}
.kt-user-card-v2{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0;margin: 0;}
.kt-user-card-v2 .kt-user-card-v2__pic{padding-right: 0.5rem;}
.kt-user-card-v2 .kt-user-card-v2__pic .kt-badge{width: 40px;height: 40px;}
.kt-user-card-v2 .kt-user-card-v2__pic img{border-radius: 50%;max-width: 40px;max-height: 40px;}
.kt-user-card-v2 .kt-user-card-v2__details{line-height: 0;}
.kt-user-card-v2 .kt-user-card-v2__details .kt-user-card-v2__name{display: block;font-size: 1rem;font-weight: 500;line-height: 1.5rem;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;color: #6c7293;}
.kt-user-card-v2 .kt-user-card-v2__details a.kt-user-card-v2__name:hover{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;color: #5d78ff;}
.kt-user-card-v2 .kt-user-card-v2__details .kt-user-card-v2__desc, .kt-user-card-v2 .kt-user-card-v2__details .kt-user-card-v2__email{margin-top: 3px;display: inline-block;font-size: 0.9rem;line-height: 1rem;font-weight: 400;color: #a7abc3;}
.kt-user-card-v2 .kt-user-card-v2__details a.kt-user-card-v2__email:hover{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;color: #5d78ff;}
@media (max-width: 1024px){
.kt-user-card-v2 .kt-user-card-v2__pic img{max-width: 30px;}
}
.kt-user-card-v3{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-user-card-v3 .kt-user-card-v3__avatar > img{height: 120px;width: 120px;border-radius: 7px;}
.kt-user-card-v3 .kt-user-card-v3__detalis{padding-left: 1.5rem;}
.kt-user-card-v3 .kt-user-card-v3__detalis a.kt-user-card-v3__name{font-size: 1.2rem;font-weight: 500;color: #33323f;text-decoration: none;padding-bottom: 0.8rem;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-user-card-v3 .kt-user-card-v3__detalis a.kt-user-card-v3__name:hover{-webkit-transition: all 0.3s;transition: all 0.3s;color: #5d78ff;}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__desc{font-size: 1rem;font-weight: 400;color: #b2afc6;padding: 0.8rem 0;}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__info a.kt-user-card-v3__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding-bottom: 0.8rem;}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__info a.kt-user-card-v3__item > i{line-height: 0;font-size: 1.4rem;}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__info a.kt-user-card-v3__item .kt-user-card-v3__tag{font-size: 0.9rem;font-weight: 400;color: #9e9bb1;padding-left: 0.8rem;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-user-card-v3 .kt-user-card-v3__detalis .kt-user-card-v3__info a.kt-user-card-v3__item:hover .kt-user-card-v3__tag{-webkit-transition: all 0.3s;transition: all 0.3s;color: #5d78ff;}
.kt-userpic{background-repeat: no-repeat;background-position: center center;background-size: cover;border-radius: 4px;}
.kt-userpic img{width: 100%;max-width: 50px;height: 50px;}
.kt-userpic span{width: 50px;height: 50px;font-size: 1.3rem;}
.kt-userpic img{border-radius: 4px;}
.kt-userpic span{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;font-weight: 600;border-radius: 4px;}
.kt-userpic.kt-userpic--brand span{background: rgba(93, 120, 255, 0.1);color: #5d78ff;}
.kt-userpic.kt-userpic--light span{background: rgba(255, 255, 255, 0.1);color: #ffffff;}
.kt-userpic.kt-userpic--dark span{background: rgba(40, 42, 60, 0.1);color: #282a3c;}
.kt-userpic.kt-userpic--primary span{background: rgba(88, 103, 221, 0.1);color: #5867dd;}
.kt-userpic.kt-userpic--success span{background: rgba(10, 187, 135, 0.1);color: #0abb87;}
.kt-userpic.kt-userpic--info span{background: rgba(85, 120, 235, 0.1);color: #5578eb;}
.kt-userpic.kt-userpic--warning span{background: rgba(255, 184, 34, 0.1);color: #ffb822;}
.kt-userpic.kt-userpic--danger span{background: rgba(253, 57, 122, 0.1);color: #fd397a;}
.kt-userpic.kt-userpic--sm img{width: 100%;max-width: 27px;height: 27px;}
.kt-userpic.kt-userpic--sm span{width: 27px;height: 27px;font-size: 0.8rem;}
.kt-userpic.kt-userpic--lg img{width: 100%;max-width: 65px;height: 65px;}
.kt-userpic.kt-userpic--lg span{width: 65px;height: 65px;font-size: 1.3rem;}
.kt-userpic.kt-userpic--xl img{width: 100%;max-width: 80px;height: 80px;}
.kt-userpic.kt-userpic--xl span{width: 80px;height: 80px;font-size: 1.7rem;}
.kt-userpic.kt-userpic--circle{border-radius: 50%;}
.kt-userpic.kt-userpic--circle img{border-radius: 50%;}
.kt-userpic.kt-userpic--circle span{border-radius: 50%;}
.kt-pagination{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;margin: 0;padding: 0;}
.kt-pagination .kt-pagination__links, .kt-pagination .kt-pagination__links li a{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-pagination .kt-pagination__links{list-style-type: none;margin: 0;padding: 0;}
.kt-pagination .kt-pagination__links li{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-right: 0.5rem;border-radius: 4px;}
.kt-pagination .kt-pagination__links li a{font-weight: 600;color: #a7abc3;font-size: 1.1rem;padding: 0 0.2rem;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;min-width: 30px;min-height: 30px;margin: 0;}
.kt-pagination .kt-pagination__links .kt-pagination__link--active a{color: #ffffff;}
.kt-pagination .kt-pagination__toolbar{margin: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0;}
.kt-pagination .kt-pagination__toolbar .form-control{padding: 0;margin-right: 10px;height: 30px;font-weight: 600;font-size: 1.1rem;font-weight: 500;line-height: 1;outline: none;border: none;}
.kt-pagination .kt-pagination__toolbar .pagination__desc{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;font-weight: 500;color: #a7abc3;}
.kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--last{background: rgba(93, 120, 255, 0.1);}
.kt-pagination.kt-pagination--brand .kt-pagination__links .kt-pagination__link--active{background: #5d78ff;}
.kt-pagination.kt-pagination--brand .kt-pagination__links li:hover{background: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-pagination.kt-pagination--brand .kt-pagination__links li:hover a{color: #ffffff;}
.kt-pagination.kt-pagination--brand .kt-pagination__links li:hover a i{color: #fff !important;}
.kt-pagination.kt-pagination--brand .kt-pagination__toolbar .form-control{background: rgba(93, 120, 255, 0.1);}
.kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--last{background: rgba(255, 255, 255, 0.1);}
.kt-pagination.kt-pagination--light .kt-pagination__links .kt-pagination__link--active{background: #ffffff;}
.kt-pagination.kt-pagination--light .kt-pagination__links li:hover{background: #ffffff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-pagination.kt-pagination--light .kt-pagination__links li:hover a{color: #ffffff;}
.kt-pagination.kt-pagination--light .kt-pagination__links li:hover a i{color: #fff !important;}
.kt-pagination.kt-pagination--light .kt-pagination__toolbar .form-control{background: rgba(255, 255, 255, 0.1);}
.kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--last{background: rgba(40, 42, 60, 0.1);}
.kt-pagination.kt-pagination--dark .kt-pagination__links .kt-pagination__link--active{background: #282a3c;}
.kt-pagination.kt-pagination--dark .kt-pagination__links li:hover{background: #282a3c;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-pagination.kt-pagination--dark .kt-pagination__links li:hover a{color: #ffffff;}
.kt-pagination.kt-pagination--dark .kt-pagination__links li:hover a i{color: #fff !important;}
.kt-pagination.kt-pagination--dark .kt-pagination__toolbar .form-control{background: rgba(40, 42, 60, 0.1);}
.kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--last{background: rgba(88, 103, 221, 0.1);}
.kt-pagination.kt-pagination--primary .kt-pagination__links .kt-pagination__link--active{background: #5867dd;}
.kt-pagination.kt-pagination--primary .kt-pagination__links li:hover{background: #5867dd;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-pagination.kt-pagination--primary .kt-pagination__links li:hover a{color: #ffffff;}
.kt-pagination.kt-pagination--primary .kt-pagination__links li:hover a i{color: #fff !important;}
.kt-pagination.kt-pagination--primary .kt-pagination__toolbar .form-control{background: rgba(88, 103, 221, 0.1);}
.kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--last{background: rgba(10, 187, 135, 0.1);}
.kt-pagination.kt-pagination--success .kt-pagination__links .kt-pagination__link--active{background: #0abb87;}
.kt-pagination.kt-pagination--success .kt-pagination__links li:hover{background: #0abb87;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-pagination.kt-pagination--success .kt-pagination__links li:hover a{color: #ffffff;}
.kt-pagination.kt-pagination--success .kt-pagination__links li:hover a i{color: #fff !important;}
.kt-pagination.kt-pagination--success .kt-pagination__toolbar .form-control{background: rgba(10, 187, 135, 0.1);}
.kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--last{background: rgba(85, 120, 235, 0.1);}
.kt-pagination.kt-pagination--info .kt-pagination__links .kt-pagination__link--active{background: #5578eb;}
.kt-pagination.kt-pagination--info .kt-pagination__links li:hover{background: #5578eb;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-pagination.kt-pagination--info .kt-pagination__links li:hover a{color: #ffffff;}
.kt-pagination.kt-pagination--info .kt-pagination__links li:hover a i{color: #fff !important;}
.kt-pagination.kt-pagination--info .kt-pagination__toolbar .form-control{background: rgba(85, 120, 235, 0.1);}
.kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--last{background: rgba(255, 184, 34, 0.1);}
.kt-pagination.kt-pagination--warning .kt-pagination__links .kt-pagination__link--active{background: #ffb822;}
.kt-pagination.kt-pagination--warning .kt-pagination__links li:hover{background: #ffb822;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-pagination.kt-pagination--warning .kt-pagination__links li:hover a{color: #ffffff;}
.kt-pagination.kt-pagination--warning .kt-pagination__links li:hover a i{color: #fff !important;}
.kt-pagination.kt-pagination--warning .kt-pagination__toolbar .form-control{background: rgba(255, 184, 34, 0.1);}
.kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--first, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--next, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--prev, .kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--last{background: rgba(253, 57, 122, 0.1);}
.kt-pagination.kt-pagination--danger .kt-pagination__links .kt-pagination__link--active{background: #fd397a;}
.kt-pagination.kt-pagination--danger .kt-pagination__links li:hover{background: #fd397a;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-pagination.kt-pagination--danger .kt-pagination__links li:hover a{color: #ffffff;}
.kt-pagination.kt-pagination--danger .kt-pagination__links li:hover a i{color: #fff !important;}
.kt-pagination.kt-pagination--danger .kt-pagination__toolbar .form-control{background: rgba(253, 57, 122, 0.1);}
.kt-pagination.kt-pagination--circle .kt-pagination__links li{min-width: 30px;min-height: 30px;border-radius: 50%;}
.kt-pagination.kt-pagination--lg .kt-pagination__links li a{font-size: 1.3rem;min-width: 35px;min-height: 35px;}
.kt-pagination.kt-pagination--lg .kt-pagination__toolbar .form-control{height: 35px;font-size: 1.2rem;padding: 0.2rem 0 0.2rem 0.2rem;}
.kt-pagination.kt-pagination--sm .kt-pagination__links li a{font-size: 1rem;min-width: 25px;min-height: 25px;}
.kt-pagination.kt-pagination--sm .kt-pagination__toolbar .form-control{height: 25px;font-size: 0.9rem;}
@media (max-width: 768px){
.kt-pagination .kt-pagination__links{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.kt-pagination .kt-pagination__links li{margin: 0.3rem 0.5rem 0.3rem 0;}
.kt-pagination .kt-pagination__links li a{font-size: 0.9rem;min-width: 25px;min-height: 25px;}
.kt-pagination .kt-pagination__toolbar .form-control{height: 25px;font-size: 0.9rem;padding: 0.2rem 0 0.2rem 0.2rem;}
}
.kt-searchbar .input-group .input-group-text{border: none;background-color: #f2f3f7;padding: 0 0.65rem 0 1rem;}
.kt-searchbar .input-group .input-group-text .kt-svg-icon{height: 20px;width: 20px;}
.kt-searchbar .input-group .input-group-text i{font-size: 1rem;}
.kt-searchbar .input-group .form-control{border: none;background-color: #f2f3f7;padding: 0;}
.kt-searchbar .kt-input-icon .form-control{border: none;background-color: #f2f3f7;}
.kt-searchbar .kt-input-icon .kt-input-icon__icon .kt-svg-icon{height: 20px;width: 20px;}
.kt-chat .kt-portlet__head{-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-chat .kt-chat__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-chat .kt-chat__head .kt-chat__label .kt-chat__title{font-size: 1.1rem;color: #464457;font-weight: 600;margin: 0;padding: 0;}
.kt-chat .kt-chat__head .kt-chat__label .kt-chat__title:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-chat .kt-chat__head .kt-chat__label .kt-chat__status{color: #a7abc3;font-size: 0.9rem;font-weight: 500;display: block;}
.kt-chat .kt-chat__head .kt-chat__left{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-align: left;}
.kt-chat .kt-chat__head .kt-chat__right{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-align: right;}
.kt-chat .kt-chat__head .kt-chat__center{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-align: center;}
.kt-chat .kt-chat__messages .kt-chat__message{margin-bottom: 1rem;}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__user .kt-chat__username{padding: 0 0.4rem;font-size: 1.1rem;color: #464457;font-weight: 500;}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__user .kt-chat__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__user .kt-chat__datetime{color: #a7abc3;font-size: 0.9rem;font-weight: 400;}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__text{display: inline-block;padding: 1.2rem 1.7rem;padding: 1.1rem 3.5rem 1.1rem 1.6rem;margin-top: 0.8rem;color: #6c7293;font-weight: 500;font-size: 1.1rem;border-radius: 4px;}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__text b{color: #6c7293;font-weight: 0;}
.kt-chat .kt-chat__messages .kt-chat__message.kt-chat__message--right{text-align: right;}
.kt-chat .kt-chat__messages .kt-chat__message.kt-chat__message--right .kt-chat__text{text-align: right;padding: 1.1rem 1.6rem 1.1rem 3.5rem;}
.kt-chat .kt-chat__messages.kt-chat__messages--modal{width: 100%;}
.kt-chat .kt-chat__messages.kt-chat__messages--modal .kt-chat__message{padding: 1.3rem 1.7rem;margin: 1.5rem 0;display: inline-block;border-radius: 4px;}
.kt-chat .kt-chat__messages.kt-chat__messages--modal .kt-chat__message:first-child{margin-top: 0;}
.kt-chat .kt-chat__messages.kt-chat__messages--modal .kt-chat__message .kt-chat__text{padding: 0;margin-top: 0.5rem;}
.kt-chat .kt-chat__messages.kt-chat__messages--modal .kt-chat__message.kt-chat__message--right{float: right;}
.kt-chat .kt-chat__input .kt-chat__editor textarea{width: 100%;border: 0 !important;outline: none !important;background-color: transparent !important;color: #adb1c7;}
.kt-chat .kt-chat__input .kt-chat__editor textarea::-moz-placeholder{color: #adb1c7;opacity: 1;}
.kt-chat .kt-chat__input .kt-chat__editor textarea:-ms-input-placeholder{color: #adb1c7;}
.kt-chat .kt-chat__input .kt-chat__editor textarea::-webkit-input-placeholder{color: #adb1c7;}
.kt-chat .kt-chat__input .kt-chat__toolbar{margin-top: 1rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-chat .kt-chat__input .kt-chat__toolbar .kt_chat__tools a i{margin-right: 1.1rem;color: #cdd1e9;font-size: 1.4rem;}
.kt-chat .kt-chat__input .kt-chat__toolbar .kt_chat__tools a i:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
@media (max-width: 1024px){
.kt-chat .kt-chat__messages .kt-chat__message{margin-bottom: 0.75rem;}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__user .kt-chat__username{padding: 0 0.25rem;font-size: 1rem;}
.kt-chat .kt-chat__messages .kt-chat__message .kt-chat__text{display: inline-block;padding: 0.75rem 1rem;margin-top: 0.5rem;}
.kt-chat .kt-chat__messages .kt-chat__message.kt-chat__message--right .kt-chat__text{padding: 0.75rem 1rem;}
.kt-chat .kt-chat__input .kt-chat__toolbar{margin-top: 0.75rem;}
.kt-chat .kt-chat__input .kt-chat__toolbar .kt_chat__tools a i{margin-right: 0.75rem;font-size: 1.3rem;}
}
.kt-demo-panel-toggle{background-color: #fff;cursor: pointer;position: fixed;z-index: 1000;right: 0;top: 200px;width: 40px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 1.5rem 0;-webkit-box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);border-top-left-radius: 4px;border-bottom-left-radius: 4px;}
.kt-demo-panel-toggle span{-webkit-writing-mode: vertical-rl;-ms-writing-mode: tb-rl;writing-mode: vertical-rl;text-orientation: mixed;font-size: 1.3rem;font-weight: 700;text-transform: uppercase;color: #5d78ff;letter-spacing: 3px;}
.kt-demo-panel{background-color: #fff;-webkit-box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);padding: 2.5rem;}
.kt-demo-panel .kt-demo-panel__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 3rem;}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__title{font-size: 1.4rem;font-weight: 500;color: #464457;margin: 0;padding: 0;}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__title small{color: #a7abc3;font-size: 1.1rem;}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__close{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 28px;height: 28px;background-color: #f7f8fa;-webkit-transition: all 0.3s;transition: all 0.3s;border-radius: 4px;}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__close i{-webkit-transition: all 0.3s;transition: all 0.3s;font-size: 0.8rem;color: #a7abc3;}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__close:hover{-webkit-transition: all 0.3s;transition: all 0.3s;background-color: #5d78ff;}
.kt-demo-panel .kt-demo-panel__head .kt-demo-panel__close:hover i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #ffffff;}
.kt-demo-panel .kt-demo-panel__body{position: relative;}
.kt-demo-panel .kt-demo-panel__body .ps__rail-y{opacity: 1;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item{display: block;margin-bottom: 2rem;outline: 0;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item:last-child{margin-bottom: 0;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item:hover{text-decoration: none;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-title{font-size: 1.1rem;font-weight: 500;color: #6c7293;text-align: center;margin-bottom: 0.5rem;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview{overflow: hidden;position: relative;-webkit-transition: all 0.3s;transition: all 0.3s;border: 4px solid #ebedf2;border-radius: 4px;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview img{width: 100%;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview .kt-demo-panel__item-preview-overlay{position: absolute;top: 0;bottom: 0;left: 0;right: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;background-color: rgba(0, 0, 0, 0.15);-webkit-transition: all 0.3s;transition: all 0.3s;opacity: 0;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview .kt-demo-panel__item-preview-overlay .btn{margin-right: 5px;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview .kt-demo-panel__item-preview-overlay .btn:last-child{margin-right: 0;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview .kt-demo-panel__item-preview-overlay .btn.btn-light{background-color: #fff;color: #a7abc3;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item .kt-demo-panel__item-preview .kt-demo-panel__item-preview-overlay .btn.btn-light:hover{background-color: #fff;color: #6c7293;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item.kt-demo-panel__item--active .kt-demo-panel__item-preview{-webkit-transition: all 0.3s;transition: all 0.3s;border: 4px solid #5d78ff;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item:hover .kt-demo-panel__item-preview{-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__item:hover .kt-demo-panel__item-preview .kt-demo-panel__item-preview-overlay{-webkit-transition: all 0.3s;transition: all 0.3s;opacity: 1;}
.kt-demo-panel .kt-demo-panel__body .kt-demo-panel__purchase{margin-bottom: 2rem;display: block;}
@media (min-width: 1025px){
.kt-demo-panel .kt-demo-panel__body{padding-right: 1.5rem;margin-right: -1.5rem;}
}
.kt-demo-panel-close{display: none;}
.kt-demo-panel{z-index: 1001;position: fixed;-webkit-overflow-scrolling: touch;top: 0;bottom: 0;overflow-y: auto;-webkit-transform: translate3d(0, 0, 0);backface-visibility: hidden;-webkit-backface-visibility: hidden;width: 350px !important;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: -370px;}
.kt-demo-panel.kt-demo-panel--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 0;}
@media screen\0{.kt-demo-panel{-webkit-transition: none !important;transition: none !important;}
}
.kt-demo-panel--right .kt-demo-panel{right: -370px;left: auto;}
.kt-demo-panel--right .kt-demo-panel.kt-demo-panel--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;right: 0;left: auto;}
.kt-demo-panel-close{width: 25px;height: 25px;top: 1px;z-index: 1002;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;position: fixed;border: 0;-webkit-box-shadow: none;box-shadow: none;border-radius: 3px;cursor: pointer;outline: none !important;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;left: -25px;}
@media screen\0{.kt-demo-panel-close{-webkit-transition: none !important;transition: none !important;}
}
.kt-demo-panel-close > i{line-height: 0;font-size: 1.4rem;}
.kt-demo-panel-close:hover{text-decoration: none;}
.kt-demo-panel--right .kt-demo-panel-close{left: auto;right: -25px;}
.kt-demo-panel--on .kt-demo-panel-close{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 324px;}
.kt-demo-panel--on.kt-demo-panel--right .kt-demo-panel-close{left: auto;right: 324px;}
.kt-demo-panel-overlay{position: fixed;top: 0;left: 0;bottom: 0;right: 0;overflow: hidden;z-index: 1000;background: rgba(0, 0, 0, 0.1);-webkit-animation: kt-animate-fade-in .3s linear 1;animation: kt-animate-fade-in .3s linear 1;}
.kt-demo-panel-overlay{background: rgba(0, 0, 0, 0.05);}
@media (max-width: 400px){
.kt-demo-panel{width: 90% !important;}
}
@media (max-width: 1024px){
.kt-demo-panel-toggle{width: 30px;padding: 1rem 0;}
.kt-demo-panel-toggle span{font-size: 1.2rem;font-weight: 600;letter-spacing: 2px;}
.kt-demo-panel{padding: 1rem;}
.kt-demo-panel-toggle{top: 20%;}
}
.kt-offcanvas-panel{background-color: #fff;-webkit-box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);padding: 2.5rem;}
.kt-offcanvas-panel .kt-offcanvas-panel__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 2rem;}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__title{font-size: 1.4rem;font-weight: 500;color: #464457;margin: 0;padding: 0;}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__title small{color: #a7abc3;font-size: 1rem;}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__close{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 28px;height: 28px;background-color: #f7f8fa;-webkit-transition: all 0.3s;transition: all 0.3s;border-radius: 4px;}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__close i{-webkit-transition: all 0.3s;transition: all 0.3s;font-size: 0.8rem;color: #a7abc3;}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__close:hover{-webkit-transition: all 0.3s;transition: all 0.3s;background-color: #5d78ff;}
.kt-offcanvas-panel .kt-offcanvas-panel__head .kt-offcanvas-panel__close:hover i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #ffffff;}
.kt-offcanvas-panel .kt-offcanvas-panel__body{position: relative;}
.kt-offcanvas-panel .kt-offcanvas-panel__body .kt-offcanvas-panel__section{padding: 0;font-size: 1.3rem;font-weight: 500;color: #6c7293;margin: 2rem 0 1.5rem 0;}
.kt-offcanvas-panel .kt-offcanvas-panel__body .kt-offcanvas-panel__section.kt-offcanvas-panel__section--first{margin-top: 0;}
.kt-offcanvas-panel-close{display: none;}
.kt-offcanvas-panel{z-index: 1001;position: fixed;-webkit-overflow-scrolling: touch;top: 0;bottom: 0;overflow-y: auto;-webkit-transform: translate3d(0, 0, 0);backface-visibility: hidden;-webkit-backface-visibility: hidden;width: 425px !important;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: -445px;}
.kt-offcanvas-panel.kt-offcanvas-panel--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 0;}
@media screen\0{.kt-offcanvas-panel{-webkit-transition: none !important;transition: none !important;}
}
.kt-offcanvas-panel--right .kt-offcanvas-panel{right: -445px;left: auto;}
.kt-offcanvas-panel--right .kt-offcanvas-panel.kt-offcanvas-panel--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;right: 0;left: auto;}
.kt-offcanvas-panel-close{width: 25px;height: 25px;top: 1px;z-index: 1002;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;position: fixed;border: 0;-webkit-box-shadow: none;box-shadow: none;border-radius: 3px;cursor: pointer;outline: none !important;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;left: -25px;}
@media screen\0{.kt-offcanvas-panel-close{-webkit-transition: none !important;transition: none !important;}
}
.kt-offcanvas-panel-close > i{line-height: 0;font-size: 1.4rem;}
.kt-offcanvas-panel-close:hover{text-decoration: none;}
.kt-offcanvas-panel--right .kt-offcanvas-panel-close{left: auto;right: -25px;}
.kt-offcanvas-panel--on .kt-offcanvas-panel-close{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 399px;}
.kt-offcanvas-panel--on.kt-offcanvas-panel--right .kt-offcanvas-panel-close{left: auto;right: 399px;}
.kt-offcanvas-panel-overlay{position: fixed;top: 0;left: 0;bottom: 0;right: 0;overflow: hidden;z-index: 1000;background: rgba(0, 0, 0, 0.1);-webkit-animation: kt-animate-fade-in .3s linear 1;animation: kt-animate-fade-in .3s linear 1;}
.kt-offcanvas-panel-overlay{background: rgba(0, 0, 0, 0.1);}
@media (max-width: 475px){
.kt-offcanvas-panel{width: 90% !important;}
}
@media (min-width: 1025px){
.kt-offcanvas-panel .kt-offcanvas-panel__body{padding-right: 1.5rem;margin-right: -1.5rem;}
}
@media (max-width: 1024px){
.kt-offcanvas-panel{padding: 1rem;}
}
.kt-quick-panel{background-color: #fff;-webkit-box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);padding: 0;}
.kt-quick-panel .kt-quick-panel__close{position: absolute;z-index: 1;right: 1.5rem;top: 1rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 28px;height: 28px;background-color: #f7f8fa;-webkit-transition: all 0.3s;transition: all 0.3s;border-radius: 4px;}
.kt-quick-panel .kt-quick-panel__close i{-webkit-transition: all 0.3s;transition: all 0.3s;font-size: 0.8rem;color: #a7abc3;}
.kt-quick-panel .kt-quick-panel__close:hover{-webkit-transition: all 0.3s;transition: all 0.3s;background-color: #5d78ff;}
.kt-quick-panel .kt-quick-panel__close:hover i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #ffffff;}
.kt-quick-panel .kt-quick-panel__nav{display: -webkit-box;display: -ms-flexbox;display: flex;position: relative;padding: 1.5rem 0 0 0;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-quick-panel .kt-quick-panel__nav .nav{width: 100%;padding: 0 1.5rem;margin: 0;}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-item{margin-right: 1.5rem;}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-item .nav-link{font-size: 1rem;color: #a7abc3;}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-item:last-child{margin-right: 0;}
.kt-quick-panel .kt-quick-panel__content{padding: 1.5rem 0;}
.kt-quick-panel .kt-quick-panel__content .ps > .ps__rail-y{right: 5px !important;}
.kt-quick-panel .kt-quick-panel__content-padding-x{padding-left: 1.5rem;padding-right: 1.5rem;}
.kt-quick-panel-close{display: none;}
.kt-quick-panel{z-index: 1001;position: fixed;-webkit-overflow-scrolling: touch;top: 0;bottom: 0;overflow-y: auto;-webkit-transform: translate3d(0, 0, 0);backface-visibility: hidden;-webkit-backface-visibility: hidden;width: 425px !important;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: -445px;}
.kt-quick-panel.kt-quick-panel--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 0;}
@media screen\0{.kt-quick-panel{-webkit-transition: none !important;transition: none !important;}
}
.kt-quick-panel--right .kt-quick-panel{right: -445px;left: auto;}
.kt-quick-panel--right .kt-quick-panel.kt-quick-panel--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;right: 0;left: auto;}
.kt-quick-panel-close{width: 25px;height: 25px;top: 1px;z-index: 1002;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;position: fixed;border: 0;-webkit-box-shadow: none;box-shadow: none;border-radius: 3px;cursor: pointer;outline: none !important;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;left: -25px;}
@media screen\0{.kt-quick-panel-close{-webkit-transition: none !important;transition: none !important;}
}
.kt-quick-panel-close > i{line-height: 0;font-size: 1.4rem;}
.kt-quick-panel-close:hover{text-decoration: none;}
.kt-quick-panel--right .kt-quick-panel-close{left: auto;right: -25px;}
.kt-quick-panel--on .kt-quick-panel-close{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 399px;}
.kt-quick-panel--on.kt-quick-panel--right .kt-quick-panel-close{left: auto;right: 399px;}
.kt-quick-panel-overlay{position: fixed;top: 0;left: 0;bottom: 0;right: 0;overflow: hidden;z-index: 1000;background: rgba(0, 0, 0, 0.1);-webkit-animation: kt-animate-fade-in .3s linear 1;animation: kt-animate-fade-in .3s linear 1;}
.kt-quick-panel{background: #ffffff;-webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);}
.kt-quick-panel-overlay{background: rgba(0, 0, 0, 0.1);}
.kt-quick-panel-close{background-color: #e8e8f2;}
.kt-quick-panel-close > i{color: #968ad4;}
.kt-quick-panel-close:hover{background-color: #5d78ff;}
.kt-quick-panel-close:hover > i{color: #ffffff;}
@media (max-width: 475px){
.kt-quick-panel{width: 90% !important;}
}
@media (max-width: 1024px){
.kt-quick-panel .kt-quick-panel__close{right: 0.5rem;top: 0.5rem;width: 24px;height: 24px;}
.kt-quick-panel .kt-quick-panel__close i{font-size: 0.8rem;}
.kt-quick-panel .kt-quick-panel__nav{padding: 1rem 0;}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-item{margin-right: 1rem;}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-item:last-child{margin-right: 0;}
.kt-quick-panel .kt-quick-panel__nav .nav .nav-link{padding-top: 0.5rem;padding-bottom: 0.5rem;font-size: 0.9rem;font-weight: 400;text-transform: uppercase;}
.kt-quick-panel .kt-quick-panel__nav .kt-quick-panel__close i{font-size: 1.1rem;}
.kt-quick-panel .kt-quick-panel__content{padding: 1rem 0.5rem 1rem 1rem;}
.kt-quick-panel .kt-quick-panel__content .tab-pane{padding-right: 0.5rem;}
}
.kt-quick-search{padding: 1.5rem;}
.kt-quick-search .kt-quick-search__form{padding: 0;background-color: #36344d;}
.kt-quick-search .kt-quick-search__form .input-group{background-color: transparent;}
.kt-quick-search .kt-quick-search__form .input-group-prepend, .kt-quick-search .kt-quick-search__form .input-group-append{background-color: transparent;}
.kt-quick-search .kt-quick-search__form .input-group-prepend .input-group-text, .kt-quick-search .kt-quick-search__form .input-group-append .input-group-text{background-color: transparent;border: 0;}
.kt-quick-search .kt-quick-search__form .form-control{border: 0;background: none;outline: none !important;-webkit-box-shadow: none;box-shadow: none;color: #575962;background-color: transparent;border: 0;padding-left: 0;padding-right: 0;}
.kt-quick-search .kt-quick-search__form .form-control::-moz-placeholder{color: #656872;opacity: 1;}
.kt-quick-search .kt-quick-search__form .form-control:-ms-input-placeholder{color: #656872;}
.kt-quick-search .kt-quick-search__form .form-control::-webkit-input-placeholder{color: #656872;}
.kt-quick-search .kt-quick-search__form i{font-size: 1.3rem;color: #64688d;}
.kt-quick-search .kt-quick-search__form .kt-quick-search__close{cursor: pointer;display: none;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-quick-search .kt-quick-search__form .kt-quick-search__close:hover{-webkit-transition: all 0.3s;transition: all 0.3s;color: #5d78ff;}
.kt-quick-search .kt-quick-search__wrapper{display: none;margin: 1.5rem;}
.kt-quick-search .kt-quick-search__wrapper.ps{margin-right: 0.5rem;}
.kt-quick-search .kt-quick-search__result{padding: 0;margin: 0;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__category{display: -webkit-box;display: -ms-flexbox;display: flex;padding: 0;margin: 1.5rem 0 0.5rem 0;font-weight: 600;font-size: 1rem;text-transform: uppercase;color: #5d78ff;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__category.kt-quick-search__category--first{margin-top: 0;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__message{display: -webkit-box;display: -ms-flexbox;display: flex;padding: 1rem 0;font-weight: 500;font-size: 1.1rem;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;margin: 0.5rem 0;outline: none;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item .kt-quick-search__item-icon{width: 2.5rem;margin-right: 0.5rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-transition: all 0.3s;transition: all 0.3s;color: #a7abc3;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item .kt-quick-search__item-icon i{font-size: 1.5rem;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item .kt-quick-search__item-icon img{border-radius: 50%;display: block;max-width: 28px !important;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item .kt-quick-search__item-text{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-box-align: center;-ms-flex-align: center;align-items: center;font-size: 1rem;color: #a7abc3;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item:hover{text-decoration: none;}
.kt-quick-search .kt-quick-search__result .kt-quick-search__item:hover .kt-quick-search__item-text{-webkit-transition: all 0.3s;transition: all 0.3s;color: #5d78ff;}
.kt-quick-search.kt-quick-search--has-result .kt-quick-search__wrapper{display: block;}
.kt-quick-search.kt-quick-search--inline{padding: 0;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form{margin: 0 1.5rem;padding: 1rem 0;background-color: #fff;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .input-group:before{right: 0 !important;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .input-group-prepend .input-group-text{padding-left: 0;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .input-group-append .input-group-text{padding-right: 0;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .form-control{color: #6c7293;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .form-control::-moz-placeholder{color: #a7abc3;opacity: 1;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .form-control:-ms-input-placeholder{color: #a7abc3;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form .form-control::-webkit-input-placeholder{color: #a7abc3;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__form i{font-size: 1.3rem;color: #a7abc3;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__wrapper{margin: 1.5rem;display: none;padding-top: 0;padding-bottom: 0;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__wrapper.ps{margin-right: 0.5rem;}
.kt-quick-search.kt-quick-search--inline .kt-quick-search__result{padding: 0;}
.kt-quick-search.kt-quick-search--inline.kt-quick-search--has-result .kt-quick-search__form{border-bottom: 1px solid #ebedf2;}
.kt-quick-search.kt-quick-search--inline.kt-quick-search--has-result .kt-quick-search__wrapper{display: block;}
.kt-quick-search.kt-quick-search--inline.kt-quick-search--has-result .kt-quick-search__wrapper .kt-quick-search__result{padding-top: 0;padding-bottom: 0;}
.kt-scroll{position: relative;overflow: hidden;}
.kt-sticky-toolbar{width: 46px;position: fixed;top: 30%;right: 0;list-style: none;padding: 5px 0;margin: 0;z-index: 50;background: #fff;-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;border-radius: 3px 0 0 3px;}
.kt-sticky-toolbar .kt-sticky-toolbar__item{margin: 0;padding: 5px;text-align: center;}
.kt-sticky-toolbar .kt-sticky-toolbar__item > a{background: #f4f4f8;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 30px;height: 30px;border-radius: 3px;-webkit-transition: background 0.3s ease;transition: background 0.3s ease;}
.kt-sticky-toolbar .kt-sticky-toolbar__item > a > i{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;font-size: 1.3rem;}
.kt-sticky-toolbar .kt-sticky-toolbar__item > a:hover{-webkit-transition: background 0.3s ease;transition: background 0.3s ease;}
.kt-sticky-toolbar .kt-sticky-toolbar__item > a:hover > i{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--brand > a > i{color: #5d78ff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--brand:hover > a{background: #5d78ff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--brand:hover > a > i{color: #ffffff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--light > a > i{color: #ffffff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--light:hover > a{background: #ffffff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--light:hover > a > i{color: #282a3c;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--dark > a > i{color: #282a3c;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--dark:hover > a{background: #282a3c;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--dark:hover > a > i{color: #ffffff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--primary > a > i{color: #5867dd;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--primary:hover > a{background: #5867dd;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--primary:hover > a > i{color: #ffffff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--success > a > i{color: #0abb87;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--success:hover > a{background: #0abb87;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--success:hover > a > i{color: #ffffff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--info > a > i{color: #5578eb;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--info:hover > a{background: #5578eb;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--info:hover > a > i{color: #ffffff;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--warning > a > i{color: #ffb822;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--warning:hover > a{background: #ffb822;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--warning:hover > a > i{color: #111111;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--danger > a > i{color: #fd397a;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--danger:hover > a{background: #fd397a;}
.kt-sticky-toolbar .kt-sticky-toolbar__item.kt-sticky-toolbar__item--danger:hover > a > i{color: #ffffff;}
@media (min-width: 1025px){
.kt-app .kt-app__aside{width: 350px;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-app .kt-app__aside{width: 262.5px;}
}
@media (min-width: 1025px){
.kt-app .kt-app__aside.kt-app__aside--sm{width: 250px;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-app .kt-app__aside.kt-app__aside--sm{width: 187.5px;}
}
@media (min-width: 1025px){
.kt-app .kt-app__aside.kt-app__aside--lg{width: 250px;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-app .kt-app__aside.kt-app__aside--lg{width: 337.5px;}
}
@media (min-width: 1025px){
.kt-app .kt-app__aside.kt-app__aside--xl{width: 600px;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-app .kt-app__aside.kt-app__aside--xl{width: 450px;}
}
.kt-app .kt-app__content{margin-left: 20px;}
.kt-app .kt-app__detail{margin-left: 20px;}
@media (min-width: 1025px){
.kt-app .kt-app__detail{width: 550px;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-app .kt-app__detail{width: 440px;}
}
@media (min-width: 1025px){
.kt-app .kt-app__detail.kt-app__detail--sm{width: 400px;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-app .kt-app__detail.kt-app__detail--sm{width: 320px;}
}
@media (min-width: 1025px){
.kt-app .kt-app__detail.kt-app__detail--lg{width: 700px;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-app .kt-app__detail.kt-app__detail--lg{width: 560px;}
}
@media (min-width: 1025px){
.kt-app .kt-app__detail.kt-app__detail--xl{width: 850px;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-app .kt-app__detail.kt-app__detail--xl{width: 680px;}
}
@media (max-width: 1024px){
.kt-app .kt-app__aside{padding: 35px 15px 15px 15px;background-color: #ffffff;}
.kt-app .kt-app__aside .kt-portlet{-webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.1);box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.1);}
.kt-app .kt-app__aside.kt-app__aside--fit{padding: 20px 0 0 0;}
.kt-app .kt-app__aside.kt-app__aside--fit .kt-portlet{-webkit-box-shadow: none;box-shadow: none;}
.kt-app .kt-app__content{margin-left: 0;}
.kt-app .kt-app__detail{margin-left: 0;}
}
.kt-app__aside-close{display: none;}
@media (max-width: 1024px){
.kt-app__aside{z-index: 1001;position: fixed;-webkit-overflow-scrolling: touch;top: 0;bottom: 0;overflow-y: auto;-webkit-transform: translate3d(0, 0, 0);backface-visibility: hidden;-webkit-backface-visibility: hidden;width: 300px !important;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: -320px;}
.kt-app__aside.kt-app__aside--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 0;}
}
@media screen\0  and (max-width: 1024px){
.kt-app__aside{-webkit-transition: none !important;transition: none !important;}
}
@media (max-width: 1024px){
.kt-app__aside--right .kt-app__aside{right: -320px;left: auto;}
.kt-app__aside--right .kt-app__aside.kt-app__aside--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;right: 0;left: auto;}
.kt-app__aside-close{width: 25px;height: 25px;top: 1px;z-index: 1002;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;position: fixed;border: 0;-webkit-box-shadow: none;box-shadow: none;border-radius: 3px;cursor: pointer;outline: none !important;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;left: -25px;}
}
@media screen\0  and (max-width: 1024px){
.kt-app__aside-close{-webkit-transition: none !important;transition: none !important;}
}
@media (max-width: 1024px){
.kt-app__aside-close > i{line-height: 0;font-size: 1.4rem;}
.kt-app__aside-close:hover{text-decoration: none;}
.kt-app__aside--right .kt-app__aside-close{left: auto;right: -25px;}
.kt-app__aside--on .kt-app__aside-close{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 274px;}
.kt-app__aside--on.kt-app__aside--right .kt-app__aside-close{left: auto;right: 274px;}
.kt-app__aside-overlay{position: fixed;top: 0;left: 0;bottom: 0;right: 0;overflow: hidden;z-index: 1000;background: rgba(0, 0, 0, 0.1);-webkit-animation: kt-animate-fade-in .3s linear 1;animation: kt-animate-fade-in .3s linear 1;}
.kt-app__aside-overlay{background: rgba(0, 0, 0, 0.05);}
.kt-app__aside-close{background-color: #f7f8fa;}
.kt-app__aside-close > i{color: #a7abc3;}
.kt-app__aside-close:hover{background-color: transparent;}
.kt-app__aside-close:hover > i{color: #5d78ff;}
}
@media (max-width: 350px){
.kt-app__aside{width: 90% !important;}
}
.kt-subheader{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;-ms-flex-wrap: wrap;flex-wrap: wrap;padding: 10px 25px;background-color: #fff;}
.kt-subheader .btn{display: -webkit-inline-box;display: -ms-inline-flexbox;display: inline-flex;height: 32px !important;padding-top: 0;padding-bottom: 0;margin-top: 0.25rem;margin-bottom: 0.25rem;}
.kt-subheader .btn i{font-size: 1rem !important;}
.kt-subheader .btn.btn-icon{width: 32px !important;}
.kt-subheader .btn:not(.dropdown-toggle-split){
margin-left: 0.5rem;}
.kt-subheader .kt-subheader__main{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-ms-flex-wrap: wrap;flex-wrap: wrap;padding: 0.25rem 0;}
.kt-subheader .kt-subheader__main .btn:not(.dropdown-toggle-split){
margin-left: 0;margin-right: 0.5rem;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle{display: none;}
.kt-subheader .kt-subheader__main .kt-subheader__title{margin: 0;padding: 0 1rem 0 0;font-size: 1.2rem;font-weight: 500;color: #434349;}
.kt-subheader .kt-subheader__main .kt-subheader__title small{padding-left: 0.25rem;font-size: 0.9rem;color: #a7abc3;}
.kt-subheader .kt-subheader__main .kt-subheader__desc{padding: 0 0.5rem 0 0;font-size: 1rem;font-weight: 500;color: #959cb6;}
.kt-subheader .kt-subheader__main .kt-subheader__separator{display: block;width: 12px;height: 4px;border-radius: 2px;background: #e7e8ef;margin: 0 1rem 0 0;}
.kt-subheader .kt-subheader__main .kt-subheader__separator.kt-subheader__separator--v{width: 1px;height: 22px;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;list-style-type: none;-ms-flex-wrap: wrap;flex-wrap: wrap;margin: 0;padding: 0;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home{padding: 0px 0.65rem 0px 0px;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home > i{font-size: 1rem;color: #c5cbe7;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home:hover > i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #5d78ff;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home.kt-subheader__breadcrumbs-home--active > i{color: #5d78ff;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link{padding: 0 0.35rem 0 0;font-size: 1rem;font-weight: 500;color: #959cb6;-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link:hover{color: #5d78ff;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link.kt-subheader__breadcrumbs-link--active{color: #5d78ff;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link:hover{-webkit-transition: all 0.3s;transition: all 0.3s;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: content;-ms-flex-pack: content;justify-content: content;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0 0.35rem 0 0;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator:after{display: block;width: 4px;height: 4px;border-radius: 50%;content: " ";background: #e7e8ef;}
.kt-subheader .kt-subheader__main .kt-subheader__group{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-subheader .kt-subheader__toolbar{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0;}
.kt-subheader .kt-subheader__toolbar .btn:not(.dropdown-toggle-split){
margin-left: 0.25rem;}
.kt-subheader .kt-subheader__btn-primary{background: #f2f3f7;color: #959cb6;font-weight: 500;}
.kt-subheader .kt-subheader__btn-primary i{color: #959cb6;}
.kt-subheader .kt-subheader__btn-primary:hover, .kt-subheader .kt-subheader__btn-primary.active, .kt-subheader .kt-subheader__btn-primary:active, .kt-subheader .kt-subheader__btn-primary:focus{color: #ffffff;background: #5d78ff;}
.kt-subheader .kt-subheader__btn-primary:hover i, .kt-subheader .kt-subheader__btn-primary.active i, .kt-subheader .kt-subheader__btn-primary:active i, .kt-subheader .kt-subheader__btn-primary:focus i{color: #ffffff;}
.kt-subheader .kt-subheader__btn-primary:hover g [fill], .kt-subheader .kt-subheader__btn-primary.active g [fill], .kt-subheader .kt-subheader__btn-primary:active g [fill], .kt-subheader .kt-subheader__btn-primary:focus g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #ffffff;}
.kt-subheader .kt-subheader__btn-primary:hover:hover g [fill], .kt-subheader .kt-subheader__btn-primary.active:hover g [fill], .kt-subheader .kt-subheader__btn-primary:active:hover g [fill], .kt-subheader .kt-subheader__btn-primary:focus:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-subheader .kt-subheader__btn-secondary{color: #959cb6;font-weight: 500;}
.kt-subheader .kt-subheader__btn-secondary i{color: #aab1df;}
.kt-subheader .kt-subheader__btn-secondary g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #aab1df;}
.kt-subheader .kt-subheader__btn-secondary:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-subheader .kt-subheader__btn-secondary:hover, .kt-subheader .kt-subheader__btn-secondary.active, .kt-subheader .kt-subheader__btn-secondary:active, .kt-subheader .kt-subheader__btn-secondary:focus{background: #f2f3f7;color: #5d78ff;}
.kt-subheader .kt-subheader__btn-secondary:hover g [fill], .kt-subheader .kt-subheader__btn-secondary.active g [fill], .kt-subheader .kt-subheader__btn-secondary:active g [fill], .kt-subheader .kt-subheader__btn-secondary:focus g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-subheader .kt-subheader__btn-secondary:hover:hover g [fill], .kt-subheader .kt-subheader__btn-secondary.active:hover g [fill], .kt-subheader .kt-subheader__btn-secondary:active:hover g [fill], .kt-subheader .kt-subheader__btn-secondary:focus:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-subheader .kt-subheader__btn-daterange{background: #f2f3f7;}
.kt-subheader .kt-subheader__btn-daterange .kt-subheader__btn-daterange-title{display: inline-block;color: #959cb6;font-weight: 500;}
.kt-subheader .kt-subheader__btn-daterange .kt-subheader__btn-daterange-date{display: inline-block;color: #5d78ff;margin-right: 0.75rem;font-weight: 500;}
.kt-subheader .kt-subheader__btn-daterange i{color: #5d78ff;}
.kt-subheader .kt-subheader__btn-daterange:hover, .kt-subheader .kt-subheader__btn-daterange.active, .kt-subheader .kt-subheader__btn-daterange:active, .kt-subheader .kt-subheader__btn-daterange:focus{background: #f2f3f7;}
.kt-subheader .kt-subheader__btn-daterange:hover i, .kt-subheader .kt-subheader__btn-daterange.active i, .kt-subheader .kt-subheader__btn-daterange:active i, .kt-subheader .kt-subheader__btn-daterange:focus i{color: #5d78ff;}
.kt-subheader .kt-subheader__search{width: 175px;display: inline-block;}
.kt-subheader .kt-subheader__search .form-control{font-size: 0.9rem;height: 33px;background: #f2f3f7;border-color: #f2f3f7 !important;}
.kt-subheader .kt-subheader__search .kt-input-icon__icon i{font-size: 1.2rem;color: #a7abc3;}
.kt-subheader--transparent .kt-subheader{background-color: transparent;padding: 20px 25px;}
.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__separator{background: #dbdce7;}
.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-home > i{color: #b7bee1;}
.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-link{color: #959cb6;}
.kt-subheader--transparent .kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs .kt-subheader__breadcrumbs-separator:after{background: #dbdce7;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary{background: #e1e3ec;color: #959cb6;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary i{color: #959cb6;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #959cb6;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus{background: #5d78ff;color: #ffffff;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover i, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active i, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active i, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus i{color: #ffffff;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover g [fill], .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active g [fill], .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active g [fill], .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #ffffff;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:hover:hover g [fill], .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary.active:hover g [fill], .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:active:hover g [fill], .kt-subheader--transparent .kt-subheader .kt-subheader__btn-primary:focus:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary{color: #8c94b0;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary i{color: #9fa7db;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:hover, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary.active, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:active, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-secondary:focus{background: #eaecf2;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange{background: #e1e3ec;}
.kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:hover, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange.active, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:active, .kt-subheader--transparent .kt-subheader .kt-subheader__btn-daterange:focus{background: #d7dbe6;}
.kt-subheader--transparent .kt-subheader .kt-subheader__search .form-control{background: #eaecf2;border-color: #eaecf2 !important;}
@media (max-width: 1024px){
.kt-subheader{padding: 2px 15px;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle{display: inline-block;position: relative;overflow: hidden;margin: 0;padding: 0;font-size: 0;text-indent: -9999px;-webkit-appearance: none;-moz-appearance: none;appearance: none;-webkit-box-shadow: none;box-shadow: none;border-radius: none;border: none;cursor: pointer;background: none;outline: none !important;width: 20px;height: 22px;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span{display: block;position: absolute;top: 10px;height: 2px;min-height: 2px;width: 100%;border-radius: 2px;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span::before, .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span::after{position: absolute;display: block;left: 0;width: 100%;height: 2px;min-height: 2px;content: "";border-radius: 2px;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span::before{top: -6px;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span::after{bottom: -6px;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left span:before{-webkit-transition: all 0.4s ease;transition: all 0.4s ease;left: auto;right: 0px;width: 50%;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left span:after{-webkit-transition: all 0.4s ease;transition: all 0.4s ease;left: auto;right: 0px;width: 75%;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left:hover span:after, .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left:hover span:before{width: 100%;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left.kt-subheader__mobile-toggle--active span:before{-webkit-transition: all 0.4s ease;transition: all 0.4s ease;left: 0px;right: auto;width: 50%;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--left.kt-subheader__mobile-toggle--active span:after{-webkit-transition: all 0.4s ease;transition: all 0.4s ease;left: 0px;right: auto;width: 75%;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right span:before{left: 0px;right: auto;width: 50%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right span:after{left: 0px;right: auto;width: 75%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right:hover span:after, .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right:hover span:before{width: 100%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right.kt-subheader__mobile-toggle--active span:before{left: auto;right: 0px;width: 50%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--right.kt-subheader__mobile-toggle--active span:after{left: auto;right: 0px;width: 75%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span{background: #a7abc3;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span::before, .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle span::after{background: #a7abc3;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle:hover span{background: #5d78ff;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle:hover span::before, .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle:hover span::after{background: #5d78ff;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--active span{background: #5d78ff;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--active span::before, .kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle.kt-subheader__mobile-toggle--active span::after{background: #5d78ff;}
.kt-subheader .kt-subheader__main .kt-subheader__mobile-toggle{display: inline-block;margin-right: 1rem;}
.kt-subheader .kt-subheader__main .kt-subheader__title{padding: 0.5rem 1.25rem 0.5rem 0;}
.kt-subheader .kt-subheader__main .kt-subheader__breadcrumbs{padding: 0.5rem 0;}
.kt-subheader .kt-subheader__toolbar{padding: 0.5rem 0;}
.kt-subheader--transparent .kt-subheader{padding: 10px 15px;}
}
@media (max-width: 768px){
.kt-subheader .kt-subheader__toolbar{padding-top: 0.5rem;}
}
.kt-badge{padding: 0;margin: 0;display: -webkit-inline-box;display: -ms-inline-flexbox;display: inline-flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;height: 18px;width: 18px;border-radius: 50%;font-size: 0.8rem;}
.kt-badge.kt-badge--bold{font-weight: 500;}
.kt-badge.kt-badge--bolder{font-weight: 600;}
.kt-badge.kt-badge--boldest{font-weight: 700;}
.kt-badge.kt-badge--md{height: 24px;width: 24px;font-size: 1rem;}
.kt-badge.kt-badge--lg{height: 35px;width: 35px;font-size: 1rem;}
.kt-badge.kt-badge--xl{height: 50px;width: 50px;font-size: 1.1rem;font-weight: 500;}
.kt-badge.kt-badge__pics{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 100%;}
.kt-badge.kt-badge__pics .kt-badge__pic{border-radius: 50%;width: 2.5rem;height: 2.5rem;overflow: hidden;margin-left: -0.7rem;border: 1px solid #ffffff;background-color: #f0f3ff;color: #a1a8c3;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;font-weight: 500;position: relative;}
.kt-badge.kt-badge__pics .kt-badge__pic img{width: 100%;}
.kt-badge.kt-badge__pics .kt-badge__pic.kt-badge__pic--last{font-size: 1rem;font-weight: 500;}
.kt-badge.kt-badge--brand{color: #ffffff;background: #5d78ff;}
.kt-badge.kt-badge--outline.kt-badge--brand{background: transparent;color: #5d78ff;border: 1px solid #5d78ff;}
.kt-badge.kt-badge--outline.kt-badge--brand.kt-badge--outline-2x{border: 2px solid #5d78ff;}
.kt-badge.kt-badge--light{color: #282a3c;background: #ffffff;}
.kt-badge.kt-badge--outline.kt-badge--light{background: transparent;color: #ffffff;border: 1px solid #ffffff;}
.kt-badge.kt-badge--outline.kt-badge--light.kt-badge--outline-2x{border: 2px solid #ffffff;}
.kt-badge.kt-badge--dark{color: #ffffff;background: #282a3c;}
.kt-badge.kt-badge--outline.kt-badge--dark{background: transparent;color: #282a3c;border: 1px solid #282a3c;}
.kt-badge.kt-badge--outline.kt-badge--dark.kt-badge--outline-2x{border: 2px solid #282a3c;}
.kt-badge.kt-badge--primary{color: #ffffff;background: #5867dd;}
.kt-badge.kt-badge--outline.kt-badge--primary{background: transparent;color: #5867dd;border: 1px solid #5867dd;}
.kt-badge.kt-badge--outline.kt-badge--primary.kt-badge--outline-2x{border: 2px solid #5867dd;}
.kt-badge.kt-badge--success{color: #ffffff;background: #0abb87;}
.kt-badge.kt-badge--outline.kt-badge--success{background: transparent;color: #0abb87;border: 1px solid #0abb87;}
.kt-badge.kt-badge--outline.kt-badge--success.kt-badge--outline-2x{border: 2px solid #0abb87;}
.kt-badge.kt-badge--info{color: #ffffff;background: #5578eb;}
.kt-badge.kt-badge--outline.kt-badge--info{background: transparent;color: #5578eb;border: 1px solid #5578eb;}
.kt-badge.kt-badge--outline.kt-badge--info.kt-badge--outline-2x{border: 2px solid #5578eb;}
.kt-badge.kt-badge--warning{color: #111111;background: #ffb822;}
.kt-badge.kt-badge--outline.kt-badge--warning{background: transparent;color: #ffb822;border: 1px solid #ffb822;}
.kt-badge.kt-badge--outline.kt-badge--warning.kt-badge--outline-2x{border: 2px solid #ffb822;}
.kt-badge.kt-badge--danger{color: #ffffff;background: #fd397a;}
.kt-badge.kt-badge--outline.kt-badge--danger{background: transparent;color: #fd397a;border: 1px solid #fd397a;}
.kt-badge.kt-badge--outline.kt-badge--danger.kt-badge--outline-2x{border: 2px solid #fd397a;}
.kt-badge.kt-badge--unified-brand{color: #5d78ff;background: rgba(93, 120, 255, 0.1);}
.kt-badge.kt-badge--unified-light{color: #ffffff;background: rgba(255, 255, 255, 0.1);}
.kt-badge.kt-badge--unified-dark{color: #282a3c;background: rgba(40, 42, 60, 0.1);}
.kt-badge.kt-badge--unified-primary{color: #5867dd;background: rgba(88, 103, 221, 0.1);}
.kt-badge.kt-badge--unified-success{color: #0abb87;background: rgba(10, 187, 135, 0.1);}
.kt-badge.kt-badge--unified-info{color: #5578eb;background: rgba(85, 120, 235, 0.1);}
.kt-badge.kt-badge--unified-warning{color: #ffb822;background: rgba(255, 184, 34, 0.1);}
.kt-badge.kt-badge--unified-danger{color: #fd397a;background: rgba(253, 57, 122, 0.1);}
.kt-badge.kt-badge--inline{height: 0;width: auto;padding: 0.75rem 0.75rem;border-radius: 2px;}
.kt-badge.kt-badge--inline.kt-badge--md{padding: 0.8rem 0.6rem;}
.kt-badge.kt-badge--inline.kt-badge--lg{padding: 0.9rem 0.7rem;}
.kt-badge.kt-badge--inline.kt-badge--xl{padding: 1rem 0.8rem;}
.kt-badge.kt-badge--pill{border-radius: 2rem;}
.kt-badge.kt-badge--rounded{border-radius: 4px;}
.kt-badge.kt-badge--dot{display: inline-block;line-height: 6px;min-height: 6px;min-width: 6px;height: 6px;width: 6px;font-size: 0;vertical-align: middle;text-align: center;}
.kt-badge.kt-badge--dot.kt-badge--sm{line-height: 4px;min-height: 4px;min-width: 4px;height: 4px;width: 4px;}
.kt-badge.kt-badge--dot.kt-badge--lg{line-height: 8px;min-height: 8px;min-width: 8px;height: 8px;width: 8px;}
.kt-badge.kt-badge--dot.kt-badge--xl{line-height: 10px;min-height: 10px;min-width: 10px;height: 10px;width: 10px;}
.kt-badge.kt-badge--square{border-radius: 0;}
.kt-badge.kt-badge--elevate{-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);}
.kt-demo-icon{margin-bottom: 20px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 20px;border-radius: 4px;}
.kt-demo-icon .kt-demo-icon__preview{display: -webkit-box;display: -ms-flexbox;display: flex;padding-right: 20px;-webkit-box-flex: 0;-ms-flex: 0 0 40px;flex: 0 0 40px;}
.kt-demo-icon .kt-demo-icon__preview i{line-height: 0;vertical-align: middle;font-size: 2.5rem !important;}
.kt-demo-icon .kt-demo-icon__class{color: #a7abc3;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding: 0;font-size: 1rem;font-weight: 400;}
.kt-demo-icon:hover{background-color: #f7f8fa;}
.kt-demo-icon.kt-demo-icon--overlay{position: relative;}
.kt-demo-icon.kt-demo-icon--overlay svg{height: 40px;width: 40px;}
.kt-demo-icon.kt-demo-icon--overlay .kt-demo-icon__overlay{position: absolute;top: 0;bottom: 0;left: 0;right: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;background-color: rgba(0, 0, 0, 0.05);-webkit-transition: all 0.3 ease;transition: all 0.3 ease;opacity: 0;}
.kt-demo-icon.kt-demo-icon--overlay .kt-demo-icon__overlay .btn{margin-right: 10px;}
.kt-demo-icon.kt-demo-icon--overlay .kt-demo-icon__overlay .btn:last-child{margin-right: 0;}
.kt-demo-icon.kt-demo-icon--overlay:hover{background-color: transparent;}
.kt-demo-icon.kt-demo-icon--overlay:hover .kt-demo-icon__overlay{-webkit-transition: all 0.3 ease;transition: all 0.3 ease;opacity: 1;}
.kt-divider{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-divider > span:first-child{width: 100%;height: 1px;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;background: #EBECF1;display: inline-block;}
.kt-divider > span:last-child{width: 100%;height: 1px;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;background: #EBECF1;display: inline-block;}
.kt-divider > span:not(:first-child):not(:last-child){
padding: 0 2rem;}
.kt-heading{font-size: 1.3rem;font-weight: 500;padding: 0;margin: 1.75rem 0;color: #464457;}
.kt-heading.kt-heading--center{text-align: center;}
.kt-heading.kt-heading--right{text-align: right;}
.kt-heading.kt-heading--space-sm{margin-top: 1rem;margin-bottom: 1rem;}
.kt-heading.kt-heading--space-md{margin-top: 2rem;margin-bottom: 2rem;}
.kt-heading.kt-heading--space-lg{margin-top: 2.5rem;margin-bottom: 2.5rem;}
.kt-heading.kt-heading--space-xl{margin-top: 3rem;margin-bottom: 3rem;}
.kt-heading.kt-heading--sm{font-size: 1.2rem;}
.kt-heading.kt-heading--md{font-size: 1.4rem;}
.kt-heading.kt-heading--lg{font-size: 1.6rem;}
.kt-heading.kt-heading--xl{font-size: 2rem;}
.kt-heading.kt-heading--medium{font-weight: 600;}
.kt-heading.kt-heading--bold{font-weight: 700;}
.kt-heading.kt-heading--bolder{font-weight: 900;}
.kt-heading.kt-heading--thin{font-weight: 300;}
.kt-heading.kt-heading--no-top-margin{margin-top: 0;}
.kt-heading.kt-heading--no-bottok-margin{margin-bottom: 0;}
.kt-link{text-decoration: none;position: relative;display: inline-block;color: #505ae2;}
.kt-link:after{display: block;content: '';position: absolute;bottom: 0;top: 1rem;left: 0;width: 0%;-webkit-transition: width 0.3s ease;transition: width 0.3s ease;}
.kt-link:hover{text-decoration: none !important;}
.kt-link:hover:after{width: 100%;}
.kt-link:hover{color: #3641de;}
.kt-link:hover:after{border-bottom: 1px solid #3641de;opacity: 0.3;}
.kt-link.kt-link--brand{color: #5d78ff;}
.kt-link.kt-link--brand:hover{color: #2a4eff;}
.kt-link.kt-link--brand:hover:after{border-bottom: 1px solid #2a4eff;opacity: 0.3;}
.kt-link.kt-link--light{color: #ffffff;}
.kt-link.kt-link--light:hover{color: #e6e6e6;}
.kt-link.kt-link--light:hover:after{border-bottom: 1px solid #e6e6e6;opacity: 0.3;}
.kt-link.kt-link--dark{color: #282a3c;}
.kt-link.kt-link--dark:hover{color: #14151d;}
.kt-link.kt-link--dark:hover:after{border-bottom: 1px solid #14151d;opacity: 0.3;}
.kt-link.kt-link--primary{color: #5867dd;}
.kt-link.kt-link--primary:hover{color: #2e40d4;}
.kt-link.kt-link--primary:hover:after{border-bottom: 1px solid #2e40d4;opacity: 0.3;}
.kt-link.kt-link--success{color: #0abb87;}
.kt-link.kt-link--success:hover{color: #078b64;}
.kt-link.kt-link--success:hover:after{border-bottom: 1px solid #078b64;opacity: 0.3;}
.kt-link.kt-link--info{color: #5578eb;}
.kt-link.kt-link--info:hover{color: #2754e6;}
.kt-link.kt-link--info:hover:after{border-bottom: 1px solid #2754e6;opacity: 0.3;}
.kt-link.kt-link--warning{color: #ffb822;}
.kt-link.kt-link--warning:hover{color: #eea200;}
.kt-link.kt-link--warning:hover:after{border-bottom: 1px solid #eea200;opacity: 0.3;}
.kt-link.kt-link--danger{color: #fd397a;}
.kt-link.kt-link--danger:hover{color: #fc0758;}
.kt-link.kt-link--danger:hover:after{border-bottom: 1px solid #fc0758;opacity: 0.3;}
.kt-section{padding: 0;margin: 0 0 2rem 0;}
.kt-section.kt-section--space-sm{margin-bottom: 1rem;}
.kt-section.kt-section--space-md{margin-bottom: 2rem;}
.kt-section.kt-section--space-lg{margin-bottom: 2.5rem;}
.kt-section.kt-section--space-xl{margin-bottom: 3rem;}
.kt-section .kt-section__heading, .kt-section .kt-section__title{display: block;font-size: 1.3rem;font-weight: 500;padding: 0;margin: 0 0 1.75rem 0;color: #464457;}
.kt-section .kt-section__heading.kt-section__title-sm, .kt-section .kt-section__title.kt-section__title-sm{font-size: 1.1rem;}
.kt-section .kt-section__heading.kt-section__title-md, .kt-section .kt-section__title.kt-section__title-md{font-size: 1.4rem;}
.kt-section .kt-section__heading.kt-section__title-lg, .kt-section .kt-section__title.kt-section__title-lg{font-size: 1.5rem;}
.kt-section .kt-section__desc{display: block;font-size: 1rem;font-weight: 400;padding: 0;margin: 0 0 1rem 0;color: #6c7293;}
.kt-section .kt-section__info{display: block;font-size: 1rem;padding: 0;margin: 0 0 1rem 0;color: #6c7293;}
.kt-section .kt-section__content{display: block;padding: 0;margin: 0;}
.kt-section .kt-section__content.kt-section__content--border{padding: 1.5rem;border: 3px solid #ebedf2;border-radius: 4px;}
.kt-section .kt-section__content.kt-section__content--solid{padding: 1.5rem;border-left: 4px solid #e2e5ec;background-color: #f7f8fa;border-radius-top-left: 4px;border-radius-top-right: 4px;}
.kt-section .kt-section__content.kt-section__content--x-fit{padding-left: 0;padding-right: 0;}
.kt-section .kt-section__content.kt-section__content--fit{padding: 0;}
.kt-section.kt-section--last{margin-bottom: 0;}
.kt-section.kt-section--first{margin-top: 0;}
.kt-separator{height: 0;margin: 20px 0;border-bottom: 1px solid #ebedf2;}
.kt-separator.kt-separator--border-dashed, .kt-separator.kt-separator--dashed{border-bottom: 1px dashed #ebedf2;}
.kt-separator.kt-separator--border-2x{border-bottom-width: 2px;}
.kt-separator.kt-separator--space{border-bottom: 0;}
.kt-separator.kt-separator--space-sm, .kt-separator.kt-separator--sm{margin: 0.5rem 0;}
.kt-separator.kt-separator--space-md, .kt-separator.kt-separator--md{margin: 2rem 0;}
.kt-separator.kt-separator--space-lg, .kt-separator.kt-separator--lg{margin: 2.5rem 0;}
.kt-separator.kt-separator--space-xl, .kt-separator.kt-separator--xl{margin: 3rem 0;}
.kt-separator.kt-separator--fit{margin-left: -25px;margin-right: -25px;}
.kt-separator.kt-separator--brand{border-bottom: 1px solid #5d78ff;}
.kt-separator.kt-separator--brand.kt-separator--dashed{border-bottom: 1px dashed #5d78ff;}
.kt-separator.kt-separator--light{border-bottom: 1px solid #ffffff;}
.kt-separator.kt-separator--light.kt-separator--dashed{border-bottom: 1px dashed #ffffff;}
.kt-separator.kt-separator--dark{border-bottom: 1px solid #282a3c;}
.kt-separator.kt-separator--dark.kt-separator--dashed{border-bottom: 1px dashed #282a3c;}
.kt-separator.kt-separator--primary{border-bottom: 1px solid #5867dd;}
.kt-separator.kt-separator--primary.kt-separator--dashed{border-bottom: 1px dashed #5867dd;}
.kt-separator.kt-separator--success{border-bottom: 1px solid #0abb87;}
.kt-separator.kt-separator--success.kt-separator--dashed{border-bottom: 1px dashed #0abb87;}
.kt-separator.kt-separator--info{border-bottom: 1px solid #5578eb;}
.kt-separator.kt-separator--info.kt-separator--dashed{border-bottom: 1px dashed #5578eb;}
.kt-separator.kt-separator--warning{border-bottom: 1px solid #ffb822;}
.kt-separator.kt-separator--warning.kt-separator--dashed{border-bottom: 1px dashed #ffb822;}
.kt-separator.kt-separator--danger{border-bottom: 1px solid #fd397a;}
.kt-separator.kt-separator--danger.kt-separator--dashed{border-bottom: 1px dashed #fd397a;}
.kt-svg-icon{height: 23px;width: 23px;}
.kt-svg-icon g [fill]{fill: #5d78ff;}
.kt-svg-icon.kt-svg-icon--brand g [fill]{fill: #5d78ff !important;}
.kt-svg-icon.kt-svg-icon--light g [fill]{fill: #ffffff !important;}
.kt-svg-icon.kt-svg-icon--dark g [fill]{fill: #282a3c !important;}
.kt-svg-icon.kt-svg-icon--primary g [fill]{fill: #5867dd !important;}
.kt-svg-icon.kt-svg-icon--success g [fill]{fill: #0abb87 !important;}
.kt-svg-icon.kt-svg-icon--info g [fill]{fill: #5578eb !important;}
.kt-svg-icon.kt-svg-icon--warning g [fill]{fill: #ffb822 !important;}
.kt-svg-icon.kt-svg-icon--danger g [fill]{fill: #fd397a !important;}
.kt-svg-icon.kt-svg-icon--sm{height: 20px;width: 20px;}
.kt-svg-icon.kt-svg-icon--md{height: 30px;width: 30px;}
.kt-svg-icon.kt-svg-icon--lg{height: 40px;width: 40px;}
.kt-svg-icon.kt-svg-icon--xl{height: 50px;width: 50px;}
.kt-widget1{padding: 25px;}
.kt-widget1.kt-widget1--fit{padding: 0;}
.kt-widget1.kt-widget1--paddingless{padding: 0;}
.kt-widget1 .kt-widget1__item{padding: 1.1rem 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;border-bottom: 0.07rem dashed #ebedf2;}
.kt-widget1 .kt-widget1__item:last-child{padding-bottom: 0;}
.kt-widget1 .kt-widget1__item:first-child{padding-top: 0;}
.kt-widget1 .kt-widget1__item .kt-widget1__info .kt-widget1__title{font-size: 1.1rem;font-weight: 500;color: #6c7293;}
.kt-widget1 .kt-widget1__item .kt-widget1__info .kt-widget1__desc{font-size: 1rem;font-weight: normal;color: #a7abc3;}
.kt-widget1 .kt-widget1__item .kt-widget1__number{font-size: 1.4rem;font-weight: 600;color: #a7abc3;}
.kt-widget1 .kt-widget1__item:last-child{border-bottom: 0;}
@media (max-width: 1024px){
.kt-widget1{padding: 15px;}
}
.kt-widget2 .kt-widget2__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 1.4rem;position: relative;}
.kt-widget2 .kt-widget2__item:before{position: absolute;display: block;width: 0.3rem;border-radius: 4px;width: 4px;border-radius: 4px;height: 100%;left: 0.8rem;content: "";}
.kt-widget2 .kt-widget2__item .kt-widget2__checkbox{padding: 1rem 0 0 2.2rem;}
.kt-widget2 .kt-widget2__item .kt-widget2__info{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;padding-left: 0.23rem 0 0 0.3rem;}
.kt-widget2 .kt-widget2__item .kt-widget2__info .kt-widget2__title{font-weight: 500;margin: 0;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget2 .kt-widget2__item .kt-widget2__info .kt-widget2__title:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget2 .kt-widget2__item .kt-widget2__info .kt-widget2__username{text-decoration: none;font-size: 0.9rem;color: #a7abc3;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget2 .kt-widget2__item .kt-widget2__info .kt-widget2__username:hover{text-decoration: none;color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget2 .kt-widget2__item .kt-widget2__actions{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-align: right;padding: 0 0.5rem 0 0.8rem;visibility: hidden;}
.kt-widget2 .kt-widget2__item .kt-widget2__actions a{text-decoration: none;}
.kt-widget2 .kt-widget2__item .kt-widget2__actions i{font-size: 2.1rem;}
.kt-widget2 .kt-widget2__item:hover .kt-widget2__actions{visibility: visible;}
.kt-widget2 .kt-widget2__item.kt-widget2__item--brand:before{background: #5d78ff;}
.kt-widget2 .kt-widget2__item.kt-widget2__item--light:before{background: #ffffff;}
.kt-widget2 .kt-widget2__item.kt-widget2__item--dark:before{background: #282a3c;}
.kt-widget2 .kt-widget2__item.kt-widget2__item--primary:before{background: #5867dd;}
.kt-widget2 .kt-widget2__item.kt-widget2__item--success:before{background: #0abb87;}
.kt-widget2 .kt-widget2__item.kt-widget2__item--info:before{background: #5578eb;}
.kt-widget2 .kt-widget2__item.kt-widget2__item--warning:before{background: #ffb822;}
.kt-widget2 .kt-widget2__item.kt-widget2__item--danger:before{background: #fd397a;}
.kt-widget3 .kt-widget3__item{margin-bottom: 1rem;border-bottom: 0.07rem dashed #ebedf2;}
.kt-widget3 .kt-widget3__item .kt-widget3__header{margin-top: 1.5rem;padding-bottom: 0.8rem;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget3 .kt-widget3__item .kt-widget3__header .kt-widget3__user-img .kt-widget3__img{width: 3.2rem;border-radius: 50%;}
.kt-widget3 .kt-widget3__item .kt-widget3__header .kt-widget3__info{padding-left: 1rem;}
.kt-widget3 .kt-widget3__item .kt-widget3__header .kt-widget3__info .kt-widget3__username{font-weight: 500;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget3 .kt-widget3__item .kt-widget3__header .kt-widget3__info .kt-widget3__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget3 .kt-widget3__item .kt-widget3__header .kt-widget3__info .kt-widget3__time{font-size: 0.9rem;font-weight: 400;color: #a7abc3;}
.kt-widget3 .kt-widget3__item .kt-widget3__header .kt-widget3__status{font-weight: 500;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-align: right;}
.kt-widget3 .kt-widget3__item .kt-widget3__body .kt-widget3__text{color: #a7abc3;}
.kt-widget3 .kt-widget3__item:last-child{border-bottom: 0;}
.kt-widget3 .kt-widget3__item:first-child .kt-widget3__header{margin-top: 0;}
.kt-widget4 .kt-widget4__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding-top: 1rem;padding-bottom: 1rem;border-bottom: 1px dashed #ebedf2;}
.kt-widget4 .kt-widget4__item .kt-widget4__pic{padding-right: 1rem;}
.kt-widget4 .kt-widget4__item .kt-widget4__pic img{width: 2.5rem;border-radius: 4px;}
.kt-widget4 .kt-widget4__item .kt-widget4__pic.kt-widget4__pic--sm img{width: 2.5rem;}
.kt-widget4 .kt-widget4__item .kt-widget4__pic.kt-widget4__pic--lg img{width: 3.5rem;}
.kt-widget4 .kt-widget4__item .kt-widget4__pic.kt-widget4__pic--xl img{width: 4rem;}
.kt-widget4 .kt-widget4__item .kt-widget4__pic.kt-widget4__pic--circle img{border-radius: 50%;}
.kt-widget4 .kt-widget4__item .kt-widget4__img{margin-right: 0.5rem;}
.kt-widget4 .kt-widget4__item .kt-widget4__info{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;padding-right: 1.25rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-widget4 .kt-widget4__item .kt-widget4__info .kt-widget4__username{font-weight: 500;font-size: 1rem;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget4 .kt-widget4__item .kt-widget4__info .kt-widget4__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget4 .kt-widget4__item .kt-widget4__info .kt-widget4__title{font-weight: 500;font-size: 1.1rem;color: #6c7293;}
.kt-widget4 .kt-widget4__item .kt-widget4__info .kt-widget4__text{font-size: 1rem;margin: 0;color: #a7abc3;}
.kt-widget4 .kt-widget4__item .kt-widget4__title{color: #6c7293;font-size: 1rem;font-weight: 500;padding-right: 1.25rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget4 .kt-widget4__item .kt-widget4__title.kt-widget4__title--light{font-weight: 400;}
.kt-widget4 .kt-widget4__item .kt-widget4__title:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget4 .kt-widget4__item .kt-widget4__number{width: 7rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-align: right;font-weight: 500;font-size: 1.1rem;}
.kt-widget4 .kt-widget4__item .kt-widget4__icon{padding-right: 1.25rem;}
.kt-widget4 .kt-widget4__item .kt-widget4__icon > i{font-size: 1.6rem;text-align: right;color: #a7abc3;}
.kt-widget4 .kt-widget4__item .kt-widget4__icon.kt-widget4__icon--2x > i{font-size: 2.2rem;}
.kt-widget4 .kt-widget4__item:first-child{padding-top: 0;}
.kt-widget4 .kt-widget4__item:last-child{padding-bottom: 0;border-bottom: 0;}
.kt-widget4.kt-widget4--progress .kt-widget4__content{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 50%;}
.kt-widget4.kt-widget4--progress .kt-widget4__content .kt-widget4__progress{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;padding-right: 1.5rem;}
.kt-widget4.kt-widget4--progress .kt-widget4__content .kt-widget4__progress .kt-widget4__stats{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 0.7rem;}
.kt-widget4.kt-widget4--progress .kt-widget4__content .kt-widget4__progress .kt-widget4__stats > span{line-height: 1.1;}
.kt-widget4.kt-widget4--progress .kt-widget4__content .kt-widget4__progress .kt-widget4__stats > span:first-child{font-size: 1.1rem;font-weight: 600;color: #6c7293;}
.kt-widget4.kt-widget4--progress .kt-widget4__content .kt-widget4__progress .kt-widget4__stats > span:last-child{font-size: 0.9rem;color: #a7abc3;font-weight: 400;}
.kt-widget4.kt-widget4--progress .kt-widget4__content .kt-widget4__progress .progress{width: 100%;}
.kt-widget4 .kt-widget4__chart{position: relative;}
.kt-widget4.kt-widget4--sticky{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;height: 100%;width: 100%;}
.kt-widget4.kt-widget4--sticky .kt-widget4__items{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-widget4.kt-widget4--sticky .kt-widget4__items.kt-widget4__items--bottom{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.kt-widget4.kt-widget4--sticky .kt-widget4__chart canvas{border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
.kt-widget5 .kt-widget5__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 1.5rem;padding-bottom: 1.5rem;border-bottom: 0.07rem dashed #ebedf2;}
.kt-widget5 .kt-widget5__item .kt-widget5__content{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__pic{padding-right: 1.25rem;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__pic img{max-width: 8.5rem;border-radius: 4px;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__title{font-size: 1.1rem;font-weight: 500;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__title:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__desc{margin: 0;padding: 0.4rem 0;font-size: 1rem;font-weight: 400;color: #a7abc3;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__info span:nth-child(even){
font-weight: 500;margin-right: 0.71rem;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__stats{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__stats .kt-widget5__number{font-size: 1.3rem;font-weight: 500;color: #6c7293;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__stats:first-child{padding-right: 3rem;}
.kt-widget5 .kt-widget5__item .kt-widget5__content:last-child{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-align: right;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;padding-left: 1rem;}
.kt-widget5 .kt-widget5__item:last-child{border-bottom: 0;}
@media (max-width: 1024px){
.kt-widget5{padding: 1rem;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__pic{padding-right: 0.5rem;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__pic img{max-width: 4rem;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__stats .kt-widget5__number{font-size: 1.2rem;font-weight: 500;}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__stats:first-child{padding-right: 1rem;}
}
.kt-widget6 .kt-widget6__head .kt-widget6__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 1.07rem;}
.kt-widget6 .kt-widget6__head .kt-widget6__item > span{font-size: 1rem;color: #a7abc3;font-weight: 400;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;text-align: left;}
.kt-widget6 .kt-widget6__head .kt-widget6__item > span:last-child{text-align: right;}
.kt-widget6 .kt-widget6__body .kt-widget6__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 1.07rem 0;border-bottom: 1px dashed #ebedf2;}
.kt-widget6 .kt-widget6__body .kt-widget6__item > span{color: #6c7293;font-weight: 400;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;text-align: left;font-size: 1rem;}
.kt-widget6 .kt-widget6__body .kt-widget6__item > span:last-child{text-align: right;}
.kt-widget6 .kt-widget6__body .kt-widget6__item:last-child{border-bottom: 0;}
.kt-widget6 .kt-widget6__foot .kt-widget6__action{text-align: right;margin-top: 1rem;}
.kt-widget7 .kt-widget7__desc{text-align: center;margin-top: 7rem;font-size: 1.3rem;color: #6c7293;}
.kt-widget7 .kt-widget7__content{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-top: 2rem;}
.kt-widget7 .kt-widget7__content .kt-widget7__userpic img{width: 3.6rem;border-radius: 50%;}
.kt-widget7 .kt-widget7__content .kt-widget7__info{padding-left: 1rem;}
.kt-widget7 .kt-widget7__content .kt-widget7__info .kt-widget7__username{color: #6c7293;font-weight: 500;font-size: 1.4rem;margin-bottom: 0;}
.kt-widget7 .kt-widget7__content .kt-widget7__info .kt-widget7__time{color: #a7abc3;font-size: 0.9rem;}
.kt-widget7 .kt-widget7__button{text-align: center;margin-top: 2rem;}
.kt-widget7.kt-widget7--skin-light .kt-widget7__desc{color: #fff;}
.kt-widget7.kt-widget7--skin-light .kt-widget7__info .kt-widget7__username{color: #fff;}
.kt-widget7.kt-widget7--skin-light .kt-widget7__info .kt-widget7__time{color: #fff;opacity: 0.8;}
.kt-widget9{padding: 2.2rem;}
.kt-widget9 .kt-widget9__header{padding: 1.1rem 0;margin-bottom: 0.5rem;}
.kt-widget9 .kt-widget9__header .kt-widget9__title{font-size: 1.2rem;font-weight: 600;margin-bottom: 0;color: #6c7293;}
.kt-widget9 .kt-widget9__header .kt-widget9__desc{display: inline-block;margin-top: 0.2rem;color: #a7abc3;}
.kt-widget9 .kt-widget9__header .kt-widget9__text{color: #a7abc3;}
.kt-widget9 .kt-widget9__chart{position: relative;margin-top: 0.5rem;}
.kt-widget9 .kt-widget9__chart .kt-widget9__stat{font-size: 2.4rem;font-weight: 700;color: #a7abc3;}
.kt-widget10 .kt-widget10__chart{margin-top: 1rem;margin-bottom: 3.5rem;position: relative;}
.kt-widget10 .kt-widget10__chart canvas{border-radius: 8px;}
.kt-widget10 .kt-widget10__items .kt-widget10__item .kt-widget10__stats{font-size: 1.1rem;font-weight: 700;color: #a7abc3;}
.kt-widget10 .kt-widget10__items .kt-widget10__item .kt-widget10__text{font-size: 0.9rem;float: right;margin-top: 0.3rem;color: #a7abc3;}
.kt-widget10 .kt-widget10__items .kt-widget10__item .progress{margin-bottom: 1.6rem;}
.kt-widget10 .kt-widget10__desc{margin-top: 1.6rem;font-size: 1rem;}
.kt-widget11 .table-responsive{overflow-y: hidden;}
.kt-widget11 .table thead > tr > td{padding: 0;vertical-align: top;border-top: 0;font-weight: 500;color: #a7abc3;}
.kt-widget11 .table tbody > tr{border-bottom: 1px dashed #ebedf2;}
.kt-widget11 .table tbody > tr > td{border: 0;padding-left: 0;padding-right: 0.5rem;padding-top: 20px;vertical-align: top;color: #6c7293;}
.kt-widget11 .table tbody > tr > td > label{right: 0;top: 0.5rem;vertical-align: top;}
.kt-widget11 .table tbody > tr > td .kt-widget11__chart{position: relative;margin-top: -0.6rem;}
.kt-widget11 .table tbody > tr > td:last-child{padding-right: 0;}
.kt-widget11 .table tbody > tr .kt-widget11__title{font-size: 1.1rem;font-weight: 500;display: block;color: #6c7293;-webkit-transition: color .3s ease;transition: color .3s ease;}
.kt-widget11 .table tbody > tr .kt-widget11__title:hover{color: #5d78ff;-webkit-transition: color .3s ease;transition: color .3s ease;}
.kt-widget11 .table tbody > tr .kt-widget11__title > span{color: #a7abc3;}
.kt-widget11 .table tbody > tr .kt-widget11__sub{display: block;font-size: 1rem;}
.kt-widget11 .table tbody > tr:last-child{border: 0;}
.kt-widget12{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;height: 100%;width: 100%;}
.kt-widget12 .kt-widget12__content{display: -webkit-box;display: -ms-flexbox;display: flex;padding-bottom: 1rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-widget12 .kt-widget12__content .kt-widget12__item{display: -webkit-box;display: -ms-flexbox;display: flex;margin-bottom: 2.5rem;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget12 .kt-widget12__content .kt-widget12__item .kt-widget12__info{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-widget12 .kt-widget12__content .kt-widget12__item .kt-widget12__info .kt-widget12__desc{font-size: 1rem;color: #a7abc3;padding-bottom: 0.5rem;font-weight: 500;display: block;}
.kt-widget12 .kt-widget12__content .kt-widget12__item .kt-widget12__info .kt-widget12__value{font-size: 1.4rem;font-weight: 600;color: #6c7293;display: block;}
.kt-widget12 .kt-widget12__content .kt-widget12__item .kt-widget12__info .kt-widget12__progress{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;line-height: 0;padding-top: 1rem;}
.kt-widget12 .kt-widget12__content .kt-widget12__item .kt-widget12__info .kt-widget12__progress .progress{width: 100%;height: 0.6rem;}
.kt-widget12 .kt-widget12__content .kt-widget12__item .kt-widget12__info .kt-widget12__progress .kt-widget12__stat{font-size: 1.3rem;font-weight: 700;color: #a7abc3;padding-left: 1rem;}
.kt-widget12 .kt-widget12__chart{position: relative;}
.kt-widget12 .kt-widget12__chart canvas{border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
.kt-widget13{padding: 2rem 0;}
.kt-widget13 .kt-widget13__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 2rem;}
.kt-widget13 .kt-widget13__item .kt-widget13__desc{color: #a7abc3;text-align: right;padding-right: 1rem;font-weight: 400;}
.kt-widget13 .kt-widget13__item .kt-widget13__text{color: #a7abc3;padding-left: 1rem;font-weight: 400;}
.kt-widget13 .kt-widget13__item .kt-widget13__text.kt-widget13__text--bold{color: #6c7293;font-size: 1.2rem;font-weight: 500;}
.kt-widget13 .kt-widget13__item > span{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-widget13 .kt-widget13__item:lasst-child{margin-bottom: 0;}
.kt-widget13 .kt-widget13__action{margin-top: 30px;padding-top: 30px;}
.kt-widget13 .kt-widget13__action .kt-widget__detalis{margin-right: 10px;border-top: 0.07rem dashed #ebedf2;}
.kt-widget13 .kt-widget13__action .kt-widget13__action{border-top: 0.07rem dashed #ebedf2;}
.kt-widget14{padding: 25px;}
.kt-widget14.kt-widget14--no-padding{padding: 0;}
.kt-widget14 .kt-widget14__header{padding: 0.5rem 0 1.1rem 0;margin-bottom: 0.5rem;}
.kt-widget14 .kt-widget14__header .kt-widget14__title{font-size: 1.3rem;font-weight: 500;margin-bottom: 0;color: #6c7293;}
.kt-widget14 .kt-widget14__header .kt-widget14__desc{display: inline-block;margin-top: 0.2rem;color: #a7abc3;}
.kt-widget14 .kt-widget14__content{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget14 .kt-widget14__legends{padding-left: 2rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-widget14 .kt-widget14__legends .kt-widget14__legend{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget14 .kt-widget14__legends .kt-widget14__legend .kt-widget14__bullet{width: 1.5rem;height: 0.45rem;border-radius: 1.1rem;}
.kt-widget14 .kt-widget14__legends .kt-widget14__legend .kt-widget14__stats{color: #a7abc3;font-weight: 500;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;padding-left: 1rem;}
.kt-widget14 .kt-widget14__legends .kt-widget14__legend:not(:first-child):not(:last-child){
padding: 0.5rem 0;}
.kt-widget14 .kt-widget14__chart{position: relative;}
.kt-widget14 .kt-widget14__chart .kt-widget14__stat{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;position: absolute;left: 0;right: 0;bottom: 0;top: 0;font-size: 2.2rem;font-weight: 500;color: #adb1c7;opacity: 0.7;}
.kt-widget14 .kt-widget14__chart canvas{position: relative;z-index: 1;}
@media (max-width: 1024px){
.kt-widget14{padding: 15px;}
.kt-widget14 .kt-widget14__legends{padding-left: 0.5rem;}
}
.kt-widget15{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;height: 100%;width: 100%;}
.kt-widget15 .kt-widget15__items{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-widget15 .kt-widget15__items.kt-widget15__items--bottom{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.kt-widget15 .kt-widget15__items .kt-widget15__item .kt-widget15__stats{font-size: 1.1rem;font-weight: 500;color: #a7abc3;}
.kt-widget15 .kt-widget15__items .kt-widget15__item .kt-widget15__text{font-size: 1rem;float: right;margin-top: 0.3rem;font-weight: 400;color: #a7abc3;}
.kt-widget15 .kt-widget15__items .kt-widget15__item .progress{margin-bottom: 1.6rem;height: 0.45rem;}
.kt-widget15 .kt-widget15__items .kt-widget15__desc{margin-top: 0.5rem;font-size: 0.9rem;font-weight: 400;color: #a7abc3;}
.kt-widget16{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-widget16 .kt-widget16__items{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;padding-right: 1rem;width: 50%;}
.kt-widget16 .kt-widget16__items .kt-widget16__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 0.7rem 0;border-bottom: 1px dashed #ebedf2;}
.kt-widget16 .kt-widget16__items .kt-widget16__item .kt-widget16__sceduled{font-weight: 500;color: #a7abc3;font-size: 1rem;}
.kt-widget16 .kt-widget16__items .kt-widget16__item .kt-widget16__amount{font-size: 1rem;font-weight: 500;color: #a7abc3;text-align: right;}
.kt-widget16 .kt-widget16__items .kt-widget16__item .kt-widget16__date{font-size: 1rem;font-weight: 300;color: #a7abc3;}
.kt-widget16 .kt-widget16__items .kt-widget16__item .kt-widget16__price{font-size: 1rem;font-weight: 500;text-align: right;}
.kt-widget16 .kt-widget16__items .kt-widget16__item:last-child{border-bottom: 0;}
.kt-widget16 .kt-widget16__stats{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;padding-left: 1rem;width: 50%;}
.kt-widget16 .kt-widget16__stats .kt-widget16__legends{font-weight: 1;padding-left: 2rem;}
.kt-widget16 .kt-widget16__stats .kt-widget16__legends .kt-widget16__legend .kt-widget16__bullet{width: 1.5rem;height: 0.45rem;display: inline-block;border-radius: 1.1rem;margin: 0 1rem 0.1rem 0;}
.kt-widget16 .kt-widget16__stats .kt-widget16__legends .kt-widget16__legend .kt-widget16__stat{display: inline-block;color: #a7abc3;font-weight: 500;}
.kt-widget16 .kt-widget16__stats .kt-widget16__legends .kt-widget16__legend:not(:first-child):not(:last-child){
padding: 0.5rem 0;}
.kt-widget16 .kt-widget16__stats .kt-widget16__legends:last-child{margin-bottom: 0;}
.kt-widget16 .kt-widget16__stats .kt-widget16__visual .kt-widget16__chart{margin-top: 0.5rem;position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-widget16 .kt-widget16__stats .kt-widget16__visual .kt-widget16__chart .kt-widget16__stat{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;position: absolute;left: 0;right: 0;bottom: 0;top: 0;font-size: 2.2rem;font-weight: 500;color: #adb1c7;}
.kt-widget16 .kt-widget16__stats .kt-widget16__visual .kt-widget16__chart canvas{position: relative;z-index: 1;}
@media (max-width: 768px){
.kt-widget16{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-widget16 .kt-widget16__items{width: 100%;}
.kt-widget16 .kt-widget16__stats{width: 100%;}
.kt-widget16 .kt-widget16__stats .kt-widget16__legends{padding-left: 1rem;}
}
.kt-widget17 .kt-widget17__visual{border-top-left-radius: 4px;border-top-right-radius: 4px;}
.kt-widget17 .kt-widget17__visual .kt-widget17__chart{position: relative;padding-top: 8rem;}
.kt-widget17 .kt-widget17__stats{display: column;margin: -4.3rem auto 0 auto;position: relative;width: 90%;}
.kt-widget17 .kt-widget17__stats .kt-widget17__items{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item{padding: 2rem;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;overflow: hidden;background-color: white;margin: 0.3rem;-webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.06);box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.06);cursor: pointer;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item .kt-widget17__icon{display: block;}
.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item .kt-widget17__icon > i{font-size: 2.6rem;}
.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item .kt-widget17__icon svg{margin-left: -4px;width: 38px;height: 38px;}
.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item .kt-widget17__subtitle{display: block;margin-top: 0.75rem;font-size: 1.2rem;font-weight: 500;color: #6c7293;}
.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item .kt-widget17__desc{display: block;font-size: 1rem;color: #a7abc3;}
.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item:hover{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;-webkit-box-shadow: 0px 1px 21px 1px rgba(69, 65, 78, 0.12);box-shadow: 0px 1px 21px 1px rgba(69, 65, 78, 0.12);}
@media (max-width: 768px){
.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item{padding-left: 0.5rem;}
}
.kt-widget19 .kt-widget19__pic{border-top-left-radius: 4px;border-top-right-radius: 4px;position: relative;background-repeat: no-repeat;background-size: cover;}
.kt-widget19 .kt-widget19__pic > img{width: 100%;}
.kt-widget19 .kt-widget19__pic .kt-widget19__shadow{position: absolute;top: 70%;bottom: 0;left: 0;right: 0;background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(0, 0, 0, 0)), color-stop(40%, rgba(0, 0, 0, 0.1)), color-stop(90%, rgba(0, 0, 0, 0.5))) no-repeat scroll 0 0;background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.5) 90%) no-repeat scroll 0 0;}
.kt-widget19 .kt-widget19__pic .kt-widget19__title{position: absolute;bottom: 0;display: block;z-index: 1;padding-left: 25px;padding-bottom: 12.5px;color: #6c7293;}
.kt-widget19 .kt-widget19__pic .kt-widget19__labels{position: absolute;top: 25px;left: 25px;}
@media (max-width: 1024px){
.kt-widget19 .kt-widget19__pic .kt-widget19__labels{top: 15px;left: 15px;}
}
.kt-widget19 .kt-widget19__wrapper{margin-bottom: 1rem;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin: 0 0 1.75rem 0;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__userpic > img{width: 3.2rem;border-radius: 100%;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__info{padding-left: 1rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__info .kt-widget19__username{font-size: 1.1rem;font-weight: 500;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__info .kt-widget19__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__info .kt-widget19__time{font-size: 1rem;font-weight: 400;color: #a7abc3;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__stats{font-size: 1rem;font-weight: 500;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__stats .kt-widget19__number{font-size: 1.4rem;font-weight: 700;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__stats .kt-widget19__comment{font-size: 0.9rem;color: #a7abc3;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__stats .kt-widget19__comment:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__content .kt-widget19__stats span{text-align: center;display: block;}
.kt-widget19 .kt-widget19__wrapper .kt-widget19__text{text-align: justify;color: #a7abc3;}
.kt-widget19 .kt-widget19__action{margin-top: 1.5rem;}
.kt-widget20{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;height: 100%;width: 100%;}
.kt-widget20 .kt-widget20__content{display: -webkit-box;display: -ms-flexbox;display: flex;padding-top: 1.15rem;padding-bottom: 1.25rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-widget20 .kt-widget20__content .kt-widget20__desc{color: #a7abc3;font-size: 1rem;font-weight: 400;margin-top: 0.25rem;}
.kt-widget20 .kt-widget20__content .kt-widget20__number{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;font-weight: 600;font-size: 1.8rem;}
.kt-widget20 .kt-widget20__chart{position: relative;}
.kt-widget20 .kt-widget20__chart canvas{border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
.kt-widget21{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;height: 100%;width: 100%;}
.kt-widget21 .kt-widget21__content{display: -webkit-box;display: -ms-flexbox;display: flex;padding-top: 1.15rem;padding-bottom: 1.25rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;display: flex;}
.kt-widget21 .kt-widget21__content .kt-widget21__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;padding-left: 2rem;margin-top: 1.5rem;}
.kt-widget21 .kt-widget21__content .kt-widget21__item .kt-widget21__icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 40px;height: 40px;border-radius: 50%;}
.kt-widget21 .kt-widget21__content .kt-widget21__item .kt-widget21__icon i{font-size: 1.4rem;}
.kt-widget21 .kt-widget21__content .kt-widget21__item .kt-widget21__info{padding-left: 1rem;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-widget21 .kt-widget21__content .kt-widget21__item .kt-widget21__info .kt-widget21__title{display: block;font-size: 1.1rem;font-weight: 500;color: #6c7293;}
.kt-widget21 .kt-widget21__content .kt-widget21__item .kt-widget21__info .kt-widget21__sub{display: block;font-size: 1rem;color: #a7abc3;}
.kt-widget21 .kt-widget21__chart{position: relative;}
.kt-widget21 .kt-widget21__chart canvas{border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
@media (max-width: 768px){
.kt-widget21 .kt-widget21__content .kt-widget21__item{padding-left: 0;margin-top: 0.5rem;}
.kt-widget21 .kt-widget21__content .kt-widget21__item .kt-widget21__info{padding-left: 0.5rem;}
}
.kt-widget24{padding: 25px;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-widget24 .kt-widget24__details{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget24 .kt-widget24__details .kt-widget24__info .kt-widget24__title{display: -webkit-box;display: -ms-flexbox;display: flex;font-size: 1.1rem;font-weight: 500;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget24 .kt-widget24__details .kt-widget24__info .kt-widget24__title:hover{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;color: #5d78ff;}
.kt-widget24 .kt-widget24__details .kt-widget24__info .kt-widget24__desc{color: #a7abc3;font-weight: 400;}
.kt-widget24 .kt-widget24__details .kt-widget24__stats{font-size: 1.75rem;font-weight: 500;padding-left: 0.5rem;}
.kt-widget24 .progress{height: 0.5rem;margin: 2rem 0 0.5rem 0;}
.kt-widget24 .kt-widget24__action{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;font-weight: 400;}
.kt-widget24 .kt-widget24__action .kt-widget24__change{color: #a7abc3;}
.kt-widget24 .kt-widget24__action .kt-widget24__number{color: #a7abc3;}
.kt-widget24.kt-widget24--solid{border: 1px solid #ebedf2;padding: 1rem;border-radius: 4px;}
@media (max-width: 1024px){
.kt-widget24{padding: 15px;}
}
.kt-widget25{margin: 2rem 0;}
.kt-widget25 .kt-widget25__stats{font-size: 4.5rem;font-weight: 500;color: #6c7293;}
.kt-widget25 .kt-widget25__subtitle{color: #a7abc3;font-size: 1.1rem;padding-left: 1rem;}
.kt-widget25 .kt-widget25__items{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-top: 2rem;}
.kt-widget25 .kt-widget25__items .kt-widget25__item{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-widget25 .kt-widget25__items .kt-widget25__item .kt-widget25__progress-sub{display: inline-block;margin-top: 6px;font-size: 1.1rem;font-weight: 500;}
.kt-widget25 .kt-widget25__items .kt-widget25__item .kt-widget25__number{font-size: 2rem;font-weight: 600;}
.kt-widget25 .kt-widget25__items .kt-widget25__item .progress{height: 0.5rem;}
.kt-widget25 .kt-widget25__items .kt-widget25__item .kt-widget25__desc{font-size: 1.1rem;font-weight: 500;color: #a7abc3;padding-top: 0.7rem;display: block;}
.kt-widget25 .kt-widget25__items .kt-widget25__item:not(:first-child):not(:last-child){
margin: 0 2rem;}
.kt-widget26{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;height: 100%;width: 100%;}
.kt-widget26 .kt-widget26__content{display: -webkit-box;display: -ms-flexbox;display: flex;padding-bottom: 1rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-widget26 .kt-widget26__content .kt-widget26__number{font-weight: 600;font-size: 1.8rem;}
.kt-widget26 .kt-widget26__content .kt-widget26__desc{color: #a7abc3;font-size: 1.1rem;font-weight: 400;margin-top: 0.55rem;}
.kt-widget26 .kt-widget26__chart{position: relative;}
.kt-widget26 .kt-widget26__chart canvas{border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
.kt-widget27{border-top-left-radius: 4px;border-top-right-radius: 4px;}
.kt-widget27 .kt-widget27__visual{position: relative;border-top-left-radius: 4px;border-top-right-radius: 4px;}
.kt-widget27 .kt-widget27__visual > img{width: 100%;height: 286px;border-top-left-radius: 4px;border-top-right-radius: 4px;}
.kt-widget27 .kt-widget27__visual .kt-widget27__title{position: absolute;left: 50%;top: 60%;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);display: block;z-index: 1;color: #ffffff;}
.kt-widget27 .kt-widget27__visual .kt-widget27__title > span{font-size: 4.5rem;}
.kt-widget27 .kt-widget27__visual .kt-widget27__title > span > span{font-size: 2.5rem;padding-right: 0.4rem;}
.kt-widget27 .kt-widget27__visual .kt-widget27__btn .btn{position: absolute;left: 50%;top: 100%;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);z-index: 1;display: block;padding: 1rem 2.5rem;}
.kt-widget27 .kt-widget27__visual .kt-widget27__btn .btn:hover, .kt-widget27 .kt-widget27__visual .kt-widget27__btn .btn:focus, .kt-widget27 .kt-widget27__visual .kt-widget27__btn .btn:active{background-color: #fff;}
.kt-widget27 .kt-widget27__container{margin: 3rem 0;width: 100%;padding: 1rem 0.5rem 0 0.5rem;border-top-left-radius: 4px;border-top-right-radius: 4px;}
.kt-widget27 .kt-widget27__container .nav{display: table;width: 100%;table-layout: fixed;border-spacing: 0.7rem;}
.kt-widget27 .kt-widget27__container .nav .nav-item{display: table-cell;}
.kt-widget27 .kt-widget27__container .nav .nav-item > a{text-align: center;font-weight: 600;padding: 0.8rem 0 0.8rem 0;color: #a7abc3;border: 1px solid #f7f8fa;}
.kt-widget27 .kt-widget27__container .nav .nav-item > a.active{background-color: #5d78ff;border-color: #5d78ff !important;color: #ffffff;}
.kt-widget27 .kt-widget27__container .tab-content{padding: 0 1rem;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__header{padding: 1.1rem 0;margin-bottom: 0.5rem;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__header .kt-widget27__title{font-size: 1.3rem;font-weight: 500;margin-bottom: 0;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__header .kt-widget27__desc{display: inline-block;margin-top: 0.2rem;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__legends .kt-widget27__legend{margin-bottom: 0.9rem;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__legends .kt-widget27__legend:last-child{margin-bottom: 0;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__legends .kt-widget27__legend .kt-widget27__stats{color: #a7abc3;display: inline-block;font-weight: 500;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__legends .kt-widget27__legend .kt-widget27__bullet{width: 1.5rem;height: 0.45rem;display: inline-block;border-radius: 1.1rem;margin: 0 1rem 0.1rem 0;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__chart{position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;height: 110px;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__chart .kt-widget27__stat{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;position: absolute;left: 0;right: 0;bottom: 0;top: 0;font-size: 2.2rem;font-weight: 500;color: #a7abc3;}
.kt-widget27 .kt-widget27__container .tab-content .kt-widget27__chart canvas{position: relative;z-index: 1;}
.kt-widget28 .kt-widget28__visual{position: relative;min-height: 286px;background-repeat: no-repeat;background-size: cover;border-top-left-radius: 4px;border-top-right-radius: 4px;}
.kt-widget28 .kt-widget28__wrapper{width: 100%;}
.kt-widget28 .kt-widget28__wrapper .nav{display: table;width: 100%;table-layout: fixed;border-spacing: 1.2rem;margin-top: -8rem;position: absolute;left: 50%;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);padding: 0 1rem;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item{display: table-cell;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a{text-align: center;padding: 1rem 0 1rem 0;border: 1px solid rgba(255, 255, 255, 0);}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a > span{display: block;color: rgba(255, 255, 255, 0.7);-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a > span > i{font-size: 2.2rem;margin-right: 0;padding-bottom: 1rem;padding-top: 0.4rem;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a > span:last-child{font-weight: 400;font-size: 1rem;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a.active{border: 1px solid white;background-color: transparent;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a.active > span{color: #fff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a.active > span > i{color: #fff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:focus, .kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:active, .kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:hover{background-color: transparent;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:focus > span, .kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:active > span, .kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:hover > span{color: #fff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:focus > span > i, .kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:active > span > i, .kt-widget28 .kt-widget28__wrapper .nav .nav-item > a:hover > span > i{color: #fff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget28 .kt-widget28__wrapper .tab-content{margin-top: 2rem;}
.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .kt-widget28__tab-items .kt-widget28__tab-item{margin-top: 1.2rem;border-bottom: 1px solid #ebedf2;}
.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .kt-widget28__tab-items .kt-widget28__tab-item > span{display: block;}
.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .kt-widget28__tab-items .kt-widget28__tab-item > span:first-child{font-size: 1rem;font-weight: 400;color: #a7abc3;}
.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .kt-widget28__tab-items .kt-widget28__tab-item > span:last-child{color: #6c7293;font-size: 1.1rem;font-weight: 500;margin-bottom: 1rem;}
.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .kt-widget28__tab-items .kt-widget28__tab-item:first-child{margin-top: 0;}
.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .kt-widget28__tab-items .kt-widget28__tab-item:last-child{border-bottom: none;}
.kt-widget29{margin-top: 1rem;}
.kt-widget29 .kt-widget29__content{margin-bottom: 1.25rem;padding: 2rem;background-color: #fff;}
.kt-widget29 .kt-widget29__content .kt-widget29__title{font-size: 1.1rem;font-weight: 500;color: #6c7293;}
.kt-widget29 .kt-widget29__content .kt-widget29__item{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-widget29 .kt-widget29__content .kt-widget29__item .kt-widget29__info{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;text-align: left;}
.kt-widget29 .kt-widget29__content .kt-widget29__item .kt-widget29__info .kt-widget29__subtitle{display: block;color: #a7abc3;font-weight: 400;font-size: 1rem;padding: 0.25rem 0;}
.kt-widget29 .kt-widget29__content .kt-widget29__item .kt-widget29__info .kt-widget29__stats{display: block;font-size: 1.2rem;font-weight: 500;}
.kt-widget29 .kt-widget29__content:last-child{margin-bottom: 0;}
.kt-widget29 .kt-widget29__actions{margin-top: 1.5rem;padding: 0rem;}
@media (max-width: 768px){
.kt-widget29 .kt-widget29__content{padding: 2.2rem 1rem;}
.kt-widget29 .kt-widget29__content .kt-widget29__item .kt-widget29__info > span{padding-right: 1rem;}
.kt-widget29 .kt-widget29__content .kt-widget29__item .kt-widget29__info > span:last-child{padding-right: 0;}
}
.kt-widget30{margin: 1.5rem 0;}
.kt-widget30 .kt-widget30__head{padding: 0;max-width: 100%;margin: 0 auto 2rem auto;}
.kt-widget30 .kt-widget30__head .owl-carousel .carousel{cursor: pointer;text-align: center;padding: 1rem 0;margin: 1rem 1rem;-webkit-box-shadow: 0px 2px 14px 2px rgba(0, 0, 0, 0.04);box-shadow: 0px 2px 14px 2px rgba(0, 0, 0, 0.04);border-radius: 4px;}
.kt-widget30 .kt-widget30__head .owl-carousel .carousel > span{display: block;}
.kt-widget30 .kt-widget30__head .owl-carousel .carousel > span:first-child{font-size: 1.1rem;font-weight: 500;color: #6c7293;}
.kt-widget30 .kt-widget30__head .owl-carousel .carousel > span:last-child{font-size: 0.9rem;font-weight: 400;color: #a7abc3;}
.kt-widget30 .kt-widget30__head .owl-carousel .center > div{cursor: auto;background-color: #5d78ff;-webkit-box-shadow: 0px 2px 14px 2px rgba(93, 120, 255, 0.2);box-shadow: 0px 2px 14px 2px rgba(93, 120, 255, 0.2);}
.kt-widget30 .kt-widget30__head .owl-carousel .center > div > span:first-child{color: #ffffff;}
.kt-widget30 .kt-widget30__head .owl-carousel .center > div > span:last-child{color: rgba(255, 255, 255, 0.7);}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items{padding: 0 2.2rem;border-bottom: 1px dashed #ebedf2;}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items .kt-widget30__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 1.5rem;padding-bottom: 1.5rem;}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items .kt-widget30__item .kt-widget30__pic > img{width: 3rem;height: 3rem;border-radius: 100%;}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items .kt-widget30__item .kt-widget30__info{width: 100%;text-align: left;padding: 0 1rem;font-weight: 500;color: #a7abc3;}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items .kt-widget30__item .kt-widget30__info > a{display: block;font-size: 1rem;font-weight: 500;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items .kt-widget30__item .kt-widget30__info > a:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items .kt-widget30__item .kt-widget30__info > span{display: block;font-size: 1rem;font-weight: 400;color: #a7abc3;}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items .kt-widget30__item:last-child{border-bottom: none;}
.kt-widget30 .kt-widget30__body .owl-carousel .kt-widget30__items:last-child{border-bottom: none;}
@media (max-width: 1024px){
.kt-widget30{margin: 1rem 0;}
}
.kt-widget31 .kt-widget31__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 1.5rem;}
.kt-widget31 .kt-widget31__item .kt-widget31__content{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__pic > img{width: 4rem;border-radius: 50%;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__info{padding: 0 1.2rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__info .kt-widget31__username{font-weight: 500;font-size: 1.1rem;color: #6c7293;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__info .kt-widget31__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__info .kt-widget31__text{font-size: 1rem;margin: 0;font-weight: 400;color: #a7abc3;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__progress{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;padding-right: 3rem;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__progress .kt-widget31__stats{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 0.7rem;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__progress .kt-widget31__stats > span{line-height: 1.1;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__progress .kt-widget31__stats > span:first-child{font-size: 1.1rem;font-weight: 500;color: #6c7293;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__progress .kt-widget31__stats > span:last-child{font-size: 1rem;color: #a7abc3;font-weight: 400;}
.kt-widget31 .kt-widget31__item .kt-widget31__content .kt-widget31__progress .progress{width: 100%;}
.kt-widget31 .kt-widget31__item .kt-widget31__content:last-child{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 50%;}
.kt-widget31 .kt-widget31__item:last-child{margin-bottom: 0;}
@media (max-width: 768px){
.kt-widget31 .kt-widget31__item{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.kt-widget31 .kt-widget31__item .kt-widget31__content{width: 100%;}
.kt-widget31 .kt-widget31__item .kt-widget31__content:last-child{width: 100%;margin: 1rem 0;}
}
.kt-widget.kt-widget--user-profile-1{padding-bottom: 1.7rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__media img{width: 90px;max-width: 100%;border-radius: 8px;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content{padding-left: 1.6rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__username{font-size: 1.3rem;color: #464457;font-weight: 500;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__username i{font-size: 1.1rem;padding-left: 0.4rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__subtitle{font-size: 1;display: block;padding: 0.25rem 0 0 0;font-weight: 500;color: #a7abc3;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__action{margin-top: 1rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__action .btn{margin-right: 0.4rem;font-weight: 600;padding: 0.3rem 1rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content{padding: 1.9rem 0 2.1rem 0;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding-bottom: 0.5rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info .kt-widget__label{color: #464457;font-weight: 500;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info .kt-widget__data{color: #a7abc3;font-weight: 400;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info a.kt-widget__data:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info:last-child{padding-bottom: 0;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 0.9rem 1.05rem 0.9rem 0.6rem;margin: 0.4rem 0;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item.kt-widget__item--active{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;background: #f2f3f7;border-radius: 4px;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item.kt-widget__item--active .kt-widget__section .kt-widget__desc{color: #5d78ff;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item.kt-widget__item--active .kt-widget__section .kt-widget__icon .kt-svg-icon g [fill]{fill: #5d78ff;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item .kt-widget__section .kt-widget__icon svg{width: 2rem;height: 1.5rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item .kt-widget__section .kt-widget__icon .kt-svg-icon g [fill]{fill: #a7abc3;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item .kt-widget__section .kt-widget__desc{color: #a7abc3;font-weight: 500;padding-left: 0.3rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item:hover{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;background: #f2f3f7;cursor: pointer;border-radius: 4px;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item:hover .kt-widget__desc{color: #5d78ff;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__items .kt-widget__item:hover .kt-widget__icon .kt-svg-icon g [fill]{fill: #5d78ff;}
.kt-portlet__body.kt-portlet__body--fit-y .kt-widget{margin-top: -10px;}
@media (max-width: 1024px){
.kt-widget.kt-widget--user-profile-1{padding-bottom: 1.2rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__media img{max-width: 60px;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__username{font-size: 1.1rem;color: #464457;font-weight: 500;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__action{margin-top: 0.5rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__action .btn{margin-right: 0.4rem;font-weight: 500;padding: 0.25rem 1.25rem;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content{padding-bottom: 2rem;}
}
.kt-widget.kt-widget--user-profile-2{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;height: 100%;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-top: -45px;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__img{max-width: 90px;border-radius: 50%;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 90px;height: 90px;font-size: 1.5rem;border-radius: 50%;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--brand{background: rgba(93, 120, 255, 0.1);}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--light{background: rgba(255, 255, 255, 0.1);}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--dark{background: rgba(40, 42, 60, 0.1);}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--primary{background: rgba(88, 103, 221, 0.1);}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--success{background: rgba(10, 187, 135, 0.1);}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--info{background: rgba(85, 120, 235, 0.1);}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--warning{background: rgba(255, 184, 34, 0.1);}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--danger{background: rgba(253, 57, 122, 0.1);}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info{padding-left: 1rem;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__username{font-size: 1.4rem;color: #464457;font-weight: 500;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__info .kt-widget__desc{display: block;font-weight: 500;font-size: 1.1rem;padding-top: 0.4rem;color: #a7abc3;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__section{padding: 1rem 0 1rem 0;color: #6c7293;font-weight: 400;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__section a{padding-right: 0.3rem;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content .kt-widget__stats{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding-bottom: 1.7rem;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content .kt-widget__stats .kt-widget__icon i{font-size: 2.7rem;color: #adb1c7;font-weight: 400;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content .kt-widget__stats .kt-widget__details{padding-left: 1rem;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content .kt-widget__stats .kt-widget__details .kt-widget__title{display: block;color: #6c7293;font-weight: 500;font-size: 0.95rem;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__content .kt-widget__stats .kt-widget__details .kt-widget__value{display: block;color: #464457;font-weight: 600;font-size: 1.2rem;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item{padding: 0.7rem 0;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding-bottom: 0.5rem;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact .kt-widget__label{color: #464457;font-weight: 600;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact .kt-widget__data{color: #a7abc3;font-weight: 400;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact a.kt-widget__data:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__body .kt-widget__item .kt-widget__contact:last-child{padding-bottom: 0;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__footer{margin-top: 2rem;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__footer .btn{font-size: 1rem;font-weight: 600;padding: 1.1rem 0;width: 100%;}
.kt-portlet__body.kt-portlet__body--fit-y .kt-widget{margin-top: -20px;}
@media (max-width: 768px){
.kt-widget.kt-widget--user-profile-2 .kt-widget__head{margin-top: -30px;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__img{max-width: 60px;}
.kt-widget.kt-widget--user-profile-2 .kt-widget__head .kt-widget__media .kt-widget__pic{max-width: 60px;max-height: 60px;font-size: 1.2rem;}
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__media{margin-top: 0.2rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__media img{width: 110px;border-radius: 8px;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic{margin-top: 0.2rem;width: 100%;max-width: 110px;height: 110px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;font-size: 1.5rem;border-radius: 8px;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--brand{background: rgba(93, 120, 255, 0.1);}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--light{background: rgba(255, 255, 255, 0.1);}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--dark{background: rgba(40, 42, 60, 0.1);}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--primary{background: rgba(88, 103, 221, 0.1);}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--success{background: rgba(10, 187, 135, 0.1);}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--info{background: rgba(85, 120, 235, 0.1);}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--warning{background: rgba(255, 184, 34, 0.1);}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic.kt-widget__pic--danger{background: rgba(253, 57, 122, 0.1);}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content{width: 100%;padding-left: 1.7rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__user{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__username{font-size: 1.3rem;color: #464457;font-weight: 500;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__username i{font-size: 1.2rem;color: #57c974;padding-left: 0.5rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__action .btn{font-weight: 600;margin-left: 0.5rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead{padding: 0.6rem 0 0.8rem 0;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead a{padding-right: 2rem;color: #a7abc3;font-weight: 500;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead a:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead a i{padding-right: 0.5rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row wrap;flex-flow: row wrap;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__desc{color: #6c7293;font-weight: 400;padding-right: 1rem;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;margin-bottom: 0.5rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;max-width: 500px;width: 100%;margin: 0.1rem 0;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress .kt-widget__text{padding-right: 1rem;color: #6c7293;font-weight: 500;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress .kt-widget__stats{padding-left: 1rem;color: #464457;font-weight: 600;font-size: 1.1rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 100%;border-top: 1px solid #ebedf2;margin-top: 2rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding: 2rem 1.5rem 0 0;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__icon i{font-size: 2.7rem;color: #adb1c7;font-weight: 400;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details{padding-left: 1rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-widget__title{display: block;color: #a7abc3;font-weight: 600;font-size: 0.95rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-widget__value{display: block;color: #464457;font-weight: 600;font-size: 1.2rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-widget__value span{color: #6c7293;font-weight: 400;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details a.kt-widget__value{font-size: 0.95rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details a.kt-widget__value:hover{color: #5d78ff;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__details .kt-section__content{padding-left: 0.7rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__form{margin-top: 1.5rem;}
@media (max-width: 768px){
.kt-widget.kt-widget--user-profile-3{padding-top: 0.5rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__media{margin-top: 0.5rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__media img{max-width: 60px;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic{width: 60px;height: 60px;margin-top: 0.5rem;font-size: 1.3rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content{padding-left: 1rem;margin-top: 0;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__username{padding-bottom: 0.6rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead{padding: 1.2rem 0;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead a:not(:first-child):not(:last-child){
padding: 0.5rem 1rem 0.5rem 0;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__desc{padding-bottom: 1rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress{width: 100%;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom{padding-top: 1rem;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item{padding: 1rem 1rem 0 0;}
.kt-widget.kt-widget--user-profile-3 .kt-widget__bottom .kt-widget__item .kt-widget__icon i{font-size: 2.5rem;}
}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head{margin-top: 1rem;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__img{max-width: 90px;max-height: 90px;border-radius: 50%;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;width: 90px;height: 90px;font-size: 1.5rem;border-radius: 50%;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--brand{background: rgba(93, 120, 255, 0.1);}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--light{background: rgba(255, 255, 255, 0.1);}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--dark{background: rgba(40, 42, 60, 0.1);}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--primary{background: rgba(88, 103, 221, 0.1);}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--success{background: rgba(10, 187, 135, 0.1);}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--info{background: rgba(85, 120, 235, 0.1);}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--warning{background: rgba(255, 184, 34, 0.1);}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic.kt-widget__pic--danger{background: rgba(253, 57, 122, 0.1);}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__username{text-align: center;display: block;padding: 0.8rem 0 0.6rem 0;font-size: 1.3rem;color: #464457;font-weight: 500;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__button{text-align: center;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__button .btn{font-weight: 600;padding: 0.3rem 0.8rem;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__action{text-align: center;margin-top: 2.3rem;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__action > .btn-label-warning:hover{color: #fff;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__action .btn:not(:first-child):not(:last-child){
margin: 0 1rem;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__body{margin-top: 2.5rem;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__body .kt-widget__item{display: block;text-align: center;color: #6c7293;font-weight: 500;font-size: 1.1rem;padding: 0.9rem 0;margin: 0.4rem 0;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__body .kt-widget__item:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;background: #edf0fc;cursor: pointer;border-radius: 4px;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__body .kt-widget__item.kt-widget__item--active{-webkit-transition: color 0.3s ease;transition: color 0.3s ease;background: #edf0fc;color: #5d78ff;border-radius: 4px;}
.kt-portlet__body.kt-portlet__body--fit-y .kt-widget.kt-widget--user-profile-4{margin-top: -20px;}
@media (max-width: 768px){
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__img{max-width: 80px;max-height: 80px;}
.kt-widget.kt-widget--user-profile-4 .kt-widget__head .kt-widget__media .kt-widget__pic{max-width: 80px;max-height: 80px;font-size: 1.2rem;}
}
.kt-widget.kt-widget--users .kt-widget__item{display: -webkit-box;display: -ms-flexbox;display: flex;margin: 1.3rem 0 2.3rem 0;}
.kt-widget.kt-widget--users .kt-widget__item .kt-userpic img{width: 100%;max-width: 43px;height: 43px;}
.kt-widget.kt-widget--users .kt-widget__item:last-child{margin-bottom: 0;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__info{padding-top: 0.1rem;-webkit-box-flex: 3;-ms-flex: 3;flex: 3;margin-left: 1rem;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__info .kt-widget__section{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__info .kt-widget__section .kt-widget__username{font-size: 1.1rem;color: #464457;font-weight: 500;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__info .kt-widget__section .kt-widget__username:hover{color: #5d78ff;-webkit-transition: color 0.3s ease;transition: color 0.3s ease;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__info .kt-widget__section .kt-badge{margin-left: 0.5rem;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__info .kt-widget__desc{display: block;color: #a7abc3;font-weight: 500;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__action{text-align: right;padding-top: 0.2rem;-webkit-box-flex: 1.1;-ms-flex: 1.1;flex: 1.1;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__action .kt-widget__date{display: block;color: #a7abc3;font-weight: 500;}
@media (max-width: 1024px){
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__info{-webkit-box-flex: 1.5;-ms-flex: 1.5;flex: 1.5;}
.kt-widget.kt-widget--users .kt-widget__item .kt-widget__action{-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
}
body{background: #f2f3f8;}
@media (min-width: 1025px){
.kt-header--fixed .kt-wrapper{padding-top: 65px;}
.kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled .kt-wrapper{padding-top: 119px;}
.kt-wrapper{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-aside--fixed .kt-wrapper{padding-left: 265px;}
.kt-aside--fixed.kt-aside--minimize .kt-wrapper{padding-left: 70px;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-subheader{margin: 0;}
.kt-header--fixed.kt-subheader--fixed .kt-subheader{position: fixed;height: 54px;top: 65px;left: 0;right: 0;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;z-index: 94;background-color: #fff;-webkit-box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);padding-top: 0;padding-bottom: 0;}
.kt-header--fixed.kt-header--minimize.kt-subheader--fixed .kt-subheader{top: 55px;-webkit-transition: top 0.3s ease;transition: top 0.3s ease;}
.kt-aside--enabled.kt-subheader--fixed .kt-subheader{left: 265px;}
.kt-subheader--fixed.kt-aside--minimize-hover .kt-subheader, .kt-subheader--fixed.kt-aside--minimize .kt-subheader{left: 70px;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
}
@media (max-width: 1024px){
.kt-header-mobile--fixed .kt-wrapper{padding-top: 50px;}
}
@media (min-width: 1025px){
.kt-header{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;height: 65px;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;position: relative;z-index: 2;}
.kt-header.kt-header--fixed{position: fixed;top: 0;right: 0;left: 0;z-index: 97;}
.kt-aside--enabled .kt-header.kt-header--fixed{left: 265px;}
.kt-header--fixed.kt-header--minimize .kt-header{height: 55px;-webkit-transition: height 0.3s ease;transition: height 0.3s ease;}
.kt-header--fixed.kt-aside--minimize .kt-header{left: 70px;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
}
@media (min-width: 1025px){
.kt-header-mobile{display: none;}
}
@media (max-width: 1024px){
.kt-header-mobile{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 0 15px;height: 50px;position: relative;z-index: 1;}
.kt-header-mobile--fixed .kt-header-mobile{position: fixed;top: 0;right: 0;left: 0;z-index: 97;}
.kt-header-mobile .kt-header-mobile__logo{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.kt-header-mobile .kt-header-mobile__toolbar{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler{display: inline-block;position: relative;overflow: hidden;margin: 0;padding: 0;font-size: 0;text-indent: -9999px;-webkit-appearance: none;-moz-appearance: none;appearance: none;-webkit-box-shadow: none;box-shadow: none;border-radius: none;border: none;cursor: pointer;background: none;outline: none !important;width: 24px;height: 24px;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span{display: block;position: absolute;top: 11px;height: 2px;min-height: 2px;width: 100%;border-radius: 2px;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::after{position: absolute;display: block;left: 0;width: 100%;height: 2px;min-height: 2px;content: "";border-radius: 2px;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::before{top: -8px;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::after{bottom: -8px;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left span:before{-webkit-transition: all 0.4s ease;transition: all 0.4s ease;left: auto;right: 0px;width: 50%;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left span:after{-webkit-transition: all 0.4s ease;transition: all 0.4s ease;left: auto;right: 0px;width: 75%;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left:hover span:after, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left:hover span:before{width: 100%;-webkit-transition: all 0.4s ease;transition: all 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left.kt-header-mobile__toggler--active span:before{-webkit-transition: all 0.4s ease;transition: all 0.4s ease;left: 0px;right: auto;width: 50%;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left.kt-header-mobile__toggler--active span:after{-webkit-transition: all 0.4s ease;transition: all 0.4s ease;left: 0px;right: auto;width: 75%;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--right span:before{left: 0px;right: auto;width: 50%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--right span:after{left: 0px;right: auto;width: 75%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--right:hover span:after, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--right:hover span:before{width: 100%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--right.kt-header-mobile__toggler--active span:before{left: auto;right: 0px;width: 50%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--right.kt-header-mobile__toggler--active span:after{left: auto;right: 0px;width: 75%;-webkit-transition: width 0.4s ease;transition: width 0.4s ease;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span{background: rgba(255, 255, 255, 0.2);}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::after{background: rgba(255, 255, 255, 0.2);}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span{background: #5d78ff;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span::after{background: #5d78ff;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span{background: #5d78ff;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span::after{background: #5d78ff;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler{margin: 0 0 0 1.5rem;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler{-webkit-appearance: none;-moz-appearance: none;appearance: none;-webkit-box-shadow: none;box-shadow: none;border-radius: none;border: none;cursor: pointer;background: none;outline: none !important;margin: 0;padding: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin: 0 0.5rem 0 1rem;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler i{-webkit-transition: all 0.3s;transition: all 0.3s;color: rgba(255, 255, 255, 0.2);font-size: 1.5rem;line-height: 0;}
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler.kt-header-mobile__topbar-toggler--active i, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler:hover i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #5d78ff;}
}
@media (min-width: 1025px){
.kt-header-menu{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;height: 100%;margin: 0 0 0 25px;}
.kt-header-menu .kt-menu__nav{list-style: none;margin: 0;padding: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link{display: -webkit-box;display: -ms-flexbox;display: flex;text-decoration: none;position: relative;vertical-align: middle;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;outline: none !important;text-decoration: none;cursor: pointer;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__link-text{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__link-badge{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;line-height: 0;white-space: nowrap;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__link-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__hor-arrow{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link .kt-menu__ver-arrow{display: none;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link:hover, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link:active, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__link:focus{text-decoration: none;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link > .kt-menu__link-text{width: auto;}
.kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--icon-only > .kt-menu__link{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--icon-only > .kt-menu__link > .kt-menu__link-icon{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu{-webkit-transform: translateZ(0);transform: translateZ(0);-webkit-transform-style: preserve-3d;display: none;z-index: 98;position: absolute;top: 100%;border-radius: 4px;padding: 20px 0px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__scroll{position: relative;overflow: hidden;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav{list-style: none !important;padding: 0;margin: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu{width: 275px;margin: 0 auto;left: auto;right: auto;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--full{margin: 0 auto;width: auto;left: 20px;right: 20px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed{left: auto;right: auto;width: auto;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--right{right: 0;left: auto;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--right.kt-menu__submenu--pull{margin-right: -40px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--left{right: auto;left: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--left.kt-menu__submenu--pull{margin-left: -40px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--center{margin: 0 auto;left: 0;right: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--submenu{position: relative;padding: 0;margin: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--submenu > .kt-menu__submenu{top: 0;display: none;margin-top: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--submenu > .kt-menu__submenu.kt-menu__submenu--left{right: 100%;left: auto;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--submenu > .kt-menu__submenu.kt-menu__submenu--right{left: 100%;right: auto;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__content{display: -webkit-box;display: -ms-flexbox;display: flex;list-style: none;padding: 0;margin: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__content > .kt-menu__item{padding: 0;margin: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__content > .kt-menu__item:last-child{border-right: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0;margin: 0;text-decoration: none;position: relative;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__link-text{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__link-badge{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;white-space: nowrap;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__link-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;font-size: 18px;padding: 0 10px 0 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__hor-arrow{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0 0 0 10px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__heading .kt-menu__ver-arrow{display: none;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav{list-style: none;margin: 0;padding: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;margin: 0;padding: 10px 20px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;text-decoration: none;position: relative;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link:hover, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link:active, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link:focus, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link:hover, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link:active, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link:focus{outline: none;text-decoration: none;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__link-text, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-text{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;font-weight: 400;font-size: 1rem;text-transform: initial;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__link-badge, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-badge{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;white-space: nowrap;padding: 0px 0px 0px 5px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__link-icon, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 0;-ms-flex: 0 0 33px;flex: 0 0 33px;padding: 0;font-size: 1.4rem;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__link-bullet, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-bullet{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;line-height: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__hor-arrow, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__hor-arrow{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0px 0px 0px 10px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner > .kt-menu__item .kt-menu__link .kt-menu__ver-arrow, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__ver-arrow{display: none;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__inner{padding: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__submenu{margin-left: 1px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__submenu.kt-menu__submenu--left{margin-right: 1px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--submenu.kt-menu__item--tabs > .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--submenu > .kt-menu__submenu{top: 100%;}
.kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--submenu.kt-menu__item--tabs > .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--submenu > .kt-menu__submenu.kt-menu__submenu--left{right: 100%;left: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--submenu.kt-menu__item--tabs > .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--submenu > .kt-menu__submenu.kt-menu__submenu--right{left: 100%;right: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0px 0.25rem;padding: 0px 0.25rem;}
.kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--rel{position: relative;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;cursor: pointer;padding: 0.25rem 0.5rem;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link:hover{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon{font-size: 1.4rem;width: 30px;padding: 0;line-height: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg{height: 23px;width: 23px;margin-left: -2px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{width: 20px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{width: 5px;height: 1px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1rem;text-transform: initial;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow{font-size: 0.8rem;width: 20px;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__item-here{display: none;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu{padding: 0px;border-radius: 4px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item{padding: 0px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;cursor: pointer;padding: 11px 30px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link:hover{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon{font-size: 1.4rem;width: 33px;padding: 0;line-height: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg{height: 23px;width: 23px;margin-left: -2px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{width: 20px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{width: 9px;height: 1px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1rem;text-transform: initial;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow{font-size: 0.8rem;width: 20px;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;padding: 0px 0px 0px 10px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__item-here{display: none;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content > .kt-menu__item{-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-ms-flex-preferred-size: 0;flex-basis: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content > .kt-menu__item:last-child{border-right: 0 !important;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__heading{padding: 30px 30px 10px 30px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__heading > .kt-menu__link-text{font-weight: 500;font-size: 1.07rem;text-transform: initial;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__heading > .kt-menu__link-icon{font-size: 1.35rem;-webkit-box-flex: 0;-ms-flex: 0 0 30px;flex: 0 0 30px;padding: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__heading .kt-menu__link-badge{padding: 0px 0px 0px 5px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner{padding: 0 0 20px 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item{padding: 0px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;cursor: pointer;padding: 11px 30px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link:hover{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__link-icon{font-size: 1.4rem;width: 33px;padding: 0;line-height: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg{height: 23px;width: 23px;margin-left: -2px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{width: 20px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{width: 9px;height: 1px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1rem;text-transform: initial;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow{font-size: 0.8rem;width: 20px;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;padding: 0px 0px 0px 10px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav .kt-menu__content .kt-menu__inner > .kt-menu__item > .kt-menu__link .kt-menu__item-here{display: none;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--classic{padding: 20px 0px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--inline{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--inline > .kt-menu__subnav > .kt-menu__item{display: -webkit-box;display: -ms-flexbox;display: flex;padding: 5px 0;}
.kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--resize{display: none;}
.kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--active-tab .kt-menu__submenu, .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--hover .kt-menu__submenu{display: block;-webkit-animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-down 0.3s ease-out 1;animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-down 0.3s ease-out 1;}
}
@media screen\0  and (min-width: 1025px){
.kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--active-tab .kt-menu__submenu, .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--hover .kt-menu__submenu{-webkit-animation: none;animation: none;}
}
@media (min-width: 1025px){
.kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--active-tab .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu, .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--hover .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu{display: block;-webkit-animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-up 0.3s ease-out 1;animation: kt-header-menu-submenu-fade-in 0.3s ease 1, kt-header-menu-submenu-move-up 0.3s ease-out 1;}
}
@media screen\0  and (min-width: 1025px){
.kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--active-tab .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu, .kt-header-menu .kt-menu__nav .kt-menu__item.kt-menu__item--hover .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu{-webkit-animation: none;animation: none;}
}
@media (min-width: 1025px) and (max-width: 1399px){
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--full, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--auto, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--auto.kt-menu__submenu--mimimal-desktop-wide, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-xl, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-xxl{margin: 0 auto;width: auto;left: 20px;right: 20px;}
}
@media (min-width: 0){
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-xs{width: -60px;}
}
@media (min-width: 576px){
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-sm{width: 516px;}
}
@media (min-width: 768px){
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-md{width: 708px;}
}
@media (min-width: 1024px){
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-lg{width: 964px;}
}
@media (min-width: 1399px){
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu.kt-menu__submenu--fixed-xl{width: 1339px;}
}
@-webkit-keyframes kt-header-menu-submenu-fade-out{
from{opacity: 1;}
to{opacity: 0;}
}
@keyframes kt-header-menu-submenu-fade-out{
from{opacity: 1;}
to{opacity: 0;}
}
@-webkit-keyframes kt-header-menu-submenu-fade-in{
from{opacity: 0;}
to{opacity: 1;}
}
@keyframes kt-header-menu-submenu-fade-in{
from{opacity: 0;}
to{opacity: 1;}
}
@-webkit-keyframes kt-header-menu-submenu-move-up{
from{margin-top: -10px;}
to{margin-top: 0;}
}
@keyframes kt-header-menu-submenu-move-up{
from{margin-top: -10px;}
to{margin-top: 0;}
}
@-webkit-keyframes kt-header-menu-submenu-move-down{
from{margin-top: 10px;}
to{margin-top: 0;}
}
@keyframes kt-header-menu-submenu-move-down{
from{margin-top: 10px;}
to{margin-top: 0;}
}
@media (max-width: 1024px){
.kt-header-menu-mobile .kt-menu__nav{margin: 0;list-style: none;padding: 30px 0 30px 0;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__scroll{position: relative;overflow: hidden;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__subnav{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding: 0;margin: 0;list-style: none !important;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__inner, .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu{display: none;float: none;margin: 0;padding: 0;width: auto !important;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__inner .kt-menu__content, .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu .kt-menu__content{padding: 0;margin: 0;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item, .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu .kt-menu__content > .kt-menu__item{padding: 0;margin: 0;list-style: none;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner, .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner > li > .kt-menu__link, .kt-header-menu-mobile .kt-menu__nav .kt-menu__submenu .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner > li > .kt-menu__link{margin: 0;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;float: none;padding: 0;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;margin: 0;padding: 0;text-decoration: none;position: relative;outline: none;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading:hover, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link:hover{text-decoration: none;cursor: pointer;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-text{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding: 0;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-badge, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-badge{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;white-space: nowrap;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;line-height: 0;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;line-height: 0;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;line-height: 0;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{-webkit-transform: translate3d(0, 0, 0);}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__hor-arrow, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow{display: none;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__heading > .kt-menu__ver-arrow:before, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__link > .kt-menu__ver-arrow:before{-webkit-transform: rotateZ(90deg)/*rtl:ignore*/;transform: rotateZ(90deg)/*rtl:ignore*/;}
[direction="rtl"] .kt-header-menu-mobile .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__heading > .kt-menu__ver-arrow:before, [direction="rtl"] .kt-header-menu-mobile .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__link > .kt-menu__ver-arrow:before{-webkit-transform: rotateZ(-90deg)/*rtl:ignore*/;transform: rotateZ(-90deg)/*rtl:ignore*/;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__submenu, .kt-header-menu-mobile .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__inner{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item .kt-menu__submenu .kt-menu__item--parent{display: none;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__section{display: -webkit-box;display: -ms-flexbox;display: flex;padding: 0 27px;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__section.kt-menu__section--first{margin-top: 0 !important;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__section .kt-menu__section-text{display: -webkit-box;display: -ms-flexbox;display: flex;margin: 0;padding: 0;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__section .kt-menu__section-icon{display: none;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__separator{height: 0;overflow: hidden;}
.kt-header-menu-mobile .kt-menu__nav .kt-menu__separator.kt-menu__separator--marginles{margin: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item{position: relative;margin: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link{cursor: pointer;height: 44px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1.02rem;text-transform: initial;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon{-webkit-box-flex: 0;-ms-flex: 0 0 35px;flex: 0 0 35px;font-size: 1.4rem;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg{margin-left: -2px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{vertical-align: middle;text-align: left;-webkit-box-flex: 0;-ms-flex: 0 0 15px;flex: 0 0 15px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{width: 5px;height: 1px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-badge, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;text-align: right;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{-webkit-box-flex: 0;-ms-flex: 0 0 20px;flex: 0 0 20px;font-size: 0.7rem;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{display: inline-block;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
[direction="rtl"] .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, [direction="rtl"] .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{content: "" !important;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__item-here, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__item-here{display: none;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow:before{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav{margin: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item{margin: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link{cursor: pointer;height: 40px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1rem;text-transform: initial;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon{-webkit-box-flex: 0;-ms-flex: 0 0 35px;flex: 0 0 35px;font-size: 1.35rem;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg{margin-left: -2px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{vertical-align: middle;text-align: left;-webkit-box-flex: 0;-ms-flex: 0 0 20px;flex: 0 0 20px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{width: 5px;height: 1px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-badge, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{-webkit-box-flex: 0;-ms-flex: 0 0 20px;flex: 0 0 20px;font-size: 0.7rem;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{display: inline-block;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
[direction="rtl"] .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, [direction="rtl"] .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{content: "" !important;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__item-here, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__item-here{display: none;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow:before{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section{margin: 20px 0 0 0;height: 40px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-text{font-size: 0.8rem;font-weight: 300;text-transform: uppercase;letter-spacing: 0.3px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator{margin: 15px 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator .kt-menu__separator--marginles{margin: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__section{margin: 20px 0 0 0;height: 40px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__section .kt-menu__section-text{font-size: 0.83rem;font-weight: 500;text-transform: uppercase;letter-spacing: 0.3px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__separator{margin: 15px 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__separator .kt-menu__separator--marginles{margin: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link{padding: 9px 30px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav .kt-menu__content .kt-menu__heading{padding: 0 30px;padding-left: 45px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav .kt-menu__content .kt-menu__inner{padding: 0;margin: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav .kt-menu__content .kt-menu__inner .kt-menu__link{padding: 0 30px;padding-left: 60px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{padding: 0 30px;padding-left: 45px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu{padding: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav{padding: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{padding: 0 30px;padding-left: 60px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu{padding: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{padding: 0 30px;padding-left: 75px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section{padding-left: 45px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu{padding: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section{padding-left: 60px;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu{padding: 0;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section{padding-left: 75px;}
}
@media (max-width: 1024px){
.kt-header-menu-mobile{background-color: #ffffff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text{color: #5e6383;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon{color: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{color: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open{-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link{background-color: #f8f8fb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-text{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here{-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link{background-color: #f8f8fb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-text{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__ver-arrow{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active{-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link{background-color: #f8f8fb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__ver-arrow{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover{-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link{background-color: #f8f8fb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-text{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-icon{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__ver-arrow{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__arrow{color: #ffffff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__section .kt-menu__section-text{color: #a4a7bd;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__section .kt-menu__section-icon{color: #aaacc2;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__separator{border-bottom: 1px solid rgba(0, 0, 0, 0.07);}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-text{color: #6b6f8d;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon{color: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{color: #a8aabb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open{-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link{background-color: #f8f8fb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-text{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here{-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__link{background-color: #f8f8fb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--here > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active{-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link{background-color: #f8f8fb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__ver-arrow{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover{-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link{background-color: #f8f8fb;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-text, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-text{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-icon, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-icon{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-icon svg g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-icon svg g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;fill: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-icon svg:hover g [fill], .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-icon svg:hover g [fill]{-webkit-transition: fill 0.3s ease;transition: fill 0.3s ease;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{background-color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__heading .kt-menu__ver-arrow, .kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__ver-arrow{color: #5d78ff;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-text{color: #a4a7bd;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-icon{color: #aaacc2;}
.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator{border-bottom: 1px solid rgba(0, 0, 0, 0.07);}
}
.kt-header-menu-wrapper-close{display: none;}
@media (max-width: 1024px){
.kt-header-menu-wrapper{z-index: 1001;position: fixed;-webkit-overflow-scrolling: touch;top: 0;bottom: 0;overflow-y: auto;-webkit-transform: translate3d(0, 0, 0);backface-visibility: hidden;-webkit-backface-visibility: hidden;width: 275px !important;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: -295px;}
.kt-header-menu-wrapper.kt-header-menu-wrapper--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 0;}
}
@media screen\0  and (max-width: 1024px){
.kt-header-menu-wrapper{-webkit-transition: none !important;transition: none !important;}
}
@media (max-width: 1024px){
.kt-header-menu-wrapper--right .kt-header-menu-wrapper{right: -295px;left: auto;}
.kt-header-menu-wrapper--right .kt-header-menu-wrapper.kt-header-menu-wrapper--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;right: 0;left: auto;}
.kt-header-menu-wrapper-close{width: 25px;height: 25px;top: 1px;z-index: 1002;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;position: fixed;border: 0;-webkit-box-shadow: none;box-shadow: none;border-radius: 3px;cursor: pointer;outline: none !important;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;left: -25px;}
}
@media screen\0  and (max-width: 1024px){
.kt-header-menu-wrapper-close{-webkit-transition: none !important;transition: none !important;}
}
@media (max-width: 1024px){
.kt-header-menu-wrapper-close > i{line-height: 0;font-size: 1.4rem;}
.kt-header-menu-wrapper-close:hover{text-decoration: none;}
.kt-header-menu-wrapper--right .kt-header-menu-wrapper-close{left: auto;right: -25px;}
.kt-header-menu-wrapper--on .kt-header-menu-wrapper-close{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 249px;}
.kt-header-menu-wrapper--on.kt-header-menu-wrapper--right .kt-header-menu-wrapper-close{left: auto;right: 249px;}
.kt-header-menu-wrapper-overlay{position: fixed;top: 0;left: 0;bottom: 0;right: 0;overflow: hidden;z-index: 1000;background: rgba(0, 0, 0, 0.1);-webkit-animation: kt-animate-fade-in .3s linear 1;animation: kt-animate-fade-in .3s linear 1;}
.kt-header-menu-wrapper{background: #ffffff;-webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);}
.kt-header-menu-wrapper-overlay{background: rgba(0, 0, 0, 0.1);}
.kt-header-menu-wrapper-close{background-color: #e8e8f2;}
.kt-header-menu-wrapper-close > i{color: #968ad4;}
.kt-header-menu-wrapper-close:hover{background-color: #5d78ff;}
.kt-header-menu-wrapper-close:hover > i{color: #ffffff;}
}
@media (max-width: 325px){
.kt-header-menu-wrapper{width: 90% !important;}
}
@media (min-width: 1025px){
body:not(.kt-aside--enabled) .kt-header-menu-wrapper{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
body:not(.kt-aside--enabled) .kt-header-menu-wrapper .kt-header-logo{padding: 0 25px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-right: 25px;}
.kt-header-menu .kt-menu__nav > .kt-menu__item:first-child{padding-left: 0;}
.kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu{border-top-left-radius: 0;border-top-right-radius: 0;}
.kt-header-menu.kt-header-menu--layout-default .kt-menu__nav > .kt-menu__item > .kt-menu__link{padding: 0.65rem 1.1rem;}
.kt-header-menu.kt-header-menu--layout-tab .kt-menu__nav > .kt-menu__item{margin: 0 1rem;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-header-menu.kt-header-menu--layout-tab .kt-menu__nav > .kt-menu__item > .kt-menu__link{-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;background-color: transparent !important;border-radius: 0 !important;padding: 0 !important;border-bottom: 1px solid transparent;margin-bottom: -1px;}
.kt-header-menu.kt-header-menu--layout-tab .kt-menu__nav > .kt-menu__item:first-child{margin-left: 0 !important;}
.kt-header-menu.kt-header-menu--layout-tab .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link{background-color: transparent !important;border-bottom: 1px solid #5d78ff !important;}
}
@media (max-width: 1024px){
.kt-header-menu-wrapper .kt-header-logo{display: none;}
}
.kt-header__topbar{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;padding: 0;padding-right: 15px;}
.kt-header__topbar .kt-header__topbar-item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;margin: 0;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper{cursor: pointer;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper .kt-badge.kt-badge--notify{position: absolute;left: 50%;margin-left: -2px;top: 14px;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-ms-flex-item-align: center;align-self: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;height: 44px;width: 44px;cursor: pointer;-webkit-transition: all 0.3s;transition: all 0.3s;border-radius: 4px;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i{-webkit-transition: all 0.3s;transition: all 0.3s;font-size: 1.4rem;color: #ffffff;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon svg{height: 24px;width: 24px;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user{padding: 0 8px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-ms-flex-item-align: center;align-self: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;height: 44px;cursor: pointer;border-radius: 4px;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-welcome{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-item-align: center;align-self: center;padding: 0 0.55rem 0 0;font-weight: 500;font-size: 0.9rem;color: #636177;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-username{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-item-align: center;align-self: center;padding: 0 0.55rem 0 0;font-weight: 500;font-size: 1rem;color: #fff;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user img{-ms-flex-item-align: center;align-self: center;max-height: 34px;border-radius: 4px;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-badge.kt-badge--username{height: 34px;width: 34px;border-radius: 4px;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--langs .kt-header__topbar-icon img{border-radius: 4px;width: 17px;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--langs .dropdown-menu .kt-nav__link-icon{padding-right: 10px;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--langs .dropdown-menu .kt-nav__link-icon img{border-radius: 4px;width: 18px;}
.kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon, .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon{-webkit-transition: all 0.3s;transition: all 0.3s;background-color: rgba(33, 32, 41, 0.7);}
.kt-header__topbar .kt-header__topbar-item:hover .kt-header__topbar-icon i, .kt-header__topbar .kt-header__topbar-item.show .kt-header__topbar-icon i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #fff;}
.kt-header__topbar .kt-header__topbar-item:hover.kt-header__topbar-item--user .kt-header__topbar-user, .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--user .kt-header__topbar-user{-webkit-transition: all 0.3s;transition: all 0.3s;}
@media (max-width: 1024px){
.kt-header__topbar{padding: 0 15px;background-color: #2b2a35;-webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);-webkit-transition: all 0.3s ease;transition: all 0.3s ease;margin-top: -50px;height: 50px;position: absolute;left: 0;right: 0;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.kt-header-mobile--fixed .kt-header__topbar{position: fixed;z-index: 96;}
.kt-header__topbar--mobile-on .kt-header__topbar{margin-top: 0;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-header__topbar .kt-header__topbar-item{margin: 0 2px;padding: 0;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper .kt-badge.kt-badge--notify{top: 6px;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon{height: 34px;width: 34px;}
.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i{font-size: 1.3rem;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user{margin-left: 2px;margin-right: 0;padding: 0;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user{padding: 0 2px;height: 34px;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-welcome{padding: 0 0.35rem 0 0;font-size: 0.9rem;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-username{padding: 0 0.55rem 0 0;font-size: 0.9rem;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user img{max-height: 30px;}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-badge.kt-badge--username{height: 30px;width: 30px;}
}
.kt-aside__brand{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;padding: 0 25px;height: 65px;}
.kt-aside__brand .kt-aside__brand-logo{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: flex-begin;-ms-flex-pack: flex-begin;justify-content: flex-begin;}
.kt-aside--minimize .kt-aside__brand .kt-aside__brand-logo img{display: none;}
.kt-aside__brand .kt-aside__brand-tools{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler{display: inline-block;padding: 0;border: 0;background: none;outline: none !important;-webkit-box-shadow: none;box-shadow: none;}
.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler span{display: inline-block;}
.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler span:nth-child(2){
display: none;}
.kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler svg{height: 26px;width: 26px;margin-right: -2px;}
@media (min-width: 1025px){
.kt-aside__brand{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-header--fixed.kt-aside--static .kt-aside__brand{position: fixed;top: 0;left: 0;width: 265px;z-index: 97;}
.kt-header--fixed.kt-aside--minimize .kt-aside__brand{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-header--fixed.kt-aside--minimize .kt-aside__brand .kt-aside__brand-tools{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-header--fixed.kt-aside--minimize .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler span:nth-child(1){
display: none;}
.kt-header--fixed.kt-aside--minimize .kt-aside__brand .kt-aside__brand-tools .kt-aside__brand-aside-toggler span:nth-child(2){
display: inline-block;}
.kt-header--fixed.kt-aside--static.kt-aside--minimize .kt-aside__brand{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;width: 70px;}
}
@media (max-width: 1024px){
.kt-aside__brand{display: none;}
}
.kt-aside{width: 265px;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-aside .kt-aside-menu{margin: 15px 0;}
.kt-aside .kt-aside-menu .ps__rail-y{right: 3px;}
.kt-aside .kt-aside__footer .kt-aside__footer-nav{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;padding: 7px 30px;}
.kt-aside .kt-aside__footer .kt-aside__footer-item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-aside .kt-aside__footer .btn{height: 45px;width: 45px;background-color: #f0f0f6;border-radius: 50%;}
.kt-aside .kt-aside__footer .btn i{-webkit-transition: all 0.3s;transition: all 0.3s;font-size: 1.4rem;color: #5d78ff;}
.kt-aside .kt-aside__footer .btn:hover{background-color: #5d78ff;}
.kt-aside .kt-aside__footer .btn:hover i{-webkit-transition: all 0.3s;transition: all 0.3s;color: #ffffff;}
.kt-aside--minimize .kt-aside .kt-aside__footer .dropdown.dropdown-inline, .kt-aside--minimize .kt-aside .kt-aside__footer .btn{display: none;}
.kt-aside--minimize .kt-aside .kt-aside__footer .dropdown.dropdown-inline:last-child, .kt-aside--minimize .kt-aside .kt-aside__footer .btn:last-child{display: inline-block;opacity: 0;}
@media (min-width: 1025px){
.kt-aside--fixed .kt-aside{position: fixed;top: 0;bottom: 0;left: 0;z-index: 98;}
.kt-aside--static .kt-aside{position: relative;z-index: 1;}
.kt-aside--minimize .kt-aside{width: 70px;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-aside--minimizing .kt-aside{overflow: hidden;}
.kt-aside--minimizing .kt-aside .kt-aside-menu .kt-menu__nav{width: 265px;overflow: hidden;}
.kt-aside .ps > .ps__rail-y{width: 4px;}
.kt-aside .ps > .ps__rail-y:hover, .kt-aside .ps > .ps__rail-y:focus{width: 4px;}
.kt-aside .ps > .ps__rail-y > .ps__thumb-y{width: 4px;border-radius: 4px;}
.kt-aside .ps > .ps__rail-y > .ps__thumb-y:hover, .kt-aside .ps > .ps__rail-y > .ps__thumb-y:focus{width: 4px;}
.kt-aside--fixed.kt-aside--minimize-hover .kt-aside{width: 265px;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-aside--fixed.kt-aside--minimize-hover .kt-header{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;left: 70px;}
.kt-aside--fixed.kt-aside--minimize-hover .kt-wrapper{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;padding-left: 70px;}
}
.kt-aside-menu .kt-menu__nav{margin: 0;list-style: none;padding: 15px 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__scroll{position: relative;overflow: hidden;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;}
.kt-aside-menu .kt-menu__nav .kt-menu__subnav{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding: 0;margin: 0;list-style: none !important;}
.kt-aside-menu .kt-menu__nav .kt-menu__inner, .kt-aside-menu .kt-menu__nav .kt-menu__submenu{display: none;float: none;margin: 0;padding: 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__inner .kt-menu__content, .kt-aside-menu .kt-menu__nav .kt-menu__submenu .kt-menu__content{padding: 0;margin: 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item, .kt-aside-menu .kt-menu__nav .kt-menu__submenu .kt-menu__content > .kt-menu__item{padding: 0;margin: 0;list-style: none;}
.kt-aside-menu .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner, .kt-aside-menu .kt-menu__nav .kt-menu__submenu .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-aside-menu .kt-menu__nav .kt-menu__inner .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner > li > .kt-menu__link, .kt-aside-menu .kt-menu__nav .kt-menu__submenu .kt-menu__content > .kt-menu__item.kt-menu--no-heading > .kt-menu__inner > li > .kt-menu__link{margin: 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__item{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;float: none;padding: 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;margin: 0;padding: 0;text-decoration: none;position: relative;outline: none;}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading:hover, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link:hover{text-decoration: none;cursor: pointer;}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-text{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;padding: 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-badge, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-badge{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;white-space: nowrap;}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;line-height: 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;line-height: 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;line-height: 0;}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{-webkit-transform: translate3d(0, 0, 0);}
.kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__heading .kt-menu__hor-arrow, .kt-aside-menu .kt-menu__nav .kt-menu__item > .kt-menu__link .kt-menu__hor-arrow{display: none;}
.kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__heading > .kt-menu__ver-arrow:before, .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__link > .kt-menu__ver-arrow:before{-webkit-transform: rotateZ(90deg)/*rtl:ignore*/;transform: rotateZ(90deg)/*rtl:ignore*/;}
[direction="rtl"] .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__heading > .kt-menu__ver-arrow:before, [direction="rtl"] .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__link > .kt-menu__ver-arrow:before{-webkit-transform: rotateZ(-90deg)/*rtl:ignore*/;transform: rotateZ(-90deg)/*rtl:ignore*/;}
.kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__submenu, .kt-aside-menu .kt-menu__nav .kt-menu__item.kt-menu__item--open > .kt-menu__inner{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.kt-aside-menu .kt-menu__nav .kt-menu__item .kt-menu__submenu .kt-menu__item--parent{display: none;}
.kt-aside-menu .kt-menu__nav .kt-menu__section{display: -webkit-box;display: -ms-flexbox;display: flex;padding: 0 25px;}
.kt-aside-menu .kt-menu__nav .kt-menu__section.kt-menu__section--first{margin-top: 0 !important;}
.kt-aside-menu .kt-menu__nav .kt-menu__section .kt-menu__section-text{display: -webkit-box;display: -ms-flexbox;display: flex;margin: 0;padding: 0;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kt-aside-menu .kt-menu__nav .kt-menu__section .kt-menu__section-icon{display: none;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-aside-menu .kt-menu__nav .kt-menu__separator{height: 0;overflow: hidden;}
.kt-aside-menu .kt-menu__nav .kt-menu__separator.kt-menu__separator--marginles{margin: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item{position: relative;margin: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link{cursor: pointer;height: 44px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1rem;text-transform: initial;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon{-webkit-box-flex: 0;-ms-flex: 0 0 35px;flex: 0 0 35px;font-size: 1.3rem;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg{height: 23px;width: 23px;margin-left: -2px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{vertical-align: middle;text-align: left;-webkit-box-flex: 0;-ms-flex: 0 0 15px;flex: 0 0 15px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet > span, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{width: 5px;height: 1px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-badge, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{-webkit-box-flex: 0;-ms-flex: 0 0 20px;flex: 0 0 20px;font-size: 0.7rem;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{display: inline-block;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
[direction="rtl"] .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, [direction="rtl"] .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{content: "" !important;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__item-here, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__item-here{display: none;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow:before{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav{margin: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item{margin: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link{cursor: pointer;height: 40px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1rem;text-transform: initial;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon{-webkit-box-flex: 0;-ms-flex: 0 0 35px;flex: 0 0 35px;font-size: 1.3rem;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg{height: 24px;width: 24px;margin-left: -2px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{vertical-align: middle;text-align: left;-webkit-box-flex: 0;-ms-flex: 0 0 15px;flex: 0 0 15px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet > span, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{width: 5px;height: 1px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__link-badge, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{-webkit-box-flex: 0;-ms-flex: 0 0 20px;flex: 0 0 20px;font-size: 0.7rem;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{display: inline-block;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
[direction="rtl"] .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, [direction="rtl"] .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{content: "" !important;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__heading .kt-menu__item-here, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item > .kt-menu__link .kt-menu__item-here{display: none;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow:before{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section{margin: 20px 0 0 0;height: 40px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__section .kt-menu__section-text{font-size: 0.8rem;font-weight: 300;text-transform: uppercase;letter-spacing: 0.3px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator{margin: 15px 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__separator .kt-menu__separator--marginles{margin: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__section{margin: 20px 0 0 0;height: 40px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-text{font-size: 0.9rem;font-weight: 500;text-transform: uppercase;letter-spacing: 0.3px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__separator{margin: 15px 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__separator .kt-menu__separator--marginles{margin: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link{padding: 9px 25px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav .kt-menu__content .kt-menu__heading{padding: 0 25px;padding-left: 40px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav .kt-menu__content .kt-menu__inner{padding: 0;margin: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav .kt-menu__content .kt-menu__inner .kt-menu__link{padding: 0 25px;padding-left: 55px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{padding: 0 25px;padding-left: 40px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu{padding: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav{padding: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{padding: 0 25px;padding-left: 55px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu{padding: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{padding: 0 25px;padding-left: 70px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section{padding-left: 40px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu{padding: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section{padding-left: 55px;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu{padding: 0;}
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section{padding-left: 70px;}
@media (min-width: 1025px){
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]) > .kt-menu__link > .kt-menu__ver-arrow:before, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]) > .kt-menu__link > .kt-menu__ver-arrow:before{-webkit-transform: translateZ(0);transform: translateZ(0);-webkit-transform-style: preserve-3d;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]) > .kt-menu__submenu, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]) > .kt-menu__submenu{display: none !important;-webkit-transform: translateZ(0);transform: translateZ(0);-webkit-transform-style: preserve-3d;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover{position: relative;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu{top: -10px;position: absolute;z-index: 98;display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;width: 245px;margin-left: 245px;border-radius: 4px;-webkit-animation: kt-aside-menu-submenu-fade-in 0.3s ease 1, kt-aside-menu-submenu-move-up 0.3s ease-out 1;animation: kt-aside-menu-submenu-fade-in 0.3s ease 1, kt-aside-menu-submenu-move-up 0.3s ease-out 1;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu.kt-menu__submenu--up, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu.kt-menu__submenu--up{bottom: 0;top: auto;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu.kt-menu__submenu--up, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu.kt-menu__submenu--up{top: auto;bottom: -10px;-webkit-animation: kt-aside-menu-submenu-fade-in 0.3s ease 1, kt-aside-menu-submenu-move-down 0.3s ease-out 1;animation: kt-aside-menu-submenu-fade-in 0.3s ease 1, kt-aside-menu-submenu-move-down 0.3s ease-out 1;}
}
@media screen\0  and (min-width: 1025px){
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu.kt-menu__submenu--up, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu.kt-menu__submenu--up{-webkit-animation: none;animation: none;}
}
@media (min-width: 1025px){
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav{padding: 20px 0;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item{margin: 0;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link{-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;padding: 7px 30px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-title, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-title{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu_link-text, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu_link-text{-webkit-transform: translateZ(0);transform: translateZ(0);-webkit-transform-style: preserve-3d;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-icon, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-icon{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-bullet, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-bullet{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-badge, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__link-badge{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__ver-arrow, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__link .kt-menu__ver-arrow{display: -webkit-box;display: -ms-flexbox;display: flex;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav{padding: 20px 0;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{padding: 7px 30px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav{padding: 20px 0;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{padding: 7px 30px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__section, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item .kt-menu__submenu .kt-menu__subnav > .kt-menu__section{padding: 0 25px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link{cursor: pointer;height: 40px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-text, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1rem;text-transform: initial;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon{-webkit-box-flex: 0;-ms-flex: 0 0 35px;flex: 0 0 35px;font-size: 1.35rem;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-icon svg, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon svg{margin-left: -2px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{vertical-align: middle;text-align: left;-webkit-box-flex: 0;-ms-flex: 0 0 15px;flex: 0 0 15px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet > span, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet > span, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet > span{vertical-align: middle;display: inline-block;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot > span, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{width: 4px;height: 4px;border-radius: 100%;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--line > span, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--line > span{width: 5px;height: 1px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-badge, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__link-badge, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;text-align: right;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{-webkit-box-flex: 0;-ms-flex: 0 0 20px;flex: 0 0 20px;font-size: 0.7rem;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{display: inline-block;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
[direction="rtl"] .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, [direction="rtl"] .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before, [direction="rtl"] .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__ver-arrow:before, [direction="rtl"] .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow:before{content: "" !important;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__item-here, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__item-here, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__heading .kt-menu__item-here, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item > .kt-menu__link .kt-menu__item-here{display: none;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow:before, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--open > .kt-menu__heading .kt-menu__ver-arrow:before, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__ver-arrow:before{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section{margin: 20px 0 0 0;height: 40px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section .kt-menu__section-text, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__section .kt-menu__section-text{font-size: 0.8rem;font-weight: 300;text-transform: uppercase;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__separator, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__separator{margin: 15px 0;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__separator .kt-menu__separator--marginles, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__separator .kt-menu__separator--marginles{margin: 0;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav > .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu{margin-left: 265px;top: -5px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu.kt-menu__submenu--up, .kt-aside-menu.kt-aside-menu--dropdown .kt-menu__nav > .kt-menu__item:not([data-ktmenu-submenu-mode=accordion]).kt-menu__item--hover > .kt-menu__submenu.kt-menu__submenu--up{bottom: -10px;}
}
@-webkit-keyframes kt-aside-menu-submenu-fade-out{
from{opacity: 1;}
to{opacity: 0;}
}
@keyframes kt-aside-menu-submenu-fade-out{
from{opacity: 1;}
to{opacity: 0;}
}
@-webkit-keyframes kt-aside-menu-submenu-fade-in{
from{opacity: 0;}
to{opacity: 1;}
}
@keyframes kt-aside-menu-submenu-fade-in{
from{opacity: 0;}
to{opacity: 1;}
}
@-webkit-keyframes kt-aside-menu-submenu-move-up{
from{margin-top: 10px;}
to{margin-top: 0;}
}
@keyframes kt-aside-menu-submenu-move-up{
from{margin-top: 10px;}
to{margin-top: 0;}
}
@-webkit-keyframes kt-aside-menu-submenu-move-down{
from{margin-bottom: 10px;}
to{margin-bottom: 0;}
}
@keyframes kt-aside-menu-submenu-move-down{
from{margin-bottom: 10px;}
to{margin-bottom: 0;}
}
@media (min-width: 1025px){
.kt-aside--minimize .kt-aside-menu .kt-menu__nav{padding: 15px 0;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-icon{width: 100%;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-bullet{display: none;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text{display: none;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge{padding: 0;position: relative;right: 7px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-badge .kt-badge{text-indent: -9999px;position: relative;padding: 0;min-width: 6px;width: 6px;min-height: 6px;height: 6px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__ver-arrow{display: none;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__submenu{display: none !important;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__arrow{display: inline-block;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover{position: relative;z-index: 96;width: 316px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover.kt-menu__item--open{background: transparent;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__link{width: 70px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__link .kt-menu__link-title{display: none;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu{top: 0;bottom: auto;display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;margin-left: 71px !important;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--parent{display: -webkit-box;display: -ms-flexbox;display: flex;margin: 0;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--parent > .kt-menu__link{height: 40px;padding: 0 30px 10px 30px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--parent > .kt-menu__link .kt-menu__link-text{font-weight: 400;font-size: 1.05rem;text-transform: initial;cursor: text !important;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--parent > .kt-menu__link .kt-menu__link-badge{padding: 0px 0px 0px 5px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__submenu .kt-menu__subnav > .kt-menu__item.kt-menu__item--parent + .kt-menu__item{margin-top: 0px;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover > .kt-menu__arrow{display: none;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__section{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-text{display: none;}
.kt-aside--minimize .kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-icon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0;-webkit-animation: kt-animate-fade-in .7s;animation: kt-animate-fade-in .7s;}
}
.kt-aside-close{display: none;}
@media (max-width: 1024px){
.kt-aside{z-index: 1001;position: fixed;-webkit-overflow-scrolling: touch;top: 0;bottom: 0;overflow-y: auto;-webkit-transform: translate3d(0, 0, 0);backface-visibility: hidden;-webkit-backface-visibility: hidden;width: 275px !important;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: -295px;}
.kt-aside.kt-aside--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 0;}
}
@media screen\0  and (max-width: 1024px){
.kt-aside{-webkit-transition: none !important;transition: none !important;}
}
@media (max-width: 1024px){
.kt-aside--right .kt-aside{right: -295px;left: auto;}
.kt-aside--right .kt-aside.kt-aside--on{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;right: 0;left: auto;}
.kt-aside-close{width: 25px;height: 25px;top: 1px;z-index: 1002;-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;position: fixed;border: 0;-webkit-box-shadow: none;box-shadow: none;border-radius: 3px;cursor: pointer;outline: none !important;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;left: -25px;}
}
@media screen\0  and (max-width: 1024px){
.kt-aside-close{-webkit-transition: none !important;transition: none !important;}
}
@media (max-width: 1024px){
.kt-aside-close > i{line-height: 0;font-size: 1.4rem;}
.kt-aside-close:hover{text-decoration: none;}
.kt-aside--right .kt-aside-close{left: auto;right: -25px;}
.kt-aside--on .kt-aside-close{-webkit-transition: left 0.3s ease, right 0.3s ease;transition: left 0.3s ease, right 0.3s ease;left: 249px;}
.kt-aside--on.kt-aside--right .kt-aside-close{left: auto;right: 249px;}
.kt-aside-overlay{position: fixed;top: 0;left: 0;bottom: 0;right: 0;overflow: hidden;z-index: 1000;background: rgba(0, 0, 0, 0.1);-webkit-animation: kt-animate-fade-in .3s linear 1;animation: kt-animate-fade-in .3s linear 1;}
.kt-aside{background: #ffffff;-webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);}
.kt-aside-overlay{background: rgba(0, 0, 0, 0.1);}
.kt-aside-close{background-color: #e8e8f2;}
.kt-aside-close > i{color: #968ad4;}
.kt-aside-close:hover{background-color: #5d78ff;}
.kt-aside-close:hover > i{color: #ffffff;}
}
@media (max-width: 325px){
.kt-aside{width: 90% !important;}
}
.kt-content{padding: 25px;}
.kt-subheader--enabled.kt-subheader--transparent:not(.kt-subheader--fixed) .kt-content{padding-top: 0;}
@media (min-width: 1025px){
.kt-footer--fixed .kt-content{padding-bottom: 60px;}
}
@media (max-width: 1024px){
.kt-content{padding: 15px;}
.kt-subheader--enabled.kt-subheader--transparent .kt-content{padding-top: 0;}
}
.kt-footer{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;padding: 20px 25px;background: #ffffff;}
.kt-footer .kt-footer__copyright{display: -webkit-box;display: -ms-flexbox;display: flex;margin: 0;padding: 0 1.25rem 0 0;font-size: 1rem;font-weight: 400;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;color: #a7abc3;}
.kt-footer .kt-footer__copyright .kt-link{color: #a7abc3;}
.kt-footer .kt-footer__copyright .kt-link:hover{color: #5d78ff;}
.kt-footer .kt-footer__copyright .kt-link:hover:after{border-bottom: 1px solid #5d78ff;opacity: 0.3;}
.kt-footer .kt-footer__menu{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;padding: 0 0 0 0.75rem;margin: 0;}
.kt-footer .kt-footer__menu .kt-footer__menu-link{margin: 0 0 0 1.25rem;padding: 0;font-size: 1rem;font-weight: 400;color: #6c7293;}
.kt-footer .kt-footer__menu .kt-footer__menu-link:hover{color: #5d78ff;}
.kt-footer .kt-footer__menu .kt-footer__menu-link:hover:after{border-bottom: 1px solid #5d78ff;opacity: 0.3;}
@media (min-width: 1025px){
.kt-footer--fixed .kt-footer{position: fixed;-webkit-transition: all 0.3s ease;transition: all 0.3s ease;height: 60px;bottom: 0;right: 0;left: 265px;-webkit-box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);}
.kt-aside--minimize-hover.kt-footer--fixed .kt-footer, .kt-aside--minimize.kt-footer--fixed .kt-footer{-webkit-transition: all 0.3s ease;transition: all 0.3s ease;left: 70px;}
}
@media (max-width: 1024px){
.kt-footer{padding: 15px 15px;}
}
@media (max-width: 768px){
.kt-footer{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.kt-footer .kt-footer__copyright{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;padding: 0;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.kt-footer .kt-footer__menu{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;padding: 0;margin: 0 0 0.75rem 0;}
.kt-footer .kt-footer__menu .kt-footer__menu-link:first-child{padding-left: 0;margin-left: 0;}
}
